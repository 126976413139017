
export function storeState(key: string, value: any) {
    try {
        sessionStorage[key] = JSON.stringify(value)
    } catch (error) {

    }
}

export function restoreState(key: string) {
    try {
        const state = JSON.parse(sessionStorage[key]) || false
        return state
    } catch (error) {

    }
    return false
}