import { t } from 'i18next'
import * as React from 'react'
import * as ShareButton from 'react-share'

export default class ModFindShare extends React.Component<{
    url: string
    copiedLink: () => void;
}> {

    render() {
        const { url } = this.props
        return (
            <div>
                <span className="modfind-share-title">{t("Messages.SHARE")}</span>
                <ShareButton.FacebookShareButton url={url} className="modfind-share-button">
                    <ShareButton.FacebookIcon size={32} round />
                </ShareButton.FacebookShareButton>
                <ShareButton.TwitterShareButton url={url} className="modfind-share-button">
                    <ShareButton.TwitterIcon size={32} round />
                </ShareButton.TwitterShareButton>
                <ShareButton.EmailShareButton url={url} className="modfind-share-button">
                    <ShareButton.EmailIcon size={32} round />
                </ShareButton.EmailShareButton>
                <ShareButton.WhatsappShareButton url={url} className="modfind-share-button">
                    <ShareButton.WhatsappIcon size={32} round />
                </ShareButton.WhatsappShareButton>

                <div className="share-link-container">
                    <span className="link">{this.props.url}</span>
                    <span className="copy"><a href='#' className="copy-ref" onClick={() => {
                        const el = document.createElement('textarea');
                        el.value = this.props.url;
                        el.setAttribute('readonly', '');
                        el.style.position = 'absolute';
                        el.style.left = '-9999px';
                        document.body.appendChild(el);
                        el.select();
                        document.execCommand('copy');
                        document.body.removeChild(el);
                        this.props.copiedLink()
                    }}>{t("Messages.COPY_LINK")}</a></span>
                </div>
            </div>
        )
    }
}
