import * as React from "react";
import { GenericDropDown, GenericDropDownProps } from "./GenericDropDown";
import { Category } from "fullcircle-api";
import { Meta } from "../../cache/meta";

export interface CategoryDropDownProps extends GenericDropDownProps<Category> {
}

export class CategoryDropDown extends React.Component<CategoryDropDownProps, {}> {

    constructor(props: CategoryDropDownProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <GenericDropDown apiFunc={Meta.getCategories} {...this.props} />
    }
}