import React from 'react';
import { formatPriceI18n } from '../../utils/formater';
import { Link } from 'react-router-dom';
import { IC_CARS_SALE, IC_FEATURED_STAR, IC_HEART_FILLED, IC_HEART } from '../../assets';
import { getPostUrl } from '../../utils/path';
import { isPostFeatured, isPostFeaturedEnding } from '../../utils/post_util';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../../styles/colors';
import { APIError, Post, User } from 'fullcircle-api';
import { Favorite } from '../../cache/favorite';
import { LoginRequiredPopup } from '../popup/LoginRequiredPopup';


export interface ModFindPostListItemProps {
    post: Post;
    currentUser?: User
}

export class ModFindPostListItem extends React.Component<ModFindPostListItemProps, { favoriteLoading: boolean; isFavorite: boolean; }> {

    constructor(props: ModFindPostListItemProps) {
        super(props);
        this.state = {
            favoriteLoading: false,
            isFavorite: false
        };
    }

    componentDidMount() {
        Favorite.isFavoritePost(this.props.post.id).then((favorite) => {
            this.setState({ isFavorite: favorite })
        })
    }

    private likePost() {
        if (!this.props.currentUser) {
            LoginRequiredPopup.show()
            return
        }
        this.setState({ favoriteLoading: true })
        let post = this.props.post
        if (post) {
            if (this.state.isFavorite) {
                Favorite.deleteFavoritePost(post).then(() => {
                    this.setState({ isFavorite: false, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            } else {
                Favorite.addFavoritePost(post).then(() => {
                    this.setState({ isFavorite: true, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            }
        }
    }

    render() {
        const { title, images, price, kickback_price, description_text, user_id, currency } = this.props.post;
        const post_image_url = images && images.length > 0 ? images[0].type === 'image' ? images[0].medium_url : images[0].thumbnail_url : undefined;

        const isFeatured = isPostFeatured(this.props.post);
        const isMyPost = this.props.currentUser && this.props.currentUser.id == user_id;
        const isFeaturedEnding = isMyPost && isPostFeaturedEnding(this.props.post);

        return (
            <React.Fragment>
                <hr className="list-hr" />
                <div className="list-item">
                    <Link to={getPostUrl(this.props.post)} style={{ width: "100%" }}>
                        <div className="list-item-container">
                            <div className={"img-container"}>
                                <div className={"img-ratio" + (isFeatured ? ' featured' : '') + (isFeaturedEnding ? ' expiring' : '')}>
                                    <img src={post_image_url || IC_CARS_SALE} alt={`Image_${title}`} className={"list-item-img" + (isFeatured ? ' featured' : '') + (isFeaturedEnding ? ' expiring' : '')} />
                                    {isFeatured && <img src={IC_FEATURED_STAR} className='featured-icon' />}
                                </div>
                            </div>
                            <div className="list-item-info-container">
                                <span className="list-item-name">{title}</span>
                                <span className="list-item-price">{formatPriceI18n(price, currency)}</span>
                                {kickback_price && <span className="list-item-kickback">{formatPriceI18n(kickback_price, currency) + " Kickback"}</span>}
                                <div className="list-item-desc">{description_text}</div>
                                <div className='watch'>
                                    {this.state.favoriteLoading ? <ClipLoader
                                        color={Colors.DODGER_BLUE}
                                        size={15} /> : <a href='#' onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.likePost()
                                        }}>
                                        <img src={this.state.isFavorite ? IC_HEART_FILLED : IC_HEART} width={24} className={'watch-img ' + (this.state.isFavorite ? 'watching' : '')} />
                                    </a>}
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}
