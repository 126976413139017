import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import { SeparatorBox } from "../components/generic/Box";
import ModFindInput from "../components/generic/ModFindInput";
import Button from "../components/generic/Button";
import { Popup } from "semantic-ui-react";
import { UserContextType } from "../context/user";
import { t } from "i18next";
import { UsersAPI } from "fullcircle-api";
import { errorToString } from "../utils/helper";
import ModFindPopUp, { TitleMessageContent } from "../components/popup/ModFindPopUp";
import { useHistory } from "react-router-dom";
import * as toastr from 'toastr'

export function BecomeAmbassador() {

    const user = useContext(UserContextType)
    const [fullName, setFullName] = useState(user?.full_name || '')
    const [email, setEmail] = useState(user?.email || '')
    const [phone, setPhone] = useState(user?.phone_number || '')
    const [zip, setZip] = useState(user?.zip || '')

    const [link, setLink] = useState<string>()

    useEffect(() => {
        UsersAPI.getAmbassadorData().then(({ link }) => {
            setLink(link)
        })
    }, [])

    const [submitting, setSubmitting] = useState(false)

    const history = useHistory();

    const isFormValid = useMemo(() => {
        if (!fullName?.trim() || !email?.trim() || !phone?.trim() || !zip?.trim()) {
            return t('Messages.Signup.NotCorrectSignInData')
        }
        return true
    }, [fullName, email, phone, zip])

    function renderPayoutPopup() {
        if (link) {
            return (
                <ModFindPopUp
                    onClose={() => {
                        history.push('/')
                    }}>
                    <TitleMessageContent
                        icon={'success'}
                        title={t("Messages.AMBASSADOR_CONGRATS")}
                        message={<>
                            <div className="link">{link}</div>
                            <div>{t("Messages.AMBASSADOR_COPY")}</div>
                        </>}
                        buttons={[{
                            text: 'Copy',
                            onClick: () => {
                                navigator.clipboard?.writeText(link);
                                toastr.success(t("Messages.COPIED"))
                            }
                        }]}
                    />
                </ModFindPopUp>
            )
        }
        return null
    }

    return <Container>
        <div className='become-dealer-vendor action-list'>
            {renderPayoutPopup()}
            <h3 style={{ marginTop: 20, textAlign: 'center', marginBottom: '2rem' }}>{t("Messages.AMBASSADOR_REGISTRATION")}</h3>
            <SeparatorBox direction='row'>
                <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                    {t("Messages.FULL_NAME")}*
                </div>
                <div className='item'>
                    <ModFindInput
                        disabled={submitting}
                        placeholder={t("Messages.FULL_NAME")}
                        colored
                        rounded
                        style={{ maxWidth: 300 }}
                        value={fullName}
                        onValueChanged={(value) => {
                            setFullName(value)
                        }} />
                </div>
            </SeparatorBox>

            <SeparatorBox direction='row'>
                <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                    {t("Messages.EMAIL")}*
                </div>
                <div className='item'>

                    <ModFindInput
                        disabled={submitting}
                        placeholder={t("Messages.EMAIL")}
                        colored
                        rounded type={'email'}
                        style={{ marginTop: 12 }}
                        value={email}
                        onValueChanged={(value) => {
                            setEmail(value)
                        }} />
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                    {t("Messages.PHONE")}*
                </div>
                <div className='item'>
                    <ModFindInput
                        disabled={submitting}
                        placeholder={t("Messages.PHONE")}
                        colored
                        rounded
                        style={{ marginTop: 12 }}
                        value={phone}
                        onValueChanged={(value) => {
                            setPhone(value)
                        }} />
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                    {t("Messages.ZIP_CODE")}*
                </div>
                <div className='item'>
                    <ModFindInput
                        disabled={submitting}
                        placeholder={t("Messages.ZIP_CODE")}
                        colored
                        rounded
                        style={{ marginTop: 12 }}
                        value={zip}
                        onValueChanged={(value) => {
                            setZip(value)
                        }} />
                </div>
            </SeparatorBox>

            <div style={{ marginTop: 20 }}>
                <Popup content={isFormValid == true ? undefined : isFormValid} disabled={isFormValid === true} trigger={<Button size='lg'
                    rounded disabled={isFormValid !== true} loading={submitting}
                    text={t("Messages.SUBMIT_APPLICATION")} onClick={() => {
                        setSubmitting(true);
                        UsersAPI.becomeAmbassador(fullName, zip, email, phone).then((u) => {
                            setLink(u.link)
                        }).catch(err => {
                            toastr.error(errorToString(err).join('\n'))
                            setSubmitting(false)
                        })
                    }} />} />
            </div>
        </div>
    </Container>
}
