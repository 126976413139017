import { DeviceData } from 'fullcircle-api/lib/endpoints/devices';
import Fingerprint2 from 'fingerprintjs2';
import { Device, DevicesAPI, Session, UsersAPI, Post, Category, User } from 'fullcircle-api';
import { UserSession } from './session';
import { BaseModel } from 'fullcircle-api/lib/models/base';
import { DeliveryMethod } from 'fullcircle-api/lib/models/post';

export function preventBodyScroll(prevent: boolean) {
    if (prevent) {
        document.body.classList.add('noscroll')
    } else {
        document.body.classList.remove('noscroll')
    }
}

export function getImageOrientation(file: File, callback: Function) {
    var reader = new FileReader();

    reader.onload = (event: ProgressEvent) => {

        if (!event.target) {
            return;
        }

        const file = event.target as FileReader;
        const view = new DataView(file.result as ArrayBuffer);

        if (view.getUint16(0, false) !== 0xFFD8) {
            return callback(-2);
        }

        const length = view.byteLength
        let offset = 2;

        while (offset < length) {
            if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
            let marker = view.getUint16(offset, false);
            offset += 2;

            if (marker === 0xFFE1) {
                if (view.getUint32(offset += 2, false) !== 0x45786966) {
                    return callback(-1);
                }

                let little = view.getUint16(offset += 6, false) === 0x4949;
                offset += view.getUint32(offset + 4, little);
                let tags = view.getUint16(offset, little);
                offset += 2;
                for (let i = 0; i < tags; i++) {
                    if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                        return callback(view.getUint16(offset + (i * 12) + 8, little));
                    }
                }
            } else if ((marker & 0xFF00) !== 0xFF00) {
                break;
            }
            else {
                offset += view.getUint16(offset, false);
            }
        }
        return callback(-1);
    };

    reader.readAsArrayBuffer(file);
}

export function rotateImg(orientation: number): string {
    switch (orientation) {
        case 8:
            return '270'
        case 3:
            return '180'
        case 6:
            return '90'
        default:
            return '0'
    }
}

export function validateEmail(email?: string) {
    if (!email) {
        return false
    }
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function includesEmail(text: string) {
    return /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g.test(text)
}

export function includesPhoneNumber(text: string) {
    return /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/g.test(text)
}

export function getDefaultDevice(debug: boolean, language: string): Promise<DeviceData> {
    return new Promise((resolve) => {
        Fingerprint2.getV18((result: string) => {
            let t: DeviceData = {
                app_version: '1.5-' + require('../../package.json').version,
                token_provider: 'web',
                device_uuid: result,
                device_data_os: 'web',
                debug: debug,
                language: language
            }
            resolve(t)
        })
    })
}

export function registerDevice(refresh_token: string): Promise<Device> {
    return new Promise<Device>(
        function (resolve, reject) {
            getDefaultDevice(DEV, navigator.language).then((deviceData) => {
                DevicesAPI.registerDevice(deviceData, refresh_token).then((device) => {
                    device.user.refresh_token = refresh_token;
                    Session.setSession(device.session_token);
                    return UsersAPI.getUser('me').then(user => {
                        UserSession.getSessionUpdatedHandler()({
                            user,
                            session: device,
                            refresh_token
                        })
                        resolve(device)
                    }).catch(() => resolve(device))
                }).catch((err) => {
                    reject(err)
                })
            }).catch((err) => {
                reject(err)
            })
        }
    )
}

export interface RemoteFile {
    id: string
    url: string,
    _fc_remote: boolean
}

export interface RemoteImage {
    file: RemoteFile
    position: number
    type: 'image' | 'video'
}

export function isRemoteFile(file: File | RemoteFile): file is RemoteFile {
    return '_fc_remote' in file
}

export function isCategory(item: BaseModel & { __typename?: string }): item is Category {
    return item.__typename == 'Category'
}

export function isUser(item: BaseModel & { __typename?: string }): item is User {
    return item.__typename == 'User'
}

export function isPost(item: BaseModel & { __typename?: string }): item is Post {
    return item.__typename == 'Post'
}

export function errorToString(err: { type?: string, message: string }, defaultMessage = 'An unhandled error occurred. Please try again later.'): Array<string> {
    if (err && err.type == 'NotVerifiedError') {
        return []
    } else if (err && err.type == 'InvalidSubscriptionError') {
        return []
    } else if (err && err.type == 'InvalidParametersError') {
        return Object.keys((err as any).invalid_keys).map((key) => {
            return snakeToPascal(key) + ': ' + (err as any).invalid_keys[key]
        })
    } else if (err && err.type == 'ResourceAlreadyExists' || err && err.message) {
        return [err.message]
    } else {
        return [defaultMessage]
    }
}

export function snakeToPascal(string: string) {
    function _upperFirst(string: string) {
        return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
    }
    return string.split('_').map((str) => {
        return _upperFirst(
            str.split('/')
                .map(_upperFirst)
                .join('/'));
    }).join(' ');
}

export function getPostImageUrl(post?: Post) {
    if (post && post.images && post.images.length) {
        return post.images[0].thumbnail_url
    }
    return undefined
}

export const DEV = process.env.REACT_APP_ENV !== 'production' ? true : false


export function hasShipping(post: Post | undefined) {
    if (post) {
        return post.delivery_method !== DeliveryMethod.PICKUP
    }
    return false
}
export function removeUndefined(obj: any) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === undefined)
            delete obj[key]
    })
    return obj
}
