import * as React from 'react'

interface ModFindToastProps {
    position: 'left' | 'right'
}

export default class ModFindToast extends React.Component<ModFindToastProps, {
    visible: boolean,
    msg: string
    err: boolean
}> {

    constructor(props: ModFindToastProps) {
        super(props);
        this.state = {
            visible: false,
            msg: '',
            err: false
        }
    }

    render() {
        const { visible, err, msg } = this.state;
        const { position } = this.props;
        return (
            <div className={`modfind-toast ${visible ? ' visible' : ''} ${err ? ' error' : ''} ${position}`}>
                <span className="msg">{msg}</span>
            </div>
        )
    }


    public show(msg: string, err?: boolean) {
        this.setState({visible: true, msg, err: err || false}, () => {
            setTimeout(() => {
                this.setState({visible: false})
            }, 3000)
        })
    }

}