
function usesMetricSystem() {
    return navigator.language != 'en-US'
}

export function lengthUnit() {
    if (usesMetricSystem()) {
        return 'cm'
    }
    return 'in'
}


export function weightUnit() {
    if (usesMetricSystem()) {
        return 'kg'
    }
    return 'lb'
}

export function systemToMetricLength(length?: string) {
    if (length) {
        const parsed = parseFloat(length)
        if (!Number.isNaN(parsed)) {
            if (!usesMetricSystem()) {
                return parsed * 2.54
            }
            return parsed
        }
    }
    return undefined
}

export function systemToMetricWeight(weight?: string) {
    if (weight) {
        const parsed = parseFloat(weight)
        if (!Number.isNaN(parsed)) {
            if (!usesMetricSystem()) {
                return parsed * 0.453592
            }
            return parsed
        }
    }
    return undefined
}

export function metricToSystemLength(length?: string) {
    if (length) {
        const parsed = parseFloat(length)
        if (!Number.isNaN(parsed)) {
            if (!usesMetricSystem()) {
                return toFixed(parsed / 2.54)
            }
            return toFixed(parsed)
        }
    }
    return undefined
}

export function metricToSystemWeight(weight?: string) {
    if (weight) {
        const parsed = parseFloat(weight)
        if (!Number.isNaN(parsed)) {
            if (!usesMetricSystem()) {
                return toFixed(parsed / 0.453592)
            }
            return toFixed(parsed)
        }
    }
    return undefined
}

function toFixed(num: number, fixed: number = 2) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)![0];
}