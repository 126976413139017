import { t } from "i18next";
import React from "react";
import ModFindPopUp, { TitleMessageContent } from "./popup/ModFindPopUp";


export class Alert extends React.Component<{}, { message: string, onOk: () => void, onCancel?: () => void, show: boolean }> {

    private static _instance: Alert;

    constructor(props: any) {
        super(props);
        this.state = {
            message: '',
            onCancel: () => { },
            onOk: () => { },
            show: false
        };
        Alert._instance = this;
    }

    render() {
        if (!this.state.show) {
            return null;
        }
        return <ModFindPopUp onClose={() => this.setState({ show: false })}>
            <TitleMessageContent
                title={''}
                message={this.state.message}
                buttons={[this.state.onCancel ? {
                    text: t("Messages.CANCEL"), onClick: () => {
                        this.state.onCancel!()
                        this.setState({ show: false })
                    }
                } : undefined!,
                {
                    text: t("Messages.OK"), onClick: () => {
                        this.state.onOk()
                        this.setState({ show: false })
                    }
                }].filter(Boolean)} />
        </ModFindPopUp>
    }

    static show(message: string, onOk: () => void, onCancel?: () => void) {
        this._instance.setState({ message, onCancel, onOk, show: true })
    }

}