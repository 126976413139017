import { Post, User } from "fullcircle-api";
import MemoryCache from 'lscache'

enum CacheKeys {
    CHATS = 'chats',
    USERS = 'users'
}

/**
 * This interface defines a chat message
 */
export interface Message {
    id: string;
    content: string;
    chat_id: string;
    //chat: Chat
    user: string;
    type: 'text' | 'image' | 'video';
    read: boolean;
    created_at: Date;
    updated_at: Date;
}

/**
 * This interface defines a chat
 */
export interface Chat {
    id: string;
    post_id: string;
    post: Post;
    messages: Array<Message>
    users: Array<User>
}

export function sortMessages(messages: Message[]) {
    return messages.sort((i1, i2) => {
        return new Date(i1.created_at).getTime() - new Date(i2.created_at).getTime()
    })
}

export class ChatDatabase {

    private _chats: Chat[] = [];
    private get chats(): Chat[] {
        return this._chats;
    }
    private set chats(value: Chat[]) {
        this._chats = value;
        this.storeChats()
    }

    private _users: User[] = [];
    private get users(): User[] {
        return this._users;
    }
    private set users(value: User[]) {
        this._users = value;
        this.storeUsers()
    }

    get messages(): Message[] {
        return this.chats.reduce((prev, curr) => {
            prev.push(...curr.messages)
            return prev
        }, new Array<Message>())
    }

    private sortChats(chats: Chat[]) {
        return chats.sort((ch1, ch2) => {
            const m1 = sortMessages(ch1.messages)
            const m2 = sortMessages(ch2.messages)
            if (m1.length == 0) {
                return 1
            }
            if (m2.length == 0) {
                return -1
            }
            return new Date(m2[0].created_at).getTime() - new Date(m1[0].created_at).getTime()
        })
    }

    private storeChats() {
        MemoryCache.set(CacheKeys.CHATS, this.sortChats(this.chats))
    }

    private storeUsers() {
        MemoryCache.set(CacheKeys.USERS, this.users)
    }

    constructor(userId: string) {
        MemoryCache.setBucket(userId)
        this.chats = this.sortChats(MemoryCache.get(CacheKeys.CHATS) || [])
        this.users = MemoryCache.get(CacheKeys.USERS) || []
    }

    /**
     * Returns the latest updated_at timestamp from the DB
     * @returns {Date | null}
     */
    public latestDate(): Date | null {
        let results = this.messages.sort((m1, m2) => new Date(m2.updated_at).getTime() - new Date(m1.updated_at).getTime())
        if (results.length < 1) {
            return null;
        } else {
            return results[0].created_at;
        }
    }

    private findOrCreateChat(chat: Chat) {
        let index = this.chats.findIndex(c => c.id == chat.id)
        if (index > -1) {
            this.chats[index] = Object.assign({}, chat, { messages: this.chats[index].messages });
        } else {
            index = this.chats.push(chat) - 1
        }
        return this.chats[index]
    }

    private findOrCreateUser(user: User) {
        let index = this.users.findIndex(c => c.id == user.id)
        if (index > -1) {
            this.users[index] = user
        } else {
            index = this.users.push(user) - 1
        }
        return this.users[index]
    }

    private findOrCreateMessage(chat: Chat, message: Message) {
        let index = chat.messages.findIndex(c => c.id == message.id)
        if (index > -1) {
            chat.messages[index] = message
        } else {
            index = chat.messages.push(message) - 1
        }
        return chat.messages[index]
    }

    /**
     * Saves a message to the database
     * @returns {Message}
     * @param inputdata
     */
    public saveMessages(inputdata: any[]): Message[] {
        const messages: Message[] = [];
        inputdata.forEach((data) => {
            if (!data.chat) {
                return;
            }
            const users: User[] = data.chat.users.map((userData: any) => {
                return this.findOrCreateUser(userData)
            });

            const chat = this.findOrCreateChat({
                id: data.chat_id,
                post_id: data.chat.post_id,
                users: users,
                post: data.chat.post,
                messages: []
            })
            messages.push(this.findOrCreateMessage(chat, {
                id: data.id,
                content: data.content,
                type: data.type,
                user: data.user_id,
                chat_id: chat.id,
                created_at: new Date(data.created_at),
                updated_at: new Date(data.updated_at),
                read: data.read
            }))
        })

        this.storeChats()
        this.storeUsers()
        return messages;
    }

    public getChats(): Array<Chat> {
        return this.chats
    }

    public deleteChat(chat: Chat) {
        this.chats = this.chats.filter(c => c.id != chat.id)
        this.storeChats()
    }

    public getMessages(): Array<Message> {
        return this.messages
    }

    public markMessagesRead() {
        this.messages.forEach(m => {
            m.read = true
            return m
        })
        this.storeChats()
    }
}
