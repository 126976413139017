import { Category } from 'fullcircle-api';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import ModFindGrid from '../components/grid/ModFindGrid';
import { UserContextType } from '../context/user';
import { encodeName } from '../utils/slug';


export function CategoriesPage() {

    const history = useHistory()
    const user = React.useContext(UserContextType)

    return <Container>
        <Row id={'categories'} className='home-padding'>
            <ModFindGrid type={"CATEGORIES"} onItemClick={(item) => {
                history.push('/category/' + encodeName((item as Category).name))
            }} currentUser={user} />
        </Row>
    </Container>
}