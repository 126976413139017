import * as React from 'react'
import { Post, PostsAPI, User, UsersAPI, PostStatus, MetaAPI, DeliveryAPI, DeliveryRequest, PostSortOrder } from 'fullcircle-api';
import { useLocation, Redirect, RouteComponentProps, Switch, Route } from 'react-router';
import { Container } from 'reactstrap';
import { Image } from 'fullcircle-api/lib/models/image';
import { formatPriceI18n, formatRelativeDate } from '../utils/formater';
import Button from '../components/generic/Button';
import { IC_TIME, IC_LOCATION_MARKER, IC_VIEW } from '../assets';
import ModFindTextarea from '../components/generic/ModFindTextArea';
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContextConsumer, UserContextType } from '../context/user';
import ChatHandler from '../api/chathandller';
import { Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { buildCarModelStringWithYear } from '../api/model_helper';
import { LoginRequiredPopup } from '../components/popup/LoginRequiredPopup';
import { ContentViewType } from 'fullcircle-api/lib/endpoints/metadata';
import { Favorite } from '../cache/favorite';
import { getUserUrl, getPostUrl } from '../utils/path';
import { Colors } from '../styles/colors';
import { Helmet } from 'react-helmet';
import ReactImageMagnify from 'react-image-magnify';
import { getLocationDetails, LocationDetails, getGeoData } from '../utils/location';
import ModFindReportPopup from '../components/popup/ModFindReportPopup';
import { Content } from '../api/events';
import { DeliveryMethod, PostType } from 'fullcircle-api/lib/models/post';
import PaymentMethods from '../components/views/payment/PaymentMethods';
import { DeliveryInteraction } from '../components/views/post-detail/DeliveryInteraction';
import { PostActionButtons } from '../components/views/post-detail/PostActionButtons';
import { calculateTotal, compareVariantArray, sortDeliveryRequests } from '../utils/post_util';
import { t } from 'i18next';
import PostCarousel from '../components/carousel/PostCarousel';
import { GenericDropDown } from '../components/dropdown/GenericDropDown';
import { DeliveryState } from "../pages/Checkout"
import { Alert } from '../components/Alert'
export const PostDetail: React.FC = () => {

    let query = new URLSearchParams(useLocation().search)
    let postId = query.get("postId")
    if (!postId) {
        return <Switch>
            <Route exact path={'/post/:postId'} render={(props: RouteComponentProps<{ postId: string }>) => {
                const { postId } = props.match.params
                return <PostDetailInner key={postId}
                    postId={postId}
                />
            }} />
            <Route render={() => {
                return <Redirect to='/' />
            }} />
        </Switch>
    }
    return (
        <PostDetailInner key={postId}
            postId={postId}
        />
    )
}

export interface PostDetailInnerProps extends RouteComponentProps {
    postId: string
}

const PostDetailInner = withRouter(class extends React.Component<PostDetailInnerProps, {
    post?: Post
    deliveryRequests?: DeliveryRequest[];
    seller?: User
    loading: boolean
    currImgIndex: number
    currency?: string,
    isFavorite?: boolean
    favoriteLoading: boolean,
    kickbackLoading: boolean,
    showSharePopUp: boolean,
    kickbackLink?: string,

    chatMessage?: string,
    chatSending: boolean,
    chatPopupMessage?: string,

    ratePopup: boolean

    related?: Post[]
    otherSelling?: Post[]

    smImgSize: {
        width: number,
        height: number
    }

    locationTitle?: string
    mobile: boolean;
    showReportPopup: boolean

    online: boolean;

    selectedVariants?: { [key: string]: string }

}> {
    private imageMagnifyContainer?: HTMLDivElement | null;

    context: User | undefined;
    static contextType = UserContextType;

    constructor(props: PostDetailInnerProps) {
        super(props)
        this.state = {
            loading: true,
            currImgIndex: 0,
            favoriteLoading: false,
            kickbackLoading: false,
            showSharePopUp: false,
            chatSending: false,
            ratePopup: false,
            smImgSize: {
                width: 0,
                height: 0
            },
            showReportPopup: false,
            mobile: false,
            online: false,
            currency: this.context?.currency,
            selectedVariants: {}
        }
        this.setSmImageSize = this.setSmImageSize.bind(this)
        this.onlineListener = this.onlineListener.bind(this)
    }


    onlineListener({ online }: Content.Incoming.OnlineStatusDidChange) {
        this.setState({ online })
    }

    componentDidMount() {
        this.checkMobile()

        this.loadRelated(this.props.postId)
        if (!this.state.currency) {
            getGeoData().then((data) => {
                this.setState({ currency: data.currency }, () => {
                    this.getPost()
                })
            }).catch(() => {
                this.getPost()
            })
        } else {
            this.getPost()
        }

        this.setSmImageSize()

        window.addEventListener('resize', () => {
            this.setSmImageSize
        })
    }

    componentWillUnmount() {
        ChatHandler.instance() && ChatHandler.instance()?.unsubscribeOnlineStatus(this.context!.id, this.onlineListener)
    }

    checkMobile() {
        if (window.innerWidth < 800) {
            this.setState({ mobile: true })
        }
        else this.setState({ mobile: false })
    }



    private loadRelated(postId: string) {
        PostsAPI.getRelatedPosts(postId).then((result) => {
            let filtered = result.filter((val) => {
                if (val.id !== postId) return true;
            });
            this.setState({ related: filtered });
        }).catch(() => {
        });
    }

    private getPost() {
        const { postId } = this.props
        if (postId) {
            MetaAPI.trackContentView(ContentViewType.Post, postId).catch(() => { });
            Favorite.isFavoritePost(postId).then(isFavorite => {
                this.setState({ isFavorite })
            })
            let kickback: string | undefined = undefined
            if (typeof mf_current_kickback_id === 'object') {
                kickback = mf_current_kickback_id[postId]
                try {
                    sessionStorage['currentKickBack'] = JSON.stringify(mf_current_kickback_id)
                } catch (error) {

                }
            }
            Promise.all([
                DeliveryAPI.getDeliveriesForPost(postId).catch(err => undefined),
                PostsAPI.getPost(postId, kickback, this.context?.currency),
            ]).then(([deliveryRequests, post]) => {
                this.setState({
                    post,
                    loading: false,
                    deliveryRequests: sortDeliveryRequests(deliveryRequests || [], this.context!),
                    locationTitle: post!.location_pickup_state
                }, () => {
                    this.setSmImageSize()
                })
                if (post!.user) {
                    this.setState({ seller: post!.user })
                    if (post!.user !== this.context?.id) {
                        ChatHandler.instance()?.subscribeOnlineStatus(this.state.seller!.id, this.onlineListener).then(({ online }) => {
                            this.setState({ online })
                        })
                    }
                }
                else {
                    UsersAPI.getUser(post!.user_id).then((user) => {
                        this.setState({ seller: user })
                    }).catch((err) => {

                    })
                }
                if (post && post.estimated_location && post.location_pickup_state) {
                    let thisLocation: LocationDetails = {
                        formatted_address: post!.location_pickup_state,
                        location: { latitude: post!.estimated_location.latitude, longitude: post!.estimated_location.longitude },
                        city: post!.location_pickup_state
                    }
                    getLocationDetails(thisLocation.location.latitude, thisLocation.location.longitude).then(location => {
                        if (location) {
                            const titleArr = [location.city, location.state]
                            if (post!.region != this.context?.region && location.country) {
                                titleArr.push(location.country)
                            }
                            const title = titleArr.filter(Boolean).unique().join(', ')
                            title.trim().length > 1 && this.setState({ locationTitle: title })
                        } else if ((post.user as User)?.city || (post.user as User)?.state) {
                            const titleArr = [(post.user as User).city, (post.user as User).state]
                            if (post!.region != this.context?.region && (post.user as User)?.region) {
                                titleArr.push((post.user as User)?.region)
                            }
                            const title = titleArr.filter(Boolean).unique().join(', ')
                            title.trim().length > 1 && this.setState({ locationTitle: title })
                        }
                    })
                } else if (post && ((post.user as User)?.city || (post.user as User)?.state)) {
                    const titleArr = [(post.user as User).city, (post.user as User).state]
                    if (post.region != this.context?.region && (post.user as User)?.region) {
                        titleArr.push((post.user as User)?.region)
                    }
                    const title = titleArr.filter(Boolean).unique().join(', ')
                    title.trim().length > 1 && this.setState({ locationTitle: title })
                }

                PostsAPI.searchPosts(PostSortOrder.Newest, 20, 0, {
                    user: post!.user_id,
                    ignore_sold_date: true
                }).then(({ posts }) => {
                    this.setState({ otherSelling: posts.filter(p => p.id != postId) });
                }).catch(() => { })
            }).catch((err) => {
                this.setState({ loading: false })
            })
        }

    }

    private onPreviewImageHover(index: number) {
        if (index !== this.state.currImgIndex) {
            const changedFromVideoToImage = this.state.post?.images![this.state.currImgIndex].type == 'video' && this.state.post?.images![index].type == 'image'
            this.setState({ currImgIndex: index }, () => {
                if (changedFromVideoToImage) {
                    this.setSmImageSize()
                }
            })
        }
    }

    render() {
        const { post, selectedVariants } = this.state
        const variantQuantity = post?.variants?.quantities.find((price) => {
            return compareVariantArray(Object.values(selectedVariants || {}), price.options, post?.variants?.variants.length);
        })
        return (
            <Container style={{ padding: "15px 0px" }}>
                {this.state.showReportPopup && <ModFindReportPopup
                    postId={this.props.postId}
                    userId={this.state.post && this.state.post.user_id}
                    onClose={() => this.setState({ showReportPopup: false })}
                />}
                {this.state.loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ClipLoader
                            color={'#000'}
                            size={35}
                        />
                    </div>
                    :
                    post ?
                        <div>
                            <div className="post-container">
                                <Helmet>
                                    <title>{post.title} | ModFind</title>
                                </Helmet>
                                <span className='post-title responsive'>{post.title}</span>

                                <div id='post-images-container' className="post-images-container">
                                    {post.images && post.images.length > 0 ?
                                        this.renderImageOrVideo(post.images[this.state.currImgIndex])
                                        :
                                        <img alt={'Image_placeholder'} />
                                    }
                                    {post.images && post.images.map((image, index) => {
                                        return (
                                            <img
                                                key={`Image_${image.id}`}
                                                src={image.type === 'image' ? image.image_url : image.thumbnail_url}
                                                alt={`Image_${image.id}`}
                                                className="preview-image"
                                                onMouseOver={() => this.onPreviewImageHover(index)}
                                            />
                                        )
                                    })}
                                </div>
                                <div className="post-info-container ">
                                    <span className='post-title'>{post.title}</span>
                                    {post.type != PostType.Single && <span>
                                        <span>{variantQuantity?.quantity || post.quantity} {t("Messages.AVAILABLE")}</span>
                                    </span>}
                                    {this.renderPricecInfo(post)}
                                    {this.renderDescrptionAndType(post)}
                                    <div className="interaction-container">
                                        <UserContextConsumer>{user => {
                                            return this.renderInteractions(post, user)
                                        }}
                                        </UserContextConsumer>
                                    </div>
                                    <PaymentMethods backgroundColor="white" />
                                    <div className="separator" />
                                    {this.state.seller && this.renderSellerChatContainer(post, this.state.seller)}
                                </div>
                            </div>
                            {this.renderRelatedItems()}
                            {this.renderOtherSellingItems()}
                        </div>
                        : null
                }
            </Container>
        )
    }

    renderInteractions(post: Post, user?: User) {
        const myPost = user && post.user_id == user.id;
        const hideBuyEditButton = (!myPost && [PostStatus.Sold, PostStatus.Pending, PostStatus.PrivateSold].includes(post.status)) || post.status == PostStatus.Sold
        const elementsRoRender = new Array<React.ReactChild>()
        if (!hideBuyEditButton) {
            elementsRoRender.push(<PostActionButtons key={'actions'} post={post} history={this.props.history} selectedVariants={this.state.selectedVariants} />)
        }
        if (this.state.deliveryRequests?.length == 0 && !myPost && post.status == PostStatus.Sold) {
            elementsRoRender.push(<Button
                key={'sold'}
                size={'sm'}
                rounded style={{ backgroundColor: Colors.GREEN }}
                text={'Sold'}
                disabled
            />)
        }
        elementsRoRender.push(<div className='deliveries' key={'deliveries'}>
            {this.state.deliveryRequests?.map((request, index) => {
                return <DeliveryInteraction key={request.id} deliveryRequest={request} post={post} updateDeliveryRequest={(delivery) => {
                    const requests = this.state.deliveryRequests?.slice() as Array<DeliveryRequest | undefined>
                    requests[index] = delivery
                    this.setState({
                        deliveryRequests: requests.filter(Boolean) as Array<DeliveryRequest>
                    })
                }} history={this.props.history} />
            })}
        </div>)
        return elementsRoRender
    }

    private renderPricecInfo(post: Post) {
        return <UserContextConsumer>{(user) => {
            const myPost = user && post.user_id == user.id;
            const hidePrice = !myPost && post.price_on_request
            const status = post.status
            return <>
                <div className="flex">
                    {!hidePrice && <>
                        <span className="price-text" style={{ fontSize: "1.857rem", fontWeight: 800 }}>{formatPriceI18n(calculateTotal(post, DeliveryState.PICK_UP, 1, Object.values(this.state.selectedVariants || {})), post.currency)}</span>
                        {(!post.variants) && post.estimated_price && <span style={{ fontSize: "1.4rem", fontWeight: 600 }}>
                            ≈ {formatPriceI18n(post.estimated_price.converted_price, post.estimated_price.currency)}
                        </span>}
                    </>}
                    {hidePrice && <span className="price-text" style={{ fontSize: "1.857rem", fontWeight: 800 }}>{t("Messages.PRICE_ON_REQUEST")}</span>}
                    {post.delivery_method !== DeliveryMethod.PICKUP && post.shipping_price != undefined && !post.calculate_shipping_price_at_checkout
                        ? <span className="includes-shipping-text">{formatPriceI18n(post.shipping_price, post.currency)} {t("Messages.SHIPPING")}</span>
                        : null}
                </div>
                {status != PostStatus.Sold && status != PostStatus.PrivateSold && post.kickback_price != undefined && <span className="kickback-price">{`${formatPriceI18n(post.kickback_price, post.currency)} Kickback`}</span>}
            </>
        }}</UserContextConsumer>

    }

    private renderImageOrVideo(image: Image) {
        if (image.type === 'video') {
            return (
                <div className="detail-image">
                    <video src={image.image_url} style={{ width: "400px !important", height: "265px !important" }} autoPlay controls poster={image.thumbnail_url} />
                </div>
            )
        } else {
            const imagRatio = this.state.smImgSize.height / this.state.smImgSize.width
            return <div ref={(ref) => this.imageMagnifyContainer = ref} id='detail-image' className='detail-image'>
                <div
                    id="magnify-portal"
                    className="magnify-portal"
                />
                <ReactImageMagnify   {...{
                    className: 'magnify',
                    smallImage: {
                        isFluidWidth: this.state.mobile ? true : false,
                        src: image.image_url,
                        ...this.state.smImgSize
                    },
                    largeImage: {
                        src: image.image_url,
                        width: 1200,
                        height: 1200 * imagRatio
                    },
                    enlargedImagePortalId: 'magnify-portal',
                    enlargedImageContainerDimensions: {
                        width: '200%',
                        height: '200%'
                    },
                    isHintEnabled: this.state.mobile ? false : true,
                    style: { zIndex: 4 }
                }} /></div>
        }
    }

    private renderDescrptionAndType(post: Post) {
        return <>
            {this.renderVariants(post)}
            <span style={{ marginTop: 16, fontSize: "1.143rem", whiteSpace: 'break-spaces' }}>{post.description_text}</span>
            {post.cars?.length > 0 && <div>
                <div className='separator margin' />
                <div className='sub-title'>{t("Messages.MODEL_TYPES")}</div>
                <span>{post.cars.map(buildCarModelStringWithYear).join(', ')}</span>
            </div>}
            <div className='separator margin' />
            {post.item_attributes != undefined && <div className='attributes'>
                {Object.keys(post.item_attributes).map((key) => {
                    return <div key={key}>
                        <div className='attribute-name'>{post.item_attributes![key].title}</div>
                        <span className='attribute-value'>{post.item_attributes![key].value}</span>
                    </div>
                })}
            </div>}
            <div /* className="center" */>
                <div className='post-detail-info'>
                    <div style={{ marginRight: 4 }}>
                        <img src={IC_TIME} />
                        <span>{formatRelativeDate(post.created_at)}</span>
                    </div>
                    <div className={`location-info ${!this.state.locationTitle ? 'hideLoc' : ''}`}>
                        <img src={IC_LOCATION_MARKER} />
                        <span style={{ maxWidth: '10rem' }}>{this.state.locationTitle || ''}</span>
                    </div>
                    <div style={{ marginLeft: 4 }}>
                        <img src={IC_VIEW} />
                        <span>{post.views ? post.views.toString() : '-'}</span>
                    </div>
                </div>
            </div>

        </>
    }

    private renderVariants(post: Post) {
        return <>
            {post.variants != undefined && post.variants.variants.length > 0 && <div>
                <div className='separator margin' />
                <div className='sub-title'>{t("Messages.SELECT_OPTION")}</div>
            </div>}
            {post.variants != undefined && <div className='attributes variants'>
                {post.variants.variants.map((variant) => {
                    const selectionId = this.state.selectedVariants?.[variant.id]
                    const selectedVariant = variant.options.find(o => o.id == selectionId)
                    return <div key={variant.id}>
                        <div className='attribute-name'>{variant.name}</div>
                        <div className='attribute-value'>
                            <GenericDropDown apiFunc={() => Promise.resolve(variant.options)} selected={selectedVariant ? [selectedVariant] : []} onSelectedItemsChange={(items) => {
                                const ids = { ...this.state.selectedVariants, [variant.id]: items[0].id }
                                let disabled = this.state.post?.variants?.disabled.find((price) => {
                                    return compareVariantArray(Object.values(ids), price.options, this.state.post?.variants?.variants.length);
                                }) != undefined
                                if (disabled) {
                                    Alert.show(t("Messages.COMBINATION_NOT_AVAILABLE"), () => { })
                                } else {
                                    this.setState({ selectedVariants: ids })
                                }
                            }} />
                        </div>
                    </div>
                })}
            </div>}
            {post.variants != undefined && post.variants.variants.length > 0 && <div>
                <div className='separator margin' />
            </div>}
        </>
    }


    private renderSellerChatContainer(post: Post, seller: User) {
        return (
            <UserContextConsumer>
                {(user) => {
                    if (user && user.id == post.user_id) {
                        return null
                    }
                    return <div>
                        <div style={{ margin: "10px 0px", display: "flex", flexDirection: "row", cursor: 'pointer' }} className='meet' onClick={() => {
                            this.props.history.push(getUserUrl(seller))
                        }}>
                            <div style={{ display: "flex", flex: 1, flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}>
                                <span style={{ fontSize: "1.571rem" }}>{t("Messages.MEET_SELLER")}</span>
                                <div className='header-name-online-wrapper'>
                                    <span style={{ fontSize: "1.571rem", fontWeight: 800 }}>{seller.full_name}</span>
                                    {this.state.online && <span className="online-indicator"></span>}
                                </div>

                            </div>
                            {seller.profile_image_url && <img src={seller.profile_image_url} alt={'user_profile'} style={{ width: 60, height: 60, objectFit: "cover" }} />}
                        </div>
                        <div className="separator" />
                        <div style={{ marginTop: 15, marginBottom: 10 }}>
                            <span style={{ fontWeight: 700 }}>{t("Messages.ASK_ANYTHING")}</span>
                        </div>
                        <ModFindTextarea value={this.state.chatMessage || ''}
                            onValueChanged={(text) => { this.setState({ chatMessage: text }) }}
                            style={{ marginBottom: 8 }} placeholder={t("Messages.ASK_ANYTHING_PLACEHOLDER")}
                            colored
                        />
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>

                            <Popup eventsEnabled={false} onOpen={() => {
                            }} content={this.state.chatPopupMessage} onClose={() => {
                                this.setState({ chatPopupMessage: undefined })
                            }} closeOnPortalMouseLeave={false} closeOnTriggerMouseLeave={false}
                                open={Boolean(this.state.chatPopupMessage)}
                                trigger={<Button
                                    size={'sm'} loading={this.state.chatSending}
                                    rounded disabled={!Boolean(this.state.chatMessage)}
                                    text={t("Messages.SEND")}
                                    onClick={() => {
                                        this.setState({ chatPopupMessage: undefined })
                                        if (user && ChatHandler.instance()) {
                                            if (this.state.chatMessage) {
                                                this.setState({ chatSending: true })
                                                ChatHandler.instance()?.sendMessage(post.id,
                                                    this.state.chatMessage, seller.id, 'text').then(() => {
                                                        this.setState({ chatSending: false, chatPopupMessage: t("Messages.SENT"), chatMessage: '' })
                                                    }).catch((err) => {
                                                        this.setState({
                                                            chatPopupMessage: err.message || t("ErrorMessages.Chat.SendingFailed"),
                                                            chatSending: false
                                                        })
                                                    })
                                            }
                                        } else {
                                            LoginRequiredPopup.show()
                                        }
                                    }}
                                />} />

                        </div>

                    </div>
                }}
            </UserContextConsumer>
        )
    }

    private renderRelatedItems() {
        if (this.state.related && this.state.related.length > 0) {
            return <PostCarousel title={t("Messages.MATCHING_PARTS")} items={this.state.related} onItemClick={(item) => {
                this.props.history.push(getPostUrl(item as Post))
            }} />
        } else {
            return null
        }
    }

    private renderOtherSellingItems() {
        if (this.state.otherSelling && this.state.otherSelling.length > 0) {
            return <PostCarousel title={t("Messages.OTHER_FROM_SELLER")} hideSeeAll items={this.state.otherSelling} onItemClick={(item) => {
                this.props.history.push(getPostUrl(item as Post))
            }} />
        } else {
            return null
        }
    }

    private setSmImageSize() {
        if (this.imageMagnifyContainer)
            this.setState({ smImgSize: { width: this.imageMagnifyContainer.clientWidth || 100, height: 265 } })
    }
})

export default React.memo(PostDetail)