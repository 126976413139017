import { Brand, Post, PostsAPI, User } from 'fullcircle-api';
import { t } from 'i18next';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { Favorite } from '../cache/favorite';
import BrandCarousel from '../components/carousel/BrandCarousel';
import PostCarousel from '../components/carousel/PostCarousel';
import UserCarousel from '../components/carousel/UserCarousel';
import { getPostUrl, getUserUrl } from '../utils/path';
import { encodeName } from '../utils/slug';


export function FollowingPage() {

    const [posts, setPosts] = React.useState<Post[]>()
    const [brands, setBrands] = React.useState<Brand[]>()
    const [users, setUsers] = React.useState<User[]>()

    const history = useHistory()

    React.useEffect(() => {
        Favorite.getFavoritePosts().then((posts) => {
            setPosts(posts)
        })
        Favorite.getFavoriteBrands().then((brands) => {
            setBrands(brands)
        })
        Favorite.getFavoriteUsers().then((users) => {
            setUsers(users)
        })
    }, [])

    return <Container>

        <Row style={{ marginTop: 20 }}>
            <PostCarousel title={t('Messages.ITEMS_YOU_WATCH')} items={posts} noItemsText={t('Messages.NOT_FOLLOWING_ITEMS')} onItemClick={(item) => {
                history.push(getPostUrl(item as Post))
            }} />
        </Row>
        <Row>
            <BrandCarousel title={t('Messages.MAKES_YOU_FOLLOW')} items={brands} noItemsText={t('Messages.NOT_FOLLOWING_BRANDS')} onItemClick={(item) => {
                history.push('/brand/' + encodeName((item as Brand).name))
            }} />
        </Row>
        <Row >
            <UserCarousel title={t('Messages.USERS_YOU_FOLLOW')} items={users} noItemsText={t('Messages.NOT_FOLLOWING_USERS')} onItemClick={(item) => {
                history.push(getUserUrl(item as User))
            }} />
        </Row>
    </Container>
}