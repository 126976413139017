import { PAYPAL, CREDIT_CARD, APPLE_PAY, LOGO } from "../assets";
import { BuyPostParameters, ProviderType, BuyPostErrorType, BuyPostError, PayParamter } from "fullcircle-api/lib/endpoints/posts";
import { PostsAPI, DeliveryRequest, DeliveryAPI, APIError, AdBannerAPI, Post } from "fullcircle-api";
import { loadStripe, CreateTokenBankAccountData, Stripe } from '@stripe/stripe-js';
import { AdBannerBuyParams } from "fullcircle-api/lib/endpoints/ads";

export enum PaymentProvider {
    PAY_PAL = 'PayPal',
    CREDITCARD = 'Credit Card',
    APPLE_PAY = 'Apple Pay',
    MODFIND = 'ModFind Balance'
}

export const PaymentProviderIcons: Map<PaymentProvider, { icon: string }> = new Map([
    [PaymentProvider.PAY_PAL, { icon: PAYPAL }],
    [PaymentProvider.CREDITCARD, { icon: CREDIT_CARD }],
    [PaymentProvider.APPLE_PAY, { icon: APPLE_PAY }],
    [PaymentProvider.MODFIND, { icon: LOGO }]
]);

export enum BankDataKeys {
    first_name = 'first_name',
    last_name = 'last_name',
    ssn_last_4 = 'ssn_last_4',
    dob = 'dob',
    email = 'email',
    phone = 'phone',
    accountNumber = 'accountNumber',
    routingNumber = 'routingNumber',
    transitNumber = 'transitNumber',
    address = 'address',
    ssn = 'ssn',
    photo_id = 'photo_id',
    photo_id_back = 'photo_id_back',
    individual_verification_additional_document = 'individual.verification.additional_document',
    individual_verification_document = 'individual.verification.document',
    individual_phone = 'individual.phone',
}

export let STRIPE_KEY = 'pk_live_453QAEyom2wu1qlTLOvCAN35';

export function setStripeKey(key: string) {
    STRIPE_KEY = key
}

export function buyPostOnApi(payForDeliveryRequest: boolean, id: string, buyPostParams: BuyPostParameters, callback: (error?: string, delivery?: DeliveryRequest) => void) {
    let buyOnApi;
    if (!payForDeliveryRequest) {
        buyOnApi = PostsAPI.buyPost;
    } else {
        buyOnApi = PostsAPI.payDelivery;
    }
    buyOnApi(id, buyPostParams)
        .then((result) => {
            PostsAPI.getPost(result.post_id)
                .then((post) => {
                    DeliveryAPI.getDelivery(result.id).then((delivery) => {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-622342217/oOauCOC9g_ABEMng4KgC',
                            'value': post.price,
                            'currency': post.currency.toUpperCase(),
                            'transaction_id': delivery.id
                        })
                        callback && callback(undefined, delivery)
                    }).catch(() => {
                        callback && callback(undefined)
                    })
                })
                .catch((e) => {
                    callback && callback(undefined);
                });
        })
        .catch((err) => {
            if (err.type == BuyPostErrorType.insufficientBalance) {
                callback("Your account has a insufficient balance. Please try again with another payment method.");
            } else {
                callback(err.underlyingError?.message || "Couldn't finalize your payment on our server. Please try again.");
            }
        });
}

export function buyAdBannerOnApi(params: AdBannerBuyParams, callback?: (error?: string) => void) {
    AdBannerAPI.buyAdPlan(params).then(() => {
        callback && callback()
    }).catch((err: BuyPostError) => {
        if (err.type == BuyPostErrorType.insufficientBalance) {
            callback && callback("Your account has a insufficient balance. Please try again with another payment method.");
        } else if (err.type == BuyPostErrorType.notEnoughItemsInStock) {
            callback && callback(err.message);
        } else
            callback && callback("Couldn't finalize your payment on our server. Please try again.");
    });
}

export function buyPostFeatureOnApi(id: string, params: PayParamter, callback?: (error?: string) => void) {
    PostsAPI.featurePost(id, params).then((post) => {
        callback && callback()
    }).catch((err: BuyPostError) => {
        if (err.type == BuyPostErrorType.insufficientBalance) {
            callback && callback("Your account has a insufficient balance. Please try again with another payment method.");
        } else if (err.type == BuyPostErrorType.notEnoughItemsInStock) {
            callback && callback(err.message);
        } else
            callback && callback("Couldn't finalize your payment on our server. Please try again.");
    });
}


export function getPaypalAuthUrlForPost(payForDeliveryRequest: boolean, id: string, buyPostParams: BuyPostParameters) {
    const returnUrl = location.href + '/paypal'
    let invocation = PostsAPI.buyPostPaypal
    if (payForDeliveryRequest) {
        invocation = PostsAPI.payDeliveryPaypal
    }
    return invocation(id, buyPostParams, returnUrl).then(url => {
        return url
    })
}

export function getPaypalAuthUrlForAdPlan(id: string) {
    const returnUrl = location.href + '/paypal'
    return AdBannerAPI.buyAdBannerPaypal(id, returnUrl).then(url => {
        return url
    })
}

export async function createBankAccountToken(data: CreateTokenBankAccountData) {
    const stripe = await loadStripe(STRIPE_KEY);
    if (stripe) {
        return stripe.createToken('bank_account', data)
    }
    return null
}