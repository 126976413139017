import { User } from 'fullcircle-api';
import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { IC_INFO, IC_MARKER_BLUE, IC_VERIFY, PROFILE_TITLE_PLACEHOLDER } from '../../assets';
import { getUserUrl } from '../../utils/path';

export interface ModFindUserListItemProps {
    user: User;
}


export class ModFindUserListItem extends React.Component<ModFindUserListItemProps, {}> {

    render() {
        const { full_name, profile_image_url, verification_complete, state, bio } = this.props.user;

        return (
            <React.Fragment>
                <hr className="list-hr" />
                <div className="list-item">
                    <Link to={getUserUrl(this.props.user)} style={{ width: "100%" }}>
                        <div className="list-item-container">
                            <div className="img-container">
                                <div className="img-ratio">
                                    <img src={profile_image_url || PROFILE_TITLE_PLACEHOLDER} alt={`Image_${full_name}`} className="list-item-img" />
                                </div>
                            </div>
                            <div className="list-item-info-container">
                                <span className="list-item-name">{full_name}</span>
                                <div className='list-item-info-item'>
                                    <div className="icon-container">
                                        <img src={IC_VERIFY} />
                                    </div>
                                    <span>
                                        {verification_complete ? t("Messages.CONFIRMED") : t("Messages.NOT_VERIFIED")}
                                    </span>
                                </div>
                                <div className='list-item-info-item'>
                                    <div className="icon-container">
                                        <img src={IC_MARKER_BLUE} />
                                    </div>
                                    <span>
                                        {Boolean(state) ? state : t("Messages.NO_STATE")}
                                    </span>
                                </div>
                                {bio != undefined && <div className='list-item-info-item'>
                                    <div className="icon-container">
                                        <img src={IC_INFO} />
                                    </div>
                                    <div className='profile-bio'>{bio.repeat(100)}</div>
                                </div>}
                            </div>
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}
