import { AdPlan } from "fullcircle-api/lib/models/adplan";
import { t } from "i18next";
import React, { useContext } from "react";
import { ADS_APP, ADS_WEB, IC_TICK } from "../assets";
import { UserContextType } from "../context/user";

import { formatPriceI18n } from "../utils/formater";
import Button from "./generic/Button";

export function AdPlanBox(props: { plan: AdPlan, onClick: () => void }) {
    const { plan, onClick } = props

    const user = useContext(UserContextType)

    function renderIllustrations(plan: AdPlan) {
        if (plan.for == 'app')
            return <img src={ADS_APP} className="illustration" />
        if (plan.for == 'web')
            return <img src={ADS_WEB} className="illustration" />

        return <>
            <img src={ADS_APP} className="illustration" />
            <img src={ADS_WEB} className="illustration-second" />
        </>
    }

    return <div className="ad-plan">
        <div className="title">{plan.title}</div>
        {renderIllustrations(plan)}
        <div className="description">{plan.description.split('\n').map((line, i) => {
            return (
                <div className="desc-box">
                    {i != 0 && line.length > 0 && <img src={IC_TICK} />}
                    <p key={i}>{line.replace('-', '')}</p>
                </div>
            )
        })}</div>
        <div className="price">Total price: {formatPriceI18n(plan.price, 'usd')}</div>
        <div className="btn-container">
            {user && <Button rounded size="sm" text={t("Messages.CONTINUE")} onClick={onClick} />}
            {!user && <div className="description press-login-link">Please <a className="default-link" href='/login'>Login</a> to Continue</div>}
        </div>

    </div>
}