import * as React from "react";

export class ModFindPager extends React.Component<{ tabs: string[] }, { selected: number }> {

    constructor(p: any) {
        super(p);
        this.state = {
            selected: 0
        }
    }
    render() {
        return <div className='viewpager'>
            <div className='viewpager-tabs'>
                {this.props.tabs.map((tab, i) => {
                    return <div className={'tab ' + (this.state.selected == i ? 'selected' : '')} key={tab} onClick={() => {
                        this.setState({ selected: i })
                    }}>
                        {tab}
                    </div>
                })}
            </div>
            <div className='viewpager-content'>
                {React.Children.toArray(this.props.children)[this.state.selected]}
            </div>
        </div>
    }
}