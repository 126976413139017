import * as React from 'react'
import ModFindPopUp from './ModFindPopUp';
import Button from '../generic/Button';
import { User, UsersAPI } from 'fullcircle-api';
import { PartialLocation } from '../../utils/location';
import { ZipCodeInput } from '../generic/ZipCodeInput';
import { removeUndefined } from '../../utils/helper';
import * as toastr from 'toastr'
import { UserSession } from '../../utils/session';

import { t } from 'i18next';

interface ZipCodeRequiredPopupProps {
    user: User,
    onClose: () => void
    onUserUpdated: (user: User) => void
}

export default class ZipCodeRequiredPopup extends React.Component<ZipCodeRequiredPopupProps, {
    location?: PartialLocation
    loading: boolean
}> {

    constructor(props: ZipCodeRequiredPopupProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        return (
            <ModFindPopUp onClose={() => this.props.onClose()}>
                <div className="modfind-popup-content-wrapper">
                    {<div className="modfind-popup-title">{t("Messages.SET_ZIP")}</div>}
                    <ZipCodeInput region={this.props.user.region_code} onLocation={location => {
                        this.setState({ location })
                    }} />
                    <div className="buttons-container">
                        <Button
                            text={t("Messages.UPDATE")}
                            size={'sm'}
                            rounded
                            className="left-button"
                            onClick={() => { this.setLocation() }}
                            loading={this.state.loading}
                            disabled={!this.state.location}
                        />
                        <Button
                            text={t("Messages.CANCEL")}
                            size={'sm'}
                            rounded
                            className="right-button"
                            loading={this.state.loading}
                            onClick={() => this.props.onClose()}
                        />
                    </div>
                </div>
            </ModFindPopUp>
        )
    }

    setLocation() {
        this.setState({ loading: true })
        const update = removeUndefined({
            zip: this.state.location?.zip,
            state: this.state.location?.state,
            city: this.state.location?.city
        })
        UsersAPI.updateProfile(update).then((u) => {
            this.props.onUserUpdated(u)
            UserSession.getUserUpdatedHandler()(u)
        }).catch(() => {
            toastr.error(UPDATING_FAILED)
        });
    }
}
const UPDATING_FAILED = 'Couldn\'t change your zip code. Please try again!';
