const canvas = document.createElement("canvas")
const context = canvas.getContext("2d");

export function measureTextLines(text: string, font: string, maxWidth: number) {
    if (!context) {
        return text.length * 12
    }
    context.font = font;
    const words = text.split(' ')
    let totalWidth = 0
    let lines = 1
    for (let index = 0; index < words.length; index++) {
        const word = words[index];
        const isLastIndex = index == words.length - 1
        const metrics = context.measureText(word + (isLastIndex ? '' : ' '));
        if (totalWidth + metrics.width >= maxWidth) {
            lines++
            totalWidth = metrics.width
        } else {
            totalWidth += metrics.width
        }
    }
    return lines
};