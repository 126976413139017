import * as React from "react"
import { Colors } from "../../../styles/colors"
import Button from "../../generic/Button"

import ModFindInput from "../../generic/ModFindInput"
import { validateEmail } from "../../../utils/helper"
import { t } from "i18next"

export class SignUpSetEmail extends React.Component<{
    onSelection: (email: string) => void
    onCancel: () => void,
}, {
    email?: string
}> {

    constructor(props: any) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <>
                <ModFindInput placeholder={t("Messages.EMAIL")} value={this.state.email} onValueChanged={(value) => {
                    this.setState({ email: value })
                }} colored rounded containerSize={'lg'} type={'email'} style={{ marginBottom: 4 }} />

                <div style={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
                    <Button text={t("Messages.SIGN_UP")} size={'lg'} rounded disabled={!Boolean(this.state.email) || !validateEmail(this.state.email)}
                        onClick={() => { this.props.onSelection(this.state.email!) }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
                    <Button text={t("Messages.CANCEL")} size={'sm'} rounded style={{ backgroundColor: Colors.GREY }}
                        onClick={() => { this.props.onCancel() }} />
                </div>
            </>
        )
    }
}