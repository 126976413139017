import * as React from "react";
import { Container } from "reactstrap";
import { SHIPPING_RATES, SHIPPING_RATES_MOBILE } from "../assets";

export function ShippingFeaturePage() {

    return <Container className='ads'>
        <div style={{ marginLeft: 16, width: '90%' }}>
            <img src={SHIPPING_RATES} style={{ marginTop: 12, maxWidth: '100%' }} className="medium-hide" />
            <img src={SHIPPING_RATES_MOBILE} style={{ marginTop: 12, maxWidth: '100%' }} className="medium-show hidden" />
            <h4 style={{ marginTop: 20, fontWeight: 600 }}>Keeping Shipping Easy for Our Sellers and Buyers</h4>
            <p>ModFind knows that determining shipping costs can be as frustrating as trying to figure out why you have a Check Engine Light. With our new shipping calculator, as a Seller, all you now need to do is select the Calculate Shipping Price at Checkout button, and then enter the dimensions and weight of your package. From there, select the shipping service you’d like to use such as UPS, FedEx, or USPS, and that’s it! </p>
            <p>For Buyers, once you proceed to purchase a product, shipping charges are determined per your shipping zip code and added to the total cost of the product. Seamless!</p>
            <p>So get out there and list those parts to make some extra cash, and browse to purchase new ones on ModFind – The Automotive Marketplace.</p>
        </div>
    </Container>
}