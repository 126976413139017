import { SessionData } from '../utils/session';
import { Session, User, MetaAPI, Notification } from 'fullcircle-api';
import { ChatClient } from './client';
import { Chat, Message } from './database';
import { EventEmitter } from 'events';
import { Content } from './events';

let Socket = require('socket.io-client');

class ExtendedSocket extends Socket {
    constructor(url: string) {
        super(url, {
            transports: ['websocket', 'polling']
        });
    }
}
export enum ChatHandlerEvents {
    ChatsUpdate = 'ChatsUpdate',
    MessageUpdate = 'MessageUpdate'
}

export function isMessageRead(message: Message, user: User) {
    if (message.user == user.id) {
        return true
    }
    return message.read
}
export { sortMessages } from './database'
export default class ChatHandler extends EventEmitter {
    private chatClient: ChatClient;
    private incomingListener: string;
    private messagesUpdatedListener: string;
    private onlineStatusSubscriptions: { [key: string]: Array<(data: Content.Incoming.OnlineStatusDidChange) => void> } = {}
    private user: User
    private notifications: Notification[] = [];

    private chatIdsToShow: string[] = []

    static currentInstance?: ChatHandler;

    static instance() {
        return ChatHandler.currentInstance
    }

    static destroy() {
        ChatHandler.instance() && ChatHandler.instance()?.shutdown()
        ChatHandler.currentInstance = undefined
    }

    static intitialize(session: SessionData) {
        ChatHandler.currentInstance = new ChatHandler(session)
    }

    private constructor(session: SessionData) {
        super();
        this.user = session.user
        this.chatClient = new ChatClient(ExtendedSocket as any, {
            url: Session.getAPIURL(),
            auth_token: session.session.session_token!,
            userId: session.user.id
        });
        this.chatClient.on('connect', () => {
            console.log('FCChat: connect')
        })

        this.chatClient.on('disconnect', () => {
            console.log('FCChat: disconnect')
        })
        this.incomingListener = this.chatClient.addChatUpdatedListener((message) => {
            if (!message) {
                return
            }
            this.chatIdsToShow = this.chatClient.chats().filter(c => {
                return this.chatIdsToShow.includes(c.id) || c.messages.find(m => !isMessageRead(m, session.user))
            }).map(c => c.id)
            this.emit(ChatHandlerEvents.ChatsUpdate)
        });

        this.messagesUpdatedListener = this.chatClient.addMessageUpdatedListener((message) => {
            if (!this.chatIdsToShow.includes(message.chat_id)) {
                this.chatIdsToShow.push(message.chat_id)
                this.emit(ChatHandlerEvents.ChatsUpdate)
            } else {
                this.emit(ChatHandlerEvents.MessageUpdate, message)
            }
        })
        this.chatClient.on('online-status', (data: Content.Incoming.OnlineStatusDidChange) => {
            if (this.onlineStatusSubscriptions[data.userID]) {
                this.onlineStatusSubscriptions[data.userID].forEach(cb => cb(data))
            }
        });
        this.loadNotifications()
    }

    public shutdown() {
        this.chatClient.removeChatUpdatedListener(this.incomingListener);
        this.chatClient.removeMessageUpdatedListener(this.messagesUpdatedListener);
        this.chatClient.disconnect()
    }

    public markMessageRead(messageId: string) {
        return this.chatClient.markMessageRead(messageId);
    }

    public markMessagesAsRead(messages: Message[]): Promise<any> {
        if (messages.length) {
            let ids: string[] = [];
            messages.forEach((m) => {
                ids.push(m.id);
            });
            return this.chatClient.markMessagesRead(ids);
        }
        return Promise.resolve();
    }

    public getChatsToShow(): Array<Chat> {
        return this.chatClient.getChats().filter(c => this.chatIdsToShow.includes(c.id))
    }

    public closeChat(chat: Chat) {
        this.chatIdsToShow = this.chatIdsToShow.filter(c => c != chat.id)
        this.emit(ChatHandlerEvents.ChatsUpdate)
    }

    public openChat(chat: Chat) {
        this.chatIdsToShow = this.chatIdsToShow.concat(chat.id).unique()
        this.emit(ChatHandlerEvents.ChatsUpdate)
    }

    public getChats(): Array<Chat> {
        let messages = this.chatClient.getChats()
        return messages
    }

    public deleteChatByUser(user: User) {
        this.getChats().filter(c => c.users.map(u => u.id).includes(user.id)).forEach(chat => {
            this.deleteChat(chat)
        })
    }

    public deleteChat(chat: Chat) {
        this.chatClient.deleteChat(chat)
    }

    public getChatByPostId(postId: string, userId: string): Chat | undefined {
        let chats = this.chatClient.chats();
        let chat: Chat | undefined;
        chats.forEach((c) => {
            let hasUserId = false;
            c.users.forEach((u) => {
                if (u.id == userId) hasUserId = true;
            });
            if (c.post_id == postId && hasUserId) {
                chat = c;
            }
        });
        return chat;
    }

    public sendMessage(postId: string, message: string, toUserId: string, type: 'text' | 'image' | 'video' | 'check-availability') {
        return this.chatClient.sendMessage(postId, message, toUserId, type).then((message) => {
            return message;
        });
    }

    public getUnreadMessages(): Array<Message> {
        return this.chatClient.getMesages().filter(m => !isMessageRead(m, this.user))
    }

    public subscribeOnlineStatus(userId: string, listener: (data: Content.Incoming.OnlineStatusDidChange) => void) {
        this.onlineStatusSubscriptions[userId] = (this.onlineStatusSubscriptions[userId] || [])
        this.onlineStatusSubscriptions[userId].push(listener)
        return this.chatClient.subscribeOnlineStatus(userId)
    }

    public unsubscribeOnlineStatus(userId: string, listener: (data: Content.Incoming.OnlineStatusDidChange) => void) {
        this.onlineStatusSubscriptions[userId] = (this.onlineStatusSubscriptions[userId] || []).filter((l) => l != listener)
        return this.chatClient.subscribeOnlineStatus(userId)
    }

    private findOrCreateNotification(notification: Notification) {
        let index = this.notifications.findIndex(c => c.id == notification.id)
        if (index > -1) {
            this.notifications[index] = notification
        } else {
            index = this.notifications.push(notification) - 1
        }
        return this.notifications[index]
    }

    public markNotificationsRead() {
        this.notifications = this.notifications.map(notification => {
            notification.read = true
            return notification
        })
    }

    public getUnreadNotifications(): Array<Notification> {
        return this.getNotifications().filter(n => n.read == false)
    }

    public saveNotifications(notifications: Notification[]) {
        notifications.forEach(n => {
            this.findOrCreateNotification(n)
        })
    }

    public deleteNotificationByUser(user: User) {
        this.getNotifications().filter(n => n.from_user_id == user.id).forEach(user => {
            this.deleteNotification(user)
        })
    }

    public deleteNotification(notification: Notification) {
        this.notifications = this.notifications.filter(n => n.id != notification.id)
    }

    public getNotifications(): Array<Notification> {
        return this.notifications
    }

    public loadNotifications(): Promise<void> {
        return MetaAPI.getNotifications()
            .then((result) => {
                this.saveNotifications(result);
                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    }
}
