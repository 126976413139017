const local = {
    API: 'http://local.modfind.com:3009',
    STRIPE_KEY: 'pk_test_2URTspFOdqmFYuOhSXzSbs9E',
    B2B_DASHBOARD: 'https://b2b.modfind.com',
    COMMUNITY: 'https://community.modfind.com',
    ENV: 'local'
};
const dev = {
    API: 'https://api.develop.modfind.com',
    STRIPE_KEY: 'pk_test_2URTspFOdqmFYuOhSXzSbs9E',
    B2B_DASHBOARD: 'https://b2b.develop.modfind.com',
    COMMUNITY: 'https://community.modfind.com',
    ENV: 'dev'
};
const prod = {
    API: 'https://api.modfind.com',
    STRIPE_KEY: 'pk_live_453QAEyom2wu1qlTLOvCAN35',
    B2B_DASHBOARD: 'https://b2b.modfind.com',
    COMMUNITY: 'https://community.modfind.com',
    ENV: 'prod'
};
const config = process.env.REACT_APP_ENV === 'local' ? prod :
    process.env.REACT_APP_ENV === 'production'
        ? prod
        : dev;
export let Environment = config
