import * as React from "react";
import { GenericDropDown, GenericDropDownProps } from "./GenericDropDown";
import { Brand } from "fullcircle-api";
import { Meta } from "../../cache/meta";

export interface BrandDropDownProps extends GenericDropDownProps<Brand> {

}

export class BrandDropDown extends React.Component<BrandDropDownProps, {}> {

    constructor(props: BrandDropDownProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <GenericDropDown apiFunc={Meta.getBrands}  {...this.props} />
    }
}