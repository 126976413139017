import * as React from "react";
import { User, Post, Notification, NotificationTypeIdentifier } from "fullcircle-api";

import ChatHandler, { sortMessages } from "../../api/chathandller";
import { Message, Chat } from "../../api/database";
import { IC_PROFILE } from "../../assets";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getPostUrl } from "../../utils/path";
import { ModFindPager } from "../generic/ModFindPager";
import { formatHoursDays } from "../../utils/formater";
import { Content } from "../../api/events";
import { t } from "i18next";

export const Notifications = withRouter(class extends React.Component<RouteComponentProps & { user: User }> {

    private getOtherUser(users: Array<User>): User | undefined {
        let otherUser: User | undefined;
        users.forEach((user) => {
            if (this.props.user && user.id != this.props.user!.id) {
                otherUser = user;
            }
        });
        return otherUser;
    }

    private openNotification(notification: Notification) {

    }

    private openChat(chat: Chat) {
        ChatHandler.instance()?.openChat(chat)
    }

    private openPost(post?: Post) {
        if (post)
            this.props.history.push(getPostUrl(post))
    }

    private getTextForNotificationType(notifcation: Notification): string {
        switch (notifcation.type_identifier) {
            case NotificationTypeIdentifier.PaymentTimeout:
                return 'Payment missed';
            case NotificationTypeIdentifier.PostSold:
                return 'Item sold';
            case NotificationTypeIdentifier.Kickback:
                return 'Kickback';
            case NotificationTypeIdentifier.PostArrived:
                return 'Item arrived';
            case NotificationTypeIdentifier.PostInDelivery:
                return 'Item in delivery';
            case NotificationTypeIdentifier.PostLiked:
                return 'Activity';
            case NotificationTypeIdentifier.PostProblem:
                return 'Alert';
            case NotificationTypeIdentifier.PostNotDeliverReminder:
            case NotificationTypeIdentifier.FeaturedPostAboutToExpire:
                return 'Reminder';
            case NotificationTypeIdentifier.PostDeliveredReminder:
                return 'Reminder';
            default:
                return 'Activity';
        }
    }

    public render() {
        const chatHandler = ChatHandler.instance()
        return <div className='notification-popup'>
            <ModFindPager tabs={[t("Messages.ACTIVITY"), t("Messages.MESSAGES")]}>
                <div className='activity-list'>
                    {chatHandler && chatHandler.getNotifications().length == 0 && <h6 className='emtpy-placeholder'>{t("Messages.NO_NOTIFICATIONS")}</h6>}
                    {chatHandler && chatHandler.getNotifications().map(notification => {

                        return <div className='activity-item' key={notification.id} onClick={() => this.openPost(notification.post)}>
                            <div className='profle-icon' onClick={() => this.openNotification(notification)}>
                                <img src={(notification.user && notification.user.profile_image_medium_url) || IC_PROFILE} alt="" />
                            </div>
                            <div className='user' onClick={() => this.openNotification(notification)}>
                                <div className='user-fullname'>{notification.title}</div>
                                <div className='user-message'>{this.getTextForNotificationType(notification)} · {formatHoursDays(notification.created_at)}</div>
                            </div>
                            <div className='post'>
                                <img src={notification.post && notification.post.images && notification.post.images.length > 0 ? notification.post.images[0].thumbnail_url : undefined} alt="" />
                            </div>
                        </div>
                    })}
                </div>
                <div className='buysell-list'>
                    {chatHandler && chatHandler.getChats().length == 0 && <h6 className='emtpy-placeholder'>{t("Messages.NO_CHATS")}</h6>}
                    {chatHandler && chatHandler.getChats().map((chat) => {
                        let message: Message | undefined = chat.messages && chat.messages.length > 0 ? sortMessages(chat.messages)[chat.messages.length - 1] : undefined;
                        let otherChatUser = this.getOtherUser(chat.users as any);

                        return <ChatMessageItem key={chat.id}
                            chat={chat}
                            message={message}
                            otherChatUser={otherChatUser}
                            openChat={this.openChat.bind(this)}
                            openPost={this.openPost.bind(this)}
                        />
                    })}
                </div>
            </ModFindPager>
        </div>
    }
})

interface ChatMessageItem {
    chat: Chat,
    message: Message | undefined,
    otherChatUser: User | undefined,
    openChat: (chat: Chat) => void
    openPost: (chat: Post) => void
}

const ChatMessageItem = (props: ChatMessageItem) => {
    const [online, setOnline] = React.useState<boolean>(false)
    const chatHandler = ChatHandler.instance()
    const onlineListener = React.useCallback(({ online }: Content.Incoming.OnlineStatusDidChange) => {
        setOnline(online)
    }, [])


    React.useEffect(() => {
        props.otherChatUser &&
            ChatHandler.instance()?.subscribeOnlineStatus(props.otherChatUser.id, onlineListener).then(({ online }) => {
                setOnline(online)
            })

        return () => {
            props.otherChatUser && props.otherChatUser.id && chatHandler?.unsubscribeOnlineStatus(props.otherChatUser.id, onlineListener)
        }
    }, [props.otherChatUser?.id, onlineListener])


    return <div className='buysell-item' key={props.chat.id}>
        <div className='profle-icon' onClick={() => props.openChat(props.chat)}>
            <img src={(props.otherChatUser && props.otherChatUser.profile_image_medium_url) || IC_PROFILE} alt="" />
        </div>
        <div className='user' onClick={() => props.openChat(props.chat)}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>

                <div className='user-fullname'>{props.otherChatUser ? props.otherChatUser.full_name : ''}</div>
                {online ? <div className="online-indicator"></div> : null}
            </div>

            <div className='user-message'>{props.message ? (props.message.type == 'text' ? props.message.content : `(${props.message.type == 'image' ? 'Image' : 'Video'})`) : ''}</div>
        </div>
        <div className='post' onClick={() => props.openPost(props.chat.post)}>
            <img src={props.chat.post && props.chat.post.images && props.chat.post.images.length > 0 ? props.chat.post.images[0].thumbnail_url : undefined} alt="" />
        </div>
    </div>
}