import { AdPlan } from "fullcircle-api/lib/models/adplan";
import React from "react";

import { formatPriceI18n } from "../utils/formater";
import { errorToString } from "../utils/helper";
import { buyAdBannerOnApi, getPaypalAuthUrlForAdPlan, PaymentProvider } from "../utils/payment";
import { SeparatorBox } from "./generic/Box";
import Button from "./generic/Button";
import ModFindPopUp, { TitleMessageContent } from "./popup/ModFindPopUp";
import { ChangePayment } from "./views/payment/ChangePayment";
import * as toastr from 'toastr'
import { CardInterface, ProviderType } from "fullcircle-api";
import { AddCreditCard } from "./views/payment/AddCreditCard";
import { StripeAppleCheckoutButton } from "./StripeCheckoutButton";
import { ModFindLoader } from "./generic/ModFindLoader";
import { Popup } from "semantic-ui-react";
import { ImageCropPopup } from "./views/ImageCropPopup";
import { IC_CLOSE } from "../assets";
import { AdBannerBuyParams } from "fullcircle-api/lib/endpoints/ads";
import ModFindInput from "./generic/ModFindInput";
import * as validator from 'validator'
import { t } from "i18next";

interface AdPlanCheckoutState {
    paymentPopupOpen: boolean
    showCropPopup?: 'web' | 'mobile'
    addCreditCard: boolean
    payparlUrlLoading: boolean
    successPopup: boolean

    payment_type?: PaymentProvider;
    payment?: CardInterface;

    webBanner?: File
    appBanner?: File
    link: string

    paypalUrl?: string

    buying: boolean
}

export class AdPlanCheckout extends React.Component<{ plan: AdPlan, onCancel: () => void }, AdPlanCheckoutState> {
    appBannerUpload?: HTMLInputElement | null;
    webBannerUpload?: HTMLInputElement | null;

    constructor(props) {
        super(props)
        this.state = {
            paymentPopupOpen: false,
            payparlUrlLoading: false,
            addCreditCard: false,
            buying: false,
            successPopup: false,
            link: ''
        }
    }

    chooseAppPicture() {
        this.appBannerUpload && this.appBannerUpload.click()

    }

    chooseWebPicture() {
        this.webBannerUpload && this.webBannerUpload.click()
    }

    loadPaypalAuthUrl() {
        getPaypalAuthUrlForAdPlan(this.props.plan.id).then((url) => {
            this.setState({ paypalUrl: url.url, payparlUrlLoading: false })
        }).catch(err => {
            toastr.error(errorToString(err).join('\n'))
            this.setState({ payparlUrlLoading: false })
        })
    }

    private checkoutEnabled(): true | string {
        if (this.props.plan.for != 'web' && !this.state.appBanner) {
            return 'Please provide a app banner'
        }
        if (this.props.plan.for != 'app' && !this.state.webBanner) {
            return 'Please provide a web banner'
        }
        if (!this.state.link || !validator.isURL(this.state.link)) {
            return 'Not a valid link'
        }
        return true
    }

    private async buy(applePayToken?: string, payPal?: { token: string, customer_id: string }) {

        const params = {
            link: this.state.link!,
            logo_web: this.state.webBanner!,
            logo_mobile: this.state.appBanner!,
            id: this.props.plan.id,
            price: this.props.plan.price
        } as any

        this.setState({ buying: true })
        if (this.state.payment || applePayToken || (this.state.payment_type == PaymentProvider.PAY_PAL && payPal)) {
            return new Promise<void>((resolve, reject) => {
                let buyParams: AdBannerBuyParams
                if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                    buyParams = {
                        ...params,
                        payment_info: {
                            ...payPal,
                            provider: ProviderType.Paypal
                        }
                    }
                } else {
                    buyParams = {
                        ...params,
                        payment_info: {
                            [applePayToken ? 'token' : 'payment_method']: applePayToken || this.state.payment!.id,
                            provider: applePayToken ? ProviderType.Stripe : this.state.payment!.provider
                        }
                    }
                }
                buyAdBannerOnApi(buyParams, (error?: string) => {
                    if (error) {
                        reject(error)
                        toastr.error(error, undefined, { timeOut: 0, extendedTimeOut: 0 })
                        this.setState({ buying: false })
                    } else {
                        resolve()
                        this.setState({ buying: false, successPopup: true })
                    }
                    if (window.location.href.includes('/paypal/')) {
                        location.href = location.href.substring(0, location.href.indexOf('/paypal/'))
                    }
                })
            })
        } else if (this.state.payment_type) {

        }
        return Promise.reject('Unknown payment method')
    }

    private renderSuccessPopup() {
        if (this.state.successPopup) {
            return (
                <ModFindPopUp
                    onClose={() => { this.props.onCancel() }}>
                    <TitleMessageContent
                        icon={'success'}
                        title={t("Messages.CONGRATS")}
                        message={t("Messages.AD_POSTED_SUCCESS")}
                    />
                </ModFindPopUp>
            )
        }
        return null
    }

    render() {
        const { plan, onCancel } = this.props
        return <div className="ad-plan">
            {this.renderSuccessPopup()}
            <input ref={(ref) => this.appBannerUpload = ref} accept='image/png, image/gif, image/jpeg' type='file'
                className="hidden-input" onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        this.setState({ appBanner: e.target.files[0], showCropPopup: 'mobile' })
                    }
                    e.target.value = ''
                }} />
            <input ref={(ref) => this.webBannerUpload = ref} accept='image/png, image/gif, image/jpeg' type='file'
                className="hidden-input" onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        this.setState({ webBanner: e.target.files[0], showCropPopup: 'web' })
                    }
                    e.target.value = ''
                }} />
            {this.state.paymentPopupOpen && <ModFindPopUp
                onClose={() => {
                    this.setState({ paymentPopupOpen: false })
                }}
            >
                <ChangePayment amount={plan.price}
                    onAddCreditCard={() => {
                        this.setState({ addCreditCard: true })
                    }}
                    selectedPaymentType={this.state.payment || this.state.payment_type}
                    additional_provider={[PaymentProvider.APPLE_PAY/*, PaymentProvider.PAY_PAL*/]} // Disable paypal for now, need to figure out how to keep state (open paypal in modal?)
                    onPaymenTypeSelected={(pymentType) => {
                        if (pymentType == PaymentProvider.PAY_PAL) {
                            this.loadPaypalAuthUrl()
                        }
                        this.setState({ payment_type: pymentType, payment: undefined, paymentPopupOpen: false, payparlUrlLoading: true })
                    }} onPaymentSelected={(payment) => {
                        this.setState({ payment, paymentPopupOpen: false })
                    }}
                />
            </ModFindPopUp>}
            {this.state.addCreditCard && <ModFindPopUp onClose={() => {
                this.setState({ addCreditCard: false })
            }}>
                <AddCreditCard onAdded={(card) => {
                    this.setState({ addCreditCard: false, payment: card })
                }} onCancel={() => {
                    this.setState({ addCreditCard: false })
                }} />
            </ModFindPopUp>}
            {this.state.showCropPopup && <ImageCropPopup
                file={this.state.showCropPopup == 'mobile' ? this.state.appBanner! : this.state.webBanner!}
                ratio={this.state.showCropPopup == 'mobile' ? (720 / 154) : (860 / 90)}
                onClose={() => this.setState({ showCropPopup: undefined, [this.state.showCropPopup == 'mobile' ? 'appBanner' : 'webBanner']: undefined } as any)}
                onSave={(file) => {
                    this.setState({ showCropPopup: undefined, [this.state.showCropPopup == 'mobile' ? 'appBanner' : 'webBanner']: file } as any)
                }}
            />}
            <div className="close-icon">
                <img src={IC_CLOSE} onClick={() => onCancel()} />
            </div>
            <div className="title">Order Summary</div>
            {plan.for != 'web' && <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.MOBILE_BANNER")}*
                    <div className="size">
                        720 x 154
                    </div>
                </div>
                <div className='item'>
                    <Button size="sm" text={this.state.appBanner ? t("Messages.CHANGE") : t("Messages.SELECT")} onClick={() => {
                        this.chooseAppPicture()
                    }} />
                </div>
            </SeparatorBox>}
            {plan.for != 'app' && <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.WEB_BANNER")}*
                    <div className="size">
                        860 X 90
                    </div>
                </div>
                <div className='item'>
                    <Button size="sm" text={this.state.webBanner ? t("Messages.CHANGE") : t("Messages.SELECT")} onClick={() => {
                        this.chooseWebPicture()
                    }} />
                </div>
            </SeparatorBox>}
            <SeparatorBox direction={'row'}>
                <div className="item checkout-link-title" >{t("Messages.LINK")}</div>
                <ModFindInput
                    className="checkout-site-input"
                    value={this.state.link}
                    colored
                    rounded
                    containerSize={'sm'}
                    info={{ text: 'https://your-site.com' }}
                    onValueChanged={(value) => {
                        this.setState({ link: value })
                    }}
                />
            </SeparatorBox>
            <SeparatorBox
                direction={'row'} onBoxClick={() => {
                    this.setState({ paymentPopupOpen: !this.state.paymentPopupOpen })
                }}>
                <div className="select-payment-wrapper">
                    <span className="item">{t("Messages.PAYMENT")}</span>
                    <div className="item blue payment-text">{this.getPaymentText()}</div>
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.PLAN_DURATION")}
                </div>
                <div className='item'>
                    {plan.duration_in_months} {plan.duration_in_months == 1 ? t("Messages.MONTH") : t("Messages.MONTHS")}
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.TOTAL_AMOUNT")}
                </div>
                <div className='item'>
                    {formatPriceI18n(plan.price, 'usd')}
                </div>
            </SeparatorBox>
            {this.renderBuyButton()}
        </div>
    }

    private renderBuyButton() {

        let button: React.ReactChild | null = null
        let hasPaymentType = true
        if (this.state.payment) {
            button = <Button
                size='lg'
                rounded
                text={`Buy with ${this.state.payment.provider_name}`}
                disabled={this.checkoutEnabled() !== true || this.state.buying}
                onClick={() => {
                    this.buy()
                }}
                loading={this.state.buying} />
        } else if (this.state.payment_type) {
            switch (this.state.payment_type) {
                case PaymentProvider.APPLE_PAY:
                case PaymentProvider.CREDITCARD:
                    button = <StripeAppleCheckoutButton label={'Buy'}
                        amount={parseFloat(this.props.plan.price) * 100}
                        currency={'usd'}
                        onToken={(token) => {
                            return this.buy(token)
                        }} />
                    break;
                case PaymentProvider.MODFIND:
                case PaymentProvider.PAY_PAL:
                    button = <Button size='lg' rounded text={`Buy with ${this.state.payment_type}`}
                        disabled={this.checkoutEnabled() !== true || this.state.buying} onClick={() => {
                            this.buy()
                        }} loading={this.state.buying} />
                    if (this.state.payment_type == PaymentProvider.PAY_PAL && (this.state.payparlUrlLoading || !this.state.paypalUrl)) {
                        button = <ModFindLoader />
                    }
                    break;

            }
        } else {
            hasPaymentType = false
            button = <Button size='lg' rounded text={`Buy`}
                disabled={true} onClick={() => {

                }} loading={this.state.buying} />
        }
        return <Popup
            trigger={button}
            content={this.checkoutEnabled() === true ? (hasPaymentType ? undefined : 'Please select a payment type') : this.checkoutEnabled()}
            disabled={hasPaymentType && this.checkoutEnabled() === true} />
    }

    private getPaymentText() {
        if (this.state.payment) {
            if (this.state.payment.last_4) {
                return `${this.state.payment.provider_name} ending in ${this.state.payment.last_4}`
            } else {
                return this.state.payment.provider_name
            }
        } else if (this.state.payment_type) {
            return this.state.payment_type
        }
        return 'Select'
    }
}