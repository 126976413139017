import * as React from "react";
import { Brand, BrandCarGeneration } from "fullcircle-api";
import { Meta } from "../../cache/meta";
import { buildCarModelString, buildCarModelStringWithYear } from "../../api/model_helper";
import { DropdownProps, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import { VariableSizeList as List } from 'react-window';
import ReactDOM from 'react-dom';
import { measureTextLines } from "../../utils/getTextWidth";
import { t } from "i18next";
export interface ModelTypeDropDownProps {
    filteredBrands: Brand[]
    filteredYears: string[]
    selected: string[]
    onSelectedItemsChange: (items: string[]) => void
    disabled?: boolean
    style?: React.CSSProperties
    className?: string
    placeholder?: string
    dropDownOptions?: DropdownProps
    showYears?: boolean
}


type BrandDataSource = { brand_name: string, data: BrandCarGeneration[] }[]
type DDOption = { key: string, text: string, value?: string, disabled?: boolean, section: string, brandCarGeneration?: BrandCarGeneration }
type DDOptions = DDOption[]
// type DDOptions = BrandCarGeneration
export class ModelTypeDropDown extends React.Component<ModelTypeDropDownProps, {
    original: BrandCarGeneration[],
    options: DDOptions,
    loading: boolean,
    showModelTypeDropdown: boolean,
    searchQuery: string,
    searchResults: DDOptions,
    dropdownInitialized: boolean,

}> {
    private reloadTimer?: NodeJS.Timeout;
    private ModelTypeDropdownRef?: HTMLDivElement | null;
    private selectedModels: DDOptions = []

    constructor(props: ModelTypeDropDownProps) {
        super(props);
        this.state = {
            options: [],
            original: [],
            loading: true,
            showModelTypeDropdown: false,
            dropdownInitialized: false,
            searchQuery: '',
            searchResults: []
        }
        this.getItemSize = this.getItemSize.bind(this)
    }

    componentDidMount() {
        this.load()
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        this.reloadTimer && clearTimeout(this.reloadTimer)
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    componentDidUpdate(props: ModelTypeDropDownProps, state: { original: Array<BrandCarGeneration> }) {
        if (
            props.filteredBrands.length != this.props.filteredBrands.length ||
            this.state.original.length != state.original.length ||
            props.filteredYears.length != this.props.filteredYears.length
        ) {
            const items = this.prepareSectionData(this.state.original, this.props.filteredBrands)
            this.setState({ options: this.getOptions(items) }, () => {
                if (this.useSearchResults()) {
                    this.setState({ searchResults: this.search(this.state.searchQuery) })
                }
            })
        } else if (this.props.selected.length != this.selectedModels.length && this.state.original.length) {
            this.selectedModels = this.props.selected.map(id => this.state.original.find(o => o.id == id)!).filter(Boolean).map(this.brandCarGenerationToDOPtions)
            this.forceUpdate()
        }
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (this.state.showModelTypeDropdown && (!domNode || !domNode.contains(event.target))) {
            this.props.onSelectedItemsChange(this.selectedModels.map(i => i.value!))
            this.setState({
                showModelTypeDropdown: false
            });
        }
    }

    private prepareSectionData(items: BrandCarGeneration[], filteredBrands = this.props.filteredBrands, filteredYears = this.props.filteredYears) {
        const uniqueBrandNames = items.map(brand => brand.brand_name).unique()
        const filterBrands = filteredBrands.map(brand => brand.name)
        const brands = uniqueBrandNames.filter(name => {
            if (filterBrands.length === 0) return true
            else return filterBrands.includes(name)
        })

        return brands.map(brand => {
            const dataFilteredByYear = items.filter(b => {
                const yearIncluded = filteredYears.length === 0 || filteredYears.includes(b.model_year!)
                return yearIncluded && b.brand_name == brand
            })

            const data = dataFilteredByYear.map(item => {
                return {
                    id: item.id,
                    brand_name: item.brand_name,
                    brand_id: item.brand_id,
                    generation_name: item.generation_name,
                    model_name: item.model_name,
                    model_year: item.model_year,
                    modification_name: item.modification_name,
                    unique_models: (item.brand_name + ' ' + item.model_name)
                } as BrandCarGeneration & { unique_models: string }
            })

            return {
                brand_name: brand,
                data: data.unique('unique_models')
            }

        }).filter((section) => section.data.length)
    }


    private load() {
        Meta.getModelTypes().then(items => {
            this.setState({ options: this.getOptions(this.prepareSectionData(items)), original: items, loading: false }, () => {
                this.selectedModels = this.props.selected.map(id => this.state.options.find(o => o.key == id)).filter(Boolean) as DDOptions
            })
        }).catch(err => {
            this.reloadTimer = setTimeout(() => {
                this.load()
            }, 1000)
        })
    }

    findAllModelsForSelecton(selected: DDOption) {
        const current = selected.brandCarGeneration!

        return this.state.original.filter(f => {
            if (f.brand_name == current.brand_name) {
                if (f.model_name == current.model_name) {
                    if (this.props.filteredYears && this.props.filteredYears.length) {
                        return this.props.filteredYears.includes(f.model_year!)
                    } else {
                        return true
                    }
                }
            }
            return false
        }).map(this.brandCarGenerationToDOPtions)
    }

    brandCarGenerationToDOPtions(bc: BrandCarGeneration) {
        return {
            key: bc.id,
            value: bc.id,
            text: buildCarModelString(bc),
            section: bc.brand_name,
            brandCarGeneration: bc
        }
    }

    getOptions(items: BrandDataSource) {
        return items.reduce((prev, current) => {
            if (prev.length == 0 || prev[prev.length - 1].section != current.brand_name) {
                prev.push({
                    key: current.brand_name,
                    text: (
                        current.brand_name
                    ),
                    disabled: true,
                    section: current.brand_name, brandCarGeneration: undefined
                })
            }
            current.data.forEach(bc => {
                prev.push(this.brandCarGenerationToDOPtions(bc))
            })
            return prev
        }, [] as DDOptions)
    }

    search(query: string) {
        const selected = this.selectedModels.map(i => i.key)
        return this.state.options.filter(op => {
            return !selected.includes(op.key) && (query.trim() == '' || op.text.toLowerCase().includes(query.trim().toLowerCase()))
        })
    }

    useSearchResults() {
        return this.state.searchQuery.trim() != '' || this.selectedModels.length
    }

    getItems() {
        const { searchResults, options } = this.state
        if (this.useSearchResults()) {
            return searchResults
        }
        return options
    }

    percent(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
    }

    getItemSize(index: number) {
        if (this.getItems().length > index) {
            const maxWidth = (this.ModelTypeDropdownRef?.clientWidth || 200) - (16 * 2) - 12 // minus padding minus scroll bar width
            const lines = measureTextLines(this.getItems()[index].text, "400 14px Montserrat", maxWidth)
            if (lines > 1) {
                return lines * 30
            } else {
                return 37
            }
        }
        return 37
    }

    determineItemCount() {
        const items = this.getItems()
        if (this.useSearchResults() && (items.length === 0)) return 1
        return items.length
    }

    render() {
        const { searchQuery, options } = this.state
        const { showYears } = this.props

        return <div className="ModelTypeDropdown" ref={(ref) => this.ModelTypeDropdownRef = ref}>
            <div className={`list-wrapper`} onClick={() => {
                this.setState({ showModelTypeDropdown: true }, () => {
                    this.setState({ dropdownInitialized: true })
                })
            }}>

                {/* //^ Search Input */}
                <div className={`input-wrapper ${this.state.showModelTypeDropdown ? 'active' : ''} ${this.selectedModels.length ? 'has-selection' : ''}`}>
                    {
                        this.selectedModels.map(item => {
                            return { ...item, text: showYears ? buildCarModelStringWithYear(item.brandCarGeneration!) : buildCarModelString(item.brandCarGeneration!) }
                        }).unique('text').map((item, index) => {
                            return <div
                                key={item.key}
                                className="input-tag"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.selectedModels = this.selectedModels.filter((s) => {
                                        if (s.brandCarGeneration!.brand_name == item.brandCarGeneration!.brand_name) {
                                            if (s.brandCarGeneration!.model_name == item.brandCarGeneration!.model_name) {
                                                return false
                                            }
                                        }
                                        return true
                                    })
                                    this.props.onSelectedItemsChange(this.selectedModels.map(i => i.value!))
                                    this.setState({ searchResults: this.search(this.state.searchQuery) })
                                }}>
                                <span className="input-tag-text">
                                    {item.text}
                                </span>
                                <span className="delete-wrapper">
                                    <i className="delete icon" />
                                </span>
                            </div>
                        })
                    }

                    <input
                        className="model-type-input"
                        type="text" style={{ width: '100%' }}
                        placeholder={this.props.placeholder || (this.selectedModels.length > 0 ? t("Messages.SELECT_ANOTHER_HOR") : t("Messages.SELECT_HOR"))}
                        onChange={(e) => {
                            const searchResults = this.search(e.currentTarget.value)
                            this.setState({ searchResults, searchQuery: e.currentTarget.value })
                        }}
                    />

                    <div className="ui fluid dropdown">
                        <i className={("dropdown icon " + (options.length == 0 ? 'loading' : ''))}></i>
                    </div>
                </div>
                <div
                    key={this.state.dropdownInitialized.toString()}
                    style={{
                        display: this.state.showModelTypeDropdown ? 'block' : 'none'
                    }}>
                    <List
                        itemData={this.getItems()}
                        key={searchQuery + this.getItems().length}
                        className={this.props.className || 'model-type-dropdown'}
                        height={224}
                        itemCount={this.determineItemCount()}
                        estimatedItemSize={37}
                        itemSize={this.getItemSize}
                        width={'100%'}
                        style={{
                            position: 'absolute',
                            left: '-1px',
                            backgroundColor: '#fff',
                            zIndex: 10,
                            boxShadow: '0 2px 3px 0 rgba(34, 36, 38, 0.15)'
                        }}
                    >
                        {(props) => {
                            const item = props.data.length > props.index && props.data[props.index];
                            return (
                                <div
                                    className="react-window-item"
                                    style={(!item || !item.value) ? {
                                        color: 'rgba(0, 0, 0, 1)',
                                        cursor: 'default',
                                        fontWeight: 600,
                                        ...props.style
                                    } : props.style}
                                    onMouseOver={(e) => e.currentTarget.style.background = 'rgba(0, 0, 0, 0.05)'}
                                    onMouseOut={(e) => e.currentTarget.style.background = 'unset'}
                                    onClick={() => {
                                        if (!item || !item.value || !item.brandCarGeneration) return
                                        else if (this.selectedModels.filter(i => i.key === item.key).length === 0) {
                                            const actualSelection = this.findAllModelsForSelecton(item)
                                            this.selectedModels = ([...this.selectedModels, ...actualSelection] as DDOptions)
                                            this.props.onSelectedItemsChange(this.selectedModels.map(i => i.value!))
                                        }
                                        this.setState({ searchResults: this.search(this.state.searchQuery) })
                                    }}>
                                    {item && item.text ? item.text : 'No results'}
                                </div>
                            );
                        }}
                    </List>
                </div>
            </div>
        </div>
    }
}