import * as React from "react";
import { Post, DeliveryRequest, CardInterface, PostsAPI, User, DeliveryAPI, PaymentsAPI, DeliveryRequestStatus, UsersAPI, APIError } from "fullcircle-api";
import { PaymentProvider, buyPostOnApi, getPaypalAuthUrlForPost } from "../utils/payment";
import { Title, SubTitle } from "../components/generic/Title";
import { formatPriceI18n } from "../utils/formater";
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from "react-router-dom";
import { UserContextType } from "../context/user";
import { errorToString, hasShipping } from "../utils/helper";
import { ModFindLoader } from "../components/generic/ModFindLoader";
import { Container, Row, Col } from "reactstrap";
import { Image } from 'fullcircle-api/lib/models/image';
import toastr from "toastr";
import { SeparatorBox } from "../components/generic/Box";
import { Dropdown, Popup } from "semantic-ui-react";
import { ChangePayment } from "../components/views/payment/ChangePayment";
import ModFindTextarea from "../components/generic/ModFindTextArea";
import { BuyPostParameters, DeliveryAddressParameters, ProviderType } from "fullcircle-api/lib/endpoints/posts";
import Button from "../components/generic/Button";
import ModFindPopUp, { TitleMessageContent } from "../components/popup/ModFindPopUp";
import { AddCreditCard } from "../components/views/payment/AddCreditCard";
import { getPostUrl } from "../utils/path";
import { StripeAppleCheckoutButton } from '../components/StripeCheckoutButton'
import querystring from 'query-string'
import ModFindInput from "../components/generic/ModFindInput";
import { UserSession } from "../utils/session";
import { CouponUsageCalc } from "fullcircle-api/lib/endpoints/payment";
import * as _ from 'lodash'
import { DeliveryMethod, PostType } from "fullcircle-api/lib/models/post";
import ZipCodeRequiredPopup from "../components/popup/ZipCodeRequiredPopup";
import { Quantity } from "../components/views/post-detail/quantity";
import { calculateShipping, calculateTotal, compareVariantArray } from "../utils/post_util";
import { t } from "i18next";
import { Alert } from "../components/Alert";
import { SupportedCurrency } from "fullcircle-api/lib/models/customer";

export enum DeliveryState {
    PICK_UP = 'Arrange Pickup',
    DELIVER = 'Deliver to me'
}


interface CheckoutProps extends RouteComponentProps<{ postId: string, status: 'success' | 'error' }> {
}

interface CheckoutState {
    post?: Post
    deliveryRequest?: DeliveryRequest;
    delivery: DeliveryState;
    payment_type?: PaymentProvider;
    payment?: CardInterface;
    deliverComment: string
    redirect?: boolean
    phoneNumber: string

    payparlUrlLoading?: boolean
    paypalUrl?: string;

    paymentPopupOpen: boolean
    buying: boolean
    addCreditCard: boolean
    boughtMode?: 'bought' | 'not_paid'

    apiCoupon?: CouponUsageCalc
    coupon: string
    couponValid?: boolean

    first_name: string
    last_name: string
    street: string
    city: string
    state: string
    zip: string
    country: string

    quantity: number

    showZipRequiredPopup?: boolean

    loadingShippingRate: boolean
    shippingRate?: {
        id: string
        amount: string
    }
    shippingRateError?: string

    loadingTaxRate: boolean
    taxRate?: {
        totalTax: number
        taxCurrency: SupportedCurrency
    }
    taxRateError?: string

    selectedVariants: { [key: string]: string }
}

class Checkout extends React.Component<CheckoutProps, CheckoutState> {

    static contextType = UserContextType
    context: User | undefined
    prevContext: User | undefined

    private paymentPopupContext = React.createRef<any>()
    private didInitial: boolean = false;

    constructor(props: CheckoutProps) {
        super(props);
        this.state = {
            payment_type: PaymentProvider.PAY_PAL,
            delivery: DeliveryState.PICK_UP,
            paymentPopupOpen: false,
            deliverComment: '',
            buying: props.match.params.status == 'success' ? true : false,
            addCreditCard: false,
            phoneNumber: '',
            coupon: '',
            first_name: '',
            last_name: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            quantity: 1,
            loadingShippingRate: false,
            loadingTaxRate: false,
            selectedVariants: (props.location.state as any)?.selectedVariants || {}
        };
        this.checkCoupon = _.debounce(this.checkCoupon, 300).bind(this)
        this.loadTaxAndShipRate = _.debounce(this.loadTaxAndShipRate, 400).bind(this)
        this.loadPaypalAuthUrl = _.debounce(this.loadPaypalAuthUrl, 400).bind(this)
        this.loadTaxRate = _.debounce(this.loadTaxRate, 300).bind(this)
    }

    setInitialState() {
        this.setState({
            first_name: this.context?.full_name.split(' ')[0] || '',
            last_name: this.context?.full_name.split(' ')[1] || '',
            street: this.context?.default_address?.address_line || '',
            city: this.context?.default_address?.city || '',
            state: this.context?.default_address?.state || '',
            zip: this.context?.default_address?.zip || '',
            country: this.context?.default_address?.country || '',
            phoneNumber: this.context && this.context.phone_number || ''
        })
    }

    initialMount() {
        if (this.didInitial) {
            return
        }
        this.didInitial = true
        this.setInitialState()
        if (this.context && !this.context?.city) {
            this.setState({ showZipRequiredPopup: true })
        }
        Promise.all([
            DeliveryAPI.getDeliveriesForPost(this.props.match.params.postId).catch(err => undefined),
            PostsAPI.getPost(this.props.match.params.postId, undefined, this.context!.currency),
            PaymentsAPI.getCards()
        ]).then(([deliveryRequests, post, cards]) => {
            const deliveryRequest = deliveryRequests?.find((d) => d.status == DeliveryRequestStatus.Unpaid)
            const payment = (cards!.length > 0 && !this.props.match.params.status) ? cards![0] : undefined
            this.setState({
                post,
                delivery: hasShipping(post) ? DeliveryState.DELIVER : DeliveryState.PICK_UP,
                deliveryRequest: deliveryRequest,
                payment: payment,
                payment_type: payment ? undefined : this.state.payment_type,
                quantity: deliveryRequest?.quantity || 1,
                selectedVariants: deliveryRequest && deliveryRequest.variants ? deliveryRequest.variants?.options.reduce((prev, option) => {
                    prev[post!.variants?.variants.find(v => v.name == option.option)?.id!] = option.id
                    return prev
                }, {}) : {}
            }, () => {
                if (this.props.match.params.status != undefined) {
                    if (this.props.match.params.status == 'success') {
                        try {
                            const { PayerID, paymentId, boughtMode } = querystring.parse(this.props.location.search)
                            if (PayerID && paymentId) {
                                try {
                                    const state = JSON.parse(localStorage['paypal_checkout_params'])
                                    this.setState({ ...state }, () => {
                                        this.buy(post!, undefined, { customer_id: PayerID as string, token: paymentId as string })
                                    })
                                    return
                                } catch (error) {

                                }

                            } else {
                                this.setState({ buying: false })
                            }
                            if (boughtMode && boughtMode == 'bought' || boughtMode == 'not_paid') {
                                this.setState({ boughtMode: boughtMode as 'bought' | 'not_paid' })
                            }
                        } catch (err) {
                            this.setState({ buying: false })
                        }
                    } else {
                        toastr.error(t("ErrorMessages.SomethingWentWrong"))
                        this.setState({ buying: false })
                    }
                }
                this.loadTaxAndShipRate()
            })
            if (deliveryRequest && ![DeliveryRequestStatus.Cancelled, DeliveryRequestStatus.Unpaid].includes(deliveryRequest.status)) {
                this.props.history.push(getPostUrl(post!))
            }
            if (post!.user_id == this.context!.id) {
                this.setState({ redirect: true })
            }
        }).catch((err) => {
            toastr.error(errorToString(err).join('\n'))
            this.setState({ redirect: true })
        })
    }

    componentDidUpdate(): void {
        if (this.prevContext == undefined && this.context) {
            this.initialMount()
        }
        this.prevContext = this.context
    }

    componentDidMount() {
        this.prevContext = this.context
        if (this.context) {
            this.initialMount()
            return
        }
    }

    private loadTaxAndShipRate() {
        if (!this.state.post) {
            return
        }
        this.loadPaypalAuthUrl()
        if (this.state.delivery != DeliveryState.DELIVER || !this.state.post.calculate_shipping_price_at_checkout) {
            this.loadTaxRate()
            return
        }
        if (this.state.zip && this.state.country) {
            this.setState({ shippingRate: undefined, shippingRateError: undefined, loadingShippingRate: true })
            PostsAPI.getShippingRateForPost(this.state.post.id, {
                zip: this.state.zip,
                country: this.state.country,
                city: this.state.city,
                address_line: this.state.street,
                state: this.state.state,
                name: `${this.state.first_name} ${this.state.last_name}`
            }).then((rate) => {
                this.setState({ loadingShippingRate: false, shippingRate: rate }, () => this.loadTaxRate())
            }).catch(() => {
                this.setState({ shippingRate: undefined, shippingRateError: t("ErrorMessages.SHIPPING_RATE_CANT_CALC") as string, loadingShippingRate: false }, () => this.loadTaxRate())
            })
        } else {
            this.setState({ shippingRate: undefined, shippingRateError: undefined, loadingShippingRate: false }, () => this.loadTaxRate())
        }
    }

    private loadTaxRate() {
        if (!this.state.post) {
            return
        }
        const deliveryAddress = {
            zip: this.state.zip,
            country: this.state.country,
            city: this.state.city,
            address_line: this.state.street,
            state: this.state.state,
        }
        if (!deliveryAddress.city || !deliveryAddress.country || !deliveryAddress.state) {
            return;
        }
        this.setState({ taxRate: undefined, taxRateError: undefined, loadingTaxRate: true })
        PostsAPI.calculateVAT(this.state.post.id, {
            quantity: this.state.quantity,
            coupon: this.state.apiCoupon?.coupon,
            will_pickup: this.state.delivery == DeliveryState.PICK_UP,
            variants: Object.values(this.state.selectedVariants),
            shipping_calc_id: this.state.shippingRate?.id,
            delivery_address: deliveryAddress
        }).then((rate) => {
            this.setState({ loadingTaxRate: false, taxRate: rate })
        }).catch((err) => {
            this.setState({ taxRate: undefined, taxRateError: err.message as string, loadingTaxRate: false })
        })

    }

    private getPaymentText() {
        if (this.state.payment) {
            if (this.state.payment.last_4) {
                return `${this.state.payment.provider_name} ending in ${this.state.payment.last_4}`
            } else {
                return this.state.payment.provider_name
            }
        } else if (this.state.payment_type) {
            return this.state.payment_type
        }
        return 'Select'
    }

    private async buy(post: Post, applePayToken?: string, payPal?: { token: string, customer_id: string }) {
        let params: BuyPostParameters = this.getButPostParamters(post)
        this.setState({ buying: true })
        if (this.context && this.context.phone_number != this.state.phoneNumber && this.state.phoneNumber.length > 0) {
            try {
                const u = await UsersAPI.updateProfile({ phone_number: this.state.phoneNumber })
                UserSession.getUserUpdatedHandler()(u)
            } catch (err) {
                toastr.error(errorToString((err as APIError)).join('\n'))
                return
            }
        }
        if (this.state.payment || applePayToken) {
            return new Promise<void>((resolve, reject) => {

                buyPostOnApi(this.state.deliveryRequest !== undefined,
                    this.state.deliveryRequest !== undefined ? this.state.deliveryRequest.id : post.id,
                    Object.assign({}, params, {
                        payment_info: {
                            [applePayToken ? 'token' : 'payment_method']: applePayToken || this.state.payment!.id,
                            provider: applePayToken ? ProviderType.Stripe : this.state.payment!.provider
                        }
                    }),
                    (error?: string, s?: DeliveryRequest) => {
                        if (error) {
                            reject(error)
                            toastr.error(error, undefined, { timeOut: 0, extendedTimeOut: 0 })
                            this.setState({ buying: false })
                        } else if (s) {
                            resolve()
                            this.setState({ boughtMode: s.status === DeliveryRequestStatus.Unpaid ? 'not_paid' : 'bought' })
                        } else {
                            reject()
                            this.setState({ buying: false })
                            toastr.error(t("ErrorMessages.SomethingWentWrong"))
                        }
                    })
            })

        } else if (this.state.payment_type) {
            if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                if (payPal) {
                    return new Promise<void>((resolve, reject) => {
                        buyPostOnApi(this.state.deliveryRequest !== undefined,
                            this.state.deliveryRequest !== undefined ? this.state.deliveryRequest.id : post.id,
                            Object.assign({}, params, {
                                payment_info: {
                                    ...payPal,
                                    provider: ProviderType.Paypal
                                }
                            }),
                            (error?: string, s?: DeliveryRequest) => {
                                if (error) {
                                    reject(error)
                                    toastr.error(error, undefined, { timeOut: 0, extendedTimeOut: 0 })
                                    this.setState({ buying: false })
                                    if (this.props.match.params.status) {
                                        location.href = location.href.substr(0, location.href.indexOf('/paypal/'))
                                    }
                                } else if (s) {
                                    resolve()
                                    this.setState({ boughtMode: s.status === DeliveryRequestStatus.Unpaid ? 'not_paid' : 'bought' })
                                    location.search = `?boughtMode=${s.status === DeliveryRequestStatus.Unpaid ? 'not_paid' : 'bought'}`
                                } else {
                                    reject()
                                    this.setState({ buying: false })
                                    toastr.error(t("ErrorMessages.SomethingWentWrong"))
                                    if (this.props.match.params.status) {
                                        location.href = location.href.substr(0, location.href.indexOf('/paypal/'))
                                    }
                                }
                            })
                    })
                } else {
                    localStorage['paypal_checkout_params'] = JSON.stringify(this.state)
                    if (this.state.paypalUrl) {
                        location.href = this.state.paypalUrl
                        return Promise.resolve()
                    }
                }
            }
        }
        return Promise.reject('Unknown payment method')
    }

    getButPostParamters(post: Post) {
        let kickback: string | undefined = undefined
        try {
            let kickBackIds = JSON.parse(sessionStorage['currentKickBack'])
            kickback = kickBackIds[post.id]
        } catch (error) {

        }
        return {
            payment_info: {} as any,
            coupon: this.state.apiCoupon?.coupon,
            will_pickup: this.state.delivery == DeliveryState.PICK_UP,
            pickup_comment: this.state.delivery == DeliveryState.PICK_UP ? this.state.deliverComment : undefined,
            variants: Object.values(this.state.selectedVariants),
            delivery_address: {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                address_line: this.state.street,
                state: this.state.state,
                country: this.state.country,
                city: this.state.city,
                zip: this.state.zip
            } as DeliveryAddressParameters,
            kickback_identifier: kickback,
            quantity: this.state.quantity,
            shipping_calc_id: this.state.shippingRate?.id
        }
    }

    loadPaypalAuthUrl() {
        if (this.checkoutEnabled() !== true || this.state.payment_type !== PaymentProvider.PAY_PAL) {
            return
        }
        this.setState({ paypalUrl: undefined, payparlUrlLoading: true })
        getPaypalAuthUrlForPost(this.state.deliveryRequest !== undefined,
            this.state.deliveryRequest !== undefined ? this.state.deliveryRequest.id : this.state.post!.id,
            this.getButPostParamters(this.state.post!)).then((url) => {
                this.setState({ paypalUrl: url.url, payparlUrlLoading: false })
            }).catch(err => {
                toastr.error(errorToString(err).join('\n'))
                this.setState({ payparlUrlLoading: false })
            })
    }

    private checkCoupon() {
        if (this.state.coupon.trim() != '' && this.state.post) {
            PaymentsAPI.checkCoupon(this.state.post.id, this.state.coupon, this.state.delivery == DeliveryState.PICK_UP, this.state.quantity, Object.values(this.state.selectedVariants)).then((data) => {
                if (this.state.coupon && data.coupon.name.toLowerCase() == this.state.coupon.toLowerCase()) {
                    this.setState({ apiCoupon: data.info, couponValid: true }, () => {
                        this.loadTaxRate()
                        if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                            this.loadPaypalAuthUrl()
                        }
                    })
                }
            }).catch(err => {
                const reloadTax = this.state.apiCoupon !== undefined
                this.setState({ couponValid: false, apiCoupon: undefined }, () => {
                    if (reloadTax) {
                        this.loadTaxRate()
                    }
                    if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                        this.loadPaypalAuthUrl()
                    }
                })
            })
        } else {
            const reloadTax = this.state.apiCoupon !== undefined
            this.setState({ couponValid: undefined, apiCoupon: undefined, coupon: '' }, () => {
                if (reloadTax) {
                    this.loadTaxRate()
                }
                if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                    this.loadPaypalAuthUrl()
                }
            })
        }
    }

    private checkoutEnabled(): true | string {
        if (this.state.phoneNumber.length == 0) {
            return t("ErrorMessages.NoPhone") as string
        }
        if (this.state.post?.variants && this.state.post.variants?.variants.length) {
            const notSelected = this.state.post.variants.variants.map(({ id, name }) => {
                if (this.state.selectedVariants[id]) {
                    return false
                }
                return name
            }).find(Boolean)
            if (notSelected) {
                return t("ErrorMessages.SelectAllOptions", { replace: { option: notSelected } }) as string
            }
        }
        const { first_name, state, last_name, street, city, zip, country } = this.state
        if (
            (first_name.trim() === '') ||
            (last_name.trim() === '') ||
            (street.trim() === '') ||
            (city.trim() === '') ||
            (state.trim() === '') ||
            (zip.trim() === '') ||
            (country.trim() === '')) {
            return t("ErrorMessages.InvalidAddress") as string
        }
        if (this.state.post?.calculate_shipping_price_at_checkout) {
            if (this.state.shippingRate == undefined) {
                return t("ErrorMessages.SHIPPING_RATE_CANT_CALC") as string
            }
        }
        return true
    }

    renderVariants() {
        if (this.state.post?.variants && this.state.post.variants?.variants.length) {
            return this.state.post.variants.variants.map((variant) => {
                return <SeparatorBox direction={'row'} key={variant.id}>
                    <span className="item-title">{variant.name}</span>
                    <span className="item blue deal-indicator dropdown-container">
                        <Dropdown disabled={this.state.deliveryRequest !== undefined}
                            options={variant.options.map((o) => ({ text: o.name, value: o.id }))}
                            value={this.state.selectedVariants[variant.id]} onChange={(_, { value }) => {
                                const ids = { ...this.state.selectedVariants, [variant.id]: value as string }
                                let disabled = this.state.post?.variants?.disabled.find((price) => {
                                    return compareVariantArray(Object.values(ids), price.options, this.state.post?.variants?.variants.length);
                                }) != undefined
                                if (disabled) {
                                    Alert.show(t("Messages.COMBINATION_NOT_AVAILABLE"), () => { })
                                } else {
                                    this.setState({ selectedVariants: ids }, () => this.loadTaxRate())
                                }
                            }} />
                    </span>
                </SeparatorBox>
            })
        }
        return null
    }

    renderTax() {
        if (this.state.taxRate) {
            return <span><span className="estimated-tax">{t("Messages.INCL_ESTIMATED_TAX")}:</span> <span className="blue-text">{formatPriceI18n(this.state.taxRate!.totalTax, this.state.taxRate!.taxCurrency)}</span></span>
        }
        if (this.state.taxRateError) {
            return <span className="tax-error">{this.state.taxRateError}</span>
        }
        if (this.state.loadingTaxRate) {
            return <div className="tax-loading"><span className="estimated-tax">{t("Messages.ESTIMATED_TAX")}:</span> <span className="tax-loader"><ModFindLoader size={10} /></span></div>
        }
    }

    render() {
        const { post, redirect } = this.state
        if (redirect) {
            return <Redirect to={'/home'} />
        }
        if (!post) {
            return <ModFindLoader inMain />
        }
        const variantQuantity = this.state.post?.variants?.quantities.find((price) => {
            return compareVariantArray(Object.values(this.state.selectedVariants), price.options, this.state.post?.variants?.variants.length);
        })

        return <Container className="checkout-container">
            {this.renderChangeZip()}
            {this.state.paymentPopupOpen && <ModFindPopUp
                onClose={() => {
                    this.setState({ paymentPopupOpen: false })
                }}
            >
                <ChangePayment amount={calculateTotal(post, this.state.delivery, this.state.quantity, Object.values(this.state.selectedVariants), this.state.shippingRate?.amount)}
                    onAddCreditCard={() => {
                        this.setState({ addCreditCard: true })
                    }}
                    selectedPaymentType={this.state.payment || this.state.payment_type}
                    additional_provider={[PaymentProvider.APPLE_PAY, PaymentProvider.PAY_PAL]}
                    onPaymenTypeSelected={(pymentType) => {
                        if (pymentType == PaymentProvider.PAY_PAL) {
                            this.loadPaypalAuthUrl()
                        }
                        this.setState({ payment_type: pymentType, payment: undefined, paymentPopupOpen: false })
                    }} onPaymentSelected={(payment) => {
                        this.setState({ payment, paymentPopupOpen: false })
                    }}
                />
            </ModFindPopUp>}
            {this.state.addCreditCard && <ModFindPopUp onClose={() => {
                this.setState({ addCreditCard: false })
            }}>
                <AddCreditCard onAdded={(card) => {
                    this.setState({ addCreditCard: false, payment: card })
                }} onCancel={() => {
                    this.setState({ addCreditCard: false })
                }} />
            </ModFindPopUp>}
            {this.state.boughtMode && <ModFindPopUp onClose={() => {
                this.props.history.push(getPostUrl(post))
            }}>
                <TitleMessageContent title={this.state.boughtMode == 'not_paid' ? t("Messages.PAYMENT_FAILED") : t("Messages.CONGRATS")}
                    message={this.state.boughtMode == 'not_paid' ? t("Messages.PAYMENT_FAILED_INFO") : t("Messages.BOUGHT_ITEM")}
                    icon={this.state.boughtMode == 'not_paid' ? 'info' : 'success'} />
            </ModFindPopUp>}
            <div className='checkout'>
                <Row>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col className="image-or-video-wrapper">
                        {post.images && post.images.length > 0 ? this.renderImageOrVideo(post.images[0]) : null}
                    </Col>
                    <Col className='action-list checkout-content'>
                        <Title>{t("Messages.buyingQuantityTitleFor", { quantity: this.state.quantity.toString(), title: post.title })} <span className="blue-text" >{formatPriceI18n(calculateTotal(post, this.state.delivery, this.state.quantity, Object.values(this.state.selectedVariants), this.state.shippingRate?.amount, this.state.taxRate?.totalTax.toFixed(2)), post.currency)}</span></Title>
                        <div>
                            {this.renderTax()}
                        </div>
                        {this.state.deliveryRequest && this.state.deliveryRequest.status == DeliveryRequestStatus.Unpaid && <p><SubTitle>
                            {t("Messages.PAYMENT_FAILED_INFO")}</SubTitle></p>}
                        <div className="under-title-inset" />
                        <SeparatorBox
                            direction={'row'} onBoxClick={() => {
                                this.setState({ paymentPopupOpen: !this.state.paymentPopupOpen })
                            }}>
                            <div
                                className="payment-block"
                                ref={this.paymentPopupContext}
                            >
                                <span className="item-title">{t("Messages.PAYMENT")}</span>
                                <div className="item blue deal-indicator">{this.getPaymentText()}</div>
                            </div>
                        </SeparatorBox>
                        {post.type != PostType.Single && <SeparatorBox direction={'row'}>
                            <span className="item-title">{t("Messages.QUANTITY")}</span>
                            <span className="item blue deal-indicator">
                                {this.state.deliveryRequest && this.state.deliveryRequest.quantity}
                                {!this.state.deliveryRequest && <Quantity onQuantityChanged={(qty) => {
                                    this.setState({ quantity: qty }, () => this.loadTaxRate())
                                }} quantity={this.state.quantity} maxQuantity={variantQuantity?.quantity || this.state.post!.quantity || 1} />}
                            </span>
                        </SeparatorBox>}
                        {this.renderVariants()}
                        <SeparatorBox direction={'row'}>
                            <span className="item-title">{t("Messages.DELIVERY")}</span>
                            <span className="item blue deal-indicator dropdown-container">
                                <Dropdown disabled={this.state.post && this.state.post?.delivery_method !== DeliveryMethod.PICKUP_SHIP ? true : false}
                                    options={[
                                        { value: DeliveryState.DELIVER, text: t("Messages.SHIP_TO_ME") },
                                        { value: DeliveryState.PICK_UP, text: t("Messages.LOCAL_PICKUP") }]}
                                    value={this.state.delivery} onChange={(_, { value }) => {
                                        this.setState({ delivery: value as DeliveryState }, () => this.loadTaxRate())
                                    }} />
                            </span>
                        </SeparatorBox>
                        <SeparatorBox direction={'row'}>
                            <div className="item-title high-title" >{t("Messages.PHONE")}</div>
                            <ModFindInput
                                type={'number'}
                                className="deal-indicator"
                                value={this.state.phoneNumber}
                                colored
                                rounded
                                containerSize={'sm'}
                                info={{ text: t("Messages.BUY_PHONE_INFO") }}
                                onValueChanged={(value) => {
                                    this.setState({ phoneNumber: value })
                                }}
                            />
                        </SeparatorBox>
                        <SeparatorBox direction={'row'}>
                            <div className="item-title high-title">{t("Messages.COUPON")}</div>
                            <ModFindInput
                                className="deal-indicator"
                                value={this.state.coupon}
                                colored
                                rounded
                                containerSize={'sm'}
                                info={{
                                    text: (() => {
                                        if (this.state.couponValid === false)
                                            return 'Coupon not found or valid'
                                        if (this.state.couponValid == true && this.state.apiCoupon) {
                                            return `Coupon applied. You'll save ${formatPriceI18n(this.state.apiCoupon.off_amount, this.state.apiCoupon.currency)} on this purchase!`
                                        }
                                        return ''
                                    })(), err: this.state.couponValid === false
                                }}
                                onValueChanged={(value) => {
                                    this.setState({ coupon: value }, () => {
                                        this.checkCoupon()
                                    })
                                }}
                            />
                        </SeparatorBox>
                        <SeparatorBox
                            className="address-section"
                            direction={'column'}>
                            <div className="item-title high-title">{t("Messages.SHIP_TO_ADDRESS")}</div>
                            <div className="address-input-flex">
                                <ModFindInput
                                    className="two-col-input"
                                    value={this.state.first_name}
                                    placeholder={t("Messages.FIRST_NAME")}
                                    colored
                                    rounded
                                    containerSize={'sm'}
                                    onValueChanged={(value) => {
                                        this.setState({ first_name: value }, () => this.loadPaypalAuthUrl())
                                    }}
                                />
                                <ModFindInput
                                    className="two-col-input"
                                    placeholder={t("Messages.LAST_NAME")}
                                    value={this.state.last_name}
                                    colored
                                    rounded
                                    containerSize={'sm'}
                                    onValueChanged={(value) => {
                                        this.setState({ last_name: value }, () => this.loadPaypalAuthUrl())
                                    }}
                                />
                            </div>
                            <ModFindInput
                                className="full-input"
                                placeholder={t("Messages.STREET")}
                                value={this.state.street}
                                colored
                                rounded
                                containerSize={'sm'}
                                onValueChanged={(value) => {
                                    this.setState({ street: value }, () => this.loadTaxAndShipRate())
                                }}
                            />
                            <ModFindInput
                                className="full-input"
                                placeholder={t("Messages.CITY")}
                                value={this.state.city}
                                colored
                                rounded
                                containerSize={'sm'}
                                onValueChanged={(value) => {
                                    this.setState({ city: value }, () => this.loadTaxAndShipRate())
                                }}
                            />
                            <div className="address-input-flex">
                                <ModFindInput
                                    className="two-col-input"
                                    value={this.state.state}
                                    placeholder={t("Messages.STATE")}
                                    colored
                                    rounded
                                    containerSize={'sm'}
                                    onValueChanged={(value) => {
                                        this.setState({ state: value }, () => this.loadTaxAndShipRate())
                                    }}
                                />
                                <ModFindInput
                                    className="two-col-input"
                                    placeholder={t("Messages.ZIP_CODE")}
                                    value={this.state.zip}
                                    colored
                                    rounded
                                    containerSize={'sm'}
                                    onValueChanged={(value) => {
                                        this.setState({ zip: value }, () => this.loadTaxAndShipRate())
                                    }}
                                />
                            </div>
                            <ModFindInput
                                className="full-input"
                                placeholder={t("Messages.COUNTRY")}
                                value={this.state.country}
                                colored
                                rounded
                                containerSize={'sm'}
                                onValueChanged={(value) => {
                                    this.setState({ country: value }, () => this.loadTaxAndShipRate())
                                }}
                            />
                        </SeparatorBox>
                        {this.state.delivery == DeliveryState.DELIVER && this.state.post?.calculate_shipping_price_at_checkout &&
                            <div className="delivery-info-container">
                                {this.state.loadingShippingRate && <ModFindLoader />}
                                {!this.state.loadingShippingRate && this.state.shippingRateError && <span>
                                    <span className="shipping-error">{this.state.shippingRateError}</span>
                                </span>}
                                {!this.state.loadingShippingRate && !this.state.shippingRateError && !this.state.shippingRate && <span>
                                    <span className="add-address-message">{t("Messages.ENTER_ADDRESS_SHIPPING")}</span>
                                </span>}
                                {!this.state.loadingShippingRate && this.state.shippingRate && <span>
                                    <span className="shipping-total-text">{t("Messages.SHIPPING_COST_TOTAL")}: <span className="shipping-total-value">{formatPriceI18n(calculateShipping(this.state.quantity, this.state.shippingRate.amount!), post.currency)}</span></span>
                                </span>}
                            </div>
                        }
                        {this.state.delivery == DeliveryState.PICK_UP && <SeparatorBox direction={'row'}>
                            <span className="item">
                                <ModFindTextarea value={this.state.deliverComment} placeholder={t("Messages.SUGGEST_TIME_AND_DAY")}
                                    onValueChanged={(val) => this.setState({ deliverComment: val })} />
                            </span>
                        </SeparatorBox>}

                        <div className='buy-button-container'>
                            {this.renderBuyButton()}
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    }

    private renderChangeZip() {
        if (this.state.showZipRequiredPopup) {
            return <ZipCodeRequiredPopup user={this.context!} onClose={() => {
                if (this.props.history.length > 2) {
                    this.props.history.goBack()
                } else {
                    this.props.history.replace('/')
                }
            }} onUserUpdated={() => {
                this.setState({ showZipRequiredPopup: false })
            }} />
        }
        return null
    }

    private renderBuyButton() {

        let button: React.ReactChild | null = null
        if (this.state.payment) {
            button = <Button
                size='lg'
                rounded
                text={t("Messages.BUY_WITH", { payment_type: this.state.payment.provider_name })}
                disabled={this.checkoutEnabled() !== true || this.state.buying}
                onClick={() => {
                    this.buy(this.state.post!)
                }}
                loading={this.state.buying} />
        } else if (this.state.payment_type) {
            switch (this.state.payment_type) {
                case PaymentProvider.APPLE_PAY:
                case PaymentProvider.CREDITCARD:
                    button = <StripeAppleCheckoutButton label={this.state.post!.title}
                        amount={parseFloat(calculateTotal(this.state.post!, this.state.delivery, this.state.quantity, Object.values(this.state.selectedVariants), this.state.shippingRate?.amount)) * 100}
                        currency={this.state.post!.currency}
                        onToken={(token) => {
                            return this.buy(this.state.post!, token)
                        }} />
                    break;
                case PaymentProvider.MODFIND:
                case PaymentProvider.PAY_PAL:
                    button = <Button size='lg' rounded text={t("Messages.BUY_WITH", { payment_type: this.state.payment_type })}
                        disabled={this.checkoutEnabled() !== true || this.state.buying} onClick={() => {
                            this.buy(this.state.post!)
                        }} loading={this.state.buying} />
                    if (this.state.payment_type == PaymentProvider.PAY_PAL && this.checkoutEnabled() === true && (this.state.payparlUrlLoading || !this.state.paypalUrl)) {
                        button = <ModFindLoader />
                    }
                    break;

            }
        }
        return <Popup
            trigger={button}
            content={this.checkoutEnabled() === true ? undefined : this.checkoutEnabled()}
            disabled={this.checkoutEnabled() === true} />
    }

    private renderImageOrVideo(image: Image) {
        let imageSrc = image.medium_url

        if (image.type === 'video') {
            imageSrc = image.thumbnail_url
        } else {
            return (
                <div className="detail-image-container">
                    <img
                        src={imageSrc}
                        className="detail-image"
                    />
                </div>
            )
        }
    }
}

export default withRouter(class extends React.Component<RouteComponentProps<{ page: string }>> {
    render() {
        return <Switch>
            <Route path={`/${this.props.match.params.page}/:postId/paypal/:status`} component={Checkout} />
            <Route path={`/${this.props.match.params.page}/:postId`} component={Checkout} />
            <Route path={`/${this.props.match.params.page}`} render={() => <Redirect to={'/home'} />} />
        </Switch>
    }
})