import { Category, PaginationResponse, Post, PostDraft, PostsAPI, PostSortOrder, PostStatus, User } from "fullcircle-api";
import { t } from "i18next";
import debounce from "lodash/debounce";
import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ChatHandler from "../../api/chathandller";
import { Content } from "../../api/events";
import { IC_INSTAGRAM_BLACK, IC_MARKER_BLACK, LOGO_CAR, IC_VERIFY } from "../../assets";
import { ModFindPager } from "../../components/generic/ModFindPager";
import { Title } from "../../components/generic/Title";
import ModFindGrid from "../../components/grid/ModFindGrid";
import { MyAccount } from "../../components/views/account/MyAccount";
import { ModFindRating } from "../../components/views/ModFindRating";
import { UserContextType } from "../../context/user";
import { isPost, isUser } from "../../utils/helper";
import { getPostUrl, getUserUrl } from "../../utils/path";

interface VendorLayoutState {
    selling?: { posts: Post[], pagination: PaginationResponse };
    sellingPage: number
    searchText: string;
    buying?: Post[];
    sold?: Post[];
    drafts?: PostDraft[]
    online: boolean
}

class VendorLayout extends React.Component<RouteComponentProps & { isPrivate: boolean, user: User, onRequestPayout: () => void }, VendorLayoutState> {

    static contextType = UserContextType
    context!: User

    private get isPrivate() {
        return this.props.isPrivate
    }

    private get user() {
        return this.props.user
    }

    private get business() {
        return this.props.user.business!
    }

    constructor(props: any) {
        super(props)
        this.state = {
            online: false,
            sellingPage: 0,
            searchText: ''
        }
        this.loadData()
        this.onlineListener = this.onlineListener.bind(this)
        this.loadSelling = debounce(this.loadSelling, 300, { leading: false, trailing: true })
    }

    componentDidMount() {
        ChatHandler.instance()?.subscribeOnlineStatus(this.user.id, this.onlineListener).then(({ online }) => {
            this.setState({ online })
        })
    }

    componentWillUnmount() {
        ChatHandler.instance() && ChatHandler.instance()?.unsubscribeOnlineStatus(this.user.id, this.onlineListener)
    }

    onlineListener({ online }: Content.Incoming.OnlineStatusDidChange) {
        this.setState({ online })
    }

    private loadSelling() {
        //my posts (private/public)
        this.isPrivate ?
            PostsAPI.searchPosts(PostSortOrder.Newest, 30, this.state.sellingPage * 30, {
                user: this.user.id,
                ignore_sold_date: true,
                include_expired: true,
                search: this.state.searchText
            }).then((data) => {
                this.setState({ selling: data });
            }).catch(() => { })
            :
            PostsAPI.searchPosts(PostSortOrder.Newest, 30, this.state.sellingPage * 30, {
                user: this.user.id,
                status: [PostStatus.Public, PostStatus.Pending],
                ignore_sold_date: true,
                search: this.state.searchText
            }).then((data) => {
                this.setState({ selling: data });
            }).catch(() => { });

    }

    private loadData() {
        this.loadSelling()
        //bought
        if (this.isPrivate) {
            PostsAPI.searchPosts(PostSortOrder.Newest, -1, 0, {
                bought: true,
                ignore_sold_date: true,
            }).then(({ posts }) => {
                this.setState({ buying: posts });
            }).catch(() => { })
        }
        if (this.isPrivate) {
            PostsAPI.searchPosts(PostSortOrder.Newest, 30, 0, {
                user: this.user.id,
                status: [PostStatus.Sold, PostStatus.PrivateSold],
                ignore_sold_date: true,
            }).then(({ posts }) => {
                this.setState({ sold: posts });
            }).catch(() => { });
        }


        this.isPrivate && PostsAPI.getPostDrafts().then((drafts) => {
            this.setState({ drafts })
        })
    }


    onItemClick(item: User | Post | Category | PostDraft) {
        if (isUser(item)) {
            this.props.history.push(getUserUrl(item))
        } else if (isPost(item)) {
            this.props.history.push(getPostUrl(item))
        } else {
            this.props.history.push('/edit/drafts/' + item.id)
        }
    }

    render() {
        return <>
            <div className='profile-info'>
                {this.renderProfileInfo()}
            </div>
            <div className='profile-items'>
                <ModFindPager tabs={this.isPrivate ?
                    [t("Messages.MY_ACCOUNT"), t("Messages.MY_POSTS"), t("Messages.SOLD"), t("Messages.DRAFTS"), t("Messages.BOUGHT"), t("Messages.MY_RATINGS")]
                    : [t("Messages.SELLING"), this.business ? undefined! : t("Messages.SOLD"), t("Messages.ABOUT"), this.context ? t("Messages.RATING") : undefined!].filter(Boolean)}>
                    {this.isPrivate && <MyAccount onRequestPayout={this.props.onRequestPayout} />}
                    <ModFindGrid
                        items={this.state.selling?.posts}
                        paging={this.state.selling?.pagination}
                        onSearchChanged={(text) => {
                            this.setState({ searchText: text }, () => this.loadSelling())
                        }}
                        onPage={(page) => {
                            this.setState({ sellingPage: page, selling: undefined }, () => {
                                this.loadSelling()
                            })
                        }}
                        loading={this.state.selling == undefined}
                        type='POSTS'
                        empty={<h5>
                            {this.isPrivate ? t("Messages.NO_ITEMS") : t("Messages.userIsNotSellingRightNow", { full_name: this.user.full_name })}
                        </h5>}
                        onItemClick={(item) => {
                            this.onItemClick(item)
                        }} currentUser={this.context}
                    />
                    {this.isPrivate && <ModFindGrid items={this.state.sold}
                        loading={this.state.sold == undefined}
                        type='POSTS'
                        empty={<h5>
                            {this.isPrivate ? t("Messages.NO_ITEMS") : t("Messages.userHasNotSoldYet", { full_name: this.user.full_name })}
                        </h5>}
                        onItemClick={(item) => {
                            this.onItemClick(item)
                        }} currentUser={this.context}
                    />}
                    {this.isPrivate && <ModFindGrid items={this.state.drafts}
                        loading={this.state.drafts == undefined}
                        type='DRAFTS'
                        empty={<h5>
                            {t("Messages.NO_SAVED_DRAFTS")}
                        </h5>}
                        onItemClick={(item) => {
                            this.onItemClick(item)
                        }} currentUser={this.context}
                    />}
                    {this.isPrivate && <ModFindGrid items={this.state.buying}
                        loading={this.state.buying == undefined}
                        type='POSTS'
                        empty={<h5>
                            {this.isPrivate ? t("Messages.NO_ITEMS") : t("Messages.userHasNotSoldYet", { full_name: this.user.full_name })}
                        </h5>}
                        onItemClick={(item) => {
                            this.onItemClick(item)
                        }} currentUser={this.context}
                    />}
                    {!this.isPrivate && <div className="business-info-wrapper">
                        <Title className="business-info-title">{this.business.name}</Title>
                        {this.user.bio != undefined && <div className='profile-info-item'>
                            <div className='profile-bio'>{this.user.bio}</div>
                        </div>}
                    </div>}
                    {this.context && <ModFindRating user={this.user} />}
                </ModFindPager>
            </div>
        </>
    }

    private renderProfileInfo() {
        return <>
            <div className="header-name-online-wrapper">
                <div className='profile-name'>{this.user.full_name}</div>
                {this.context && this.state.online && (this.user.id !== this.context.id) && <span className="online-indicator"></span>}
            </div>
            <div className='profile-info-item first'>
                <span className="item-text">
                    {t("Messages.MEMBER_SINCE") + " " + moment(this.user.created_at).fromNow()}
                </span>
            </div>
            <div className='profile-info-item'>
                <div className="img-container">
                    <img src={IC_VERIFY} />
                </div>
                <span className={!this.user.verification_complete ? 'empty' : ''}>
                    {this.user.verification_complete ? t("Messages.CONFIRMED") : t("Messages.NOT_VERIFIED")}
                </span>
            </div>
            <div className='profile-info-item link' onClick={() => {
                Boolean(this.user.instagram_handle) ? window.open("https://www.instagram.com/" + this.user.instagram_handle, '__blank') : undefined
            }}>
                <div className="img-container">
                    <img src={IC_INSTAGRAM_BLACK} />
                </div>
                <span className={!Boolean(this.user.instagram_handle) ? 'empty' : ''} >
                    {Boolean(this.user.instagram_handle) ? this.user.instagram_handle : t("Messages.NO_INSTAGRAM")}
                </span>
            </div>
            <div className='profile-info-item'>
                <div className="img-container">
                    <img src={LOGO_CAR} />
                </div>
                <span className={!Boolean(this.user.car_type) ? 'empty' : ''}>
                    {Boolean(this.user.car_type) ? this.user.car_type : t("Messages.NO_CAR_TYPE")}
                </span>
            </div>
            <div className='profile-info-item'>
                <div className="img-container">
                    <img src={IC_MARKER_BLACK} />
                </div>
                <span className={!Boolean(this.user.state) ? 'empty' : ''}>
                    {Boolean(this.user.state) ? this.user.state : t("Messages.NO_STATE")}
                </span>
            </div>
        </>
    }
}

export default withRouter(VendorLayout)