import MemoryCache from 'memory-cache'
import { MetaAPI, Brand, PostsAPI, Post, User, UsersAPI, SavedSearch } from 'fullcircle-api'

enum CacheKeys {
    POSTS = 'posts',
    USER = 'users',
    SEARCHES = 'searches',
    BRANDS = 'brands'
}
export class Favorite {

    private static userId: string | undefined

    static clear() {
        MemoryCache.clear()
    }

    static setUserId(userId: string | undefined) {
        Favorite.userId = userId;
    }

    private static brandLoadingPromise: Promise<Brand[]> | undefined
    private static savedSearchLoadingPromise: Promise<SavedSearch[]> | undefined
    private static userLoadingPromise: Promise<User[]> | undefined
    private static postLoadingpromise: Promise<Post[]> | undefined

    static isFavoritePost(postId: string) {
        if (!Favorite.userId) {
            return Promise.resolve(false)
        }
        return Favorite.getFavoritePosts().then((posts) => {
            return posts.find(post => post.id === postId) != undefined
        }).catch(() => false)
    }

    static getFavoritePosts() {
        if (!Favorite.userId) {
            return Promise.resolve([])
        }
        const stored = MemoryCache.get(`${CacheKeys.POSTS}_${Favorite.userId}`) as Post[]
        if (stored) {
            return Promise.resolve(stored)
        }
        if (!Favorite.postLoadingpromise) {
            Favorite.postLoadingpromise = PostsAPI.getFavorites()
        }
        return Favorite.postLoadingpromise.then((categories) => {
            Favorite.postLoadingpromise = undefined
            MemoryCache.put(`${CacheKeys.POSTS}_${Favorite.userId}`, categories)
            return categories
        })
    }

    static addFavoritePost(post: Post): Promise<void> {
        return PostsAPI.addFavorite(post.id).then(() => {
            return Favorite.getFavoritePosts().then((posts) => {
                posts.push(post)
                MemoryCache.put(`${CacheKeys.POSTS}_${Favorite.userId}`, posts.unique('id'))
            })
        })
    }

    static deleteFavoritePost(deletedPost: Post): Promise<void> {
        return PostsAPI.removeFavorite(deletedPost.id).then(() => {
            return Favorite.getFavoritePosts().then((posts) => {
                posts = posts.filter(post => post.id !== deletedPost.id)
                MemoryCache.put(`${CacheKeys.POSTS}_${Favorite.userId}`, posts.unique('id'))
            })
        })
    }

    static isFavoriteUser(userId: string) {
        if (!Favorite.userId) {
            return Promise.resolve(false)
        }
        return Favorite.getFavoriteUsers().then((users) => {
            return users.find(u => u.id === userId) != undefined
        })
    }

    static getFavoriteUsers() {
        if (!Favorite.userId) {
            return Promise.resolve([])
        }
        const stored = MemoryCache.get(`${CacheKeys.USER}_${Favorite.userId}`) as User[]
        if (stored) {
            return Promise.resolve(stored)
        }
        if (!Favorite.userLoadingPromise) {
            Favorite.userLoadingPromise = UsersAPI.getFavorites()
        }
        return Favorite.userLoadingPromise.then((users) => {
            Favorite.userLoadingPromise = undefined
            MemoryCache.put(`${CacheKeys.USER}_${Favorite.userId}`, users)
            return users
        })
    }

    static addFavoriteUser(user: User): Promise<void> {
        return UsersAPI.addFavorite(user.id).then(() => {
            return Favorite.getFavoriteUsers().then((users) => {
                users.push(user)
                MemoryCache.put(`${CacheKeys.USER}_${Favorite.userId}`, users.unique('id'))
            })
        })
    }

    static deleteFavoriteUser(user: User): Promise<void> {
        return UsersAPI.removeFavorite(user.id).then(() => {
            return Favorite.getFavoriteUsers().then((users) => {
                users = users.filter(post => post.id !== user.id)
                MemoryCache.put(`${CacheKeys.USER}_${Favorite.userId}`, users.unique('id'))
            })
        })
    }

    static getSavedSearches() {
        if (!Favorite.userId) {
            return Promise.resolve([])
        }
        const stored = MemoryCache.get(`${CacheKeys.SEARCHES}_${Favorite.userId}`) as SavedSearch[]
        if (stored) {
            return Promise.resolve(stored)
        }
        if (!Favorite.savedSearchLoadingPromise) {
            Favorite.savedSearchLoadingPromise = UsersAPI.getSavedSearches()
        }
        return Favorite.savedSearchLoadingPromise.then(searches => {
            Favorite.savedSearchLoadingPromise = undefined
            MemoryCache.put(`${CacheKeys.SEARCHES}_${Favorite.userId}`, searches.unique('id'))
            return searches
        })
    }

    static addSavedSearch(search: SavedSearch) {
        return Favorite.getSavedSearches().then(searches => {
            MemoryCache.put(`${CacheKeys.SEARCHES}_${Favorite.userId}`, [search].concat(searches).unique('id'))
            return searches
        })
    }

    static deleteSavedSearch(search: SavedSearch) {
        return Favorite.getSavedSearches().then(searches => {
            MemoryCache.put(`${CacheKeys.SEARCHES}_${Favorite.userId}`, searches.filter(s => s.id != search.id).unique('id'))
            return searches
        })
    }

    static isFavoriteBrand(brandId: string) {
        if (!Favorite.userId) {
            return Promise.resolve(false)
        }
        return Favorite.getFavoriteBrands().then((brands) => {
            return brands.find(brand => brand.id === brandId) != undefined
        }).catch(() => false)
    }

    static getFavoriteBrands() {
        if (!Favorite.userId) {
            return Promise.resolve([])
        }
        const stored = MemoryCache.get(`${CacheKeys.BRANDS}_${Favorite.userId}`) as Brand[]
        if (stored) {
            return Promise.resolve(stored)
        }
        if (!Favorite.brandLoadingPromise) {
            Favorite.brandLoadingPromise = MetaAPI.getFavoriteBrands();
        }
        return Favorite.brandLoadingPromise.then((brands) => {
            Favorite.brandLoadingPromise = undefined
            MemoryCache.put(`${CacheKeys.BRANDS}_${Favorite.userId}`, brands)
            return brands
        })
    }

    static addFavoriteBrand(brand: Brand): Promise<void> {
        return MetaAPI.addFavoriteBrand(brand.id).then(() => {
            return Favorite.getFavoriteBrands().then((brands) => {
                brands.push(brand)
                MemoryCache.put(`${CacheKeys.BRANDS}_${Favorite.userId}`, brands.unique('id'))
            })
        })
    }

    static deleteFavoriteBrand(deletedBrand: Brand): Promise<void> {
        return MetaAPI.removeFavoriteBrand(deletedBrand.id).then(() => {
            return Favorite.getFavoriteBrands().then((brands) => {
                brands = brands.filter(brand => brand.id !== deletedBrand.id)
                MemoryCache.put(`${CacheKeys.BRANDS}_${Favorite.userId}`, brands.unique('id'))
            })
        })
    }

}