import React from "react";
import { AdBanner, AdBannerAPI } from "fullcircle-api";
export function AdBannerView(props: { ad: AdBanner }) {
    return <div className='adbanner'>
        <a href={props.ad.link} target='__blank' onClick={(e) => {
            AdBannerAPI.trackClick(props.ad.id, window.location.pathname)
        }}>
            <img src={props.ad.preview_image_url} />
        </a>
    </div>;
}
