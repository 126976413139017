import * as React from "react";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router-dom";
import Box from "../components/generic/Box";
import { Container } from "reactstrap";
import { UserContextType, UserContextConsumer } from "../context/user";
import { User, UsersAPI, APIError } from "fullcircle-api";
import ClipLoader from 'react-spinners/ClipLoader'
import { PROFILE_TITLE_PLACEHOLDER, IC_HEART_FILLED_WHITE, IC_HEART_WHITE, BLOCK, IC_SHARE_WHITE, MODFIND_STORE_BANNER, MODFIND_STORE_BADGE } from "../assets";
import { LoginRequiredPopup } from "../components/popup/LoginRequiredPopup";
import { Favorite } from "../cache/favorite";

import toastr from 'toastr'
import { errorToString } from "../utils/helper";
import { Helmet } from "react-helmet";
import { UserSession } from "../utils/session";
import { Popup } from 'semantic-ui-react'
import ModFindReportPopup from "../components/popup/ModFindReportPopup";
import ModFindShare from "../components/views/ModFindShare";
import ModFindToast from "../components/generic/ModFindToast";
import { formatPriceI18n } from "../utils/formater";
import { ModFindLoader } from "../components/generic/ModFindLoader";
import { Colors } from "../styles/colors";
import { ImageCropPopup } from "../components/views/ImageCropPopup";
import UserLayout from "./Profile/UserLayout";
import { Transactions } from "../components/views/account/Transactions";
import { Payout } from "../components/views/account/Payout";
import VendorLayout from "./Profile/VendorLayout";
import { t } from "i18next";

interface ProfileState {
    user?: User

    favoriteLoading: boolean
    isFavorite?: boolean

    blockUserPopup: boolean

    didLoadContent: boolean

    shareLoading: boolean
    showSharePopup: boolean
    shareLink?: string,
    balanceError?: any,
    balance?: {
        balance: string;
        pending: string;
    },

    showTransactions?: boolean,
    showPayout?: boolean,
    showCropPopup: boolean
    file?: File
    tempBannerImage?: string
}
class Profile extends React.Component<RouteComponentProps<{ userId?: string }>, ProfileState> {

    static contextType = UserContextType

    context: User | undefined;
    private profilePictureUpload?: HTMLInputElement | null;
    private bannerPictureUpload?: HTMLInputElement | null;
    private copiedToast: ModFindToast | null = null

    private get isPrivate() {
        return Boolean(!this.props.match.params.userId || (this.context && this.context.id == this.props.match.params.userId))
    }

    private get isBusiness() {
        return Boolean(this.user?.business)
    }

    private get user() {
        if (this.props.match.params.userId) {
            return this.state.user
        } else {
            return this.context
        }
    }

    constructor(p: any) {
        super(p);
        this.state = {
            blockUserPopup: false,
            didLoadContent: false,
            showSharePopup: false,
            shareLoading: false,
            favoriteLoading: false,
            showCropPopup: false,
        }
    }


    componentDidMount() {
        if (this.props.match.params.userId) {
            this.loadUser()
            Favorite.isFavoriteUser(this.props.match.params.userId).then((isFavorite) => {
                this.setState({ isFavorite })
            })
        } else if (this.context) {
            this.setState({ didLoadContent: true })
            // context just updated, we have the user now
            Favorite.isFavoriteUser(this.context.id).then((isFavorite) => {
                this.setState({ isFavorite })
            })
        }
        this.getBalance()
    }

    componentDidUpdate() {
        if (!this.props.match.params.userId && this.context && this.state.didLoadContent == false) {
            this.setState({ didLoadContent: true })
            // context just updated, we have the user now
            Favorite.isFavoriteUser(this.context.id).then((isFavorite) => {
                this.setState({ isFavorite })
            })
        }
    }

    private getBalance() {
        UsersAPI.getBalance().then((balance) => {
            this.setState({
                balanceError: undefined,
                balance: balance
            })
        }).catch((error) => {
            this.setState({
                balanceError: error
            })
        })
    }

    private favoriteUser() {
        this.setState({ favoriteLoading: true })
        if (this.state.isFavorite) {
            Favorite.deleteFavoriteUser(this.user!).then(() => {
                this.setState({ favoriteLoading: false, isFavorite: false })
            }).catch(() => {
                this.setState({ favoriteLoading: false })
            })
        } else {
            Favorite.addFavoriteUser(this.user!).then(() => {
                this.setState({ favoriteLoading: false, isFavorite: true })
            }).catch(() => {
                this.setState({ favoriteLoading: false })
            })
        }
    }

    private blockUser() {
        this.setState({ blockUserPopup: true })
    }

    private loadUser() {
        UsersAPI.getUser(this.props.match.params.userId!).then(user => {
            this.setState({ user })
        }).catch(err => {
            setTimeout(() => {
                this.loadUser()
            }, 1000)
        })
    }

    private getUserProp(key: keyof User) {
        return this.user ? this.user[key] : undefined
    }

    chooseProfilePic() {
        if (this.isPrivate) {
            this.profilePictureUpload && this.profilePictureUpload.click()
        }
    }

    chooseBannerPic() {
        if (this.isPrivate) {
            this.bannerPictureUpload && this.bannerPictureUpload.click()
        }
    }

    public render() {
        let bannerImage = `linear-gradient(to bottom, transparent, transparent 70%, black), url('${PROFILE_TITLE_PLACEHOLDER}')`
        if (this.state.tempBannerImage) {
            bannerImage = `url('${this.state.tempBannerImage}')`
        } else if (this.user?.banner_image_url) {
            bannerImage = `url('${this.user.banner_image_url}')`
        } else if (this.isBusiness) {
            bannerImage = `url('${MODFIND_STORE_BANNER}')`
        }
        return <Container className="profile-outer-container">
            <input ref={(ref) => this.profilePictureUpload = ref} accept='image/png, image/gif, image/jpeg' type='file'
                className="hidden-input"
                onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        this.setState({ file: e.target.files[0], showCropPopup: true })
                    }
                    e.target.value = ''
                }} />
            <input ref={(ref) => this.bannerPictureUpload = ref} accept='image/png, image/gif, image/jpeg' type='file'
                className="hidden-input"
                onChange={(e) => {
                    let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                    if (file) {
                        file.arrayBuffer().then((buf) => {
                            const blob = new Blob([buf])
                            this.setState({ tempBannerImage: URL.createObjectURL(blob) })
                        })
                        UsersAPI.uploadBannerImage(file).then((user) => {
                            toastr.success(t("Messages.BANNER_UPLOADED"))
                            UserSession.getUserUpdatedHandler()(user)
                            if (this.state.tempBannerImage) {
                                const img = this.state.tempBannerImage
                                this.setState({ tempBannerImage: undefined }, () => {
                                    URL.revokeObjectURL(img)
                                })
                            }
                        }).catch(err => {
                            if (this.state.tempBannerImage) {
                                const img = this.state.tempBannerImage
                                this.setState({ tempBannerImage: undefined }, () => {
                                    URL.revokeObjectURL(img)
                                })
                            }
                            errorToString(err).forEach(error => {
                                toastr.error(error)
                            })
                        })
                    }
                    e.target.value = ''
                }} />
            {this.state.blockUserPopup && <ModFindReportPopup
                userId={(this.getUserProp('id') as string)}
                onClose={() => this.setState({ blockUserPopup: false })}
            />}
            {this.state.showCropPopup && this.state.file && <ImageCropPopup
                file={this.state.file}
                ratio={1 / 1}
                onClose={() => this.setState({ showCropPopup: false, file: undefined })}
                onSave={(file) => {
                    UsersAPI.uploadProfileImage(file).then((user) => {
                        this.setState({ showCropPopup: false, file: undefined })
                        toastr.success(t("Messages.PROFILE_IMAGE_UPLOADED"))
                        UserSession.getUserUpdatedHandler()(user)
                    }).catch(err => {
                        errorToString(err).forEach(error => {
                            toastr.error(error)
                        })
                    })
                }}
            />}
            <Box className={'profile ' + (this.isPrivate ? 'private' : '')} white>
                {!this.user && <div className="clip-loader-wrapper">
                    <ClipLoader size={30} />
                </div>}
                {this.user && <>
                    <div className='aspect-container'>
                        <div className='container-img' style={{ backgroundImage: bannerImage, backgroundSize: 'cover' }}>
                            {this.isBusiness && <img className='store-badge' src={MODFIND_STORE_BADGE} />}
                            {this.isPrivate && !this.state.tempBannerImage && <div className='change-banner-pic' onClick={() => this.chooseBannerPic()}><div className='link'>{t("Messages.CHANGE").toUpperCase()}</div></div>}
                            {this.state.tempBannerImage && <div className='change-banner-pic-loading'>
                                <ModFindLoader color={Colors.DODGER_BLUE} />
                            </div>}
                            <Helmet>
                                <title>{this.user.full_name} | ModFind</title>
                            </Helmet>
                            {!this.isPrivate && <div className='profile-block mTooltip'>
                                <div>
                                    <img src={BLOCK} alt={'block'} onClick={() => {
                                        if (!this.context) {
                                            LoginRequiredPopup.show()
                                        } else
                                            this.blockUser()
                                    }} />
                                    <span className="mTooltiptext">{t("Messages.BLOCK_USER")}</span>
                                </div>
                            </div>}
                            {!this.isPrivate && <div className='profile-favorite mTooltip'>
                                <div>
                                    {this.state.favoriteLoading ?
                                        <ClipLoader
                                            color={'#fff'}
                                            size={20}
                                        />
                                        :
                                        <>
                                            <img src={this.state.isFavorite ? IC_HEART_FILLED_WHITE : IC_HEART_WHITE} alt={'favorite'} onClick={() => {
                                                if (!this.context) {
                                                    LoginRequiredPopup.show()
                                                } else
                                                    this.favoriteUser()
                                            }} />
                                            <span className="mTooltiptext">{t("Messages.LIKE_USER")}</span>
                                        </>
                                    }
                                </div>
                            </div>}
                            {this.isPrivate && this.context && <div className="profile-balance">
                                <div className="balance-container" onClick={() => this.setState({ showTransactions: true })}>
                                    <span className="balance">{t("Messages.BALANCE")}</span>
                                    <span className="amount">{this.renderBalance()}</span>
                                </div>
                            </div>}
                            {this.renderShare()}

                        </div>
                    </div>
                    <div className='container-profile'>
                        <div className={'container-profile-img ' + (this.isPrivate ? 'link' : '')} onClick={() => this.chooseProfilePic()}>
                            {<img src={(this.getUserProp('profile_image_url') || PROFILE_TITLE_PLACEHOLDER)} />}
                        </div>
                        {this.isPrivate && <div className='change-profile-pic' onClick={() => this.chooseProfilePic()}><div className='link'>{t("Messages.CHANGE").toUpperCase()}</div></div>}
                        {this.state.showTransactions && <div className='profile-items'><Transactions history={this.props.history}
                            onClose={() => this.setState({ showTransactions: false })}
                            onPayoutClick={() => {
                                this.setState({ showTransactions: false, showPayout: true })
                            }}
                        /></div>}
                        {this.state.showPayout && <Payout
                            onClose={() => this.setState({ showPayout: false })}
                        />}
                        {(!this.state.showTransactions && !this.state.showPayout) && !this.isBusiness &&
                            <UserLayout key={this.user.id} isPrivate={this.isPrivate} user={this.user} onRequestPayout={() => {
                                this.setState({ showPayout: true })
                            }} />}
                        {(!this.state.showTransactions && !this.state.showPayout) && this.isBusiness &&
                            <VendorLayout key={this.user.id} isPrivate={this.isPrivate} user={this.user} onRequestPayout={() => {
                                this.setState({ showPayout: true })
                            }} />}
                    </div>
                </>
                }
            </Box>
        </Container>
    }

    private renderBalance() {
        if (this.state.balance && this.context) {
            if (this.state.balance.pending > '0.00') {
                return `${formatPriceI18n(this.state.balance.balance, this.context.currency)} (${formatPriceI18n(this.state.balance.pending, this.context.currency)} ${t("Messages.PENDING")})`
            }
            return formatPriceI18n(this.state.balance.balance, this.context.currency)
        }
        return '--'
    }

    private renderShare() {
        return <UserContextConsumer>{(user) => {
            return <Popup
                position={'top right'}
                onClose={() => { this.setState({ showSharePopup: false }) }}
                eventsEnabled={false} closeOnPortalMouseLeave={false} closeOnTriggerMouseLeave={false}
                trigger={
                    <div className="profile-share mTooltip">
                        <div>
                            <img src={IC_SHARE_WHITE} alt={'share'} onClick={() => {
                                if (!user) {
                                    LoginRequiredPopup.show()
                                } else
                                    this.getUserLink()
                            }} />
                            <span className="mTooltiptext">{t("Messages.SHARE_USER")}</span>
                        </div>
                    </div>
                }
                open={Boolean(this.state.shareLink) && this.state.showSharePopup}
                content={
                    <>
                        <ModFindShare url={this.state.shareLink!} copiedLink={() => { this.copiedToast && this.copiedToast.show(t("Messages.COPIED")) }} />
                        <ModFindToast ref={(ref) => this.copiedToast = ref} position={'left'} />
                    </>
                }>
            </Popup>
        }}</UserContextConsumer>
    }

    private getUserLink() {
        this.setState({ shareLoading: true })
        if (this.user) {
            UsersAPI.getShareLink(this.user.id).then((url) => {
                this.setState({ shareLoading: false, showSharePopup: true, shareLink: url })
            }).catch((err: APIError) => {
                this.setState({ shareLoading: false })
            })
        }

    }
}

export default withRouter(class extends React.Component<RouteComponentProps<{ page: string }>> {
    render() {
        return <Switch>
            <Route path={`/${this.props.match.params.page}/:userId?`} render={(props) => <Profile {...props} key={props.match.params.userId} />} />
        </Switch>
    }
})