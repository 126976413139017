import * as React from "react";
import { MINUS, PLUS_GRAY } from "../../../assets";

export function Quantity(props: { onQuantityChanged: (qty: number) => void, quantity: number, maxQuantity: number }) {
    return <div className='quantity'>
        <div className='quantity-button' onClick={() => {
            if (props.quantity > 1)
                props.onQuantityChanged(props.quantity - 1)
        }}>
            <img src={MINUS} className='quantity-button-image minus' />
        </div>
        <div className='quantity-text-container'>
            <span className='quantity-text'>{props.quantity}</span>
        </div>
        <div className='quantity-button' onClick={() => {
            if (props.quantity < props.maxQuantity)
                props.onQuantityChanged(props.quantity + 1)
        }}>
            <img src={PLUS_GRAY} className='quantity-button-image plus' />
        </div>
    </div>
}