import { PaginationResponse } from "fullcircle-api";
import * as React from "react";
import ReactPaginate from 'react-paginate';

export interface ModfindPagingProps {
    paging: PaginationResponse
    onPageSelected: (page: number) => void
}

export const ModFindPaging: React.FC<ModfindPagingProps> = ({ paging, onPageSelected }) => {

    const total = Math.ceil(paging.total / paging.limited)
    if (total == 1) {
        return null
    }
    return <div className='paging'>
        <ReactPaginate
            breakLabel="..."
            nextLabel={<svg className="chevron" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 16.5L14.5 12L10 7.5" stroke-width="2" stroke-miterlimit="10" />
            </svg>}
            onPageChange={(s) => {
                onPageSelected(s.selected)
            }}
            forcePage={paging.page - 1}
            pageCount={total}
            pageLinkClassName={'default-link'}
            activeClassName={'active'}
            previousLabel={<svg className="chevron transform" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 16.5L14.5 12L10 7.5" stroke-width="2" stroke-miterlimit="10" />
            </svg>}
        />
    </div>

}