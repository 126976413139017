import * as React from 'react';
import { IC_CARS_SALE } from '../../assets';
import { APIError, Brand, User } from 'fullcircle-api';
import { Favorite } from '../../cache/favorite';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../../styles/colors';
import { LoginRequiredPopup } from '../popup/LoginRequiredPopup';
import { t } from 'i18next';

export default class BrandCarouselItem extends React.Component<{
    brand: Brand
    currentUser?: User
    onItemClick?: () => void;
}, { favoriteLoading: boolean, isFavorite: boolean }> {

    constructor(props: any) {
        super(props)
        this.state = {
            favoriteLoading: false,
            isFavorite: false
        }
    }

    componentDidMount() {
        Favorite.isFavoriteBrand(this.props.brand.id).then((favorite) => {
            this.setState({ isFavorite: favorite })
        })
    }

    private likeBrand() {
        if (!this.props.currentUser) {
            LoginRequiredPopup.show()
            return
        }
        this.setState({ favoriteLoading: true })
        let brand = this.props.brand
        if (brand) {
            if (this.state.isFavorite) {
                Favorite.deleteFavoriteBrand(brand).then(() => {
                    this.setState({ isFavorite: false, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            } else {
                Favorite.addFavoriteBrand(brand).then(() => {
                    this.setState({ isFavorite: true, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            }
        }
    }


    render() {
        const { brand, onItemClick } = this.props
        const { preview_image_url, name } = brand

        return (
            <div className="modFind-carousel-item-container">
                <div className="modFind-carousel-item-container-ratio">
                    <div className="modFind-carousel-item" onClick={onItemClick}>
                        <div className={"carousel-img-container"}>
                            <img src={preview_image_url || IC_CARS_SALE} alt={name} className={"image"} />
                        </div>
                    </div>
                </div>
                <div className='modFind-carousel-description' onClick={onItemClick}>
                    <span className="item-name">{name}</span>
                    <div className='modFind-carousel-lower-container'>
                        <div className='watch button-wraper'>
                            {this.state.favoriteLoading ? <ClipLoader
                                color={Colors.DODGER_BLUE}
                                size={13}
                            /> : <a href='#' onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                this.likeBrand()
                            }}>
                                <span className='follow'>{this.state.isFavorite ? t('Messages.UNFOLLOW') : t('Messages.FOLLOW')}</span>
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}