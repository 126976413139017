import * as React from 'react';
import { Popup } from "semantic-ui-react";
import Switch from "react-switch";
import { DropdownItem } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import { MetaAPI, Region, Currency, RegisterAPI } from 'fullcircle-api';
import { RegisterUserError, RegisterUserErrorType } from 'fullcircle-api/lib/endpoints/register';
import { t } from 'i18next';

import ModFindInput from '../components/generic/ModFindInput';
import ModFindDropDown from '../components/dropdown/ModFindDropDown';
import { ZipCodeInput } from '../components/generic/ZipCodeInput';
import Button from '../components/generic/Button';
import { Colors } from '../styles/colors';
import { getGeoData, PartialLocation } from '../utils/location';
import { validateEmail, registerDevice } from '../utils/helper';

interface SignupProps extends RouteComponentProps {
    pushToHome: () => void;
}
export class SignUp extends React.Component<SignupProps, {
    region?: Region;
    currency?: Currency;
    location?: PartialLocation
    regions?: Region[];
    currencies?: Currency[];
    formValid: boolean;
    loadingDropdown: boolean;
    loading: boolean;
    error?: string;
    isDealerOrVendor: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            formValid: false,
            loadingDropdown: true,
            loading: false,
            isDealerOrVendor: false
        };
    }
    componentDidMount() {
        Promise.all([
            MetaAPI.getRegions(), MetaAPI.getCurrencies(), getGeoData().catch(() => undefined)
        ]).then(([regions, currencies, geoData]) => {
            const regionCode = geoData?.country_code || 'United States';
            const region = regions!.find(r => r.code.toLowerCase() === regionCode.toLowerCase());
            const currencyCode = geoData?.currency || 'usd';
            const currency = currencies!.find(c => c.code.toLowerCase() === currencyCode.toLowerCase());
            this.setState({
                region,
                currency,
                regions,
                currencies,
                loadingDropdown: false
            });
        });
    }

    private email: string = '';
    private password: string = '';
    private name: string = '';

    checkValidForm() {
        let valid = false;
        if (this.email.length > 0 && validateEmail(this.email) && this.password.length >= 6 && this.name.length > 0 && this.state.location) {
            valid = true;
        }
        this.setState({ formValid: valid });
    }

    signUp() {
        this.setState({ loading: true });
        let regionName = this.state.region ? this.state.region.name : 'United States';
        let currencyName = this.state.currency ? this.state.currency.code : 'USD';

        RegisterAPI.registerUser(this.email, this.password, this.name, regionName, currencyName, this.state.location!).then((user) => {
            window.gtag('event', 'conversion', { 'send_to': 'AW-622342217/vNWfCMmSlfABEMng4KgC' });

            registerDevice(user.refresh_token!).then(() => {
                this.setState({ loading: false });
                const urlParams = new URLSearchParams(window.location.search)
                if (this.state.isDealerOrVendor) {
                    this.props.history.push(`/dealer-vendor`)
                } else if (urlParams.get('redirect') && (urlParams.get('redirect')?.trim() !== '')) {
                    this.props.history.push(`${urlParams.get('redirect')}`)
                }
                else {
                    this.props.pushToHome()
                }
            }).catch((err) => {
                this.setState({ loading: false, error: t("Messages.SOMETHING_WENT_WRONG") });
            });
            return null;
        }).catch((err: RegisterUserError) => {
            let error: string = '';
            switch (err.type) {
                case RegisterUserErrorType.emailAlreadyTaken:
                    error = t("ErrorMessages.Signup.EmailAlreadyExists");
                    break;
                default:
                    error = err.underlyingError.message || t("Messages.SOMETHING_WENT_WRONG");
            }
            this.setState({ loading: false, error });
        });
    }

    onValueChanged() {
        this.setState({ error: undefined });
        this.checkValidForm();
    }

    render() {
        return (<>
            <ModFindInput placeholder={t("Messages.EMAIL")} onValueChanged={(value) => {
                this.email = value;
                this.onValueChanged();
            }} colored rounded containerSize={'lg'} type={'email'} className="auth-input-wrapper"
                onEnter={() => {
                    this.signUp()
                }} />
            <ModFindInput placeholder={t("Messages.PASSWORD")} onValueChanged={(value) => {
                this.password = value;
                this.onValueChanged();
            }} colored rounded containerSize={'lg'} type={'password'} className="auth-input-wrapper"
                onEnter={() => {
                    this.signUp()
                }} />
            <ModFindInput placeholder={t("Messages.FULL_NAME")} onValueChanged={(value) => {
                this.name = value;
                this.onValueChanged();
            }} colored autoComplete='name' name='name' rounded containerSize={'lg'} className="auth-input-wrapper" onEnter={() => {
                this.signUp()
            }} />
            <ZipCodeInput className="auth-input-wrapper" textContainerSize='lg' region={this.state.region?.code} onLocation={(location) => {
                this.setState({ location }, () => {
                    this.checkValidForm();
                })
            }} />
            <ModFindDropDown value={this.state.loadingDropdown ? '-' : this.state.region ? this.state.region.name : 'United States'} textColor={'black'} className="auth-dropdown-wrapper" rounded>
                {(this.state.regions || []).map((region) => {
                    return (<DropdownItem key={region.name} onClick={() => {
                        this.setState({ region });
                    }}>{region.name}
                    </DropdownItem>);
                })}
            </ModFindDropDown>
            <ModFindDropDown value={this.state.loadingDropdown ? '-' : this.state.currency ? this.state.currency.code.toUpperCase() : 'USD'} textColor={'black'} className="auth-dropdown-wrapper" rounded>
                {(this.state.currencies || []).map((currency) => {
                    return (<DropdownItem key={currency.code} onClick={() => {
                        this.setState({ currency });
                    }}>{currency.code.toUpperCase()}
                    </DropdownItem>);
                })}
            </ModFindDropDown>
            <div className="input-container">
                <div className="switch-wrapper auth-switch-wrapper">
                    <Switch
                        checked={this.state.isDealerOrVendor}
                        onChange={() => {
                            this.setState({ isDealerOrVendor: !this.state.isDealerOrVendor })
                        }}
                        offColor={Colors.GREY}
                        onColor={Colors.DODGER_BLUE}
                        onHandleColor={'#fff'}
                        offHandleColor={'#fff'}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={24}
                        width={52}
                    />
                    <span className="signup-switch-title">{t("Messages.SIGN_UP_DEALER_VENDOR")}</span>
                </div>
            </div>
            {this.state.error && <div className="form-subcontainer-auth">
                <span className="error-text-auth">{this.state.error}</span>
            </div>}
            <div className="form-subcontainer-auth">
                <Popup
                    trigger={
                        <Button
                            text={t("Messages.SIGN_UP")}
                            size={'lg'}
                            rounded
                            disabled={!this.state.formValid}
                            loading={this.state.loading}
                            onClick={() => { this.signUp(); }}
                        />
                    }
                    content={t('ErrorMessages.Signup.NotCorrectSignInData')}
                    disabled={this.state.formValid}
                />
            </div>

        </>);
    }
}
