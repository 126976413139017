import * as React from "react";
import { Container } from "reactstrap";
import { CSV_IMPORT_1, CSV_IMPORT_2, CSV_IMPORT_3, CSV_IMPORT_4, CSV_IMPORT_5, CSV_IMPORT_6, CSV_IMPORT_7, CSV_IMPORT_8, CSV_IMPORT_9 } from "../assets";

export function CSVUpload() {

    return <Container className='csv-feature'>
        <div style={{ marginLeft: 16, width: '90%' }}>
            <h4 style={{ marginTop: 20, fontWeight: 600 }}>1. Log into your ModFind Vendor account.</h4>
            <p><a href="https://modfind.com/login">https://modfind.com/login</a></p>
            <div className="image-container">
                <img src={CSV_IMPORT_1} />
            </div>
            <h4>2. Use the link below to access your Vendor Dashboard.</h4>
            <p><a href="https://b2b.modfind.com">https://b2b.modfind.com</a></p>
            <div className="image-container">
                <img src={CSV_IMPORT_2} />
            </div>
            <h4>3. Select “Import” button under CSV Import option</h4>
            <p><a href="https://b2b.modfind.com/#/import">https://b2b.modfind.com/#/import</a></p>
            <div className="image-container">
                <img src={CSV_IMPORT_3} />
            </div>
            <h4>4. Select “Choose” button under Choose CSV option to upload an existing CSV file.</h4>
            <p>You can upload exported CSV files from any platform, or a CSV file created by you. If you do not have a CSV file and wish to create one, see our FAQ’s “How to Create a CSV Template” section for step‐by‐step instructions.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_4} />
            </div>
            <h4>5. Select CSV file you wish to upload and click “Open.”</h4>
            <p>Make sure the file you are trying to upload is saved as a CSV (comma delimited) type.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_5} />
            </div>
            <h4>6. Match listing information fields with the corresponding data columns from your CSV file.</h4>
            <p>Allow a few seconds for system to upload data before matching your CSV data. Make sure to match all the required data fields before you submit import at the bottom. If your CSV file is missing a ModFind required data field; you will have to edit a new column in your CSV file with the missing data, and re‐upload your file.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_6} />
            </div>
            <h4>7. Match your CSV’s Product Categories with ModFind Categories using the options provided in the drop down.</h4>
            <p>The categories listed in your CSV file will populate once you match your CSV’s Category data column to the ModFind required “Category” field. If you don’t have a category column in your CSV file, use your CSV’s “Title” column to populate listing titles and then match a ModFind category to each.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_7} />
            </div>
            <h4>8. Import your items.</h4>
            <p>Import button is located at the bottom of the page. Once “Import Items” is selected, allow a few seconds for “Importing” window to display and remain on this window until importing process is completed. Do not exit or open a different window, this may disrupt import progress. Larger files will take longer to import but should not exceed 20‐30 minutes.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_8} />
            </div>
            <h4>9. Track your import process.</h4>
            <p>The “Import Jobs” Tab will show you the status and history of uploaded import jobs. By clicking the down arrow, you can view all the listings in detail.</p>
            <div className="image-container">
                <img src={CSV_IMPORT_9} />
            </div>
            <div className="video-container tutorial">
                <h4 id="tutorial">Tutorial</h4>
                <video playsInline controls preload="auto" poster={'https://media.full-circle.io/videos/how-to-upload-CSV-file-poster.jpg'}>
                    <source src={'https://media.full-circle.io/videos/how-to-upload-CSV-file.mp4'} />
                </video>
            </div>
        </div>
    </Container>
}