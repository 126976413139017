import * as React from 'react'
import { IC_ARROW_LEFT, IC_ARROW_RIGHT } from '../../assets';
import { Post, User, Brand } from 'fullcircle-api';

import { ModFindLoader } from '../generic/ModFindLoader';
import { Colors } from '../../styles/colors';
import { Title } from '../generic/Title';
import { UserContextType } from '../../context/user';
import { t } from 'i18next';

export interface CarouselProps<M> {
    items?: M[]
    title?: string
    loading?: boolean
    onItemClick?: (post: Post | User | Brand) => void
    onSeeAllClick?: () => void
    noItemsText?: string
    refItemCount?: 5 | 4 | 3
    hideSeeAll?: boolean
    defaultExpanded?: boolean
}

export default abstract class Carousel<M extends { id: string }> extends React.Component<CarouselProps<M>, {
    all: boolean
    offset: number
}> {


    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>

    private scrollView: HTMLDivElement | null = null;
    private didCalculateOffset = false
    private scrollRequestCode: number = 0;

    constructor(props: CarouselProps<M>) {
        super(props)
        this.state = {
            offset: 0,
            all: !!props.defaultExpanded
        }
        this.updateOffset = this.updateOffset.bind(this)
    }

    componentDidMount() {
        this.updateOffset()
        window.addEventListener('resize', this.updateOffset)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOffset)
    }

    getOffset() {
        if (this.scrollView) {
            if (this.scrollView.childNodes.length) {
                let gap = 10
                try {
                    let [strGap] = window.getComputedStyle(this.scrollView)['grid-gap'].split(' ')
                    if (strGap) {
                        gap = parseInt(strGap)
                    }
                } catch (error) {

                }
                this.didCalculateOffset = true
                return (this.scrollView.childNodes[0] as HTMLDivElement).clientWidth + gap
            }
        }
        // backup
        let width = window.innerWidth
        let margin = 16
        if (width >= 1200) {
            return (198 + margin)
        } else {
            return (176 + margin)
        }
    }

    updateOffset() {
        this.setState({ offset: this.getOffset() })
    }

    render() {
        return (
            <div className='modfind-carousel'>
                {this.props.title && <div className='carousel-header'>
                    <Title>{this.props.title}</Title>
                    {this.renderLinks()}
                </div>}
                {this.props.loading ?
                    <div>
                        <ModFindLoader />
                    </div>
                    :
                    this.props.items ?
                        <div className={"carousel-container " + ((this.state.all) ? 'all ' : '') + (this.props.refItemCount ? `ref-count-${this.props.refItemCount}` : '')}>
                            {this.props.items.length > 0 && <div className="arrow-container left" onClick={() => { this.scrollView && this.scrollElements(this.scrollView, -this.state.offset, 500) }}>
                                <img src={IC_ARROW_LEFT} alt={'arrow_left'} />
                            </div>}
                            <div ref={(element) => {
                                this.scrollView = element
                                if (!this.didCalculateOffset) {
                                    this.updateOffset()
                                }
                            }} className={"scroll-container"}>
                                {this.props.items.length > 0 ?
                                    this.renderItems(this.props.items)
                                    :
                                    this.renderEmptyComponent()
                                }
                            </div>
                            {this.props.items.length > 0 && <div className="arrow-container right" onClick={() => { this.scrollView && this.scrollElements(this.scrollView, this.state.offset, 500) }}>
                                <img src={IC_ARROW_RIGHT} alt={'arrow-right'} />
                            </div>}
                        </div>
                        :
                        <div>
                        </div>
                }
            </div>

        )
    }

    protected getLeftFunction(): { message: string, func: () => void } | undefined {
        return undefined
    }

    renderLinks() {
        let leftText: string | undefined = undefined
        let rightText: string | undefined = undefined
        let leftFunc: (() => void) | undefined = undefined
        let rightFunc: (() => void) | undefined = undefined

        if (!this.props.hideSeeAll) {
            rightText = t("Messages.SEE_ALL")
            if (this.state.all) {
                rightText = t("Messages.HIDE")
            }
        }
        if (!this.props.items || this.props.items?.length == 0) {
            rightText = undefined
        }

        const data = this.getLeftFunction()

        if (data) {
            leftFunc = data.func
            leftText = data.message
        }

        rightFunc = () => this.onSeeAllClick()

        return (
            <div className="links-wrapper">
                {leftText && <span className="left-text"
                    onClick={leftFunc}>{leftText}</span>}
                {leftText && rightText && <span className="separator">|</span>}
                {rightText && <span className="right-text" onClick={rightFunc}>{rightText}</span>}
            </div>
        )
    }

    abstract renderItems(items: M[]): React.ReactElement[]

    abstract getEmptyPlaceholder(): string

    renderEmptyComponent() {
        return (
            <div className='empty'>
                <span>{this.props.noItemsText || this.getEmptyPlaceholder()}</span>
            </div>
        )
    }

    onSeeAllClick() {
        if (this.props.onSeeAllClick) {
            this.props.onSeeAllClick()
        } else
            this.setState({ all: !this.state.all })
    }

    private scrollElements(element: HTMLDivElement, offset: number, duration: number) {
        var start = element.scrollLeft;
        var currentTime = 0;
        var increment = 20;

        offset = this.getNewOffset(start, offset)

        let _requestCode = ++this.scrollRequestCode;
        var animateScroll = () => {
            if (_requestCode !== this.scrollRequestCode) {
                return;
            }

            currentTime += increment;
            var val = this.easeInOutQuad(currentTime, start, offset, duration);
            element.scrollLeft = val;
            //this.setArrows(element.scrollLeft)
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    private getNewOffset(start: number, offset: number): number {
        let rest = start % offset
        return rest < Math.abs(offset) / 2 ? offset - rest : (offset - rest) + Math.abs(offset)
    }

    private easeInOutQuad(currentTime: number, startValue: number, offset: number, duration: number) {		//https://stackoverflow.com/questions/52747018/horizontal-scrolling-using-buttons-in-reactjs
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return offset / 2 * currentTime * currentTime + startValue;
        }
        currentTime--;
        return -offset / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
    }
}