import React from 'react';
import ModFindChat from './ModFindChat';
import { Chat } from '../../../api/database';
import ChatHandler, { ChatHandlerEvents } from '../../../api/chathandller';
import { User } from 'fullcircle-api';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getPostUrl } from '../../../utils/path';

class Chats extends React.Component<{ user: User, stickToScreenBottom: boolean } & RouteComponentProps, {
    chats: Chat[]
}> {

    constructor(props) {
        super(props)
        this.state = {
            chats: []
        }
        this.loadChats = this.loadChats.bind(this)
    }

    loadChats() {
        if (ChatHandler.instance()) {
            this.setState({ chats: ChatHandler.instance()!.getChatsToShow() })
        }
    }

    componentDidMount() {
        if (ChatHandler.instance()) {
            ChatHandler.instance()?.addListener(ChatHandlerEvents.ChatsUpdate, this.loadChats)
            this.loadChats()
        }
    }

    componentWillUnmount() {
        if (ChatHandler.instance()) {
            ChatHandler.instance()?.removeListener(ChatHandlerEvents.ChatsUpdate, this.loadChats)
        }
    }

    private renderChatWindows = () => {
        return this.state.chats.map((chat) => (
            <ModFindChat key={chat.id} chat={chat} user={this.props.user} closeChat={this.closeChatHandler} onChatClick={() => {
                this.props.history.push(getPostUrl(chat.post))
            }} />
        ));
    }

    private closeChatHandler = (chat: Chat) => {
        ChatHandler.instance()?.closeChat(chat)
    }

    render() {
        return (
            <div className="chats-full-container hide-mobile" style={this.props.stickToScreenBottom ? { position: 'fixed' } : { position: 'absolute' }}>
                {this.state.chats.length > 0 && this.renderChatWindows()}
            </div>
        )
    }
}

export default withRouter(Chats)