import * as React from "react";
import { GenericDropDown, GenericDropDownProps } from "./GenericDropDown";
import { Meta } from "../../cache/meta";

export interface YearDropDownProps extends GenericDropDownProps<{ id: string, name: string }> {
}

export class YearDropDown extends React.Component<YearDropDownProps, { years: string[] }> {

    constructor(props: YearDropDownProps) {
        super(props);
        this.state = {
            years: []
        }
    }

    getYears() {
        return Meta.getModelTypes().then(resp => {
            const years = resp.map(i => {
                return i.model_year!
            }).unique()
            return years.sort((a, b) => Number(b) - Number(a)).map((year) => {
                return {
                    id: year, name: year
                }
            })
        })
    }

    componentDidMount() {
        this.getYears()
    }

    render() {
        return <GenericDropDown apiFunc={this.getYears}   {...this.props} />
    }
}