import * as React from 'react'
import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.css"
import { Colors } from '../styles/colors';
import Button from './generic/Button';

interface ImageCropProps {
    file: File,
    ratio: number
    onSave: (file: File) => void;
    onCancel: () => void;
}

export class ImageCrop extends React.Component<ImageCropProps, {
    loading: boolean
}> {

    constructor(props: ImageCropProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        this.crop()

    }
    private cropper: Cropper | null = null

    private crop() {
        const image = document.getElementById('image') as HTMLImageElement
        if (image) {
            this.cropper = new Cropper(image, {
                aspectRatio: this.props.ratio,
                guides: false
            })
        }
    }

    render() {
        const { file, onCancel } = this.props

        return (
            <div className='image-crop'>
                <div>
                    <img id="image" src={URL.createObjectURL(file)} style={{ maxHeight: window.screen.availHeight * 0.6 }} />
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
                    <Button
                        text={'Cancel'}
                        size={'sm'}
                        style={{ backgroundColor: Colors.RED, marginRight: 16 }}
                        onClick={() => onCancel()}
                    />
                    <Button
                        text={'Save'}
                        size={'sm'}
                        onClick={() => this.createNewFile()}
                        loading={this.state.loading}
                    />
                </div>

            </div>
        )
    }

    createNewFile() {
        const { file, onSave } = this.props
        this.setState({ loading: true })
        this.cropper?.getCroppedCanvas().toBlob((blob) => {
            if (blob) {
                let newFile = new File([blob], file.name, { type: 'image/png' })
                onSave(newFile)
            }
        })
    }
}