import * as React from "react";
import { GenericDropDown, GenericDropDownProps } from "./GenericDropDown";
import { Meta } from "../../cache/meta";

import { t } from "i18next";

export interface RegionDropDownProps extends GenericDropDownProps<{ id: string, name: string }> {

}

export class RegionDropDown extends React.Component<RegionDropDownProps, {}> {

    constructor(props: RegionDropDownProps) {
        super(props);
        this.state = {
        }
    }

    loadRegions() {
        return Meta.getRegions().then(regions => {
            return [{ id: 'All', name: 'All' }].concat(regions.map(region => {
                return { id: region.name, name: region.name }
            }))
        })
    }

    render() {
        return <GenericDropDown placeholder={t("Messages.SELECT_REGION")} apiFunc={this.loadRegions}  {...this.props} />
    }
}