import MemoryCache from 'lscache'
import { MetaAPI, Category, Brand, BrandCarGeneration, HelpTopic, FeaturedItem, Region } from 'fullcircle-api'
import { Environment } from '../env'

enum CacheKeys {
    CATEGORIES = 'categories',
    BRANDS = 'brands',
    MODELTYPES = 'modelTypes',
    HELP = 'help',
    BANNER = 'bannerv2',
    REGION = 'region'
}
const CACHE_DURATION_MS = 5
const PREFIX = Environment.ENV + 'v3'
export class Meta {

    static getCategories(): Promise<Category[]> {
        const stored = MemoryCache.get(PREFIX + CacheKeys.CATEGORIES)
        if (stored && stored.length) {
            return Promise.resolve(stored)
        }
        return MetaAPI.getCategories().then((categories) => {
            MemoryCache.set(PREFIX + CacheKeys.CATEGORIES, categories, CACHE_DURATION_MS)
            return categories
        })
    }

    static getRegions(): Promise<Region[]> {
        const stored = MemoryCache.get(PREFIX + CacheKeys.REGION)
        if (stored && stored.length) {
            return Promise.resolve(stored)
        }
        return MetaAPI.getRegions().then((regions) => {
            MemoryCache.set(PREFIX + CacheKeys.REGION, regions, CACHE_DURATION_MS)
            return regions
        })
    }

    static getBrands(): Promise<Brand[]> {
        const stored = MemoryCache.get(PREFIX + CacheKeys.BRANDS)
        if (stored && stored.length) {
            return Promise.resolve(stored)
        }
        return MetaAPI.getBrands().then((brands) => {
            MemoryCache.set(PREFIX + CacheKeys.BRANDS, brands, CACHE_DURATION_MS)
            return brands
        })
    }

    static metaLoadingPromise: Promise<BrandCarGeneration[]> | undefined

    static getModelTypes(): Promise<BrandCarGeneration[]> {
        const stored = MemoryCache.get(PREFIX + CacheKeys.MODELTYPES)
        if (stored && stored.length) {
            return Promise.resolve(stored)
        }
        if (!Meta.metaLoadingPromise) {
            Meta.metaLoadingPromise = MetaAPI.getBrandCarGenerations_unserialized().then((brands) => {
                MemoryCache.set(PREFIX + CacheKeys.MODELTYPES, brands, CACHE_DURATION_MS)
                return brands
            })
        }
        return Meta.metaLoadingPromise
    }

    static getHelpContent(): Promise<HelpTopic[]> {
        const stored = MemoryCache.get(PREFIX + CacheKeys.HELP)
        if (stored && stored.length) {
            return Promise.resolve(stored)
        }
        return MetaAPI.getHelpContent().then((topics) => {
            MemoryCache.set(PREFIX + CacheKeys.HELP, topics, CACHE_DURATION_MS)
            return topics
        })
    }

    static getBanner() {
        const stored = MemoryCache.get(PREFIX + CacheKeys.BANNER) as FeaturedItem[]
        if (stored && stored.length) {
            //return Promise.resolve(stored)
        }
        return MetaAPI.getFeaturedItems('web').then((topics) => {
            //MemoryCache.set(PREFIX + CacheKeys.BANNER, topics, CACHE_DURATION_MS)
            return topics
        })
    }

}