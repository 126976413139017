import * as React from "react";
import ModFindPopUp from '../popup/ModFindPopUp';
import 'react-image-crop/dist/ReactCrop.css';
import { ImageCrop } from "../ImageCrop";

interface ImageCropPopupProps {
    file: File
    ratio: number
    onClose: () => void;
    onSave: (file: File) => void;
}

export class ImageCropPopup extends React.Component<ImageCropPopupProps, {}> {


    constructor(props: ImageCropPopupProps) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { file, ratio, onClose, onSave } = this.props
        return <ModFindPopUp onClose={() => onClose()}>
            <ImageCrop
                file={file}
                ratio={ratio}
                onSave={onSave}
                onCancel={onClose}
            />
        </ModFindPopUp>
    }

}