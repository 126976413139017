import * as React from 'react'
import ModFindPopUp from './ModFindPopUp';
import { Dropdown } from 'semantic-ui-react'
import Button from '../generic/Button';
import { DeliveryCarriers } from 'fullcircle-api';
import ModFindInput from '../generic/ModFindInput';

import { t } from 'i18next';

interface ModFindDeliverPopupProps {
    message: string
    mode: 'add' | 'submit'
    onClose: () => void
    onSubmit: (trackingCarrier?: DeliveryCarriers, trackingNumber?: string) => Promise<void>
}

export default class ModFindDeliverPopup extends React.Component<ModFindDeliverPopupProps, {
    trackingNumber?: string
    trackingCarrier?: DeliveryCarriers
    loading: boolean
}> {

    constructor(props: ModFindDeliverPopupProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        return (
            <ModFindPopUp onClose={() => this.props.onClose()}>
                <div className="modfind-popup-content-wrapper">
                    <div className="modfind-popup-title">{this.props.message}</div>
                    <div>
                        <span className="modfind-popup-input-title">{t("Messages.WHICH_CARRIER")}</span>
                        <Dropdown selectOnBlur={false}
                            options={Object.keys(DeliveryCarriers).map((key) => {
                                return {
                                    key,
                                    value: key,
                                    text: DeliveryCarriers[key]
                                }
                            })} onChange={(_, { value }) => {
                                this.setState({ trackingCarrier: (value as DeliveryCarriers) })
                            }} defaultValue={DeliveryCarriers.usps}
                            className='carrier-dropdown'
                            fluid
                            selection search
                        />
                        <div className="separator indent" />
                    </div>
                    <span className="modfind-popup-input-title">{t("Messages.WHAT_TRACKING_NUMBER")}</span>
                    <ModFindInput onValueChanged={e => {
                        this.setState({ trackingNumber: e })
                    }} />
                    <div className="separator" />
                    <div className="modfind-popup-info">{t("Messages.ALLOW_MODERATION")}</div>
                    <div className="buttons-container-vertical">
                        <Button
                            text={'Submit tracking info'}
                            size={'sm'} disabled={!this.state.trackingCarrier || !this.state.trackingNumber}
                            rounded loading={this.state.loading}
                            onClick={() => {
                                this.setState({ loading: true })
                                this.props.onSubmit(this.state.trackingCarrier, this.state.trackingNumber).finally(() => {
                                    this.setState({ loading: false })
                                })
                            }}
                        />
                        {this.props.mode == 'submit' && <Button
                            text={'No tracking info'}
                            size={'sm'}
                            rounded loading={this.state.loading}
                            className="top-button"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.props.onSubmit().finally(() => {
                                    this.setState({ loading: false })
                                })
                            }}
                        />}
                        <Button
                            text={'Cancel'}
                            size={'sm'}
                            rounded loading={this.state.loading}
                            className="bottom-button"
                            onClick={() => {
                                this.props.onClose()
                            }}
                        />
                    </div>
                </div>
            </ModFindPopUp>
        )
    }


}