import * as React from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js'
import { ModFindLoader } from './generic/ModFindLoader';
import { Message } from 'semantic-ui-react';

import { t } from "i18next";

export const StripeAppleCheckoutButton: React.FunctionComponent<{
    label: string, amount: number,
    currency: string,
    onToken: (token: string) => Promise<any>
}> = (props) => {
    const [paymentRequest, setPaymentRequest] = React.useState<PaymentRequest | null>(null);
    const [loading, setLoading] = React.useState(true)
    const stripe = useStripe()

    React.useEffect(() => {
        if (!stripe) {
            return
        }
        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: props.currency,
            total: {
                label: 'ModFind',
                amount: props.amount,
            },
            displayItems: [{ label: props.label, amount: props.amount }],
            requestPayerName: true,
            requestPayerEmail: true
        });
        paymentRequest.on('token', async (ev) => {
            props.onToken(ev.token.id).then(() => {
                ev.complete('success')
            }).catch(err => {
                ev.complete('fail')
            })
        })
        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(result => {
            if (result) {
                setPaymentRequest(paymentRequest);
                setLoading(false)
            } else {
                setLoading(false)
            }
        });
    }, []);

    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{ paymentRequest }} />
    }
    if (loading) {
        return <ModFindLoader />
    }


    // Render a normal traditional checkout form instead.
    return <span>{t("ErrorMessages.Payout.NoApplePay")}</span>;
}