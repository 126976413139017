const APP_STORE = require('./img/app-store.png')
const IC_ARROW_DOWN = require('./img/ic_arrow_down.png');
const IC_ARROW_DOWN_BLACK = require('./img/ic_arrow_down_black.png');
const IC_ARROW_DOWN_COLOR = require('./img/ic_arrow_down_color.png');
const IC_ARROW_LEFT = require('./img/ic_arrow_left.png');
const IC_ARROW_RIGHT = require('./img/ic_arrow_right.png');
const IC_TICK = require('./img/tick_icon.svg');
const IC_WATCH = require('./img/ic_watch.svg');
const IC_WATCH_WHITE = require('./img/ic_watch_white.svg');
const IC_WATCH_PLUS = require('./img/ic_watch_plus.svg');
const IC_FOLLOW = require('./img/ic_follow.svg');
const IC_FOLLOW_WHITE = require('./img/ic_follow_white.svg');
const IC_FOLLOW_PLUS = require('./img/ic_follow_plus.svg');
const IC_CARS_SALE = require('./img/ic_car_sales.svg');
const IC_CLOSE = require('./img/ic-close-color.png');
const IC_FOLLOW_PEOPLE_BRANDS = require('./img/ic_follow_people_brands.png');
const IC_CLOSE_RED = require('./img/ic_close_red.png');
const IC_CREATE = require('./img/ic-Create.png');
const IC_DELETE = require('./img/ic-delete.png');
const IC_FEATURED_STAR = require('./img/ic_featured_star.svg');
const IC_FACEBOOK = require('./img/ic_facebook.png');
const IC_GOOGLE = require('./img/ic_google.png');
const IC_HEART = require('./img/ic_heart.png');
const IC_HEART_FILLED = require('./img/ic_heart_filled.png');
const IC_HEART_WHITE = require('./img/ic_heart_white.png');
const IC_HEART_FILLED_WHITE = require('./img/ic_heart_filled_white.png');
const IC_INSTAGRAM = require('./img/ic_instagram.png');
const IC_INSTAGRAM_BLACK = require('./img/ic_instagram_black.png');
const IC_INSTAGRAM_BLUE = require('./img/ic_instagram.svg');
const IC_LINKED_IN = require('./img/ic_linked_in.png');
const IC_REPORT = require('./img/ic_report.png');
const IC_PROFILE = require('./img/ic_profile.png');
const IC_SEARCH = require('./img/ic_search_black.png');
const IC_SHARE = require('./img/ic_share.png')
const IC_TWITTER = require('./img/ic_twitter.png');
const IC_YOU_TUBE = require('./img/ic_you_tube.png');
const IC_MARKER = require('./img/ic_marker.png');
const IC_MARKER_BLACK = require('./img/ic_marker_black.png');
const IC_MARKER_BLUE = require('./img/ic_marker.svg');
const IC_MESSAGES_BLUE = require('./img/ic_messages_blue.png');
const IC_MESSAGES = require('./img/ic_messages.png');
const IC_LOCATION_MARKER_LIGHT_GRAY = require('./img/ic_location_marker_light_gray.png');
const LINED_BG = require('./img/lined_background.png');
const PLAY_STORE = require('./img/google-play-badge.png')
const TOP_BANNER = require('./img/top_banner.png');
const SUCCESS = require('./img/success.png');
const INFO = require('./img/info.png');
const NOTIFICATION = require('./img/notification.png');
const SETTINGS = require('./img/ic_settings.png')
const PROFILE_TITLE_PLACEHOLDER = require('./img/profile_title_placeholder.png')
const BLOCK = require('./img/block.png')
const LOGO_CAR = require('./img/logo_car.svg')
const IC_CHECK = require('./img/check_icon.png')
const IC_CHECK_BLUE = require('./img/ic_check_blue.png')
const IC_VERIFY = require('./img/ic_verified.svg')
const LOGO = require('./img/logo.png')
const APPLE_PAY = require('./img/apple_pay.png')
const APPLE_PAY_WHITE = require('./img/apple_pay_white.png')
const PAYPAL = require('./img/logo_pay_pal.png')
const GOOGLE_PAY = require('./img/logo_google_pay.png')
const CREDIT_CARD = require('./img/ic_credit_card.png')
const PAYMENT = require('./img/payment.png')
const DELIVERY = require('./img/delivery.png')
const RATING = require('./img/rating.png')
const RATING_FILLED = require('./img/rating_filled.png')
const PROFILE_BLUE = require('./img/ic_profile_blue.png')
const CLOSE_BLUE = require('./img/ic_close_blue.png')
const SCREENSHOTS = require('./img/screenshots.jpg')
const ADS_1 = require('./img/ads_1.jpg')
const ADS_2 = require('./img/ads_2.jpg')
const IC_TIME = require('./img/ic_time.png')
const IC_LOCATION_MARKER = require('./img/ic_location_marker.png')
const IC_VIEW = require('./img/ic_view.png')
const CLOSE_BLACK = require('./img/ic_close_black.svg')
const IC_FILTER = require('./img/ic-filter-color.svg')
const BG_WHEELGIVEAWAY = require('./img/wheelgiveaway_bg.svg')
const IC_SHARE_WHITE = require('./img/ic_share_white.svg')
const IC_ATTACHEMENT = require('./img/attachement.png')
const IC_BIO = require('./img/bio-icon.svg')
const IC_INFO = require('./img/ic_info.svg')
const IC_ADD = require('./img/ic_add.svg')
const CONTEST = require('./img/Modfind-Contest2.jpg')
const DISCOVER = require('./img/discover.svg')
const MASTERCARD = require('./img/mastercard.svg')
const MASTERCARD_CARD = require('./img/mastercard_card.svg')
const PAYPAL_CARD = require('./img/paypal_card.svg')
const VISA = require('./img/visa.svg')
const GIVEAWAY = require('./img/giveaway.jpg')
const APPLE_PAY_NO_BORDER = require('./img/apple_pay_no_border.svg')
const MODFIND_STORE_BANNER = require('./img/Modfind-Store-Banner.jpg')
const MODFIND_STORE_BADGE = require('./img/ModFind-Store-Icon.png')
const MINUS = require('./img/minus.png')
const PLUS_GRAY = require('./img/plus-gray.png')
const QUANTITY = require('./img/quantity.png')
const ADS_APP = require('./img/ads-app.png')
const ADS_WEB = require('./img/ads-web.png')
const SHIPPING_RATES = require('./img/shipping-rates.jpg')
const SHIPPING_RATES_MOBILE = require('./img/shipping-rates.mobile.jpg')
const BLUE_QUOTE = require('./img/blue-quote.svg')
const AMBASSADOR_STEP_1 = require('./img/ambassador_1step.svg')
const AMBASSADOR_STEP_2 = require('./img/ambassador_2step.svg')
const AMBASSADOR_STEP_3 = require('./img/ambassador_3step.svg')
const AMBASSADOR_STEP_4 = require('./img/ambassador_4step.svg')
const BLUE_01 = require('./img/blue01.svg')
const BLUE_2A = require('./img/blue2A.svg')
const BLUE_2B = require('./img/blue2B.svg')
const BLUE_3A = require('./img/blue3A.svg')
const BLUE_4A = require('./img/blue4A.svg')
const BLUE_5A = require('./img/blue5A.svg')
const BLUE_3B = require('./img/blue3B.svg')
const CSV_IMPORT_1 = require('./img/csv-import-1.jpg')
const CSV_IMPORT_2 = require('./img/csv-import-2.jpg')
const CSV_IMPORT_3 = require('./img/csv-import-3.jpg')
const CSV_IMPORT_4 = require('./img/csv-import-4.jpg')
const CSV_IMPORT_5 = require('./img/csv-import-5.jpg')
const CSV_IMPORT_6 = require('./img/csv-import-6.jpg')
const CSV_IMPORT_7 = require('./img/csv-import-7.jpg')
const CSV_IMPORT_8 = require('./img/csv-import-8.jpg')
const CSV_IMPORT_9 = require('./img/csv-import-9.jpg')
const CHEVRON_RIGHT = require('./img/chevron-right.svg')
const TRASH = require('./img/trash.svg')
const IC_PLUS_BORDER = require('./img/ic-plus-border.svg');
const IC_MINUS_BORDER = require('./img/ic-minus-border.svg');

export {
    IC_MINUS_BORDER,
    IC_PLUS_BORDER,
    TRASH,
    CHEVRON_RIGHT,
    CSV_IMPORT_1,
    CSV_IMPORT_2,
    CSV_IMPORT_3,
    CSV_IMPORT_4,
    CSV_IMPORT_5,
    CSV_IMPORT_6,
    CSV_IMPORT_7,
    CSV_IMPORT_8,
    CSV_IMPORT_9,
    SHIPPING_RATES_MOBILE,
    SHIPPING_RATES,
    ADS_APP,
    ADS_WEB,
    MINUS,
    PLUS_GRAY,
    QUANTITY,
    MODFIND_STORE_BADGE,
    MODFIND_STORE_BANNER,
    APPLE_PAY_NO_BORDER,
    DISCOVER,
    MASTERCARD_CARD,
    MASTERCARD,
    PAYPAL_CARD,
    VISA,
    CONTEST,
    GIVEAWAY,
    IC_LOCATION_MARKER,
    IC_LOCATION_MARKER_LIGHT_GRAY,
    IC_VIEW,
    IC_TIME,
    SCREENSHOTS,
    IC_FOLLOW_WHITE,
    IC_FOLLOW,
    IC_FOLLOW_PEOPLE_BRANDS,
    IC_FOLLOW_PLUS,
    ADS_1, ADS_2,
    CLOSE_BLACK,
    CLOSE_BLUE,
    PAYMENT,
    DELIVERY,
    RATING_FILLED,
    RATING,
    PROFILE_BLUE,
    LOGO,
    CREDIT_CARD, APPLE_PAY, APPLE_PAY_WHITE, PAYPAL, GOOGLE_PAY,
    IC_CHECK,
    IC_CHECK_BLUE,
    IC_MARKER_BLACK,
    LOGO_CAR,
    IC_INSTAGRAM_BLACK,
    BLOCK,
    PROFILE_TITLE_PLACEHOLDER,
    SETTINGS,
    NOTIFICATION,
    INFO,
    IC_HEART_FILLED_WHITE,
    IC_HEART_WHITE,
    SUCCESS,
    IC_MESSAGES_BLUE,
    IC_MESSAGES,
    IC_MARKER,
    APP_STORE,
    IC_ARROW_DOWN,
    IC_ARROW_DOWN_BLACK,
    IC_ARROW_DOWN_COLOR,
    IC_ARROW_LEFT,
    IC_ARROW_RIGHT,
    IC_CARS_SALE,
    IC_FEATURED_STAR,
    IC_CLOSE,
    IC_CLOSE_RED,
    IC_CREATE,
    IC_DELETE,
    IC_FACEBOOK,
    IC_GOOGLE,
    IC_WATCH_WHITE,
    IC_WATCH_PLUS,
    IC_HEART,
    IC_HEART_FILLED,
    IC_INSTAGRAM,
    IC_LINKED_IN,
    IC_REPORT,
    IC_PROFILE,
    IC_SEARCH,
    IC_SHARE,
    IC_TWITTER,
    IC_YOU_TUBE,
    IC_WATCH,
    LINED_BG,
    PLAY_STORE,
    TOP_BANNER,
    IC_FILTER,
    BG_WHEELGIVEAWAY,
    IC_SHARE_WHITE,
    IC_ATTACHEMENT,
    IC_BIO,
    IC_ADD,
    IC_INSTAGRAM_BLUE,
    IC_INFO,
    IC_MARKER_BLUE,
    IC_VERIFY,
    IC_TICK,
    BLUE_QUOTE,
    AMBASSADOR_STEP_1,
    AMBASSADOR_STEP_2,
    AMBASSADOR_STEP_3,
    AMBASSADOR_STEP_4,
    BLUE_01,
    BLUE_2A,
    BLUE_2B,
    BLUE_3A,
    BLUE_3B,
    BLUE_4A,
    BLUE_5A
}