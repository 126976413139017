import * as React from 'react'
import { PayoutAPI, User, Region, UsersAPI, APIErrorType } from 'fullcircle-api';
import { CLOSE_BLUE } from '../../../assets';
import { PayoutProvider, StripeSellerAccount } from 'fullcircle-api/lib/endpoints/payouts';
import { UserContextType } from '../../../context/user';
import { ModFindCurrencyInput } from '../../generic/ModFindCurrencyInput';

import { formatPriceI18n, unformatPriceI18n } from '../../../utils/formater';
import Button from '../../generic/Button';
import toastr from 'toastr'
import ModFindPopUp, { TitleMessageContent } from '../../popup/ModFindPopUp';
import { Colors } from '../../../styles/colors';
import { t } from 'i18next';
import { ModFindLoader } from '../../generic/ModFindLoader';

export class Payout extends React.Component<{
    onClose: () => void;
}, {
    showBank?: boolean
    account?: StripeSellerAccount,
    amount?: number,
    payoutLoading: boolean,
    region?: Region,
    balanceError?: any,
    balance?: string,
    inputBalance?: string,
    inputValid: boolean,
    payoutSuccess: boolean
    loadingBank: boolean
    loadingBalance: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            payoutLoading: false,
            inputValid: false,
            payoutSuccess: false,
            loadingBank: true,
            loadingBalance: true
        }
    }

    static contextType = UserContextType
    context: User | undefined

    componentDidMount() {
        this.getAccount()
        this.getBalance()
        PayoutAPI.getAccountRegion().then((region) => {
            this.setState({ region })
        }).catch(err => {
            toastr.error(t("ErrorMessages.SomethingWentWrong"))
        })
    }

    private getAccount() {

        PayoutAPI.getAccount().then((account) => {
            let filteredReqKeys = account.required_keys /* && account.required_keys.filter((key) => key !== 'individual.verification.additional_document') */
            if (filteredReqKeys && filteredReqKeys.length > 0) {
                this.setState({ showBank: true, account, loadingBank: false })
            } else {
                this.setState({ showBank: false, account, loadingBank: false })
            }
        }).catch(() => {
            this.setState({ showBank: true, loadingBank: false })
        });
    }

    private getBalance() {
        UsersAPI.getBalance().then(({ balance }) => {
            this.setState({
                balanceError: undefined,
                balance: balance,
                loadingBalance: false
            })
        }).catch((error) => {
            this.setState({
                balanceError: error,
                loadingBalance: false
            })
        })
    }

    render() {

        if (!this.state.region) {
            return <div className="payout-container"><ModFindLoader /></div>
        }
        if (this.state.loadingBank || this.state.loadingBalance) {
            return <div className="payout-container"><ModFindLoader /></div>
        }
        return (
            <div className="payout-container">
                {this.renderPayoutPopup()}
                <div className="payout-close">
                    <img src={CLOSE_BLUE} onClick={() => this.props.onClose()} />
                </div>
                {this.state.showBank ?
                    <div className="payout-inner">
                        <Button size='sm' rounded style={{ marginTop: 20 }} text={t("Messages.CONNECT_ACCOUNT")} onClick={() => {
                            this.setState({ payoutLoading: true })
                            PayoutAPI.createAccount().then(({ url }) => {
                                location.href = url
                            }).catch(err => {
                                toastr.error(t("ErrorMessages.SomethingWentWrong"))
                                this.setState({ payoutLoading: false })
                            })
                        }} loading={this.state.payoutLoading} />
                    </div>
                    :
                    <div className="payout-inner">
                        <span>{t("Messages.REQUEST_PAYOUT")}</span>
                        <div className="input-row">
                            <label className='headerText'>{t("Messages.PAYOUT_AMOUNT")}</label>
                            <div className="input-container">
                                <ModFindCurrencyInput
                                    disabled={this.state.payoutLoading}
                                    onValueChanged={(value) => {
                                        this.changeInput(value)
                                    }}
                                    placeholder={this.state.balance ? (formatPriceI18n(this.state.balance as unknown as string, this.state.region.currency) + ' ' + t("Messages.AVAILABLE")) : '...'}
                                    value={this.state.inputBalance}
                                    colored
                                    rounded
                                    style={{ margin: "8px 0px 4px" }}
                                    currency={this.state.region.currency}
                                />
                            </div>
                        </div>
                        <div className='payout-button'>
                            <Button size='sm' text={'Payout'} disabled={this.state.payoutLoading || !this.state.inputValid} loading={this.state.payoutLoading} rounded onClick={() => {
                                this.payout()
                            }} />
                        </div>
                    </div>
                }
            </div>
        )
    }

    private changeInput(text: string) {
        let amount = unformatPriceI18n(text, this.state.region!.currency)
        if (amount > Number(this.state.balance)) {
            text = formatPriceI18n(this.state.balance!, this.state.region!.currency)
            amount = Number(this.state.balance)
        }
        this.setState({
            inputBalance: text,
            amount: amount,
            inputValid: amount > 0
        })
    }

    private payout() {
        this.setState({ payoutLoading: true })
        PayoutAPI.requestPayout(this.state.amount!, PayoutProvider.BANK_ACCOUNT).then(() => {
            this.setState({ payoutSuccess: true, payoutLoading: false })
        }).catch((error) => {
            if (error.type == APIErrorType.InvalidParameters) {
                toastr.error(t("ErrorMessages.Payout.NotAvailable"))
            } else if (error.type == APIErrorType.ResourceNotFound) {
                this.setState({ showBank: true })
                toastr.error(t("ErrorMessages.Payout.InvalidAccount"))
            } else {
                toastr.error(t("ErrorMessages.SomethingWentWrong"))
            }
            this.setState({ payoutLoading: false })
        })
    }

    private renderPayoutPopup() {
        if (this.state.payoutSuccess) {
            return (
                <ModFindPopUp
                    onClose={() => { this.props.onClose() }}>
                    <TitleMessageContent
                        icon={'success'}
                        title={t("Messages.CONGRATS")}
                        message={t("Messages.PAYOUT_SUCCESS")}
                    />
                </ModFindPopUp>
            )
        }
        return null
    }
}