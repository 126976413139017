import React from "react";
import { CardInterface, PaymentsAPI, ProviderType } from "fullcircle-api";
import { PaymentProvider, PaymentProviderIcons } from "../../../utils/payment";

import toastr from 'toastr'
import { Title } from "../../generic/Title";
import * as money from "money-math";
import Box from "../../generic/Box";
import { ModFindLoader } from "../../generic/ModFindLoader";
import Button from "../../generic/Button";
import { t } from "i18next";

interface ChangePaymentProps {
    onPaymentSelected?: (payment: CardInterface) => void;
    onPaymenTypeSelected?: (type: PaymentProvider) => void;
    onAddCreditCard?: () => void
    additional_provider?: PaymentProvider[];
    amount?: string;
    currency?: string;
    selectedPaymentType?: PaymentProvider | CardInterface
}

export class ChangePayment extends React.Component<ChangePaymentProps, { cards: CardInterface[], loading: boolean }> {
    constructor(p: any) {
        super(p);
        this.state = {
            cards: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        PaymentsAPI.getCards()
            .then(cards => this.setState({
                cards: cards.filter(c => {
                    return this.props.currency == undefined || c.currency == undefined || c.currency == this.props.currency
                }), loading: false
            }))
            .catch(() => {
                this.setState({ loading: false })
                toastr.error(t("ErrorMessages.SomethingWentWrong"))
            })
    }

    renderProvider(item: PaymentProvider) {
        return <div className='change-payment-item' key={item} onClick={() => {
            this.props.onPaymenTypeSelected && this.props.onPaymenTypeSelected(item)
        }}>
            <div className='item-logo'>
                <img src={PaymentProviderIcons.get(item)!.icon} />
            </div>
        </div>
    }

    renderCard(card: CardInterface) {
        let insufficient_funds = false
        let editable = true
        if (card.provider == ProviderType.ModFind) {
            editable = false;
            if (typeof card.balance == 'string' && this.props.amount) {
                insufficient_funds = money.isNegative(money.subtract(card.balance, this.props.amount))
            }
        }

        return <div className='change-payment-item' key={card.id} onClick={() => {
            if (!insufficient_funds)
                this.props.onPaymentSelected && this.props.onPaymentSelected(card)
        }}>
            <span className='item-name'>{card.provider_name} {card.last_4 && t("Messages.ENDING_ON") + card.last_4 + ""}</span>

            {insufficient_funds && <div className='item-info'>
                (Insufficent Funds)
            </div>}
        </div>
    }

    renderAdd() {
        return <div className='change-payment-item'>
            <span className='item-add'>{t("Messages.ADD_CREDIT_CARD")}</span>

        </div>
    }

    render() {
        let sections = [{ data: this.state.cards, title: 'My Accounts' }] as { data: CardInterface[] | PaymentProvider[], title: string }[]
        if (this.props.additional_provider)
            sections.push({ data: this.props.additional_provider || [], title: 'External Accounts' })

        return <div className='change-payment'>
            {this.state.loading && <ModFindLoader />}
            {!this.state.loading && sections.map(section => {
                return <div className='change-payment-container' key={section.title}>
                    <Title>{section.title}</Title>
                    <div className='change-payment-items'>
                        {(section.data as Array<PaymentProvider | CardInterface>).map(d => {
                            const selected = (typeof d === 'string' ? this.props.selectedPaymentType == d : (this.props.selectedPaymentType && typeof this.props.selectedPaymentType != 'string' && this.props.selectedPaymentType.id == d.id))
                            return <><Box key={(d as any).id || d} className={selected ? 'selected' : ''}>
                                {typeof d == 'string' && this.renderProvider(d as PaymentProvider)}
                                {typeof d != 'string' && this.renderCard(d)}
                            </Box>
                            </>
                        })}
                    </div>
                </div>
            }).concat(<div className='change-payment-container' key='add' style={{ flexDirection: 'column', display: 'flex' }}>
                <Title>{t("Messages.ADD_ACCOUNTS")}</Title>
                {/*                 <div className='change-payment-items'>
                    <Button text='Add Credit Card' onClick={() => {
                        this.props.onAddCreditCard && this.props.onAddCreditCard()
                    }} size='lg' />
                </div> */}
                <Button text='Add Credit Card' onClick={() => {
                    this.props.onAddCreditCard && this.props.onAddCreditCard()
                }} size='lg' rounded style={{ marginTop: 16 }} />
            </div>)}
        </div>

    }
}