import * as React from 'react'
import ModFindPopUp from './ModFindPopUp';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react'
import { Colors } from '../../styles/colors';
import Button from '../generic/Button';
import { PostsAPI, APIError, APIErrorType, UsersAPI } from 'fullcircle-api';
import toastr from 'toastr'

import { t } from 'i18next';

interface ModFindReportPopupProps {
    postId?: string,
    userId?: string,
    onClose: () => void
}

export default class ModFindReportPopup extends React.Component<ModFindReportPopupProps, {
    reportType: string,
    reportIssue: string,
    sendReport: boolean
}> {

    constructor(props: ModFindReportPopupProps) {
        super(props);
        this.state = {
            reportType: props.postId ? 'report_item' : 'block_user',
            reportIssue: 'inappropriate_content',
            sendReport: false
        }
    }

    options() {
        const REPORT_TYPES: DropdownItemProps[] = [
            {
                key: 'report_item',
                value: 'report_item',
                text: t("Messages.REPORT_ITEM"),
                section: 'report_item'
            },
            {
                key: 'block_user',
                value: 'block_user',
                text: t("Messages.BLOCK_USER"),
                section: 'block_user'
            },
        ]

        const REPORT_ISSUES: DropdownItemProps[] = [
            {
                key: 'advertising_soliciting',
                value: 'advertising_soliciting',
                text: t("Messages.ADVERTISING"),
                section: 'advertising_soliciting'
            },
            {
                key: 'counterfeit_item',
                value: 'counterfeit_item',
                text: t("Messages.ISSUE_COUNTERFEIT"),
                section: 'counterfeit_item'
            },
            {
                key: 'digital_item',
                value: 'digital_item',
                text: t("Messages.ISSUE_DIGITAL_ITEM"),
                section: 'digital_item'
            },
            {
                key: 'inappropriate_content',
                value: 'inappropriate_content',
                text: t("Messages.ISSUE_INAPPROPRIATE"),
                section: 'inappropriate_content'
            },
            {
                key: 'prohibited_item',
                value: 'prohibited_item',
                text: t("Messages.ISSUE_PROHIBITED"),
                section: 'prohibited_item'
            },
            {
                key: 'trades_offline_transaction',
                value: 'trades_offline_transaction',
                text: t("Messages.ISSUE_OFFLINE_TRANSACTION"),
                section: 'trades_offline_transaction',
            },
            {
                key: 'stolen_item',
                value: 'stolen_item',
                text: t("Messages.ISSUE_STOLEN"),
                section: 'stolen_item',
            },
            {
                key: 'not_responding',
                value: 'not_responding',
                text: t("Messages.ISSUE_NO_RESPONDING"),
                section: 'not_responding',
            },
            {
                key: 'other',
                value: 'other',
                text: t("Messages.ISSUE_OTHER"),
                section: 'other',
            },
        ]
        return { REPORT_ISSUES, REPORT_TYPES }
    }

    render() {
        const { postId, userId } = this.props
        const { REPORT_ISSUES, REPORT_TYPES } = this.options()
        return (
            <ModFindPopUp onClose={() => this.props.onClose()}>
                <div className="modfind-popup-content-wrapper">
                    {this.state.reportType === 'block_user' && <div className="modfind-popup-title">{t("Messages.BLOCK_QUESTION")}</div>}
                    {postId && userId && <div>
                        <Dropdown
                            options={REPORT_TYPES}
                            className={'report-dropdown modfind-dropdown-content'}
                            defaultValue={this.state.reportType}
                            onChange={(_, { value }) => {
                                this.setState({ reportType: (value as string) })
                            }}
                            fluid
                            placeholder={t("Messages.TYPE")}
                            selection
                        />
                        <div className="separator indent" />
                    </div>}
                    <span className="modfind-popup-input-title">{t("Messages.ISSUE_QUESTION")}</span>
                    <Dropdown
                        options={REPORT_ISSUES}
                        className={'report-dropdown modfind-dropdown-content-bold'}
                        defaultValue={this.state.reportIssue}
                        onChange={(_, { value }) => {
                            this.setState({ reportIssue: (value as string) })
                        }}
                        fluid
                        placeholder={t("Messages.ISSUE")}
                        selection
                    />
                    <div className="separator" />
                    <div className="modfind-popup-info">{t("Messages.REPORT_INFO")}</div>
                    <div className="buttons-container">
                        <Button
                            text={t("Messages.SEND")}
                            size={'sm'}
                            rounded
                            className="left-button"
                            onClick={() => { this.report() }}
                            loading={this.state.sendReport}
                            disabled={this.state.sendReport}
                        />
                        <Button
                            text={t("Messages.CANCEL")}
                            size={'sm'}
                            rounded
                            className="right-button"
                            onClick={() => this.props.onClose()}
                            disabled={this.state.sendReport}
                        />
                    </div>
                </div>
            </ModFindPopUp>
        )
    }

    private report() {
        this.setState({ sendReport: true })
        switch (this.state.reportType) {
            case 'report_item':
                if (this.props.postId) {
                    PostsAPI.reportPost(this.props.postId, this.state.reportIssue).then((post) => {
                        toastr.success(t("Messages.ITEM_WAS_REPORTED"))
                    }).catch((e: APIError) => {
                        toastr.error(
                            e.type === APIErrorType.AlreadyExists ?
                                t("Messages.ALREADY_REPORTED") :
                                t("Messages.CANNOT_REPORT"))
                    }).finally(() => {
                        this.setState({ sendReport: false })
                        this.props.onClose()
                    })
                } else {
                    toastr.error(t("Messages.CANNOT_REPORT"))
                }

                break;
            case 'block_user':
                if (this.props.userId) {
                    UsersAPI.blockUser(this.props.userId, this.state.reportIssue).then(() => {
                        toastr.success(t("Messages.USER_BLOCKED"))
                    }).catch(() => {
                        toastr.error(t("Messages.CANNOT_BLOCK_USER"))
                    }).finally(() => {
                        this.setState({ sendReport: false })
                        this.props.onClose()
                    })
                } else {
                    toastr.error(t("ErrorMessages.SomethingWentWrong"))
                    this.setState({ sendReport: false })
                    this.props.onClose()
                }
                break;
            default:
                toastr.error(t("ErrorMessages.SomethingWentWrong"))
                this.setState({ sendReport: false })
                this.props.onClose()
        }
    }
}