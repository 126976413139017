import { User, Device, LoginAPI } from "fullcircle-api";
import ChatHandler from "../api/chathandller";
import { Favorite } from "../cache/favorite";

export const USER_SESSION = 'user-session'
export const FAVORITE_POSTS = 'favorite-posts'

export interface SessionData {
    user: User;
    session: Device;
    refresh_token: string;
}

let sessionUpdatedHandler: ((session: SessionData | undefined) => void) | undefined = undefined;
let userUpdatedHandler: ((user: User) => void) | undefined = undefined;

export class UserSession {

    static setSessionUpdatedHandler(handler: ((session: SessionData | undefined) => void) | undefined) {
        sessionUpdatedHandler = handler;
    }

    static getSessionUpdatedHandler() {
        return sessionUpdatedHandler || (() => { })
    }

    static setUserUpdatedHandler(handler: ((user: User) => void) | undefined) {
        userUpdatedHandler = handler;
    }

    static getUserUpdatedHandler() {
        return userUpdatedHandler || (() => { })
    }

    static async logout() {
        try {
            localStorage.removeItem(USER_SESSION)
        } catch (error) {

        }
        Favorite.clear()
        ChatHandler.destroy()
        UserSession.getSessionUpdatedHandler()(undefined)
        await LoginAPI.logout().catch(() => {

        })
    }
}