import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Popup } from "semantic-ui-react";
import { t } from 'i18next';

import { LoginAPI } from 'fullcircle-api';

import Box from '../components/generic/Box';
import ModFindInput from '../components/generic/ModFindInput';
import Button from '../components/generic/Button';
import { validateEmail } from '../utils/helper';

export interface ForgotPasswordProps extends RouteComponentProps<{ page: string }> { }

class ForgotPassword extends React.Component<ForgotPasswordProps, {
    formValid: boolean,
    loading: boolean,
    error?: string,
}> {

    constructor(props: ForgotPasswordProps) {
        super(props)
        this.state = {
            formValid: false,
            loading: false,
        }
    }


    private email: string = ''

    checkValidForm() {
        let valid = false;
        if (this.email.length > 0 && validateEmail(this.email)) {
            valid = true;
        }
        this.setState({
            formValid: valid,
        });
    }

    onValueChanged() {
        this.setState({ error: undefined });
        this.checkValidForm();
    }

    resetPassword() {
        this.setState({ loading: true })
        LoginAPI.forgotPassword(this.email).then(() => {
            this.props.history.push('login')
        }).catch((e) => {
            let error: string = ''
            if (e && e.status && e.status == 404) {
                error = t('Messages.USER_NOT_FOUND');
            } else {
                error = t('Messages.SOMETHING_WENT_WRONG');
            }
            this.setState({ error, loading: false });
        })
    }
    render() {

        return (
            <Box>
                <div className="forgot-pw-container">
                    <div className="headline">
                        <span>{t('Messages.FORGOT_PASSWORD')}</span>
                    </div>
                    <div className="form-container">
                        <div className="instruction-container">
                            <span className="instruction-text">{t('Messages.FORGOT_PW_INSTRUCTIONS')}</span>
                        </div>
                        <ModFindInput
                            placeholder={t("Messages.EMAIL")}
                            onValueChanged={(value) => {
                                this.email = value
                                this.onValueChanged()
                            }}
                            colored
                            rounded
                            containerSize={'lg'}
                            type={'email'}
                        />
                        {this.state.error && <div className="form-subcontainer-auth">
                            <span className="error-text-auth">{this.state.error}</span>
                        </div>}
                        <div className="form-subcontainer-auth">
                        <Popup
                            trigger={
                                <Button
                                    text={t('Messages.RESET_PW')}
                                    size={'lg'}
                                    rounded
                                    disabled={!this.state.formValid}
                                    loading={this.state.loading}
                                    onClick={() => { this.resetPassword() }}
                                />
                            }
                            content={t('ErrorMessages.ForgotPassword.InvalidEmail')}
                            disabled={this.state.formValid}
                        />
                        </div>
                    </div>
                </div>
            </Box>
        )
    }
}

export default withRouter(ForgotPassword)