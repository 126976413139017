import * as React from "react";
import ModFindPopUp, { TitleMessageContent } from './ModFindPopUp';

import { RouteComponentProps } from "react-router";
import { t } from "i18next";

export class LoginRequiredPopup extends React.Component<RouteComponentProps, { visible: boolean }> {

    static _instance: LoginRequiredPopup

    static show() {
        LoginRequiredPopup._instance && LoginRequiredPopup._instance.setState({ visible: true })
    }

    constructor(p: any) {
        super(p);
        LoginRequiredPopup._instance = this;
        this.state = {
            visible: false
        }
    }

    render() {
        if (this.state.visible == false) {
            return null
        }
        return <ModFindPopUp onClose={() => this.setState({ visible: false })}>
            <TitleMessageContent
                title={t("Messages.LOGIN_REQUIRED_TITLE")}
                message={t("Messages.LOGIN_REQUIRED_MESSAGE")}
                buttons={[{
                    text: t("Messages.CANCEL"), onClick: () => {
                        this.setState({ visible: false })
                    }
                },
                {
                    text: t("Messages.OK"), onClick: () => {
                        this.props.history.push(`/login?redirect=${window.location.pathname + window.location.search}`)
                        this.setState({ visible: false })
                    }
                }]} />
        </ModFindPopUp>
    }

}