import * as React from 'react'
import { Container } from 'reactstrap';
import { TOP_BANNER, IC_FACEBOOK, IC_INSTAGRAM, PLAY_STORE, APP_STORE } from '../../../assets';

import ModFindInput from '../../generic/ModFindInput';
import Button from '../../generic/Button';
import ChatHandler, { ChatHandlerEvents } from '../../../api/chathandller';
import toastr from 'toastr'
import { UserContextConsumer } from '../../../context/user';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PaymentMethods from '../payment/PaymentMethods';
import * as _ from 'lodash'
import { t } from 'i18next';
export interface FooterProps extends RouteComponentProps {
    onNavItemPressed: (item: string) => void;
    onFooterVisible: (visible: boolean) => void
}

export default withRouter(class Footer extends React.Component<FooterProps> {
    private footerDiv?: HTMLDivElement | null;

    constructor(props: FooterProps) {
        super(props)
        this.checkDivPosition = _.debounce(this.checkDivPosition.bind(this), 300)
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.checkDivPosition()
        })
        if (ChatHandler.instance()) {
            ChatHandler.instance()?.addListener(ChatHandlerEvents.ChatsUpdate, this.checkDivPosition)
        }
    }

    checkDivPosition() {
        if (this.footerDiv) {
            const position = this.footerDiv.getBoundingClientRect()
            if (position.top < window.innerHeight && position.bottom >= 0) {
                this.props.onFooterVisible(true)
            } else {
                this.props.onFooterVisible(false)
            }
        }
    }

    render() {
        const { pathname } = this.props.location
        return <UserContextConsumer>{(user) => {
            return (
                <div className="footer-container" ref={(ref) => {
                    this.footerDiv = ref
                    this.checkDivPosition()
                }}>
                    <Container className="inner-container">
                        <div className="footer-top">
                            <div className="logo">
                                <img src={TOP_BANNER} className="top-banner" alt={'logo'} />
                                <span className="footer-text">&copy; {`Copyright 2021, All Rights Reserved. ModFind\u00A0LLC.`}</span>
                            </div>
                            <div className="social-wrapper">
                                <span className="footer-text">{t("Messages.SOCIALIZE")}</span>
                                <div className="img-wrapper">
                                    <img src={IC_FACEBOOK} alt={'facebook'} className="social-img" onClick={() => { window.open('https://www.facebook.com/buysellkickback/', '_blank') }} />
                                    <img src={IC_INSTAGRAM} alt={'instagram'} className="social-img" onClick={() => { window.open('https://www.instagram.com/modfind/', '_blank') }} />
                                </div>

                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="bottom-wrapper">
                                <div className="navigation">
                                    {!user && <div className="nav-item">
                                        <span className="footer-item-text" onClick={() => this.props.onNavItemPressed('sign-up')}>{t("Messages.SIGN_UP")} </span>
                                    </div>}
                                    {!user && <div className="nav-item">
                                        <span className="footer-item-text" onClick={() => {
                                            this.props.onNavItemPressed('login')
                                        }}>{t("Messages.LOGIN")}</span>
                                    </div>}
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (user && pathname == '/sell' ? 'bold' : '')} onClick={() => {
                                            if (user) {
                                                this.props.onNavItemPressed('sell')
                                            } else {
                                                this.props.onNavItemPressed('login')
                                            }
                                        }}>{t("Messages.SELL")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/featured' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('featured')}>{t("Messages.FEATURED_ITEMS")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/following' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('following')}>{t("Messages.FOLLOWING")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/recently' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('recently')}>{t("Messages.RECENTLY_LISTED")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/makes' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('makes')}>{t("Messages.MAKES")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/categories' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('categories')}>{t("Messages.CATEGORIES")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/help' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('help')}>{t("Messages.HELP_CONTACT")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <span className={"footer-item-text " + (pathname == '/advertising' ? 'bold' : '')} onClick={() => this.props.onNavItemPressed('advertising')}>{t("Messages.ADVERTISE")}</span>
                                    </div>
                                    <div className="nav-item">
                                        <a href="https://terms.modfind.com" target="_blank" className="terms">
                                            <span className="footer-item-text">{t("Messages.TERMS_FOOTER")}</span>
                                        </a>
                                    </div>
                                    <div className="nav-item">
                                        <a href="https://privacy.modfind.com" target="_blank" className="privacy">
                                            <span className="footer-item-text">{t("Messages.PRIVACY")}</span>
                                        </a>
                                    </div>
                                    <div className="nav-item">
                                        <a href="https://refund.modfind.com" target="_blank" className="privacy">
                                            <span className="footer-item-text">{t("Messages.RETURN_PRIVACY")}</span>
                                        </a>
                                    </div>
                                    <div className="nav-item">
                                        <a href="https://www.iubenda.com/privacy-policy/60470687/cookie-policy" target="_blank" className="cookie-policy">
                                            <span className="footer-item-text">{t("Messages.COOKIE_POLICY")}</span>
                                        </a>
                                    </div>

                                </div>
                                <div className="app-pay-wrapper">
                                    <div className="app">
                                        <div className="footer-item-text app">{'Download ModFind App:'}</div>
                                        <img src={APP_STORE} className="store-img" alt={'App Store Icon'} onClick={() => { window.open('https://apps.apple.com/us/app/modfind/id1396412499', '_blank') }} />
                                        <img src={PLAY_STORE} className="store-img" alt={'Play Store Icon'} onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.fullcircle', '_blank') }} />
                                    </div>
                                    <PaymentMethods backgroundColor="black" />
                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            )
        }}</UserContextConsumer>
    }
})