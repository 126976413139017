import * as React from 'react';
import { IC_CARS_SALE, IC_FEATURED_STAR } from '../../assets';
import { formatPriceI18n } from '../../utils/formater';

import { APIError, Post, User } from 'fullcircle-api';
import { isPostFeatured, isPostFeaturedEnding } from '../../utils/post_util';
import { t } from 'i18next';
import { Favorite } from '../../cache/favorite';
import { LoginRequiredPopup } from '../popup/LoginRequiredPopup';

export default class PostCarouselItem extends React.Component<{
    post: Post
    currentUser?: User
    onItemClick?: () => void;
}, { favoriteLoading: boolean, isFavorite: boolean }> {

    constructor(props: any) {
        super(props)
        this.state = {
            favoriteLoading: false,
            isFavorite: false
        }
    }

    componentDidMount() {
        Favorite.isFavoritePost(this.props.post.id).then((favorite) => {
            this.setState({ isFavorite: favorite })
        })
    }

    private likePost() {
        if (!this.props.currentUser) {
            LoginRequiredPopup.show()
            return
        }
        this.setState({ favoriteLoading: true })
        let post = this.props.post
        if (post) {
            if (this.state.isFavorite) {
                Favorite.deleteFavoritePost(post).then(() => {
                    this.setState({ isFavorite: false, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            } else {
                Favorite.addFavoritePost(post).then(() => {
                    this.setState({ isFavorite: true, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            }
        }
    }

    render() {
        const { onItemClick } = this.props
        const { title, images, price_on_request, price, kickback_price, currency, user_id } = this.props.post
        const imageUrl = images && images.length > 0 ? images[0].type === 'image' ? images[0].medium_url : images[0].thumbnail_url : undefined

        const isFeatured = isPostFeatured(this.props.post)
        const isMyPost = this.props.currentUser && this.props.currentUser.id == user_id
        const isFeaturedEnding = isMyPost && isPostFeaturedEnding(this.props.post)

        return (
            <div className="modFind-carousel-item-container">
                <div className="modFind-carousel-item-container-ratio">
                    <div className="modFind-carousel-item" onClick={onItemClick}>
                        <div className={'carousel-img-container full-image' + (isFeatured ? ' featured' : '') + (isFeaturedEnding ? ' expiring' : '')}>
                            <img src={imageUrl || IC_CARS_SALE} alt={'Item'} className={"image"} />
                            {isFeatured && <img src={IC_FEATURED_STAR} className='featured-icon' />}
                        </div>
                    </div>
                </div>
                <div className='modFind-carousel-description' onClick={onItemClick}>
                    <span className="item-name">{title}</span>
                    {!price_on_request && <span className="price-text">{formatPriceI18n(price, currency)}</span>}
                    {price_on_request && <span className="price-text">{t("Messages.PRICE_ON_REQUEST")}</span>}
                    <div className='modFind-carousel-lower-container'>
                        <div className="kickback-text">
                            {kickback_price ? `${formatPriceI18n(kickback_price, currency)} Kickback` : <span>&nbsp;</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}