import React from 'react';
import { Session, User, UsersAPI, MetaAPI, ProviderType } from 'fullcircle-api';
import { RouteComponentProps, Redirect } from 'react-router';
import Header from './components/views/layout/Header';
import Footer from './components/views/layout/Footer';
import Home from './pages/Home';
import PostDetail from './pages/PostDetail';
import Selling from './pages/Selling';
import SignUpLogin from './pages/SignUpLogin';
import { UserContextProvider } from './context/user';
import ForgotPassword from './pages/ForgotPassword';
import { UserSession, USER_SESSION, SessionData } from './utils/session';
import Search from './pages/Search';
import Chats from './components/views/chat/Chats';
import ChatHandler from './api/chathandller';
import { SearchQuery } from './components/search/SearchBar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LoginRequiredPopup } from './components/popup/LoginRequiredPopup'
import { VerificationRequiredPopup } from './components/popup/VerificationRequiredPopup';
import Profile from './pages/Profile';
import { Favorite } from './cache/favorite';
import { Helmet } from "react-helmet";
import { Environment } from './env';
import Checkout from './pages/Checkout';
import { Help } from './pages/Help';
import { RegionContextProvider } from './context/region';
import { registerDevice } from './utils/helper';
import { BecomeDealerVendor } from './pages/BecomeDealerVendor';
import * as H from 'history'
import { AdPage } from './pages/Ads';
import { setStripeKey } from './utils/payment';
import { setDefaultRegion } from './components/search/filtersettings';
import NameRequiredPopup from './components/popup/NameRequiredPopup';
import { getGeoData } from './utils/location';
import { FeaturedPage } from './pages/Featured';
import { MakesPage } from './pages/Makes';
import { CategoriesPage } from './pages/Categories';
import { FollowingPage } from './pages/Following';
import { ShippingFeaturePage } from './pages/ShippingFeature';
import { AmbassadorFeaturePage } from './pages/AmbassadorFeature';
import { BecomeAmbassador } from './pages/BecomeAmbassador';
import { CSVUpload } from './pages/CSV-Upload';
import { Alert } from './components/Alert';


Session.setAPIURL(Environment.API);

Session.setNotVerifiedHandler(() => {
    VerificationRequiredPopup.show()
})

const stripePromise = loadStripe(Environment.STRIPE_KEY).catch(() => null);

export default class App extends React.Component<RouteComponentProps<{ page: string }>, {
    user?: User,
    geoData?: { country_name: string, currency?: string }
    searchQuery?: SearchQuery
    footerVisble: boolean
    showNamePopup?: boolean
}> {

    get userSession() {
        try {
            let cachedSession = localStorage.getItem(USER_SESSION)
            if (cachedSession) {
                const session: SessionData = JSON.parse(cachedSession)
                return session
            }
        } catch (error) {

        }
    }

    constructor(props: any) {
        super(props);
        this.state = {
            footerVisble: false
        }
        Session.setUnauthorizedHandler(() => {
            try {
                localStorage.removeItem(USER_SESSION)
            } catch (error) {

            }
            if (this.state.user)
                location.reload()
        })
        try {
            const session = this.userSession
            if (session) {
                Session.setSession(session.session.session_token)
                registerDevice(session.refresh_token)
                Favorite.setUserId(session.user.id)
                this.startChat(session)
                this.state = {
                    ...this.state, user: session.user
                }
            }
        } catch (error) {

        }
    }

    private startChat(session: SessionData) {
        ChatHandler.intitialize(session);
    }

    componentDidMount() {
        UsersAPI.getUser('me').then((user) => {
            this.setState({ user }, () => {
                setDefaultRegion(this.getRegion().region)
            })
        }).catch(err => {
            this.setState({ user: undefined })
        })
        getGeoData().then((data) => {
            this.setState({ geoData: data }, () => {
                setDefaultRegion(this.getRegion().region)
            })
        }).catch(() => {
            setDefaultRegion(this.getRegion().region)
        })
        UserSession.setUserUpdatedHandler((user) => {
            this.setState({ user })
            Favorite.setUserId(user.id)
            const session = this.userSession
            if (session) {
                session.user = user
                try {
                    localStorage.setItem(USER_SESSION, JSON.stringify(session))
                } catch (error) {

                }
            }
        })
        UserSession.setSessionUpdatedHandler((session) => {
            Favorite.setUserId(session?.user?.id)
            if (session) {
                if (!this.state.user || session.user.id != this.state.user.id) {
                    this.startChat(session)
                }
                try {
                    localStorage.setItem(USER_SESSION, JSON.stringify(session))
                } catch (error) {

                }
                Favorite.clear()
                this.setState({ user: session.user })
            } else {
                this.setState({ user: undefined })
            }
        })
        MetaAPI.getPaymentTokens().then(tokens => {
            tokens.forEach(token => {
                if (token.type == ProviderType.Stripe) {
                    setStripeKey(token.token)
                }
            })
        }).catch(() => { })
        this.props.history.listen((location, action) => {
            window.scrollTo(0, 0)
        })
        listenToHistoryChanges(this.props.history)
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.state.user && this.state.user.full_name == '' && this.state.showNamePopup == undefined) {
            this.setState({ showNamePopup: true })
        }
    }

    private getMainComponent(page: string): [any, any?, string?] {
        switch (page) {
            case undefined:
            case '':
            case 'home':
                return [Home, undefined, 'Home']
            case 'featured':
                return [FeaturedPage, undefined, 'Featured']
            case 'following':
                return [FollowingPage, undefined, 'Following']
            case 'makes':
                return [MakesPage, undefined, 'Makes']
            case 'categories':
                return [CategoriesPage, undefined, 'Categories']
            case 'recently':
                return [Search, { showRecents: true }, 'Recent']
            case 'category':
                return [Search, {}, 'Search']
            case 'brand':
                return [Search, {}, 'Search']
            case 'search':
                return [Search, undefined, 'Search']
            case 'top-vendors':
            case 'top-dealers':
            case 'brands':
            case 'categories':
            case 'post':
            case 'post-detail':
                return [PostDetail]
            case 'sell':
            case 'edit':
                return [this.state.user ? Selling : SignUpLogin, undefined, 'Sell']
            case 'sign-up':
            case 'login':
                return [SignUpLogin, undefined, 'Login']
            case 'forgot-password':
                return [ForgotPassword, undefined, 'Forgot Password']
            case 'my-account':
            case 'profile':
            case 'vendor':
            case 'dealer':
                return [Profile, { key: page }]
            case 'checkout':
                return [Checkout, undefined, 'Checkout']
            case 'help':
                return [Help, undefined, 'Help']
            case 'dealer-vendor':
                if (!this.state.user) {
                    return [Redirect as any, { to: '/' }]
                }
                return [BecomeDealerVendor]
            case 'advertising':
                return [AdPage]
            /*case 'giveaway':
                return [WheelGiveaway]*/
            case 'ambassador':
                return [AmbassadorFeaturePage]
            case 'ambassador-registration':
                if (!this.state.user) {
                    return [Redirect as any, { to: '/' }]
                }
                return [BecomeAmbassador]
            case 'shipping-feature':
                return [ShippingFeaturePage]
            case 'csv-feature':
                return [CSVUpload]
            case 'stripe-acc':
                return [Redirect as any, { to: '/my-account' }]
            default:
                return [Redirect as any, { to: '/' }]
        }
    }

    getRegion() {
        return this.state.user ? { region: this.state.user.region, currency: this.state.user.currency } : this.state.geoData ? { currency: this.state.geoData.currency?.toLowerCase() || 'usd', region: this.state.geoData.country_name } : { region: 'United States', currency: 'usd' }
    }

    render() {
        const [Comp, Props = {}, title] = this.getMainComponent(this.props.match.params.page)
        return (
            <UserContextProvider value={this.state.user}>
                <RegionContextProvider value={this.getRegion()}>
                    <Elements stripe={stripePromise}>
                        <LoginRequiredPopup {...this.props} />
                        <Alert />
                        <VerificationRequiredPopup />
                        <Helmet>
                            {title && <title>{title} | ModFind</title>}
                            {!title && <title>ModFind</title>}
                        </Helmet>
                        <Header
                            onNavItemPress={(item) => {
                                const { pathname } = window.location

                                if (item == 'b2b-dashboard') {
                                    window.open(Environment.B2B_DASHBOARD, '__blank')
                                    return
                                } else if (item == 'community') {
                                    window.open(Environment.COMMUNITY, '__blank')
                                }

                                if ((pathname === '/login') || (pathname === '/sign-up')) {
                                    this.props.history.push(`/${item}`)
                                }
                                else if ((item !== 'login') && (item !== 'sign-up')) {
                                    this.props.history.push(`/${item}`)
                                }
                                else {
                                    this.props.history.push(`/${item}?redirect=${pathname + window.location.search}`)
                                }
                            }}
                        />
                        <div className='main-component-container'>
                            <Comp {...Props} searchQuery={this.state.searchQuery} />
                            {this.state.user && <Chats key={this.state.user.id} user={this.state.user}
                                stickToScreenBottom={!this.state.footerVisble} />}
                        </div>

                        {this.state.showNamePopup && <NameRequiredPopup user={this.state.user!} onClose={() => {
                            this.setState({ showNamePopup: false })
                        }} onUserUpdated={() => {
                            this.setState({ showNamePopup: false })
                        }} />}

                        <Footer key={this.state.user ? this.state.user.id : 'logged-out'} onNavItemPressed={(item) => {
                            this.props.history.push(`/${item}`)
                        }} onFooterVisible={(visible) => {
                            if (visible)
                                document.dispatchEvent(new Event('footerVisible'))
                            if (visible != this.state.footerVisble)
                                this.setState({ footerVisble: visible })
                        }} />
                    </Elements>
                </RegionContextProvider>
            </UserContextProvider>
        )
    }
}

function listenToHistoryChanges(history: H.History<H.LocationState>, timeout: number = 4000) {
    let observer;
    let timeoutId;
    let lastKnownScrollPos = window.scrollY

    if (!window.MutationObserver) {
        return;
    }

    const reset = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);

            timeoutId = null;
        }

        if (observer) {
            observer.disconnect();
        }
        observer = undefined
        if (!observer) {
            window.removeEventListener('scroll', checkForReset)
        }

    };

    const createScrollToElement = (id: string) => {
        return () => {
            const element = document.getElementById(id);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                lastKnownScrollPos = window.scrollY
                //reset();

                return true;
            }

            return false;
        };
    };

    const checkForReset = () => {
        if (Math.abs(lastKnownScrollPos - window.scrollY) > 100) {
            reset();
        }
    }

    window.addEventListener('scroll', checkForReset)

    history.listen((location: H.Location, action: string) => {
        if (timeoutId || observer) {
            reset();
        }

        if (action !== 'PUSH') {
            return;
        }

        if (typeof location.hash !== 'string') {
            return;
        }

        const elementId = location.hash.slice(1);

        if (!elementId) {
            return;
        }

        const scrollToElement = createScrollToElement(elementId);

        setTimeout(() => {
            if (scrollToElement()) {
                //return;
            }
            observer = new MutationObserver(scrollToElement);

            observer.observe(document, {
                attributes: true,
                childList: true,
                subtree: true
            });

            timeoutId = setTimeout(reset, timeout);
        });
    });
};