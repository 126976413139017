import { AdBannerView } from '../banner/AdBanner';
import * as React from 'react'
import { TOP_BANNER, IC_FOLLOW_PEOPLE_BRANDS, LINED_BG, NOTIFICATION, PROFILE_BLUE } from '../../../assets';

import { Container, Row } from 'reactstrap'
import { SearchBar } from '../../search/SearchBar';
import { UserContextConsumer, UserContextType } from '../../../context/user';
import { HashLink as Link } from 'react-router-hash-link'
import { UserSession } from '../../../utils/session';
import { Popup } from 'semantic-ui-react';
import { Notifications } from '../Notifications';
import { Favorite } from '../../../cache/favorite';
import { Meta } from '../../../cache/meta';
import { getSearchUrlConfig } from '../../../utils/path';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModFindSavedSearches } from '../ModFindSavedSearches';
import Banner from '../banner/Banner';
import { AdBannerAPI, AdBanner, UsersAPI, SavedSearch, User } from 'fullcircle-api';
import ModFindPopUp, { TitleMessageContent } from '../../popup/ModFindPopUp';
import { Colors } from '../../../styles/colors';
import { errorToString } from '../../../utils/helper';
import { preventBodyScroll } from '../../../utils/helper';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { t } from 'i18next';
import { LoginRequiredPopup } from '../../popup/LoginRequiredPopup';
export interface HeaderProps extends RouteComponentProps {
    onNavItemPress: (item: NavItem) => void;
}
const iOS = (() => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
})()

export type NavItem = 'home' | 'hiw' | 'help' | 'download' | 'sell' | 'sign-up' | 'login' | 'logout' | 'advertising' | 'b2b-dashboard' | 'community';

const DEFAULT_SEARCH_HEIGHT = 110

export default withRouter(class Header extends React.Component<HeaderProps, {
    searchHeight: number,
    prevSearchHeight: number,
    negativeTopOffset?: number
    ad?: AdBanner,
    showTestPopup: boolean,
    savedSearchPopup: {
        show: boolean,
        search?: SavedSearch
    }
}> {

    static contextType = UserContextType
    context!: User | undefined

    navBar?: HTMLDivElement | null;
    private scrollWillChange: boolean = false;
    private allowNextAnimation: boolean = true;

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            searchHeight: DEFAULT_SEARCH_HEIGHT,
            prevSearchHeight: DEFAULT_SEARCH_HEIGHT,
            showTestPopup: true,
            savedSearchPopup: {
                show: false
            }
        }
        this.handleScroll = this.handleScroll.bind(this)
    }

    logout() {
        UserSession.logout()
        this.props.onNavItemPress('login')
    }

    navigateTorCarsForSale() {
        Meta.getCategories().then(categories => {
            let c = categories.find(c => c.name == 'Cars for Sale')
            if (c) {
                const [url, state] = getSearchUrlConfig({ categories: [c] }, this.props.location.state)
                this.props.history.push(url, state)
            }
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        AdBannerAPI.getAdBanner('web').then(banner => {
            if (banner) {
                this.setState({ ad: banner })
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll(e: Event) {
        const scrollTop = window.scrollY,
            minHeight = 50,
            searchHeight = Math.min(Math.max(minHeight, DEFAULT_SEARCH_HEIGHT - scrollTop), DEFAULT_SEARCH_HEIGHT)
        this.setState({
            searchHeight,
            prevSearchHeight: this.state.searchHeight,
            negativeTopOffset: (scrollTop < 0 && iOS) ? scrollTop * -1 : undefined
        }, () => {
        });
    }

    private showRemoveSearchPopup() {

        return (
            <ModFindPopUp onClose={() => this.setState({ savedSearchPopup: { show: false, search: undefined } })}>
                <TitleMessageContent title={t("Messages.DELETE_SAVED_SEARCH")} message={t("Messages.DELETE_SAVED_SEARCH_INFO")} icon={'info'} buttons={[
                    {
                        text: t("Messages.CONFIRM"), onClick: () => {
                            if (this.state.savedSearchPopup.search) {
                                UsersAPI.deleteSavedSearch(this.state.savedSearchPopup.search.id).then(res => {
                                    Favorite.deleteSavedSearch(this.state.savedSearchPopup.search!)
                                }).catch(err => {
                                    toastr.error(errorToString(err).join('\n'));
                                }).finally(() => {
                                    this.setState({ savedSearchPopup: { show: false, search: undefined } })
                                })
                            } else {
                                toastr.error(t("Messages.DELETE_SAVED_SEARCH_ERROR"));
                                this.setState({ savedSearchPopup: { show: false, search: undefined } })
                            }

                        }
                    },
                    {
                        text: t("Message.CANCEL"), onClick: () => { this.setState({ savedSearchPopup: { show: false, search: undefined } }) }, color: Colors.RED
                    }
                ]} />
            </ModFindPopUp>
        )
    }

    render() {
        const { pathname } = this.props.location;
        const { negativeTopOffset } = this.state;
        const padding = Math.max(4, 27 - ((DEFAULT_SEARCH_HEIGHT - this.state.searchHeight) / 2));
        const headerBlueBoxWidth = Math.min(220, 186 + ((DEFAULT_SEARCH_HEIGHT - this.state.searchHeight) / 2));
        const overrideFontSize = this.state.searchHeight <= 84 ? '.9rem' : undefined;
        const isScrolled = DEFAULT_SEARCH_HEIGHT - this.state.searchHeight != 0;

        return (
            <UserContextConsumer>{user => {
                return (
                    <>
                        {this.state.savedSearchPopup.show && this.showRemoveSearchPopup()}
                        <div className='header-sticky'>
                            <div className="black-header">
                                <Container className="container">
                                    <Row>
                                        <div className="left-wrapper">
                                            <Link to={'/home'}>
                                                <img src={TOP_BANNER} alt={'ModFind App'} />
                                            </Link>
                                            <div>
                                                <span className="white-headline-1">
                                                    <h1>{t("Messages.AUTOMOTIVE_MARKETPLACE")}</h1>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='medium-hide right-wrapper'>
                                            <div>
                                                <span className="white-headline-2">
                                                    <h2>{t("Messages.HEADER_RIGHT_HEADLINE")}</h2>
                                                </span>
                                            </div>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            <div>
                                <Container>
                                    <Row className='header-menu' onClick={() => {
                                        if (this.navBar) {
                                            if (this.navBar.classList.contains('responsive')) {
                                                this.navBar.classList.toggle('responsive')
                                            }
                                        }
                                    }}>
                                        <div ref={(ref) => this.navBar = ref} className='navbar left'>
                                            <div className={"nav-item important " + (pathname == '/featured' ? 'active' : '')}>
                                                <Link to='/featured'>
                                                    <h3>
                                                        <span className={"item-text " + (pathname == '/featured' ? 'bold' : '')}>{t("Messages.FEATURED_ITEMS")}</span>
                                                    </h3>
                                                </Link>
                                            </div>
                                            <div className={"nav-item important " + (pathname == '/following' ? 'active' : '')}>
                                                <Link to='/following' onClick={e => {
                                                    if (!this.context) {
                                                        e.preventDefault()
                                                        LoginRequiredPopup.show()
                                                    }
                                                }}>
                                                    <h3>
                                                        <span className={"item-text " + (pathname == '/following' ? 'bold' : '')}>{t("Messages.FOLLOWING")}</span>
                                                    </h3>
                                                </Link>
                                            </div>
                                            <div className={"nav-item important " + (pathname == '/recently' ? 'active' : '')}>
                                                <Link to='/recently'>
                                                    <h3>
                                                        <span className={"item-text " + (pathname == '/recently' ? 'bold' : '')}>{t("Messages.RECENTLY_LISTED")}</span>
                                                    </h3>
                                                </Link>
                                            </div>
                                            <div className={"nav-item " + (pathname == '/makes' ? 'active' : '')}>
                                                {<Link to='/makes'>
                                                    <h3>
                                                        <span className="item-text">{t("Messages.MAKES")}</span>
                                                    </h3>
                                                </Link>}
                                            </div>
                                            <div className={"nav-item important " + (pathname == '/categories' ? 'active' : '')} >
                                                {
                                                    <Link to='/categories'>
                                                        <h3>
                                                            <span className="item-text">{t("Messages.CATEGORIES")}</span>
                                                        </h3>
                                                    </Link>
                                                }
                                            </div>
                                            {user && <div className={"nav-item"}>
                                                <Popup wide={'very'} trigger={
                                                    <h3>
                                                        <span className="item-text">{t("Messages.SAVEDSEARCHES")}</span>
                                                    </h3>
                                                }
                                                    onOpen={() => {
                                                        preventBodyScroll(true)
                                                    }} onClose={() => {
                                                        preventBodyScroll(false)
                                                    }} closeOnTriggerMouseLeave={false} closeOnPortalMouseLeave={false}
                                                    position='bottom center' on='click' hoverable
                                                    content={
                                                        <ModFindSavedSearches
                                                            onViewResults={(filter, search) => {
                                                                const [url, state] = getSearchUrlConfig({ search, ...filter }, this.props.location.state)
                                                                this.props.history.push(url, state)
                                                            }}
                                                            onRemove={(search) => this.setState({ savedSearchPopup: { show: true, search } })}
                                                        />
                                                    }
                                                />
                                            </div>}
                                            <div className={"nav-item " + (pathname == '/help' ? 'active' : '')}>
                                                <h3>
                                                    <span className={"item-text " + (pathname == '/help' ? 'bold' : '')} onClick={() => this.props.onNavItemPress('help')}>{t("Messages.HELP_CONTACT")}</span>
                                                </h3>
                                            </div>
                                            <div className={"nav-item md-hidden"}>
                                                <h3>
                                                    <span className={"item-text bold"} onClick={() => this.props.onNavItemPress('community')}>{t("Messages.COMMUNITY")}</span>
                                                </h3>
                                            </div>
                                            {Boolean(user?.business) && <div className={"nav-item hide-mobile"}>
                                                <h3>
                                                    <span className="item-text" onClick={() => this.props.onNavItemPress('b2b-dashboard')}>{t("Messages.BUSINESS_DASHBAORD")}</span>
                                                </h3>
                                            </div>}
                                        </div>
                                        <div className='navbar right'>
                                            {user && <div className="nav-item right important">
                                                <Popup trigger={
                                                    <h3>
                                                        <Tippy content={t("Messages.NOTIFICATIONS")}>
                                                            <img className='link' height={22} src={NOTIFICATION} />
                                                        </Tippy>
                                                    </h3>
                                                }
                                                    onOpen={() => {
                                                        preventBodyScroll(true)
                                                    }} onClose={() => {
                                                        preventBodyScroll(false)
                                                    }} position='bottom center' on={['click']} closeOnTriggerMouseLeave={false}
                                                    content={<Notifications user={user} />} />
                                            </div>}
                                            {user && <div className="nav-item important">
                                                <h3>
                                                    <Tippy content={t("Messages.MY_PROFILE")}>
                                                        <img className='link' height={22} src={PROFILE_BLUE} onClick={() => {
                                                            this.props.history.push('/my-account')
                                                        }} />
                                                    </Tippy>
                                                </h3>
                                            </div>}
                                            {!user && <div className="nav-item important">
                                                <h3>
                                                    <span className="item-text" onClick={() => this.props.onNavItemPress('sign-up')}>{t("Messages.SIGN_UP")}</span>
                                                </h3>
                                            </div>}
                                            {!user && <div className="nav-item important">
                                                <h3>
                                                    <span className="item-text" onClick={() => this.props.onNavItemPress('login')}>{t("Messages.LOGIN")}</span>
                                                </h3>
                                            </div>}
                                            {user && <div className="nav-item">
                                                <h3 className="logout-item">
                                                    <span className="item-text" onClick={() => this.logout()}>{t("Messages.LOGOUT")}</span>
                                                </h3>
                                            </div>}
                                            <div className={"nav-item hide-mobile " + (pathname == '/advertising' ? 'active' : '')}>
                                                <h3>
                                                    <span className="item-text" onClick={() => this.props.onNavItemPress('advertising')}>{t("Messages.ADVERTISE")}</span>
                                                </h3>
                                            </div>
                                            <a className="menu-icon" onClick={(e) => {
                                                e.stopPropagation()
                                                this.navBar && this.navBar.classList.toggle('responsive')
                                            }}>&#9776;</a>
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                            <div className={`grey-header collapsing-header${negativeTopOffset ? ' collapsing-header-absolute' : ''}`}>
                                <Container>
                                    <Row style={{ height: this.state.searchHeight }}>
                                        <div className="header-blue-box" style={{ backgroundImage: `url(${LINED_BG})`, width: headerBlueBoxWidth }} onClick={() => {
                                            this.props.history.push('/home#more_people_brands')
                                        }}>
                                            <div className="cars-sale-text" style={overrideFontSize ? { fontSize: overrideFontSize } : {}}>
                                                <span>{t("Messages.FOLLOW_MAKES_AND_PEOPLE")}</span>
                                                <span className="click-here">&raquo; {t("Messages.CLICK_HERE")}</span>
                                            </div>
                                        </div>
                                        <div className="cars-sale-box" onClick={() => {
                                            this.props.history.push('/home#more_people_brands')
                                        }}>
                                            <img src={IC_FOLLOW_PEOPLE_BRANDS} alt={'Follow'} className='car-sale-img' />
                                        </div>
                                        <div
                                            className={"search-box " + (isScrolled ? 'scrolled' : '')}
                                            style={{ padding: `${padding == 4 ? "0px" : `${padding}px`} 0px ${padding == 4 ? "0px" : `${padding}px`} ${padding == 4 ? "0px" : `${padding}px`}` }}>
                                            <SearchBar
                                                onSearchPressed={(search, brand) => {
                                                    const [url, state] = getSearchUrlConfig({ search, brands: brand ? [brand] : [] }, this.props.location.state)
                                                    this.props.history.push(url, state)
                                                }} onSellPressed={() => {
                                                    if (user) {
                                                        this.props.onNavItemPress('sell')
                                                    } else {
                                                        this.props.onNavItemPress('login')
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="grey-header">
                            <div className="upper-filler" />
                            <Container>
                                {(this.props.location.pathname == '/home' || this.props.location.pathname == '/') && <Row>
                                    <Banner />
                                    {this.state.ad && <AdBannerView ad={this.state.ad} />}
                                    <div className="lower-filler" />
                                </Row>}
                            </Container>
                        </div>
                    </>
                )
            }}
            </UserContextConsumer>
        )
    }
})