import { APIError, Post, PostsAPI, User } from "fullcircle-api";
import moment from "moment";
import React from "react";

import Button from "../../generic/Button";
import { LoginRequiredPopup } from "../../popup/LoginRequiredPopup";
import * as H from 'history'
import { Colors } from "../../../styles/colors";
import { IC_HEART_FILLED, IC_REPORT, IC_HEART } from "../../../assets";
import { Popup } from "semantic-ui-react";
import { ClipLoader } from "react-spinners";
import { UserContextConsumer } from "../../../context/user";
import ModFindShare from "../ModFindShare";
import ModFindToast from "../../generic/ModFindToast";
import ModFindPopUp, { TitleMessageContent } from "../../popup/ModFindPopUp";
import ChatHandler from "../../../api/chathandller";
import { Favorite } from "../../../cache/favorite";
import { isPostFeatured } from "../../../utils/post_util";
import { FeaturedCheckout } from "../../FeaturedCheckout";
import { t } from "i18next";

export interface PostActionButtonsProps {
    post: Post
    history: H.History
    selectedVariants?: { [key: string]: string }
}
export interface PostActionButtonsState {
    featureItem: boolean
    showReportPopup: boolean
    isFavorite: boolean
    favoriteLoading: boolean
    kickbackLoading: boolean
    showSharePopUp: boolean
    kickbackLink: string
    justFeatured: boolean

    confirmPopup?: React.ReactChild
}

export class PostActionButtons extends React.Component<PostActionButtonsProps, PostActionButtonsState> {

    private copiedToast: ModFindToast | null = null

    constructor(props: PostActionButtonsProps) {
        super(props);
        this.state = {
            kickbackLoading: false,
            isFavorite: false,
            showSharePopUp: false,
            kickbackLink: '',
            favoriteLoading: false,
            showReportPopup: false,
            featureItem: false,
            justFeatured: false
        }
    }

    componentDidMount() {
        Favorite.isFavoritePost(this.props.post.id).then((favorite) => {
            this.setState({ isFavorite: favorite })
        })
    }

    render() {
        const { post } = this.props
        return <UserContextConsumer>{(user) => {
            if (user && post.user_id == user.id) {
                return this.renderSellerInteractions(user)
            } else {
                return this.renderBuyerInteractions(user)
            }
        }}</UserContextConsumer>
    }

    private likePost() {
        this.setState({ favoriteLoading: true })
        let post = this.props.post
        if (post) {
            if (this.state.isFavorite) {
                Favorite.deleteFavoritePost(post).then(() => {
                    this.setState({ isFavorite: false, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            } else {
                Favorite.addFavoritePost(post).then(() => {
                    this.setState({ isFavorite: true, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            }
        }
    }

    genericPopup(
        title: string,
        message: string,
        confirmAction?: () => void,
        confirmText?: string,
        cancelText?: string
    ) {

        return <ModFindPopUp onClose={() => {
            this.setState({ confirmPopup: undefined })
        }}>
            <TitleMessageContent title={title} message={message} icon={'info'} buttons={[
                {
                    text: confirmText || t("Messages.CONFIRM"), onClick: () => {
                        confirmAction && confirmAction()
                        this.setState({ confirmPopup: undefined })
                    }
                },
                {
                    text: cancelText || t("Messages.CANCEL"), onClick: () => {
                        this.setState({ confirmPopup: undefined })
                    }, color: Colors.RED
                }
            ]} />
        </ModFindPopUp>
    }

    renderFeatureItemPopup() {
        if (this.state.featureItem) {
            return (
                <ModFindPopUp
                    onClose={() => {
                        this.setState({ featureItem: false })
                    }}>
                    <FeaturedCheckout post={this.props.post} onCancel={() => {
                        this.setState({ featureItem: false })
                    }} onSuccess={() => {
                        this.setState({ featureItem: false, justFeatured: true })
                    }} />
                </ModFindPopUp>
            )
        }
    }

    renderSellerInteractions(user: User) {
        const { post } = this.props
        return <>
            {this.renderFeatureItemPopup()}
            {this.state.confirmPopup ? this.state.confirmPopup : null}
            <Button
                size={'sm'}
                rounded style={{ backgroundColor: 'black' }}
                text={post.expired || post.expires_soon ? t("Messages.REACTIVATE") : t("Messages.EDIT")}
                onClick={() => {
                    this.props.history.push('/edit/' + post.id)
                }}
            />
            {!isPostFeatured(post) && !this.state.justFeatured && <Button
                size={'sm'} style={{ marginLeft: 8 }}
                rounded
                text={t("Messages.FEATURE_ITEM")}
                onClick={() => {
                    this.setState({ featureItem: true })
                }}
            />}
            {this.renderShare(post, user)}
        </>
    }


    renderBuyerInteractions(user?: User) {
        const { post } = this.props
        return <>
            {this.state.confirmPopup ? this.state.confirmPopup : null}
            <Button
                size={'sm'}
                rounded
                text={t("Messages.BUY_NOW")}
                onClick={() => {
                    if (post.created_at) {
                        if (!user) {
                            LoginRequiredPopup.show()
                        }
                        else if (moment(post.created_at).isBefore(moment().add(-30, 'days'), 'days')) {
                            this.setState({
                                confirmPopup: this.genericPopup(t("Messages.WARNING"), t("Messages.ITEM_LISTED_LONG"), () => {
                                    this.props.history.push('/checkout/' + post.id, { selectedVariants: this.props.selectedVariants })
                                })
                            })
                        }
                        else {
                            this.props.history.push('/checkout/' + post.id, { selectedVariants: this.props.selectedVariants })
                        }
                    }
                }}
            />
            {this.renderCheckAvailability(post, user)}
            {this.renderShare(post, user)}
            <div className="interaction-item mTooltip">
                {this.state.favoriteLoading ?
                    <ClipLoader
                        color={'#000'}
                        size={25}
                    />
                    :
                    <>
                        <img src={this.state.isFavorite ? IC_HEART_FILLED : IC_HEART} alt={'favorite'} onClick={() => {
                            if (!user) {
                                LoginRequiredPopup.show()
                            } else {
                                this.likePost()
                            }
                        }} />
                        <span className="mTooltiptext">{this.state.isFavorite ? t("Messages.DISLIKE_POST") : t("Messages.LIKE_POST")}</span>
                    </>
                }
            </div>
            <div className="interaction-item report mTooltip">
                <img src={IC_REPORT} alt={'report'} onClick={() => { this.setState({ showReportPopup: true }) }} />
                <span className="mTooltiptext">{t("Messages.REPORT_POST")}</span>
            </div>
        </>
    }

    private renderShare(post: Post, user?: User) {
        return <Popup
            onClose={() => { this.setState({ showSharePopUp: false }) }}
            eventsEnabled={false} closeOnPortalMouseLeave={false} closeOnTriggerMouseLeave={false}
            position={'top center'}
            trigger={
                <Button
                    text={t("Messages.SHARE")}
                    size={'sm'}
                    rounded
                    style={{ backgroundColor: Colors.BLACK, marginLeft: 8 }}
                    onClick={() => {
                        if (!user) {
                            LoginRequiredPopup.show()
                        } else {
                            this.getKickbackLink()
                        }
                    }}
                />
            }
            open={Boolean(this.state.kickbackLink) && this.state.showSharePopUp}
            content={
                <>
                    <ModFindShare url={this.state.kickbackLink!} copiedLink={() => { this.copiedToast && this.copiedToast.show(t("Messages.COPIED")) }} />
                    <ModFindToast ref={(ref) => this.copiedToast = ref} position={'left'} />
                </>
            }>
        </Popup>
    }

    private renderCheckAvailability(post: Post, user?: User) {
        if (!user) {
            return null
        }
        return <Button
            className="check-availability-btn"
            text={t("Messages.CHECK_AVAILABILITY")}
            size={'sm'}
            rounded
            style={{ backgroundColor: Colors.GREEN, marginLeft: 8 }}
            onClick={() => {
                this.setState({
                    confirmPopup: this.genericPopup('', t("Messages.SEND_MESSAGE_TO_SELLER"), () => {
                        ChatHandler.instance() && ChatHandler.instance()?.sendMessage(post.id, '', post.user_id, 'check-availability')
                    }, 'Ok')
                })
            }}
        />
    }


    private getKickbackLink() {
        this.setState({ kickbackLoading: true })
        let post = this.props.post
        if (post) {
            PostsAPI.getKickbackLink(post.id).then((link) => {
                this.setState({ kickbackLoading: false, showSharePopUp: true, kickbackLink: link })
            }).catch((err: APIError) => {
                this.setState({ kickbackLoading: false })
            })
        }
    }

}