import * as React from 'react'

export default class Box extends React.Component<{ className?: string, white?: boolean, onClick?: () => void }> {


    render() {

        return (
            <div className={"modFind-box " + (this.props.className || '') + (this.props.white ? ' white' : '')} onClick={this.props.onClick}>
                {this.props.children}
            </div>
        )
    }
}

export class SeparatorBox extends React.Component<{
    direction?: 'row' | 'column'
    className?: string
    style?: React.CSSProperties
    onBoxClick?: () => void;
}> {

    render() {
        const { direction, onBoxClick } = this.props

        return (
            <div className={"modFind-separator-box " + (this.props.className || '')} style={{ flexDirection: direction || 'column', justifyContent: 'space-between', cursor: onBoxClick ? 'pointer' : 'default', ...this.props.style }} onClick={onBoxClick}>
                {this.props.children}
            </div>
        )
    }
}