import * as React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { RouteComponentProps, withRouter } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import AppleLogin from 'react-apple-login';
import { RegisterAPI, Region, Currency, Session, LoginAPI } from 'fullcircle-api';
import * as qs from 'query-string';
import { APIVersion } from 'fullcircle-api/lib/requests/request';
import { RegisterUserError, RegisterUserErrorType } from 'fullcircle-api/lib/endpoints/register';
import { uniqueId } from 'lodash';
import { t } from 'i18next';
import toastr from 'toastr';
import querystring from 'query-string'
import { IC_FACEBOOK, IC_GOOGLE } from '../assets';
import { registerDevice } from '../utils/helper';
import { PartialLocation } from '../utils/location';
import Box from '../components/generic/Box';
import Button from '../components/generic/Button';
import { VerificationRequiredPopup } from '../components/popup/VerificationRequiredPopup';
import { SignUpSelectCurrencyAndRegion } from '../components/views/signup/SignUpSelectCurrencyAndRegion';
import { SignUpSetName } from '../components/views/signup/SignUpSetName';
import { SignUpSetEmail } from '../components/views/signup/SignUpSetEmail';
import { SignUp } from './SignUp';
import { Login } from './Login';

export interface SignUpLoginProps extends RouteComponentProps<{ page: string }> {
    onDone?: () => void
}

export interface SignUpLoginState {
    currentPage: 'sign-up' | 'login'
    regionCurrencyCallback?: (region: Region, Currerncy: Currency, location: PartialLocation) => void,
    nameCallback?: (name: string) => void
    emailCallback?: (email: string) => void
    loading: boolean
}

class SignUpLogin extends React.Component<SignUpLoginProps, SignUpLoginState> {

    constructor(props: any) {
        super(props)
        this.state = {
            currentPage: this.props.location.pathname.substr(1) == 'login' ? 'login' : 'sign-up',
            loading: false
        }
        this.loginWithFacebook = this.loginWithFacebook.bind(this)
        this.loginWithGoogle = this.loginWithGoogle.bind(this)
    }

    componentDidMount() {
        if (location.search) {
            try {
                let s = qs.parse(location.search)
                if (s.login_error) {
                    toastr.error(s.login_error as string)
                } else if (s.refresh_token) {
                    registerDevice(s.refresh_token as string).then((device) => {
                        this.onLoggedIn()
                    })
                } else if (s.apple_token) {
                    this.loginWithApple(s.apple_token as string)
                }
            } catch (error) {

            }

        }
    }

    shouldComponentUpdate(nextProps: SignUpLoginProps, nextState: SignUpLoginState) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.setState({ currentPage: nextProps.location.pathname.substr(1) == 'login' ? 'login' : 'sign-up' })
        }
        return true
    }

    discourseSSOLogin() {
        if (this.props.location.search.length) {
            const { sso, sig } = querystring.parse(this.props.location.search);
            if (sso && sig) {
                window.location.assign(LoginAPI.discourseSSOLoginUrl(sso as string, sig as string));
                return true
            }
        }
        return false
    }

    render() {
        if (this.state.regionCurrencyCallback) {
            return <Box>
                <div className="sign-up-login-container">
                    <div className="form-container">
                        <div className="add-info-needed-title">{t("Messages.ADD_INFO_NEEDED")}</div>
                        <SignUpSelectCurrencyAndRegion onSelection={this.state.regionCurrencyCallback} onCancel={() => {
                            this.setState({ regionCurrencyCallback: undefined })
                        }} />
                    </div>
                </div>
            </Box>
        } else if (this.state.nameCallback) {
            return <Box>
                <div className="sign-up-login-container">
                    <div className="form-container">
                        <div className="add-info-needed-title">{t("Messages.ADD_INFO_NEEDED")}</div>
                        <SignUpSetName onSelection={this.state.nameCallback} onCancel={() => {
                            this.setState({ nameCallback: undefined })
                        }} />
                    </div>
                </div>
            </Box>
        } else if (this.state.emailCallback) {
            return <Box>
                <div className="sign-up-login-container">
                    <div className="form-container">
                        <div className="add-info-needed-title">{t("Messages.ADD_INFO_NEEDED")}</div>
                        <SignUpSetEmail onSelection={this.state.emailCallback} onCancel={() => {
                            this.setState({ emailCallback: undefined })
                        }} />
                    </div>
                </div>
            </Box>
        }
        return (
            <Box>
                <div className="sign-up-login-container">
                    <div className="pager">
                        <span
                            className={this.state.currentPage === 'sign-up' ? 'active' : 'inactive'}
                            onClick={() => {
                                this.setState({ currentPage: 'sign-up' })
                            }}
                        >{t("Messages.SIGN_UP")}</span>
                        <span
                            className={this.state.currentPage === 'login' ? 'active' : 'inactive'}
                            onClick={() => {
                                this.setState({ currentPage: 'login' })
                            }}
                        >{t("Messages.LOGIN")}</span>
                    </div>
                    {this.state.loading && <div className="clip-loader-container"><ClipLoader size={25} /></div>}
                    <div className="form-container">
                        <FacebookLogin
                            appId={'203849220371084'}
                            fields="name,email,picture" isDisabled={this.state.loading}
                            callback={this.loginWithFacebook} disableMobileRedirect={true}
                            render={renderProps => (
                                <Button
                                    text={t("Messages.LOGIN_FACEBOOK")}
                                    size={'lg'}
                                    rounded
                                    className="button-style-facebook-sign-in"
                                    classNameText="button-social-networks-text"
                                    icon={IC_FACEBOOK}
                                    onClick={renderProps.onClick}
                                />
                            )}
                        />
                        <GoogleLogin
                            clientId={'541760730337-7snrmipu8ctquvec0bs4k10v49377396.apps.googleusercontent.com'}
                            onSuccess={this.loginWithGoogle}
                            onFailure={(err) => {
                                console.error(err)
                            }} disabled={this.state.loading}
                            render={renderProps => (
                                <Button
                                    text={t("Messages.LOGIN_GOOGLE")}
                                    size={'lg'}
                                    rounded
                                    className="button-style-google-sign-in"
                                    classNameText="button-social-networks-text-google"
                                    icon={IC_GOOGLE}
                                    onClick={() => renderProps.onClick()}
                                />
                            )}
                        />
                        <AppleLogin
                            clientId={"com.modfind.web.signin"}
                            redirectURI={Session.getAPIURL() + '/' + APIVersion.v2_0 + '/users/apple/web'}
                            responseType={"code id_token"}
                            nonce={uniqueId('apple')}
                            responseMode={'form_post'}
                            scope='email name'
                            usePopup={false}
                            designProp={
                                {
                                    height: 52,
                                    width: 375,
                                    color: "white",
                                    border: true,
                                    type: "sign-in",
                                    border_radius: 6,
                                    scale: 1,
                                    locale: "en_US",
                                }
                            }
                        />

                        <div className="sign-up-subtitle-container">
                            <span className="web-link-text">{t("Messages.USE_MAIL")}</span>
                        </div>
                        {this.state.currentPage === 'login' ?
                            <Login
                                {...this.props}
                                pushToHome={() => this.onLoggedIn()}
                                forgotPassword={() => this.props.history.push('forgot-password')}
                            />
                            :
                            <SignUp
                                {...this.props}
                                pushToHome={() => this.onSignedUp()}
                            />
                        }

                        <div className="links-container">
                            <span>{t("Messages.AGREE")} <a className="web-links-auth" href="https://terms.modfind.com" target="_blank">{t("Messages.TERMS")} </a>
                                {t("Messages.AND")}
                                <a className="web-links-auth" href="https://privacy.modfind.com" target="_blank"> {t("Messages.PRIVACY")}</a>
                            </span>

                        </div>
                    </div>
                </div>
            </Box>
        )
    }

    onLoggedIn() {
        if (!this.discourseSSOLogin()) {
            if (!this.props.onDone) {
                this.props.history.push('home')
            } else
                this.props.onDone()
        }
    }

    onSignedUp() {
        if (!this.props.onDone) {
            VerificationRequiredPopup.show()
            this.props.history.push('home')
        } else {
            this.props.onDone()
        }
    }

    handleError(error: RegisterUserError, cb: (region?: Region, currency?: Currency, location?: PartialLocation, email?: string, name?: string) => void, region?: Region, currency?: Currency, location?: PartialLocation, email?: string, name?: string) {
        if (error.type === RegisterUserErrorType.emailMissing) {
            this.setState({
                emailCallback: (email) => {
                    cb(region, currency, location, email, name)
                }
            })
        } else if (error.type == RegisterUserErrorType.currencyRegionMissing) {
            this.setState({
                regionCurrencyCallback: (region, currency, location) => {
                    cb(region, currency, location, email, name)
                }
            })
        } else if (error.type == RegisterUserErrorType.currencyRegionNotSupported) {
            this.setState({
                regionCurrencyCallback: (region, currency, location) => {
                    cb(region, currency, location, email, name)
                }
            })
            toastr.error(error.underlyingError.message)
        } else if (error.type == RegisterUserErrorType.nameMissing) {
            this.setState({
                nameCallback: (name: string) => {
                    cb(region, currency, location, email, name)
                }
            })
            toastr.error(error.underlyingError.message)
        } else {
            toastr.error(error.underlyingError.message)
        }
    }

    loginWithFacebook(response: ReactFacebookLoginInfo) {
        const moveForward = (region?: Region, currency?: Currency, location?: PartialLocation, email?: string) => {
            this.setState({ regionCurrencyCallback: undefined, nameCallback: undefined, emailCallback: undefined, loading: true })
            RegisterAPI.authenticateFacebookUser(response.accessToken.toString(), email,
                region ? region.name : undefined, currency ? currency.code : undefined, location).then((result) => {
                    if (result.isNew) {
                        window.gtag('event', 'conversion', { 'send_to': 'AW-622342217/vNWfCMmSlfABEMng4KgC' });
                    }
                    return registerDevice(result.user.refresh_token!).then(() => {
                        this.onLoggedIn();
                    }).catch((err) => {
                        this.setState({ loading: false })
                        toastr.error(t("Messages.SOMETHING_WENT_WRONG"))
                    });
                }).catch((error: RegisterUserError) => {
                    this.setState({ loading: false })
                    this.handleError(error, moveForward, region, currency, location, email)
                })
        }
        if (response.accessToken) {
            moveForward()
        }
    }

    loginWithGoogle(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
        const moveForward = (region?: Region, currency?: Currency, location?: PartialLocation, email?: string) => {
            this.setState({ regionCurrencyCallback: undefined, nameCallback: undefined, emailCallback: undefined, loading: true })
            RegisterAPI.authenticateGoogleUser((response as GoogleLoginResponse).getAuthResponse().id_token, email,
                region ? region.name : undefined, currency ? currency.code : undefined, location).then((result) => {
                    if (result.isNew) {
                        window.gtag('event', 'conversion', { 'send_to': 'AW-622342217/vNWfCMmSlfABEMng4KgC' });
                    }
                    return registerDevice(result.user.refresh_token!).then(() => {
                        this.onLoggedIn();
                    }).catch((err) => {
                        this.setState({ loading: false })
                        toastr.error(t("Messages.SOMETHING_WENT_WRONG"))
                    });
                }).catch((error: RegisterUserError) => {
                    this.setState({ loading: false })
                    this.handleError(error, moveForward, region, currency, location, email)
                })
        }
        moveForward()
    }

    loginWithApple(nonce: string) {
        const moveForward = (region?: Region, currency?: Currency, location?: PartialLocation, email?: string, name?: string) => {
            const authResponse = { nonce: '', identityToken: nonce } as any
            if (name) {
                authResponse.fullName = {
                    givenName: name
                } as any
            }
            this.setState({ regionCurrencyCallback: undefined, nameCallback: undefined, emailCallback: undefined, loading: true })
            RegisterAPI.authenticateAppleUser(authResponse, email,
                region ? region.name : undefined, currency ? currency.code : undefined, location).then((result) => {
                    if (result.isNew) {
                        window.gtag('event', 'conversion', { 'send_to': 'AW-622342217/vNWfCMmSlfABEMng4KgC' });
                    }
                    return registerDevice(result.user.refresh_token!).then(() => {
                        this.onLoggedIn();
                    }).catch((err) => {
                        this.setState({ loading: false })
                        toastr.error(t("Messages.SOMETHING_WENT_WRONG"))
                    });
                }).catch((error: RegisterUserError) => {
                    this.setState({ loading: false })
                    this.handleError(error, moveForward, region, currency, location, email, name)
                })
        }
        moveForward()
    }
}

export default withRouter(SignUpLogin)