import * as React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CardInterface, PaymentsAPI, ProviderType, APIError } from 'fullcircle-api';
import Button from '../../generic/Button';
import { Title } from '../../generic/Title';

import toastr from 'toastr'
import { errorToString } from '../../../utils/helper';
import { t } from 'i18next';

export const AddCreditCard: React.FunctionComponent<{ onAdded: (card: CardInterface) => void, onCancel: () => void }> = (props) => {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = React.useState(false)

    const handleSubmit = async () => {

        if (!stripe || !elements) {
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement)!;

        // Use your card Element with other Stripe.js APIs
        setLoading(true)
        const { error, token } = await stripe.createToken(cardElement)
        if (error) {
            setLoading(false)
            toastr.error(error.message || t("ErrorMessages.SomethingWentWrong"))
        } else {
            PaymentsAPI.addCard(ProviderType.Stripe, token!.id).then((card) => {
                toastr.success(t("Messages.CREDIT_CARD_ADDED"))
                props.onAdded(card)
            }).catch((err: APIError) => {
                setLoading(false)
                toastr.error(errorToString(err).join('\n'))
            })

        }
    };

    return (
        <div className='add-card'>
            <Title>{t("Messages.ADD_CREDIT_CARD")}</Title>
            <CardElement className="card-input" />
            <Button text='Add' onClick={handleSubmit} size='sm' loading={loading} rounded />
        </div>
    );
};