import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Popup } from "semantic-ui-react";
import { t } from "i18next";
import { LoginAPI } from "fullcircle-api";
import { LoginUserError } from "fullcircle-api/lib/endpoints/login";
import querystring from 'query-string'

import Button from "../components/generic/Button";
import ModFindInput from "../components/generic/ModFindInput";
import { validateEmail, registerDevice } from "../utils/helper";

interface LoginProps extends RouteComponentProps {
    pushToHome: () => void
    forgotPassword: () => void
}
export class Login extends React.Component<LoginProps, {
    formValid: boolean,
    loading: boolean,
    error?: string
}> {

    constructor(props: any) {
        super(props)
        this.state = {
            formValid: false,
            loading: false
        }
    }


    private email: string = ''
    private password: string = ''

    checkValidForm() {
        let valid = false
        if (this.email.length > 0 && validateEmail(this.email) && this.password.length >= 6) {
            valid = true
        }
        this.setState({ formValid: valid })
    }

    discourseSSOLogin() {
        if (this.props.location.search.length) {
            const { sso, sig } = querystring.parse(this.props.location.search);
            if (sso && sig) {
                window.location.assign(LoginAPI.discourseSSOLoginUrl(sso as string, sig as string));
                return true
            }
        }
        return false
    }

    login() {
        this.setState({ loading: true })
        LoginAPI.login(this.email, this.password).then((user) => {
            registerDevice(user.refresh_token!).then(() => {
                this.setState({ loading: false })
                const urlParams = new URLSearchParams(window.location.search)

                if (urlParams.get('redirect') && (urlParams.get('redirect')?.trim() !== '')) {
                    if (urlParams.get('redirect')?.startsWith('http')) {
                        window.location.assign(urlParams.get('redirect')!)
                    }
                    this.props.history.push(`${urlParams.get('redirect')}`)
                } else if (!this.discourseSSOLogin()) {
                    this.props.pushToHome()
                }
            }).catch((err) => {
                this.setState({ loading: false, error: t("Messages.SOMETHING_WENT_WRONG") })
            })
        }).catch((err: LoginUserError) => {
            let error: string
            switch (err.type) {
                case 0:
                    error = t("Messages.INVALID_PASSWORD")
                    break;
                case 1:
                    if ((err as any).underlyingError.resource_type == 'User') {
                        error = t("Messages.USER_NOT_FOUND")
                    } else {
                        error = error = err.underlyingError.message || t("Messages.SOMETHING_WENT_WRONG");
                    }
                    break;
                default:
                    error = error = err.underlyingError.message || t("Messages.SOMETHING_WENT_WRONG");
                    break;
            }
            this.setState({ loading: false, error })
        })
    }

    onValueChanged() {
        this.setState({ error: undefined })
        this.checkValidForm()
    }

    render() {

        return (
            <>
                <ModFindInput
                    placeholder={t("Messages.EMAIL")}
                    onValueChanged={(value) => {
                        this.email = value
                        this.onValueChanged()
                    }}
                    colored
                    rounded
                    containerSize={'lg'}
                    type={'email'}
                    className="auth-input-wrapper"
                    onEnter={() => {
                        this.login()
                    }}
                />
                <ModFindInput
                    placeholder={t("Messages.PASSWORD")}
                    onValueChanged={(value) => {
                        this.password = value
                        this.onValueChanged()
                    }}
                    colored
                    rounded
                    containerSize={'lg'}
                    type={'password'} onEnter={() => {
                        this.login()
                    }}
                    className="auth-input-wrapper"
                />
                <div className="forgot-password-link-container" >
                    <span className="forgot-password-link" onClick={() => { this.props.forgotPassword() }}>{t("Messages.FORGOT")}</span>
                </div>
                {this.state.error && <div className="form-subcontainer-auth">
                    <span className="error-text-auth">{this.state.error}</span>
                </div>}
                <div className="form-subcontainer-auth">
                    <Popup
                        trigger={
                            <Button
                                text={t("Messages.LOGIN")}
                                size={'lg'}
                                rounded
                                disabled={!this.state.formValid}
                                loading={this.state.loading}
                                onClick={() => { this.login() }}
                            />
                        }
                        content={t('ErrorMessages.Login.NotCorrectFormatEmailOrPassword')}
                        disabled={this.state.formValid}
                    />
                </div>
            </>
        )
    }
}