import * as React from "react";
import { Container } from "reactstrap";
import {
    BLUE_QUOTE,
    AMBASSADOR_STEP_1,
    AMBASSADOR_STEP_2,
    AMBASSADOR_STEP_3,
    AMBASSADOR_STEP_4,
    BLUE_01,
    BLUE_2A,
    BLUE_2B,
    BLUE_3A,
    BLUE_3B,
} from "../assets";
import { t } from "i18next";

import Button from '../components/generic/Button';
import { useHistory } from "react-router-dom";
import { UserContextType } from "../context/user";
import { LoginRequiredPopup } from "../components/popup/LoginRequiredPopup";
import { useState } from "react";
import { UsersAPI } from "fullcircle-api";

export function AmbassadorFeaturePage() {

    const history = useHistory();
    const user = React.useContext(UserContextType);
    const [link, setLink] = useState<string>()

    React.useEffect(() => {
        UsersAPI.getAmbassadorData().then(({ link }) => {
            setLink(link)
        })
    }, [])

    function renderLink() {
        if (link) {
            return <div className="link-text">Your link: <span className="link">{link}</span></div>
        } else {
            return <Button
                text={t("Messages.SIGN_UP")}
                size={'lg'}
                rounded
                disabled={false}
                loading={false}
                onClick={() => {
                    if (!user) {
                        LoginRequiredPopup.show()
                    } else {
                        history.push('/ambassador-registration')
                    }
                }}
            />
        }
    }

    return <Container>
        <div className="ambassador-top-container">
            <div className="program-presentation">
                <div className="presentation-text">
                    <div className="top-mark" />
                    <h3>{t("Messages.AMBASSADOR_PRESENTATION_LINE_1")}</h3>
                    <h1>{t("Messages.AMBASSADOR_PRESENTATION_LINE_2")}</h1>
                    <h3>{t("Messages.AMBASSADOR_PRESENTATION_LINE_3")}</h3>
                    {renderLink()}
                </div>
            </div>
            <div className="details">
                <div className="question-answer">
                    <h2>{t("Messages.AMBASSADOR_QUESTION")}</h2>
                    <p>{t("Messages.AMBASSADOR_ANSWER")}</p>
                </div>
                <div className="ambassador-benefits">
                    <div className="benefit">
                        <img src={BLUE_QUOTE} alt="" />
                        <div className="benefit-text">
                            <h3>{t("Messages.AMBASSADOR_BENEFIT_TITLE_1")}</h3>
                            <p>{t("Messages.AMBASSADOR_BENEFIT_TEXT_1")}</p>
                        </div>
                    </div>
                    <div className="benefit">
                        <img src={BLUE_QUOTE} alt="" />
                        <div className="benefit-text">
                            <h3>{t("Messages.AMBASSADOR_BENEFIT_TITLE_2")}</h3>
                            <p>{t("Messages.AMBASSADOR_BENEFIT_TEXT_2")}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div className='ambassador-middle-container'>
            <div className="steps-title-wrapper">
                <h1>{t("Messages.AMBASSADOR_STEPS_TITLE")}</h1>
            </div>
            <div className="steps-wrapper">
                <div className="step-pair">
                    <fieldset className="step">
                        <legend>
                            <img src={AMBASSADOR_STEP_1} alt="" />
                        </legend>
                        <h6>{t("Messages.AMBASSADOR_STEP_1_TITLE")}</h6>
                        <p>{t("Messages.AMBASSADOR_STEP_1")}</p>
                        {renderLink()}
                    </fieldset>
                    <fieldset className="step">
                        <legend>
                            <img src={AMBASSADOR_STEP_2} alt="" />
                        </legend>
                        <h6>{t("Messages.AMBASSADOR_STEP_2_TITLE")}</h6>
                        <p>{t("Messages.AMBASSADOR_STEP_2")}</p>
                    </fieldset>
                </div>
                <div className="step-pair">
                    <fieldset className="step">
                        <legend>
                            <img src={AMBASSADOR_STEP_3} alt="" />
                        </legend>
                        <h6>{t("Messages.AMBASSADOR_STEP_3_TITLE")}</h6>
                        <p>{t("Messages.AMBASSADOR_STEP_3")}</p>
                    </fieldset>
                    <fieldset className="step">
                        <legend>
                            <img src={AMBASSADOR_STEP_4} alt="" />
                        </legend>
                        <h6>{t("Messages.AMBASSADOR_STEP_4_TITLE")}</h6>
                        <p>{t("Messages.AMBASSADOR_STEP_4")}</p>
                    </fieldset>
                </div>
            </div>
        </div>
        <div className='ambassador-bottom-container'>
            <div className="scenario-text-wrapper">
                <h2>{t("Messages.AMBASSADOR_SCENARIO_TITLE")}</h2>
                <p>{t("Messages.AMBASSADOR_SCENARIO_TEXT")}</p>
            </div>
            <div className="scenarios-wrapper">
                <div className="horizontal-block">
                    <div className="scenario first">
                        <img src={BLUE_01} alt="" />
                        <p>{t("Messages.AMBASSADOR_SCENARIO_STEP_1")}</p>
                    </div>
                </div>
                <div className="horizontal-block">
                    <div className="scenario middle">
                        <img src={BLUE_2A} alt="" />
                        <p>{t("Messages.AMBASSADOR_SCENARIO_STEP_2A")}</p>
                    </div>
                    <div className="scenario middle">
                        <img src={BLUE_2B} alt="" />
                        <p>{t("Messages.AMBASSADOR_SCENARIO_STEP_2B")}</p>
                    </div>
                </div>
                <div className="horizontal-block">
                    <div className="scenario last">
                        <img src={BLUE_3A} alt="" />
                        <p>{t("Messages.AMBASSADOR_SCENARIO_STEP_MUTUAL")}</p>
                        <p className="sum">$24</p>
                    </div>
                    <div className="scenario last">
                        <img src={BLUE_3B} alt="" />
                        <p>{t("Messages.AMBASSADOR_SCENARIO_STEP_MUTUAL")}</p>
                        <p className="sum">$12</p>
                    </div>
                </div>
                <p style={{ textAlign: 'right' }}>{t("Messages.AMBASSADOR_NOTE")}</p>
            </div>
        </div>
        <div className="ambassador-video-container tutorial">
            <h4 id="tutorial">Tutorial</h4>
            <video playsInline controls preload="auto" poster={'https://media.full-circle.io/videos/how-to-upload-CSV-file-poster.jpg'}>
                <source src={'https://media.full-circle.io/videos/How-to-Become-a-ModFind-ambassador.mp4'} />
            </video>
        </div>
    </Container>
}
