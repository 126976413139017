import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { TagBox } from "../dropdown/TagBox";
import ModFindInput from "../generic/ModFindInput";
import { IC_PLUS_BORDER, IC_MINUS_BORDER } from '../../assets';
import { VariantOption } from "fullcircle-api/lib/models/post";
import * as uuid from "uuid";

interface EditVariantProps {
    disabled?: boolean
    name: string;
    options: VariantOption[]
    required?: boolean
    onUpdated: (name: string, options: VariantOption[], required: boolean) => void
    onAddVariant?: () => void
    onRemove?: () => void
    currency: string
}

export function EditVariant({ disabled, onAddVariant, onRemove, onUpdated, options, name: propsName, required, currency }: EditVariantProps) {

    const [selected, setSelected] = useState<VariantOption[]>(options);
    const [name, setName] = useState<string>(propsName);

    const inputSelected = useMemo(() => selected.map((s) => s.name), [selected])

    useEffect(() => {
        onUpdated(name, selected, !!required);
    }, [name, selected])

    return (
        <>
            <div className='variant'>
                <div className='option-name-container'>
                    <div className='option-name'>
                        <div className='actions'>
                            <span className='pointer' onClick={onAddVariant}>
                                <img src={IC_PLUS_BORDER} width={19} />
                            </span>
                            <span className='pointer' onClick={onRemove}>
                                <img src={IC_MINUS_BORDER} width={19} />
                            </span>
                        </div>
                        <ModFindInput
                            disabled={disabled}
                            placeholder={t("Messages.OPTION_NAME") + '*'}
                            colored
                            rounded
                            className='small-input'
                            value={name}
                            onValueChanged={(e) => {
                                setName(e)
                            }}
                        />
                    </div>

                </div>
                <div className='option-values'>
                    <TagBox
                        className='small-input'
                        selected={inputSelected}
                        colored
                        rounded
                        placeholder={t("Messages.OPTION_VALUES") + (selected.length == 0 ? '*' : '')}
                        onSelectedItemsChange={(items) => {
                            setSelected(items.map((item) => {
                                return {
                                    name: item,
                                    id: selected.find((s) => s.name == item)?.id || uuid.v1()
                                }
                            }))
                        }} />
                    <span className="option-values-hint">{t("Messages.OPTION_VALUES_HINT")}</span>
                </div>
            </div>
        </>
    )
}