import React from "react";

import { formatPriceI18n } from "../utils/formater";
import { buyAdBannerOnApi, buyPostFeatureOnApi, PaymentProvider } from "../utils/payment";
import { SeparatorBox } from "./generic/Box";
import Button from "./generic/Button";
import ModFindPopUp, { TitleMessageContent } from "./popup/ModFindPopUp";
import { ChangePayment } from "./views/payment/ChangePayment";
import * as toastr from 'toastr'
import { CardInterface, Post, PostsAPI, ProviderType } from "fullcircle-api";
import { AddCreditCard } from "./views/payment/AddCreditCard";
import { StripeAppleCheckoutButton } from "./StripeCheckoutButton";
import { ModFindLoader } from "./generic/ModFindLoader";
import { Popup } from "semantic-ui-react";
import { AdBannerBuyParams } from "fullcircle-api/lib/endpoints/ads";
import { IC_CHECK_BLUE } from "../assets";
import { PayParamter } from "fullcircle-api/lib/endpoints/posts";
import { t } from "i18next";

interface FeaturedCheckoutState {
    paymentPopupOpen: boolean
    addCreditCard: boolean
    payparlUrlLoading: boolean
    successPopup: boolean

    payment_type?: PaymentProvider;
    payment?: CardInterface;

    paypalUrl?: string

    loading: boolean

    amount?: string
}

export class FeaturedCheckout extends React.Component<{ post: Post, onCancel: () => void, onSuccess: () => void }, FeaturedCheckoutState> {

    constructor(props) {
        super(props)
        this.state = {
            paymentPopupOpen: false,
            payparlUrlLoading: false,
            addCreditCard: false,
            loading: false,
            successPopup: false
        }
    }

    componentDidMount() {
        PostsAPI.getFeaturedPrice().then(({ amount }) => {
            this.setState({ amount })
        }).catch(err => {
            this.setState({ amount: '4.00' })
        })
    }


    loadPaypalAuthUrl() {

    }

    private async buy(applePayToken?: string, payPal?: { token: string, customer_id: string }) {
        this.setState({ loading: true })
        if (this.state.payment || applePayToken || (this.state.payment_type == PaymentProvider.PAY_PAL && payPal)) {
            return new Promise<void>((resolve, reject) => {
                let buyParams: PayParamter
                if (this.state.payment_type == PaymentProvider.PAY_PAL) {
                    buyParams = {
                        payment_info: {
                            ...payPal!,
                            provider: ProviderType.Paypal
                        }
                    }
                } else {
                    buyParams = {
                        payment_info: {
                            [applePayToken ? 'token' : 'payment_method']: applePayToken || this.state.payment!.id,
                            provider: applePayToken ? ProviderType.Stripe : this.state.payment!.provider
                        } as any
                    }
                }
                buyPostFeatureOnApi(this.props.post.id, buyParams, (error?: string) => {
                    if (error) {
                        reject(error)
                        toastr.error(error, undefined, { timeOut: 0, extendedTimeOut: 0 })
                        this.setState({ loading: false })
                    } else {
                        resolve()
                        this.setState({ loading: false, successPopup: true })
                    }
                })
            })
        } else if (this.state.payment_type) {

        }
        return Promise.reject('Unknown payment method')
    }

    private renderSuccessPopup() {
        if (this.state.successPopup) {
            return (
                <ModFindPopUp
                    onClose={() => { this.props.onSuccess() }}>
                    <TitleMessageContent
                        icon={'success'}
                        title={t("Messages.CONGRATS")}
                        message={t("Messages.ITEM_HAS_BEEN_FEATURED")}
                    />
                </ModFindPopUp>
            )
        }
        return null
    }

    render() {
        if (!this.state.amount) {
            return <ModFindLoader />
        }
        return <div className="featured-checkout">
            {this.renderSuccessPopup()}
            {this.state.paymentPopupOpen && <ModFindPopUp
                onClose={() => {
                    this.setState({ paymentPopupOpen: false })
                }}
            >
                <ChangePayment amount={this.state.amount}
                    onAddCreditCard={() => {
                        this.setState({ addCreditCard: true })
                    }}
                    selectedPaymentType={this.state.payment || this.state.payment_type}
                    additional_provider={[PaymentProvider.APPLE_PAY/*, PaymentProvider.PAY_PAL*/]} // Disable paypal for now, need to figure out how to keep state (open paypal in modal?)
                    onPaymenTypeSelected={(pymentType) => {
                        if (pymentType == PaymentProvider.PAY_PAL) {
                            this.loadPaypalAuthUrl()
                        }
                        this.setState({ payment_type: pymentType, payment: undefined, paymentPopupOpen: false, payparlUrlLoading: true })
                    }} onPaymentSelected={(payment) => {
                        this.setState({ payment, paymentPopupOpen: false })
                    }}
                />
            </ModFindPopUp>}
            {this.state.addCreditCard && <ModFindPopUp onClose={() => {
                this.setState({ addCreditCard: false })
            }}>
                <AddCreditCard onAdded={(card) => {
                    this.setState({ addCreditCard: false, payment: card })
                }} onCancel={() => {
                    this.setState({ addCreditCard: false })
                }} />
            </ModFindPopUp>}
            <div className="title">{t("Messages.ORDER_SUMMARY")}</div>
            <div className="featured-item-wrapper">
                <div className='featured-info'>{t("Messages.FEATURED_ITEM_INFO")}</div>
                <div className='featured-item-info'>
                    <img src={IC_CHECK_BLUE} />
                    <span>{t("Messages.FEATURED_ITEM_ADV_1")}</span>
                </div>
                <div className='featured-item-info'>
                    <img src={IC_CHECK_BLUE} />
                    <span>{t("Messages.FEATURED_ITEM_ADV_2")}</span>
                </div>
                <div className='featured-item-info'>
                    <img src={IC_CHECK_BLUE} />
                    <span>{t("Messages.FEATURED_ITEM_ADV_3")}</span>
                </div>
                <div className='featured-item-info'>
                    <img src={IC_CHECK_BLUE} />
                    <span>{t("Messages.FEATURED_ITEM_ADV_4")}</span>
                </div>
            </div>
            <SeparatorBox
                direction={'row'} onBoxClick={() => {
                    this.setState({ paymentPopupOpen: !this.state.paymentPopupOpen })
                }}>
                <div className="payment-wrapper">
                    <span className="item">{t("Messages.PAYMENT")}</span>
                    <div className="item blue right">{this.getPaymentText()}</div>
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.PLAN_DURATION")}
                </div>
                <div className='item'>
                    3 {t("Messages.MONTHS")}
                </div>
            </SeparatorBox>
            <SeparatorBox direction='row'>
                <div className='item'>
                    {t("Messages.TOTAL_AMOUNT")}
                </div>
                <div className='item'>
                    {formatPriceI18n(this.state.amount, this.props.post.currency)}
                </div>
            </SeparatorBox>
            {this.renderBuyButton()}
        </div>
    }

    private renderBuyButton() {

        let button: React.ReactChild | null = null
        let hasPaymentType = true
        if (this.state.payment) {
            button = <Button
                size='lg'
                rounded
                text={`Buy with ${this.state.payment.provider_name}`}
                disabled={this.state.loading}
                onClick={() => {
                    this.buy()
                }}
                loading={this.state.loading} />
        } else if (this.state.payment_type) {
            switch (this.state.payment_type) {
                case PaymentProvider.APPLE_PAY:
                case PaymentProvider.CREDITCARD:
                    button = <StripeAppleCheckoutButton label={'Buy'}
                        amount={parseFloat(this.state.amount!) * 100}
                        currency={this.props.post.currency}
                        onToken={(token) => {
                            return this.buy(token)
                        }} />
                    break;
                case PaymentProvider.MODFIND:
                case PaymentProvider.PAY_PAL:
                    button = <Button size='lg' rounded text={`Buy with ${this.state.payment_type}`}
                        disabled={this.state.loading} onClick={() => {
                            this.buy()
                        }} loading={this.state.loading} />
                    if (this.state.payment_type == PaymentProvider.PAY_PAL && (this.state.payparlUrlLoading || !this.state.paypalUrl)) {
                        button = <ModFindLoader />
                    }
                    break;

            }
        } else {
            hasPaymentType = false
            button = <Button size='lg' rounded text={`Buy`}
                disabled={true} onClick={() => {
                }} loading={this.state.loading} />
        }
        return <Popup
            trigger={button}
            className="featured-checkout-button-popup"
            content={hasPaymentType ? undefined : 'Please select a payment type'}
            disabled={hasPaymentType} />
    }

    private getPaymentText() {
        if (this.state.payment) {
            if (this.state.payment.last_4) {
                return `${this.state.payment.provider_name} ending in ${this.state.payment.last_4}`
            } else {
                return this.state.payment.provider_name
            }
        } else if (this.state.payment_type) {
            return this.state.payment_type
        }
        return 'Select'
    }
}