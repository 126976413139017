import * as React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { IC_ARROW_DOWN, IC_ARROW_DOWN_BLACK } from '../../assets';

export interface ModFindDropDownProps {
    style?: React.CSSProperties;
    className?: string
    textColor?: string;
    value: string
    rounded?: boolean
}

export default class ModFindDropDown extends React.Component<ModFindDropDownProps, {
    isOpen: boolean;
    active: string;
}> {

    constructor(props: ModFindDropDownProps) {
        super(props)
        this.state = {
            isOpen: false,
            active: this.props.value,
        }
    }

    private toggle = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        } else {
            this.setState({ isOpen: true })
        }
    }

    componentDidUpdate() {
        if (this.state.active !== this.props.value) {
            this.setState({ active: this.props.value })
        }
    }

    render() {
        const { style, rounded, textColor, className } = this.props
        return (
            <Dropdown className={(rounded ? 'rd' : '') + ' modfind-dropdown ' + (className || '')} isOpen={this.state.isOpen} toggle={this.toggle} style={{ ...style }}>
                <DropdownToggle className="dropdown-item">
                    <span className={`dropdown-item-text ${textColor ? textColor : 'black'}`} style={{ color: `${textColor}` }}>{this.state.active}</span>
                    <img src={textColor === 'black' ? IC_ARROW_DOWN_BLACK : IC_ARROW_DOWN} alt={'arrow'} style={{ width: 9, height: 9, objectFit: 'contain' }} />
                </DropdownToggle>
                <DropdownMenu modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: '60vh',
                                },
                            };
                        },
                    },
                }}>
                    {this.props.children}
                </DropdownMenu>
            </Dropdown>
        )
    }
}