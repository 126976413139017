import * as React from 'react'
import ModFindPopUp from './ModFindPopUp';
import Button from '../generic/Button';
import { User, UsersAPI } from 'fullcircle-api';
import * as toastr from 'toastr'
import { UserSession } from '../../utils/session';

import ModFindInput from '../generic/ModFindInput';
import { t } from 'i18next';

interface NameRequiredPopupProps {
    user: User,
    onClose: () => void
    onUserUpdated: (user: User) => void
}

export default class NameRequiredPopup extends React.Component<NameRequiredPopupProps, {
    name?: string
    loading: boolean
}> {

    constructor(props: NameRequiredPopupProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    render() {
        return (
            <ModFindPopUp onClose={() => this.props.onClose()}>
                <div className="modfind-popup-content-wrapper">
                    {<div className="modfind-popup-title">{t("Messages.SET_FULL_NAME")}</div>}
                    <ModFindInput value={this.state.name} onValueChanged={(value) => {
                        this.setState({ name: value })
                    }} colored />
                    <div className="buttons-container">
                        <Button
                            text={t("Messages.UPDATE")}
                            size={'sm'}
                            rounded
                            className="left-button"
                            onClick={() => { this.setName() }}
                            loading={this.state.loading}
                            disabled={!this.state.name || this.state.name.trim() == ''}
                        />
                        <Button
                            text={t("Messages.CANCEL")}
                            size={'sm'}
                            rounded
                            className="right-button"
                            loading={this.state.loading}
                            onClick={() => this.props.onClose()}
                        />
                    </div>
                </div>
            </ModFindPopUp>
        )
    }

    setName() {
        this.setState({ loading: true })
        UsersAPI.updateProfile({ full_name: this.state.name! }).then((u) => {
            this.props.onUserUpdated(u)
            UserSession.getUserUpdatedHandler()(u)
        }).catch(() => {
            toastr.error(UPDATING_FAILED)
        });
    }
}
const UPDATING_FAILED = 'Couldn\'t change your zip code. Please try again!';
