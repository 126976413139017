export declare namespace Content {

    /**
     * This namespace contains all incoming events sent from the client
     */
    export namespace Outgoing {

        export interface BasePacket {
            request_id: string;
            device_uuid?: string;
        }

        /**
        * This packet contains the payload for marking a message as read
        */
        export interface MessageRead extends BasePacket {
            id: string;
        }

        /**
         * This packet contains the payload for marking a message as read
         */
        export interface MessagesRead extends BasePacket {
            ids: string[];
        }

        /**
         * This packet contains the payload for deleting a message
         */
        export interface DeleteMessage extends BasePacket {
            id: string;
        }

        /**
        * This packet contains the payload for sending a new message
        */
        export interface SendMessage extends BasePacket {
            chat: string
            content: string
        }

        /**
        * This packet constains the payload for fetching messages
        */
        export interface GetMessages extends BasePacket {
            since: Date
            include_own: boolean
        }
    }

    /**
     * This namespace contains all outgoing events sent from the server
     */
    export namespace Incoming {

        export interface OnlineStatusDidChange {
            userID: string
            online: boolean
        }

        /**
         * This packet contains typing info for a chat
         */
        export interface TypingInfo {
            userID: string
            chat: string
            typing: boolean
        }

        /**
         * This packet contains an update to a message's read state
         */
        export interface MessageUpdate {
            messageID: string
            read: boolean
        }

        /**
         * This packet contains an update to a message's read state
         */
        export interface MessagesUpdate {
            messageIDs: string
            read: boolean
        }

        /**
         * This packet contains the payload for a new incoming message
         */
        export interface NewMessage {
            message: {
                id: string | undefined;
                content: string;
                chat_id: string;
                user_id: string;
                device_uuid?: string;
                read?: boolean;
            }
            meta?: any
        }

    }

}
/**
 * The packets being sent to the different endpoints
 */
export class OutgoingPacket {
    /**
     * This function validates an incoming MessageRead packet
     * @param packet
     * @returns {packet is Content.Incoming.Packets.MessageRead}
     */
    static isMessageReadPacket(packet: any): packet is Content.Outgoing.MessageRead {
        return typeof packet.id === 'string';
    }

    /**
     * This function validates an incoming MessageRead packet
     * @param packet
     * @returns {packet is Content.Incoming.Packets.MessageRead}
     */
    static isMessagesReadPacket(packet: any): packet is Content.Outgoing.MessagesRead {
        return Array.isArray(packet.ids);
    }

    /**
     * This function validates an incoming DeleteMessage packet
     * @param packet
     * @returns {packet is Content.Incoming.Packets.DeleteMessage}
     */
    static isDeleteMessagePacket(packet: any): packet is Content.Outgoing.DeleteMessage {
        return typeof packet.id === 'string';
    }

    /**
     * This function validates an incoming SendMessage packet
     * @param packet
     * @returns {packet is Content.Incoming.Packets.SendMessage}
     */
    static isSendMessagePacket(packet: any): packet is Content.Outgoing.SendMessage {
        return typeof packet.chat === 'string' && typeof packet.content === 'string'
    }

    /**
     * This function validates an incoming GetMessages packet
     * @param packet
     * @returns {packet is Content.Incoming.Packets.GetMessages}
     */
    static isGetMessagesPacket(packet: any): packet is Content.Outgoing.GetMessages {
        if (packet.since) {
            packet.since = new Date(packet.since);
            if (isNaN(packet.since.getTime())) {
                return false
            }
        }
        return typeof packet.include_own === 'boolean';
    }

}
/**
 * The different "endpoints" that can be requested from the client
 */
export enum OutgoingPacketEventType {
    SendMessage = "sendMessage",
    GetMessages = "getMessages",
    SubscribeOnlineStatus = "subscribeOnlineStatus",
    UnsubscribeOnlineStatus = "unSubscribeOnlineStatus",
    DeleteMessages = "deleteMessages",
    MessageRead = "messageRead",
    MessagesRead = "messagesRead",
    Authenticate = "authenticate"
}

/**
 * This namespace contains the packets that can be sent for the corresponding EventTypes
 */
export class IncomingPacket {



    /**
     * This function can be used to validate an incoming TypingInfo packet
     * @param packet
     * @returns {packet is Content.Outgoing.Packets.TypingInfo}
     */
    static isTypingInfo(packet: any): packet is Content.Incoming.TypingInfo {
        return typeof packet.chat === 'string' && typeof packet.userID === 'string' && typeof packet.typing === 'boolean'
    }



    /**
     * This function can be used to validate an incoming MessagesUpdate packet
     * @param packet
     * @returns {packet is Content.Outgoing.Packets.MessageUpdate}
     */
    static isMessageUpdate(packet: any): packet is Content.Incoming.MessageUpdate {
        return typeof packet.messageID === 'string' && typeof packet.read === 'boolean'
    }
    /**
     * This function can be used to validate an incoming MessagseUpdate packet
     * @param packet
     * @returns {packet is Content.Outgoing.Packets.MessageUpdate}
     */
    static isMessagesUpdate(packet: any): packet is Content.Incoming.MessagesUpdate {
        return Array.isArray(packet.messageIDs) && typeof packet.read === 'boolean'
    }



    /**
     * This function an be used to validate an incoming NewMessage packet
     * @param packet
     * @returns {packet is Content.Outgoing.Packets.NewMessage}
     */
    static isNewMessage(packet: any): packet is Content.Incoming.NewMessage {
        return typeof packet.message === 'object'
    }
}

/**
* The different types of events that can be pushed to the client
*/
export enum IncomingPacketEventType {
    NewMessage = "newMessage",
    OnlineStatusChange = "onlineStatusDidChange",
    TypingChanged = "typingChanged",
    MessageUpdated = "messageUpdated",
    MessagesUpdated = "messagesUpdated"
}