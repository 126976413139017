import * as React from "react";
import { SubCategory } from "fullcircle-api";
import { StaticSourceDropDown, StaticSourceDropDownProps } from "./StaticSourceDropDown";

export interface SubCategoryDropDownProps extends StaticSourceDropDownProps<SubCategory> {
    items: SubCategory[]
}
export class SubCategoryDropDown extends React.Component<SubCategoryDropDownProps, {}> {

    constructor(props: SubCategoryDropDownProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <StaticSourceDropDown {...this.props} />
    }
}