import { Post, PostsAPI } from 'fullcircle-api';
import * as React from 'react';
import { Container, Row } from 'reactstrap';
import ModFindList from '../components/list/ModFindList';


export function FeaturedPage() {

    const [items, setItems] = React.useState<Post[]>()

    React.useEffect(() => {
        PostsAPI.getFeaturedPosts(0, undefined).then((posts) => {
            setItems(posts)
        }).catch((err) => { })
    }, [])

    return <Container>

        <Row id={'posts_featured'} style={{ marginTop: 20 }}>
            <ModFindList posts={items} loading={items == undefined} />
        </Row>
    </Container>
}