import * as React from 'react'
import { PayoutAPI, User } from 'fullcircle-api';
import { Balance, BalanceSource } from 'fullcircle-api/lib/models/balance';
import { ModFindLoader } from '../../generic/ModFindLoader';
import { UserContextType } from '../../../context/user';
import { formatPriceI18n } from '../../../utils/formater';
import * as h from 'history'
import { IC_TIME, CLOSE_BLUE } from '../../../assets';
import moment from 'moment';
import Button from '../../generic/Button';
import { t } from 'i18next';
import { getPostUrl } from '../../../utils/path';

export class Transactions extends React.Component<{
    onClose: () => void;
    onPayoutClick: () => void;
    history: h.History
}, {
    balance_history?: Balance[],
    loading: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: true
        }
    }

    static contextType = UserContextType
    context: User | undefined

    componentDidMount() {
        this.getBalanceHistory()
    }


    private getBalanceHistory() {
        PayoutAPI.getBalanceHistory().then((balanceHistory) => {
            this.setState({
                balance_history: balanceHistory,
                loading: false
            })
        }).catch(() => {
            this.setState({
                balance_history: [],
                loading: false
            })
        })
    }

    render() {
        if (!this.context) {
            return <div>{t("Messages.SOMETHING_WENT_WRONG")}</div>
        }
        return (
            <div className="transactions-container">
                <div className="transaction-close">
                    <img src={CLOSE_BLUE} onClick={() => this.props.onClose()} />
                </div>
                <div style={{ marginBottom: 16 }}>
                    <Button
                        text={t("Messages.REQUEST_PAYOUT")}
                        size={'sm'}
                        rounded
                        onClick={() => this.props.onPayoutClick()}
                    />
                </div>
                {this.state.loading ? <ModFindLoader /> : this.state.balance_history ? this.state.balance_history.length > 0 ? this.state.balance_history.map((balance) => {
                    return this.renderBalanceItem(balance)
                }) : <div className="info-container">{t("Messages.NO_TRANSACTIONS")}</div> : <div className="info-container">{t("Messages.SOMETHING_WENT_WRONG")}</div>}
                <div style={{ marginTop: 16 }}>
                    <Button
                        text={t("Messages.REQUEST_PAYOUT")}
                        size={'sm'}
                        rounded
                        onClick={() => this.props.onPayoutClick()}
                    />
                </div>

            </div>
        )
    }

    private renderBalanceItem(item: Balance) {
        let title: string = ''
        let subTitle: string | undefined = undefined
        switch (item.source) {
            case BalanceSource.Charge:
                title = 'Charge'
                break;
            case BalanceSource.Ambassador:
                title = 'Ambassador Referal Credit'
                break;
            case BalanceSource.Kickback:
                title = 'Kickback received'
                if (item.pending) {
                    subTitle = '(pending until item received)'
                }
                break;
            case BalanceSource.Payout:
                title = 'Payout received';
                break;
            case BalanceSource.Purchase:
                title = 'Purchased an item';
                break;
            case BalanceSource.Sale:
                title = 'Sold an item';
                if (item.pending) {
                    subTitle = '(pending until item received)'
                }
                break;
            case BalanceSource.ModFind:
                title = 'ModFind Credit';
                break;
        }
        return <div className={"balance-container " + (item.post_id ? 'link' : '')} key={item.id} onClick={() => {
            if (item.post_id) {
                this.props.history.push(getPostUrl({ id: item.post_id }))
            }
        }}>
            <div className="header">
                <span className="title">{title}</span>
                <span className="amount">{formatPriceI18n(item.amount, this.context!.currency)}</span>
            </div>
            {subTitle && <span className="subtitle">{subTitle}</span>}
            <div className="footer">
                <img src={IC_TIME} />
                <span>{moment(item.created_at).fromNow()}</span>
            </div>
        </div>
    }
}