import * as React from 'react'
import { IC_SEARCH } from '../../assets';
import { Brand } from 'fullcircle-api';
import Button from '../generic/Button';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { BrandDropDown } from '../dropdown/BrandDropDown';
import { Colors } from '../../styles/colors';
import { t } from 'i18next';

export interface SearchBarProps extends RouteComponentProps<{}, {}, { search?: string, brands?: Brand[] }> {
    onSearchPressed: (search: string, brand?: Brand) => void;
    onSellPressed: () => void;
}

export interface SearchQuery { search: string, brand?: string };


class SearchBarInner extends React.Component<SearchBarProps, {
    searchValue: string;
    brand?: Brand;
}> {

    constructor(props: SearchBarProps) {
        super(props)
        this.state = {
            searchValue: '',
        }
        if (props.location.state) {
            this.state = {
                searchValue: props.location.state.search || '',
                brand: (props.location.state.brands && props.location.state.brands.length > 0) ? props.location.state.brands[0] : undefined
            }
        }
        this.valueChanged = this.valueChanged.bind(this)
    }

    private focusInput = React.createRef<HTMLInputElement>();

    private valueChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ searchValue: event.target.value });
    }

    private onSearchPressed() {
        this.props.onSearchPressed(this.state.searchValue, this.state.brand);
    }

    private handleSearchFocus() {
        const node = this.focusInput.current;
        if (node) node.focus();
    }

    render() {
        return (
            <div className="search-upper-container">
                <div onClick={this.handleSearchFocus.bind(this)} className="search-container">
                    <img src={IC_SEARCH} className="search-icon" alt={'loup'} />
                    <input ref={this.focusInput} value={this.state.searchValue}
                        onKeyUp={(e) => {
                            if (e.key == 'Enter') {
                                this.onSearchPressed()
                            }
                        }}
                        placeholder={t("Messages.SEARCH_PLACEHOLDER")} onChange={this.valueChanged} />
                </div>
                <div className='brand-selector-container'>
                    <BrandDropDown className='brand-selector' placeholder={t("Messages.ALL_BRANDS")} selected={[this.state.brand!].filter(Boolean)}
                        dropDownOptions={{ inline: true, clearable: true, className: 'black' }}
                        style={{
                            height: '100%',
                            color: 'white',
                            borderRadius: 0, borderWidth: 0
                        }} onSelectedItemsChange={(item) => {
                            const propagate = () => {
                                document.dispatchEvent(new CustomEvent('Brand-changed', { detail: this.state.brand ? [this.state.brand] : undefined }))
                            }

                            if (item.length > 0) {
                                this.setState({ brand: item[0] }, propagate)
                            } else {
                                this.setState({ brand: undefined }, propagate)
                            }
                        }} />
                </div>
                <Button className='search-selector-button'
                    size={'lg'}
                    rounded={false}
                    text={t("Messages.SEARCH")}
                    onClick={() => this.onSearchPressed()} />

                <Button className='search-selector-button sell-button'
                    size={'lg'}
                    rounded={false}
                    text={t("Messages.SELL")}
                    onClick={() => this.props.onSellPressed()} />
            </div>
        )
    }
}

export const SearchBar = withRouter(SearchBarInner)