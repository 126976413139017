import * as React from 'react';
import { IC_CARS_SALE, IC_FOLLOW, IC_FOLLOW_PLUS } from '../../assets';
import { APIError, User } from 'fullcircle-api';
import { Favorite } from '../../cache/favorite';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../../styles/colors';
import { LoginRequiredPopup } from '../popup/LoginRequiredPopup';
import { t } from 'i18next';

export default class UserCarouselItem extends React.Component<{
    user: User
    currentUser?: User
    onItemClick?: () => void;
}, { favoriteLoading: boolean, isFavorite: boolean }> {

    constructor(props: any) {
        super(props)
        this.state = {
            favoriteLoading: false,
            isFavorite: false
        }
    }

    componentDidMount() {
        Favorite.isFavoriteUser(this.props.user.id).then((favorite) => {
            this.setState({ isFavorite: favorite })
        })
    }

    private likeBrand() {
        if (!this.props.currentUser) {
            LoginRequiredPopup.show()
            return
        }
        this.setState({ favoriteLoading: true })
        let user = this.props.user
        if (user) {
            if (this.state.isFavorite) {
                Favorite.deleteFavoriteUser(user).then(() => {
                    this.setState({ isFavorite: false, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            } else {
                Favorite.addFavoriteUser(user).then(() => {
                    this.setState({ isFavorite: true, favoriteLoading: false })
                }).catch((err: APIError) => {
                    this.setState({ favoriteLoading: false })
                })
            }
        }
    }


    render() {
        const { user, onItemClick } = this.props
        const { profile_image_medium_url, full_name } = user

        return (
            <div className="modFind-carousel-item-container">
                <div className="modFind-carousel-item-container-ratio">
                    <div className="modFind-carousel-item" onClick={onItemClick}>
                        <div className={"carousel-img-container"}>
                            <img src={profile_image_medium_url} alt={full_name} className={"image"} />
                        </div>
                    </div>
                </div>
                <div className='modFind-carousel-description' onClick={onItemClick}>
                    <span className="item-name">{full_name}</span>
                    <div className='modFind-carousel-lower-container'>
                        <div className='watch button-wraper'>
                            {this.state.favoriteLoading ? <ClipLoader
                                color={Colors.DODGER_BLUE}
                                size={13}
                            /> : <a href='#' onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                this.likeBrand()
                            }}>
                                <span className='follow'>{this.state.isFavorite ? t('Messages.UNFOLLOW') : t('Messages.FOLLOW')}</span>
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}