import * as React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

export interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text: string,
    size: 'sm' | 'lg',
    rounded?: boolean,
    style?: React.CSSProperties
    textStyle?: React.CSSProperties
    disabled?: boolean,
    icon?: string
    loading?: boolean
    onClick?: () => void;
    className?: string;
    classNameText?: string;
}

export default class Button extends React.Component<ButtonProps, {}> {

    render() {
        const {
            text,
            size,
            rounded,
            style,
            textStyle,
            disabled,
            icon,
            onClick: onButtonPressed,
            loading,
            className,
            classNameText,
            ...other
        } = this.props;
        const onClick = () => { !disabled && onButtonPressed && onButtonPressed() }
        return (
            <div
                className={`modFind-button ${disabled ? 'disabled' : ''} ${size === 'sm' ? 'sm' : 'lg'}${rounded ? ' rd' : ''}${icon ? ' icon' : ''} ${className || ''}`}
                style={{ ...style }} {...other} onClick={onClick}
            >
                {icon && <img className={size} src={icon} alt={'icon'} />}
                {loading ?
                    <ClipLoader size={size == 'sm' ? 20 : 35}
                        color={'#fff'}
                    />
                    :
                    <span
                        className={`modFind-button-text${size === 'lg' ? ' lg' : ''}${icon ? ' icon' : ''} ${classNameText || ''}`}
                        style={{ ...textStyle }}
                    >
                        {text}
                    </span>
                }
            </div>
        )
    }
}