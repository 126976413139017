import * as React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker, Circle } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import { Colors } from '../styles/colors';
import ClipLoader from 'react-spinners/ClipLoader'
import { IC_MARKER } from '../assets';
import { mapStyle } from '../styles/map_styles';
import { GoogleDetails, convertGoogle, LocationDetails, API_KEY } from '../utils/location';

import { t } from 'i18next';

interface MapProps {
    location?: {
        location?: {
            latitude: number;
            longitude: number;
        }
        formatted_address?: string
    }
    onLocationDetailsChange?: (data: LocationDetails) => void
    disabled?: boolean
    zoom?: number
}
class Map extends React.Component<MapProps> {
    private mapView?: GoogleMap | null;
    private autoComplete: any;

    componentDidUpdate(prevProps: MapProps) {
        if (this.props.location != prevProps.location && this.props.location) {
            if (this.mapView) {
                this.mapView.panTo({ lat: this.props.location!.location!.latitude, lng: this.props.location.location!.longitude })
            }
        }
    }

    render() {
        const center = ((this.props.location && this.props.location.location) ?
            { lat: this.props.location.location.latitude, lng: this.props.location.location.longitude }
            : { lat: 0, lng: 0 })
        return <div><GoogleMap ref={(ref) => this.mapView = ref} options={{
            styles: mapStyle, streetViewControl: false, mapTypeControl: false, fullscreenControl: false
        }}
            defaultZoom={this.props.zoom || 2} zoom={this.props.zoom ? this.props.zoom : this.props.location ? 14 : 2}
            center={center}>
            {this.props.children && this.props.children}
            {!this.props.children && this.props.location?.location && <Marker icon={IC_MARKER}
                position={{ lat: this.props.location.location.latitude, lng: this.props.location.location.longitude }}>
                {this.props.location.formatted_address && <InfoWindow>
                    <div>
                        <h4>{this.props.location.formatted_address}</h4>
                    </div>
                </InfoWindow>}
            </Marker>}
            {!this.props.children && this.props.onLocationDetailsChange && <Autocomplete
                ref={(ref) => this.autoComplete = ref} options={{ types: ['address'] }}
                style={{
                    width: '100%',
                    height: '40px',
                    paddingLeft: '16px',
                    borderColor: Colors.LIGHT_GREY,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderTop: 0
                } as React.CSSProperties}
                onPlaceSelected={(details: GoogleDetails) => {
                    let streetNumber: number | undefined = undefined
                    try {
                        const currentLocationText = this.autoComplete ? this.autoComplete.refs.input.value : undefined

                        if (currentLocationText) {
                            let number = currentLocationText.replace(/(^\d+)(.+$)/i, '$1').trim()
                            if (number != '' && number.length > 0) {
                                streetNumber = parseInt(number)
                            }
                        }
                    } catch (error) {

                    }

                    this.setLocationObject(details, streetNumber)
                }}
            />}
        </GoogleMap>
        </div>
    }
    private setLocationObject(details: GoogleDetails, streetNumber?: number) {
        let data = convertGoogle(details, streetNumber);
        if (data)
            this.props.onLocationDetailsChange && this.props.onLocationDetailsChange(data)
    }
}

const MapComponent = withScriptjs(withGoogleMap(Map))

interface ModFindMapProps {
    height: number;
    location?: LocationDetails;
    onLocationDetailsChange?: (data: LocationDetails) => void;
    disabled?: boolean;
}

export class ModFindMap extends React.Component<ModFindMapProps>{

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    private compareLocation(location?: LocationDetails, location2?: LocationDetails) {
        if (location == location2) {
            return false
        }
        if (location == undefined) {
            if (location2 == undefined) {
                return false
            }
            return true
        } else if (location2 == undefined) {
            return true
        }
        return Object.keys(location).find(k => {
            return location2[k] != location[k]
        }) != undefined
    }

    shouldComponentUpdate(props: ModFindMapProps) {
        let shouldUpdate = this.props.height != props.height ||
            this.compareLocation(props.location, this.props.location) ||
            this.props.disabled != props.disabled ||
            this.props.onLocationDetailsChange != props.onLocationDetailsChange
        return shouldUpdate
    }

    render() {
        return <MapComponent disabled={this.props.disabled} location={this.props.location} onLocationDetailsChange={this.props.onLocationDetailsChange}
            googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + API_KEY}
            loadingElement={
                <div style={{ height: this.props.height, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <ClipLoader
                        color={Colors.DODGER_BLUE}
                        size={25}
                    />
                    <p style={{ marginTop: 20 }}>
                        <span>{t("Messages.LOADING_MAP")}</span>
                    </p>
                </div>
            }
            containerElement={
                <div style={{ height: this.props.height }} />
            }
            mapElement={
                <div style={{ height: `100%` }} />
            }
        />
    }
}

interface ModFindPostMapProps {
    height: number;
    location?: { latitude: number, longitude: number };
}

export class ModFindPostMap extends React.Component<ModFindPostMapProps>{

    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        // const {location: { latitude, longitude }} = this.props .location


        return <MapComponent
            location={{ location: this.props.location }}
            googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + API_KEY}
            loadingElement={
                <div style={{ height: this.props.height, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <ClipLoader
                        color={Colors.DODGER_BLUE}
                        size={25}
                    />
                    <p style={{ marginTop: 20 }}>
                        <span>{t("Messages.LOADING_MAP")}</span>
                    </p>
                </div>
            }
            containerElement={
                <div style={{ height: this.props.height }} />
            }
            mapElement={
                <div style={{ height: `100%` }} />
            } zoom={8}>
        </MapComponent>
    }
}