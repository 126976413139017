import { API } from "fullcircle-api/lib/endpoints/ads";
import { AdPlan } from "fullcircle-api/lib/models/adplan";
import { t } from "i18next";
import * as React from "react";
import { Container } from "reactstrap";
import { SCREENSHOTS, ADS_1, ADS_2 } from "../assets";
import { AdPlanBox } from "../components/adplan";
import { AdPlanCheckout } from "../components/AdPlanCheckout";


export function AdPage() {

    const [plans, setPlans] = React.useState<AdPlan[]>()
    const [checkoutPlan, setCheckoutPlan] = React.useState<AdPlan>()

    React.useEffect(() => {
        API.getAdPlans().then((plans) => {
            setPlans(plans)
        })
    }, [])

    return <Container className='ads'>
        <div className="ads-container">
            <h4>{t("Messages.ADVERISE_WITH_MODFIND")}</h4>
            <p></p>
            <p>{t("Messages.ADVERTISE_L1")}</p>
            <p className="image-container">
                <img src={SCREENSHOTS} />
            </p>
            <div className="plan-container">
                {!checkoutPlan && plans?.map((plan) => {
                    return <AdPlanBox key={plan.id} plan={plan} onClick={() => {
                        setCheckoutPlan(plan)
                    }} />
                })}
                {checkoutPlan && <div className="plan-checkout">
                    <AdPlanCheckout plan={checkoutPlan} onCancel={() => {
                        setCheckoutPlan(undefined)
                    }} />
                </div>}
            </div>
            <p>{t("Messages.ADVERTISE_L3")}</p>
            <div>
                <ul>
                    <p>
                        <li>{t("Messages.ADVERTISE_P1")}</li>
                        <li>{t("Messages.ADVERTISE_P2")}</li>
                        <li>{t("Messages.ADVERTISE_P10")}</li>
                        <li>{t("Messages.ADVERTISE_P4")}</li>
                    </p>
                </ul>
            </div>
            <p className="image-container">
                <img src={ADS_1} />
            </p>
            <h4>{t("Messages.ADVERTISE_L2")}</h4>
            <p></p>
            <p>{t("Messages.ADVERTISE_P11")}</p>
            <div>
                <ul>
                    <p>
                        <li>{t("Messages.ADVERTISE_P5")}</li>
                        <li>{t("Messages.ADVERTISE_P6")}</li>
                        <li>{t("Messages.ADVERTISE_P7")}</li>
                        <li>{t("Messages.ADVERTISE_P8")}</li>
                        <li>{t("Messages.ADVERTISE_P9")}</li>
                        <li>{t("Messages.ADVERTISE_P13")}</li>
                    </p>
                </ul>
            </div>
            <p className="image-container">
                <img src={ADS_2} />
            </p>
            <h4>{t("Messages.ADVERTISE_L4")}</h4>
            <p></p>
            <p>{t("Messages.ADVERTISE_L5")}</p>
            <p>
                <a href="mailto:advertise@modfind.com">advertise@modfind.com</a>
            </p>
        </div>
    </Container>
}