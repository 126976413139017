import React from "react";
import { Post } from "fullcircle-api";
import Carousel from "./Carousel"
import PostCarouselItem from "./PostCarouseItem";
import { t } from "i18next";



export default class PostCarousel extends Carousel<Post> {

    renderItems(items: Post[]): React.ReactElement[] {
        return items.map((post) => {
            return (
                <PostCarouselItem
                    key={post.id}
                    post={post} currentUser={this.context}
                    onItemClick={() => this.props.onItemClick && this.props.onItemClick(post)}
                />
            )
        })
    }

    getEmptyPlaceholder(): string {
        return t("Messages.NO_ITEMS")
    }
}

