import { DeliveryAPI, DeliveryRequest, DeliveryRequestStatus, Post, RatingAPI, User } from "fullcircle-api";
import React from "react";
import { UserContextConsumer } from "../../../context/user";
import ModFindPopUp, { TitleMessageContent } from "../../popup/ModFindPopUp";
import { ModFindRating } from "../ModFindRating";

import Button from "../../generic/Button";
import { Colors } from "../../../styles/colors";
import { addressToString } from "../../../utils/location";
import { errorToString, snakeToPascal } from "../../../utils/helper";
import * as H from 'history'
import ModFindDeliverPopup from "../../popup/ModFindDeliverPopup";
import * as toastr from 'toastr'
import { getUserUrl } from "../../../utils/path";
import ChatHandler from "../../../api/chathandller";
import { DeliveryMethod } from "fullcircle-api/lib/models/post";
import { t } from "i18next";
import ModFindRequestCancelItemPopup from "../../popup/ModFindRequestCancelItemPopup";
import { formatPriceI18n } from "../../../utils/formater";

export interface DeliveryInteractionProps {
    post: Post
    deliveryRequest: DeliveryRequest
    updateDeliveryRequest: (deliveryRequest: DeliveryRequest) => void
    history: H.History
}

export interface DeliveryInteractionState {
    ratePopup: boolean
    popup?: React.ReactChild
    loading: boolean
}

export class DeliveryInteraction extends React.Component<DeliveryInteractionProps, DeliveryInteractionState> {

    constructor(props: DeliveryInteractionProps) {
        super(props);
        this.state = {
            ratePopup: false,
            popup: undefined,
            loading: false
        }
    }

    deliveryPopup(title: string, message: string, statusToSet: DeliveryRequestStatus) {
        if (statusToSet == DeliveryRequestStatus.InDelivery && this.props.deliveryRequest.delivery_method == DeliveryMethod.SHIP) {
            return <ModFindDeliverPopup mode={this.props.deliveryRequest?.status == DeliveryRequestStatus.InDelivery ? 'add' : 'submit'} message={message} onClose={() => {
                this.setState({ popup: undefined })
            }} onSubmit={(trackingCarrier, trackinNumber) => {
                return DeliveryAPI.updateDelivery(this.props.deliveryRequest!.id, statusToSet,
                    { tracking_number: trackinNumber, tracking_carrier: trackingCarrier }).then((result) => {
                        result && this.props.updateDeliveryRequest(result)
                        this.setState({ loading: false, popup: undefined })
                    }).catch(err => {
                        toastr.error(errorToString(err).join('\n'))
                        this.setState({ loading: false })
                    })
            }} />
        }
        return this.genericPopup(title, message, () => {
            this.setState({ loading: true })
            DeliveryAPI.updateDelivery(this.props.deliveryRequest!.id, statusToSet).then((result) => {
                this.setState({ loading: false })
                this.props.updateDeliveryRequest(result!)
            }).catch(err => {
                toastr.error(errorToString(err).join('\n'))
                this.setState({ loading: false })
            })
        })
    }

    requestCancellationPopup() {
        return <ModFindRequestCancelItemPopup deliveryId={this.props.deliveryRequest.id} onClose={() => {
            this.setState({ popup: undefined })
        }} />
    }

    genericPopup(
        title: string,
        message: string,
        confirmAction?: () => void,
        confirmText?: string,
        cancelText?: string
    ) {

        return <ModFindPopUp onClose={() => {
            this.setState({ popup: undefined })
        }}>
            <TitleMessageContent title={title} message={message} icon={'info'} buttons={[
                {
                    text: confirmText || 'Confirm', onClick: () => {
                        confirmAction && confirmAction()
                        this.setState({ popup: undefined })
                    }
                },
                {
                    text: cancelText || 'Cancel', onClick: () => {
                        this.setState({ popup: undefined })
                    }, color: Colors.RED
                }
            ]} />
        </ModFindPopUp>
    }

    render() {
        const { deliveryRequest } = this.props
        return <UserContextConsumer>{(user) => {
            return <div className='delivery-container'>
                <div className='delivery'>
                    <div className='order-id'>{t("Messages.ORDER_ID_FROM", { id: deliveryRequest.order_id, time: new Date(deliveryRequest.created_at).toLocaleDateString() })}</div>
                    <div className='quantity-text'>{t("Messages.QUANTITY_ORDERED", { quantity: deliveryRequest.quantity })}</div>
                    {deliveryRequest.variants && deliveryRequest.variants.options.length > 0 && <div className='variants'>
                        <div className='variants-text'>{t("Messages.VARIANTS_ORDERED")}</div>
                        {deliveryRequest.variants.options.map(({ name, id, option }) => {
                            return <div key={id}>{option}: {name}</div>
                        })}
                        {deliveryRequest.variants?.price && <div>{t("Messages.VARIANT_PRICE")}: {formatPriceI18n(deliveryRequest.variants.price, deliveryRequest.post!.currency)}</div>}
                    </div>}
                    {this.renderButtons(user)}
                    {this.renderRating()}
                    {this.state.popup ? this.state.popup : null}
                </div>
                <div className='chat'>
                    {this.renderChat(user)}
                </div>
            </div>
        }}</UserContextConsumer>

    }

    renderButtons(user?: User) {
        const { post } = this.props
        if (user && post.user_id == user.id) {
            return this.renderSellerInteractions(post)
        } else {
            return this.renderBuyerInteractions(post)
        }
    }

    private renderChat(user?: User) {
        if (!user) {
            return null
        }
        const isSeller = user.id == this.props.post.user_id
        const otherUser: User = isSeller ? this.props.deliveryRequest.buyer! : this.props.post.user!
        let chat = (otherUser && this.props.post && ChatHandler.instance() && ChatHandler.instance()!.getChatByPostId(this.props.post.id, otherUser.id)) || undefined;
        return <div className='chat-small'>
            <div className="chat-small-wrapper">
                <div className='chat-small-header' onClick={() => {
                    this.props.history.push(getUserUrl(otherUser))
                }}>
                    <div className="center">
                        <span className="meet-text">{isSeller ? t("Messages.MEET_BUYER") : t("Messages.MEET_SELLER")}</span>
                        <div className='header-name-online-wrapper'>
                            <span className="meet-username-text">{otherUser.full_name}</span>
                            {/*this.state.online && <span className="online-indicator"></span>*/}
                        </div>
                    </div>
                    {otherUser.profile_image_url && <img className='user-image' src={otherUser.profile_image_url} alt={'user_profile'} />}
                </div>
                {chat && <Button
                    size={'lg'}
                    rounded
                    text={t("Messages.OPEN_CHAT")}
                    onClick={() => {
                        ChatHandler.instance()?.openChat(chat!)
                    }}
                />}
            </div>
        </div>
    }

    private renderShippingAddress() {
        const elementsToRender = new Array<React.ReactChild>()
        if (this.props.deliveryRequest.pickup_comment) {
            elementsToRender.push(<div className='shipping'>
                <span>
                    {`Comment from ${this.props.deliveryRequest.buyer!.full_name}: ${this.props.deliveryRequest.pickup_comment}`}
                </span>
            </div>)
        }
        if (this.props.deliveryRequest && this.props.deliveryRequest.delivery_method == DeliveryMethod.SHIP) {
            elementsToRender.push(<div className='shipping'>
                {('Ship to:\n' + addressToString(this.props.deliveryRequest.user_address!)).split('\n').map((item) => {
                    return (
                        <span key={item}>
                            {item}
                            <br />
                        </span>
                    )
                })}
            </div>)
        }
        return elementsToRender
    }

    private renderSellerInteractions(post: Post) {
        if (this.props.deliveryRequest) {
            switch (this.props.deliveryRequest.status) {
                case DeliveryRequestStatus.Unpaid:
                    return <>
                        <Button
                            size={'sm'}
                            rounded className="success-button"
                            text={t("Messages.RESERVED_CANCEL_ORDER")}
                            onClick={() => {
                                this.setState({
                                    popup: this.deliveryPopup(t("Messages.CONFIRM"), t("Messages.CONFIRM_CANCEL_ORDER"), DeliveryRequestStatus.Cancelled)
                                })
                            }}
                        />
                    </>
                case DeliveryRequestStatus.Pending:
                    return <>
                        <Button
                            size={'sm'}
                            rounded className="danger-button"
                            text={this.props.deliveryRequest?.delivery_method == DeliveryMethod.SHIP ? t("Messages.COMFIRM_SHIPPING") : t("Messages.COMFIRM_PICKUP")}
                            onClick={() => {
                                this.setState({
                                    popup: this.deliveryPopup(t("Messages.CONFIRM"), t("Messages.SHIPPED_PROMPT"), DeliveryRequestStatus.InDelivery)
                                })
                            }}
                        />
                        {this.renderShippingAddress()}
                        <Button
                            size={'sm'}
                            rounded
                            className="danger-button-outline cancel-order"
                            text={t("Messages.CANCEL_ORDER")}
                            onClick={() => {
                                this.setState({
                                    popup: this.requestCancellationPopup()
                                })
                            }}
                        />
                    </>
                case DeliveryRequestStatus.InDelivery:
                    if (this.props.deliveryRequest?.delivery_method == DeliveryMethod.SHIP && !this.props.deliveryRequest?.ship_tracking_number) {
                        return <>
                            <Button
                                size={'sm'}
                                rounded className="danger-button"
                                text={t("Messages.ADD_TRACKING_NUMBER")}
                                onClick={() => {
                                    this.setState({
                                        popup: this.deliveryPopup('', t("Messages.TRACKING_NUMBER_PROMPT"), DeliveryRequestStatus.InDelivery)
                                    })
                                }}
                            />
                            {this.renderShippingAddress()}
                        </>
                    }
                    return <>
                        <Button
                            size={'sm'}
                            rounded className="success-button"
                            text={t("Messages.HAS_SHIPPED")}
                            disabled
                        />
                        {this.props.deliveryRequest?.ship_tracking_info?.status && <div>
                            Tracking Status: <b>{snakeToPascal(this.props.deliveryRequest?.ship_tracking_info.status.toLowerCase())}</b>
                            {this.props.deliveryRequest?.ship_tracking_info?.eta && <>
                                <br />
                                Tracking Number: <b>{this.props.deliveryRequest?.ship_tracking_number} ({this.props.deliveryRequest?.ship_tracking_carrier})
                                </b>
                                <br />
                                ETA: <b>{new Date(this.props.deliveryRequest?.ship_tracking_info?.eta).toLocaleDateString()}
                                </b>
                            </>}
                        </div>}
                        {!this.props.deliveryRequest?.ship_tracking_info?.status && this.props.deliveryRequest?.ship_tracking_number && <div>
                            <br />
                            Tracking Number: <b>{this.props.deliveryRequest?.ship_tracking_number} ({this.props.deliveryRequest?.ship_tracking_carrier})</b>
                        </div>}
                    </>
                case DeliveryRequestStatus.Received:
                case DeliveryRequestStatus.Rated_Seller:
                    return <Button
                        size={'sm'}
                        rounded className="success-button"
                        text={t("Messages.RATE_BUYER")}
                        onClick={() => {
                            this.setState({ ratePopup: true })
                        }}
                    />
                case DeliveryRequestStatus.Rated:
                case DeliveryRequestStatus.Rated_Buyer:
                    return <Button
                        size={'sm'}
                        rounded className="success-button"
                        text={t("Messages.BUYER_RATED")}
                        disabled
                    />
            }
        }
    }

    private renderBuyerInteractions(post: Post) {
        if (this.props.deliveryRequest) {
            switch (this.props.deliveryRequest.status) {
                case DeliveryRequestStatus.Unpaid:
                    return <Button
                        size={'sm'}
                        rounded
                        text={'Reserved, pay now'}
                        onClick={() => {
                            this.props.history.push('/checkout/' + post.id)
                        }}
                    />
                case DeliveryRequestStatus.Pending:
                    return <>
                        <Button
                            size={'sm'}
                            rounded className="success-button"
                            text={'Waiting for delivery'} disabled
                        />
                    </>
                case DeliveryRequestStatus.InDelivery:
                    return <>
                        <Button
                            size={'sm'}
                            rounded className="success-button"
                            text={'Received & Approved'}
                            onClick={() => {
                                this.setState({
                                    popup: this.deliveryPopup('Confirm', 'Have you received and approved the item?', DeliveryRequestStatus.Received)
                                })
                            }}
                        />
                        {this.props.deliveryRequest?.ship_tracking_info?.status && <div>
                            Tracking Status: <b>{snakeToPascal(this.props.deliveryRequest?.ship_tracking_info.status.toLowerCase())}</b>
                            {this.props.deliveryRequest?.ship_tracking_info?.eta && <>
                                <br />
                                Tracking Number: <b>{this.props.deliveryRequest?.ship_tracking_number} ({this.props.deliveryRequest?.ship_tracking_carrier})
                                </b>
                                <br />
                                ETA: <b>{new Date(this.props.deliveryRequest?.ship_tracking_info?.eta).toLocaleDateString()}
                                </b>
                            </>}
                            {!this.props.deliveryRequest?.ship_tracking_info?.status && this.props.deliveryRequest?.ship_tracking_number && <div>
                                <br />
                                Tracking Number: <b>{this.props.deliveryRequest?.ship_tracking_number} ({this.props.deliveryRequest?.ship_tracking_carrier})</b>
                            </div>}
                        </div>}
                    </>
                case DeliveryRequestStatus.Received:
                case DeliveryRequestStatus.Rated_Buyer:
                    return <Button
                        size={'sm'}
                        rounded className="success-button"
                        text={'Rate seller'}
                        onClick={() => {
                            this.setState({ ratePopup: true })
                        }}
                    />
                case DeliveryRequestStatus.Rated:
                case DeliveryRequestStatus.Rated_Seller:
                    return <Button
                        size={'sm'}
                        rounded className="neutral-button"
                        text={'Seller rated'}
                        disabled
                    />
            }
        }
    }

    private renderRating() {
        const { deliveryRequest } = this.props;
        if (this.state.ratePopup) {
            let imBuyer = deliveryRequest && deliveryRequest.buyer && this.context && deliveryRequest.buyer.id == this.context.id ? true : false;
            return <ModFindPopUp onClose={() => {
                this.setState({ ratePopup: false })
            }}>
                <>
                    <span className="delivery-interaction-live-rating-title">{t('Messages.LEAVE_A_FAIR_RATING')}</span>
                    <ModFindRating
                        popup
                        ratingType={imBuyer ? 'seller' : 'buyer'}
                        editMode
                        onSubmit={(value: { [key: string]: number }) => {
                            this.setState({ loading: true, ratePopup: false })
                            RatingAPI.rateUser(deliveryRequest!.post_id, deliveryRequest.id, Object.keys(value).reduce((p, k) => {
                                if (value[k] != 0) {
                                    p.push({ rating: value[k] as 2, category_id: k })
                                }
                                return p
                            }, new Array<{ rating: 1 | 2 | 3 | 4 | 5, category_id: string }>())).then(() => {
                                return DeliveryAPI.getDelivery(deliveryRequest!.id)
                                    .then((result) => {
                                        this.props.updateDeliveryRequest(result)
                                        this.setState({ loading: false, ratePopup: false })
                                    })
                            }).catch(err => {
                                this.setState({ loading: false, ratePopup: false })
                                toastr.error(t("ErrorMessages.SomethingWentWrong"))
                            })
                        }} />
                </>
            </ModFindPopUp>
        } else {
            return null
        }
    }
}