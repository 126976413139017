import { BrandCarGeneration } from "fullcircle-api";

export function buildCarModelString(item: BrandCarGeneration) {
    let text = `${item.brand_name} ${item.model_name}`
    if (item.modification_name) {
        text += ' ' + item.modification_name
    }
    return text
}


export function buildCarModelStringWithYear(item: BrandCarGeneration) {
    let text = `${item.brand_name}`
    if (item.model_name) {
        text += ' ' + item.model_name
    }
    if (item.model_year) {
        text += ' (' + item.model_year + ')'
    }
    return text
}