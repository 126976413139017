import React from "react";
import { Post, PostsAPI } from "fullcircle-api";
import { useEffect, useState } from "react";
import { t } from "i18next";
import PostCarousel from "./PostCarousel";
import { CarouselProps } from "./Carousel";

export interface RecentItemsCarouselProps extends Omit<CarouselProps<Post>, 'items'> {
}

export function RecentItemsCarousel(props: RecentItemsCarouselProps) {
    const [items, setItems] = useState<Post[]>()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        PostsAPI.getRecentPosts().then((posts) => {
            setItems(posts)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }, [])

    return <PostCarousel items={items} loading={loading} title={t("Messages.RECENTLY_LISTED")} {...props} />
}