import * as React from 'react'
import { IC_DELETE, INFO, SUCCESS } from '../../assets';
import Button from '../generic/Button';
import ModFindInput from '../generic/ModFindInput';
import { Colors } from '../../styles/colors';
import { t } from 'i18next';


export default class ModFindPopUp extends React.Component<{
    onClose: () => void
    children: React.ReactElement
    style?: React.CSSProperties
}> {

    render() {

        return (
            <div className="pop-up" style={this.props.style}>
                <div className="pop-up-content">
                    <div className="close-icon">
                        <img src={IC_DELETE} onClick={() => this.props.onClose()} />
                    </div>
                    <div>
                        {this.props.children && React.cloneElement(this.props.children!, { onClose: this.props.onClose })}
                    </div>
                </div>
            </div>
        )
    }

    static confirm(message: string, onConfirm: () => void, onClose: () => void, title: string = 'Confirm', style?: React.CSSProperties) {
        return <ModFindPopUp onClose={onClose} style={style}>
            <TitleMessageContent title={title} message={message} icon={'info'} buttons={[
                {
                    text: t("Message.CONFIRM"), onClick: onConfirm
                },
                {
                    text: t("Message.CANCEL"), onClick: onClose, color: Colors.RED
                }
            ]} />
        </ModFindPopUp>
    }
}

export class TitleMessageContent extends React.Component<{
    icon?: 'info' | 'success' | number
    title: string,
    message?: string | React.ReactNode,
    input?: { value: string, onValueChanged: (value: string) => void }
    buttons?: { text: string | React.ReactElement, onClick?: () => void, color?: string, borderColor?: string, textColor?: string }[],
    onClose?: () => void
}> {

    inputRef?: ModFindInput | null

    static defaultProps = {
        buttons: [{
            text: t("Messages.OK"), onClick: function (this: TitleMessageContent) {
                this.props.onClose && this.props.onClose()
            }
        }]
    }

    private getImage() {
        switch (this.props.icon) {
            case 'info':
                return INFO
            case 'success':
                return SUCCESS
        }
        return this.props.icon!
    }

    componentDidMount(): void {
        this.inputRef?.focus()
    }

    render() {
        return <div className='title-message-content'>
            {this.props.icon && <img className='title-message-content-icon' src={this.getImage()} />}
            <h2 className='title-message-content-title'>{this.props.title}</h2>
            {this.props.message && <span className='title-message-content-message'>{this.props.message}</span>}
            {this.props.input && <div className='title-message-content-input'>
                <ModFindInput ref={ref => this.inputRef = ref} value={this.props.input.value} onValueChanged={this.props.input.onValueChanged}
                    colored />
            </div>}
            {this.props.buttons && <span className='title-message-content-buttons'>
                {this.props.buttons.map((btn, i) => {
                    if (typeof btn.text == 'string') {
                        return <Button style={{ backgroundColor: btn.color, border: btn.borderColor ? `1px solid ${btn.borderColor}` : undefined }} textStyle={{ color: btn.textColor }} rounded size={'sm'} text={btn.text} key={btn.text} onClick={btn.onClick ? btn.onClick.bind(this) : undefined} />
                    } else if (btn.text) {
                        return React.cloneElement(btn.text, { key: i })
                    }
                })}
            </span>}
        </div>
    }
}