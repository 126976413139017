import React from 'react'
import { APPLE_PAY_NO_BORDER, DISCOVER, MASTERCARD_CARD, PAYPAL_CARD, VISA } from '../../../assets'

interface PaymentMethodsProps {
    backgroundColor: 'black' | 'white'
}

export default function PaymentMethods(props: PaymentMethodsProps) {
    return (
        <div className={`PaymentMethods ${props.backgroundColor === 'white' ? 'white-background' : 'black-background'}`}>
            <img src={PAYPAL_CARD} alt="" />
            <img src={APPLE_PAY_NO_BORDER} alt="" />
            <img src={VISA} alt="" />
            <img src={MASTERCARD_CARD} alt="" />
            <img src={DISCOVER} alt="" />
        </div>
    )
}
