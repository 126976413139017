import React from 'react';
import { Post, PostsAPI, PostSortOrder, UsersAPI, User, Region, MetaAPI, Brand, AdBannerAPI, AdBanner, Category } from 'fullcircle-api';
import { Container, Row } from 'reactstrap';
import ModFindList from '../components/list/ModFindList';
import { FilterSettings, DefaultFilterSettings, prepareSearchParams, removeDefaultFilterSettings } from '../components/search/filtersettings';
import Button from '../components/generic/Button';
import { withRouter, RouteComponentProps, Route, Switch as RSwitch } from 'react-router-dom';
import { BrandDropDown } from '../components/dropdown/BrandDropDown';
import { getPostUrl } from '../utils/path';
import ModFindPopUp, { TitleMessageContent } from '../components/popup/ModFindPopUp';
import toastr from 'toastr'
import { Popup } from 'semantic-ui-react';
import { errorToString, preventBodyScroll } from '../utils/helper';
import { LoginRequiredPopup } from '../components/popup/LoginRequiredPopup';
import { UserContextConsumer } from '../context/user';
import { Favorite } from '../cache/favorite';
import Switch from "react-switch";
import { ModFindCurrencyInput } from '../components/generic/ModFindCurrencyInput';
import { RegionContextConsumer, RegionContextType } from '../context/region';
import { unformatPriceI18n, formatPriceI18n } from '../utils/formater';
import { ModelTypeDropDown } from '../components/dropdown/ModelTypeDropDown';
import { Colors } from '../styles/colors';
import { RegionDropDown } from '../components/dropdown/RegionDropDown';
import * as uuid from 'uuid'
import { Meta } from '../cache/meta';
import { decodeSlug, encodeName } from '../utils/slug';
import { CategoryDropDown } from '../components/dropdown/CategoryDropDown';
import { Dropdown } from 'semantic-ui-react';
import Slider from 'rc-slider';
import { ModFindMap } from '../components/ModFindMap';
import { IC_FILTER } from '../assets';
import { storeState, restoreState } from '../utils/state';
import * as _ from 'lodash'
import { t } from 'i18next';
import { RecentItemsCarousel } from '../components/carousel/RecentItemsCarousel';
import { YearDropDown } from '../components/dropdown/YearDropDown';

export enum SortBy {
    RELEVANCE = 'Relevance',
    NEWEST = 'Newest',
    PRICE_HIGHT_TO_LOW = 'Price: High to Low',
    PRICE_LOW_TO_HIGHT = 'Price: Low to High'
}

interface SearchProps extends RouteComponentProps<{ categoryName?: string, brandName?: string }, {}, Partial<(FilterSettings & { search?: string })>> {
    showRecents?: boolean;
    page: string
}

class Search extends React.Component<SearchProps, {
    selectedYears: string[]
    filterSettings: Partial<FilterSettings>;
    loading: boolean;
    posts?: Post[]
    users?: User[]
    search?: string
    regions?: Region[]
    location: boolean

    saveSearchPopup: boolean
    saveSearchName: string
    saveSearchSaving: boolean

    ad?: AdBanner
    showFilter: boolean
    selected: 'items' | 'user'
}> {
    static contextType = RegionContextType
    static LOAD_AMOUNT = 20;

    private step = 0;
    private currentPostsSearchToken: string = '';
    private currentUsersSearchToken: string = '';

    context: { region: string } | undefined

    constructor(props) {
        super(props)
        this.state = {
            filterSettings: Object.assign({}, DefaultFilterSettings, this.getSearchQueryFilters()),
            selectedYears: [],
            loading: true,
            search: this.getSearchQueryFilters().search,
            saveSearchPopup: false,
            saveSearchName: '',
            saveSearchSaving: false,
            location: false,
            showFilter: false,
            selected: 'items'
        }
        this.onFooterVisible = _.debounce(this.onFooterVisible.bind(this), 800, { leading: true })
        this.onBrandChanged = this.onBrandChanged.bind(this)
        this.onCategoryChanged = this.onCategoryChanged.bind(this)
    }

    componentDidMount() {
        let didRestore = false
        document.addEventListener('footerVisible', this.onFooterVisible)
        document.addEventListener('Brand-changed', this.onBrandChanged as any)
        AdBannerAPI.getAdBanner('web').then(banner => {
            if (banner) {
                this.setState({ ad: banner })
            }
        })
        if (this.props.history.action == 'POP') {
            const restore = restoreState(this.props.location.pathname)
            if (restore !== false) {
                this.step = restore.extra?.step || this.step
                this.setState({ ...this.state, ...restore })
                didRestore = true
            }
        }
        if (didRestore) {
            return
        }
        this.callApiFunc(true);
        MetaAPI.getRegions().then((regions) => {
            this.setState({ regions })
        })
        this.setCategory()
        this.setBrand()

    }

    componentDidUpdate(prevProps: SearchProps) {
        if (this.props.location.state) {
            let simpleNotEqual = Object.keys(this.props.location.state).find(k => {
                if (prevProps.location.state) {
                    return prevProps.location.state[k] != this.props.location.state![k]
                } else {
                    return true
                }
            }) != undefined
            if (simpleNotEqual) {
                this.setState({
                    filterSettings: Object.assign({}, this.getSearchQueryFilters()),
                    search: this.getSearchQueryFilters().search
                }, () => {
                    this.callApiFunc()
                })
            }
        } else if (this.props.match.params.categoryName != prevProps.match.params.categoryName) {
            if (this.props.match.params.categoryName) {
                this.setCategory()
            } else {
                this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, categories: undefined } }), () => { this.callApiFunc() });
            }
        } else if (this.props.match.params.brandName != prevProps.match.params.brandName) {
            if (this.props.match.params.brandName) {
                this.setBrand()
            } else {
                this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, brands: undefined } }), () => { this.callApiFunc() });
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('Brand-changed', this.onBrandChanged as any)
        document.removeEventListener('footerVisible', this.onFooterVisible)
        storeState(this.props.location.pathname, { ...this.state, extra: { step: this.step } })
    }

    setCategory() {
        if (this.props.match.params.categoryName) {
            Meta.getCategories().then(categories => {
                if (this.props.match.params.categoryName) {
                    let category = categories.find(c => c.name.toLowerCase() == decodeSlug(this.props.match.params.categoryName!));
                    if (category) {
                        this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, categories: [category!] } }), () => { this.callApiFunc() });
                    }
                }
            })
        }
    }

    setBrand() {
        if (this.props.match.params.brandName) {
            Meta.getBrands().then(brands => {
                if (this.props.match.params.brandName) {
                    let brand = brands.find(c => c.name.toLowerCase() == decodeSlug(this.props.match.params.brandName!));
                    if (brand) {
                        this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, brands: [brand!] } }), () => { this.callApiFunc() });
                    }
                }
            })
        }
    }

    onBrandChanged({ detail }: { detail: Brand[] | undefined }) {
        if (this.props.page != 'search') {
            if (this.props.page == 'brand' && detail && detail.length == 1) {
                this.props.history.push('/brand/' + encodeName(detail[0].name))
            } else
                this.props.history.replace('/search', { ...this.state.filterSettings, brands: detail })
        } else {
            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, brands: detail } }), () => { this.callApiFunc() });
        }
    }

    onCategoryChanged(categories: Category[]) {
        if (this.props.page != 'search') {
            if (this.props.page == 'category' && categories.length == 1) {
                this.props.history.push('/category/' + encodeName(categories[0].name))
            } else
                this.props.history.replace('/search', { ...this.state.filterSettings, categories: categories })
        } else {
            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, categories: categories } }), () => {
                this.callApiFunc()
            });
        }
    }

    onFooterVisible() {
        if (!this.state.loading) {
            this.callApiFunc(false, true)
        }
    }

    private getSearchQueryFilters() {
        return this.props.location.state || {}
    }

    private resetAll() {
        this.setState({ filterSettings: DefaultFilterSettings }, () => {
            const doReload = Object.keys(this.props.location.pathname).length == 0
            doReload && this.props.history.replace(this.props.location.pathname, {})
            this.callApiFunc()
        })
    }

    private saveSearch() {
        this.setState({ saveSearchSaving: true })
        UsersAPI.createSavedSearch(this.state.saveSearchName, prepareSearchParams(this.state.filterSettings, this.state.search)).then(res => {
            toastr.success(t("Messages.SEARCH_SAVED"));
            Favorite.addSavedSearch(res)
            this.setState({ saveSearchPopup: false, saveSearchSaving: false, saveSearchName: '' })
        }).catch(err => {
            toastr.error(errorToString(err).join('\n'))
            this.setState({ saveSearchSaving: false })
        })
    }

    private callApiFunc(doNotSetLaoding: boolean = false, loadingMore?: boolean) {
        if (loadingMore && this.state.posts) {
            if (this.state.posts.length % Search.LOAD_AMOUNT != 0) {
                return
            }
        }
        if (!doNotSetLaoding) {
            this.setState({ loading: true })
        }
        if (loadingMore == true) {
            this.step++;
        } else {
            this.step = 0;
        }
        this.currentUsersSearchToken = uuid.v4()
        this.currentPostsSearchToken = uuid.v4()
        const filterSettings = prepareSearchParams(this.state.filterSettings, this.state.search || '');
        if (this.state.selected === 'items') {
            PostsAPI.searchPosts(this.state.filterSettings.sort || PostSortOrder.Newest, Search.LOAD_AMOUNT, Search.LOAD_AMOUNT * this.step, filterSettings as any,
                this.currentPostsSearchToken).then(({ posts, token }) => {
                    if (token != this.currentPostsSearchToken) {
                        return
                    }
                    if (loadingMore) {
                        this.setState({ posts: (this.state.posts || []).concat(posts), loading: false });
                    } else {
                        this.setState({ posts: posts, loading: false });
                    }
                }).catch((err) => {
                    this.setState({ loading: false });
                })
        } else {
            UsersAPI.searchUsers(Search.LOAD_AMOUNT, Search.LOAD_AMOUNT * this.step, this.state.search || '', this.context!.region, this.currentUsersSearchToken).then(({ users, token }) => {
                if (token != this.currentUsersSearchToken) {
                    return
                }
                if (loadingMore) {
                    this.setState({ users: (this.state.users || []).concat(users), loading: false });
                } else {
                    this.setState({ users: users, loading: false });
                }
            }).catch((err) => {
                this.setState({ loading: false });
            })
        }

    }

    public static getTextForSort(sort: PostSortOrder) {
        switch (sort) {
            case PostSortOrder.Relevance:
                return t('Messages.RELEVANCE');
            case PostSortOrder.Newest:
                return t('Messages.NEWEST');
            case PostSortOrder.PriceAscending:
                return t('Messages.PRICE_LOW_TO_HIGHT');
            case PostSortOrder.PriceDescending:
                return t('Messages.PRICE_HIGHT_TO_LOW');
            default:
                return t('Messages.RELEVANCE');
        }
    }

    private getSortOptions() {
        return [PostSortOrder.Relevance, PostSortOrder.Newest, PostSortOrder.PriceDescending, PostSortOrder.PriceAscending].map((val) => {
            return {
                key: val,
                value: val,
                text: Search.getTextForSort(val),
                section: val
            }
        })
    }

    renderSaveSearchPopup() {
        if (this.state.saveSearchPopup) {

            return <ModFindPopUp onClose={() => this.setState({ saveSearchPopup: false })}>
                <TitleMessageContent title={t("Messages.SAVESEARCH")} message={t("Messages.NAME_SEARCH")}
                    input={{ value: this.state.saveSearchName, onValueChanged: (val) => { this.setState({ saveSearchName: val }) } }}
                    buttons={[
                        {
                            text: <Popup onOpen={() => {
                                preventBodyScroll(true)
                            }} onClose={() => {
                                preventBodyScroll(false)
                            }} trigger={<Button size='sm' text='Save' rounded loading={this.state.saveSearchSaving}
                                onClick={() => {
                                    if (this.state.saveSearchName != '') {
                                        this.saveSearch()
                                    } else {
                                        toastr.info(t("Messages.NAME_SEARCH"))
                                    }
                                }}
                                disabled={this.state.saveSearchName.trim().length == 0} />}
                                content={this.state.saveSearchName.trim().length > 0 ? undefined : t("Messages.NAME_SEARCH")}
                                disabled={this.state.saveSearchName.trim().length > 0} />, onClick: () => {

                                }
                        }
                    ]} />
            </ModFindPopUp>
        }
        return null
    }

    renderFilter() {
        return (
            <>
                <div className="filter-separator" />
                <div className="filter-item">
                    <CategoryDropDown
                        className={'filter-dropdown'}
                        allowMultiSelect
                        placeholder={t("Messages.CATEGORY")}
                        selected={this.state.filterSettings.categories || []}
                        onSelectedItemsChange={(selected) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, categories: selected } }), () => {
                                this.callApiFunc()
                            });
                        }}
                        dropDownOptions={{ fluid: true }}
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item">
                    <YearDropDown
                        className={'filter-dropdown'}
                        allowMultiSelect
                        selected={(this.state.selectedYears || []).map((year) => ({ id: year, name: year }))}
                        onSelectedItemsChange={(selected) => {
                            this.setState(({ selectedYears: selected.map(s => s.id) }))
                        }}
                        placeholder={t("Messages.YEARS")}
                        dropDownOptions={{ fluid: true }}
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item">
                    <BrandDropDown
                        className={'filter-dropdown'}
                        selected={this.state.filterSettings.brands || []}
                        onSelectedItemsChange={(selected) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, brands: selected } }), () => {
                                this.callApiFunc()
                            });
                        }}
                        allowMultiSelect
                        placeholder={t("Messages.MAKES")}
                        dropDownOptions={{ fluid: true }}
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item">
                    <ModelTypeDropDown
                        filteredBrands={this.state.filterSettings.brands || []}
                        filteredYears={this.state.selectedYears}
                        selected={(this.state.filterSettings.cars || [])}
                        onSelectedItemsChange={(selected) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, cars: selected } }), () => {
                                this.callApiFunc()
                            });
                        }}
                        placeholder={t("Messages.MODEL_TYPES")}
                        dropDownOptions={{ fluid: true }}
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item">
                    <Dropdown
                        options={this.getSortOptions()}
                        className={'filter-dropdown'}
                        value={this.state.filterSettings.sort}
                        onChange={(_, { value }) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, sort: (value as PostSortOrder) || PostSortOrder.Newest } }), () => {
                                this.callApiFunc()
                            });
                        }}
                        fluid
                        placeholder={t("Messages.SORT")}
                        selection
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item margin">
                    <span>{t("Messages.HAS_KICKBACK")}</span>
                    <div style={{ marginLeft: 'auto' }}>
                        <Switch
                            checked={!!this.state.filterSettings.hasKickback}
                            onChange={(e: boolean) => {
                                this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, hasKickback: e === true ? true : undefined } }), () => {
                                    this.callApiFunc()
                                });
                            }}
                            offColor={Colors.GREY}
                            onColor={Colors.DODGER_BLUE}
                            onHandleColor={'#fff'}
                            offHandleColor={'#fff'}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={24}
                            width={52}
                        />
                    </div>
                </div>
                <div className="filter-separator" />
                <div className="filter-item margin">
                    <span>{t("Messages.LOCATION")}</span>
                    <div style={{ marginLeft: 'auto' }}>
                        <Switch
                            checked={this.state.location}
                            onChange={(e: boolean) => {
                                this.setState((prevState) => ({ location: e, filterSettings: { ...prevState.filterSettings, location: undefined, distance: prevState.filterSettings.distance ? 30 : prevState.filterSettings.distance } }), () => {
                                    this.callApiFunc()
                                });
                            }}
                            offColor={Colors.GREY}
                            onColor={Colors.DODGER_BLUE}
                            onHandleColor={'#fff'}
                            offHandleColor={'#fff'}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={24}
                            width={52}
                        />
                    </div>
                </div>
                {this.state.location && <div>
                    <div className="filter-separator" />
                    <ModFindMap
                        height={window.matchMedia('(max-width: 767px)') ? 250 : 400}
                        location={this.state.filterSettings.location}
                        onLocationDetailsChange={(location) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, location: location } }), () => {
                                this.callApiFunc()
                            });
                        }}
                    />
                    <div className="filter-item margin" style={{ marginTop: 40 }}>
                        <span>{t("Messages.DISTANCE")}</span>
                        <span style={{ marginLeft: 'auto' }}>{(this.state.filterSettings.distance || 30) + ' mi'}</span>
                    </div>
                    <div style={{ marginLeft: 14, marginRight: 8 }}>
                        <Slider min={1} max={100} value={this.state.filterSettings.distance || 30} onChange={(e) => this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, distance: e } }), () => {
                            this.callApiFunc()
                        })} />
                    </div>
                </div>}
                <div className="filter-separator" />
                <div className="filter-item">
                    <RegionDropDown
                        selected={[{ id: this.state.filterSettings.region } as Region]}
                        onSelectedItemsChange={(selected) => {
                            this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, region: selected.length > 0 ? selected[0].name : undefined } }), () => {
                                this.callApiFunc()
                            });
                        }}
                        className='region-selector'
                        dropDownOptions={{ fluid: true }}
                    />
                </div>
                <div className="filter-separator" />
                <div className="filter-item margin">
                    <span>{t("Messages.SOLD")}</span>
                    <div style={{ marginLeft: 'auto' }}>
                        <Switch
                            checked={!!this.state.filterSettings.sold}
                            onChange={(e: boolean) => {
                                this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, sold: e === true ? true : undefined } }), () => {
                                    this.callApiFunc()
                                });
                            }}
                            offColor={Colors.GREY}
                            onColor={Colors.DODGER_BLUE}
                            onHandleColor={'#fff'}
                            offHandleColor={'#fff'}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={24}
                            width={52}
                        />
                    </div>
                </div>
                <RegionContextConsumer>{({ currency }) => {
                    return <div>
                        <div className="filter-separator" />
                        <div className="filter-item margin">
                            <span style={{ display: "flex", flex: 1 }}>{t("Messages.MIN_PRICE")}</span>
                            <div style={{ display: "flex", flex: 1 }}>
                                <ModFindCurrencyInput
                                    currency={currency}
                                    value={this.state.filterSettings.price_min ? formatPriceI18n(this.state.filterSettings.price_min, currency) : ''}
                                    onValueChanged={(value) => {
                                        this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, price_min: unformatPriceI18n(value, currency).toString() } }), () => {
                                            this.callApiFunc()
                                        });
                                    }}
                                    colored
                                    containerSize={'xs'}
                                />
                            </div>
                        </div>
                        <div className="filter-separator" />
                        <div className="filter-item margin">
                            <span style={{ display: "flex", flex: 1 }}>{t("Messages.MAX_PRICE")}</span>
                            <div style={{ display: "flex", flex: 1 }}>
                                <ModFindCurrencyInput
                                    currency={currency}
                                    value={this.state.filterSettings.price_max ? formatPriceI18n(this.state.filterSettings.price_max, currency) : ''}
                                    onValueChanged={(value) => {
                                        this.setState((prevState) => ({ filterSettings: { ...prevState.filterSettings, price_max: unformatPriceI18n(value, currency).toString() } }), () => {
                                            this.callApiFunc()
                                        });
                                    }}
                                    colored
                                    containerSize={'xs'}
                                />
                            </div>
                        </div>
                        <div className="filter-separator" />
                    </div>
                }}
                </RegionContextConsumer>
            </>
        )
    }

    render() {
        return (
            <Container className="recently-listed">
                <Row style={{ display: 'block' }}>
                    {this.renderSaveSearchPopup()}
                    <div className="recently-listed-container">
                        <div className={`recently-listed-leftside-container${this.state.showFilter ? ' show-mobile' : ' hide-mobile'}`}>
                            <div className="select-bar">
                                <div className="item" onClick={() => {
                                    if (this.state.selected !== 'items') {
                                        this.step = 0
                                        this.setState({ selected: 'items', posts: undefined, users: undefined }, () => {
                                            this.callApiFunc()
                                        })
                                    }
                                }}>
                                    <span>{t("Messages.ITEMS")}</span>
                                    <div style={{ backgroundColor: this.state.selected === 'items' ? Colors.DODGER_BLUE : Colors.LIGHT_GREY }} />
                                </div>
                                <div className="item" onClick={() => {
                                    if (this.state.selected !== 'user') {
                                        this.setState({ selected: 'user', posts: undefined, users: undefined }, () => {
                                            this.callApiFunc()
                                        })
                                    }
                                }}>
                                    <span>{t("Messages.USER")}</span>
                                    <div style={{ backgroundColor: this.state.selected === 'user' ? Colors.DODGER_BLUE : Colors.LIGHT_GREY }} />
                                </div>
                            </div>
                            {this.state.selected == 'items' && this.renderFilter()}
                            <div className="button-row">
                                <div className="button-container">
                                    {Object.keys(removeDefaultFilterSettings(prepareSearchParams(this.state.filterSettings))).length > 0 &&
                                        <Button
                                            className='reset'
                                            text={t("Messages.RESET")}

                                            onClick={() => {
                                                this.resetAll()
                                            }}

                                            size='sm'
                                            rounded />}
                                </div>
                                <div className="button-container">
                                    {!(!this.state.search && Object.keys(removeDefaultFilterSettings(prepareSearchParams(this.state.filterSettings))).length == 0) &&
                                        <UserContextConsumer>{(user) => {
                                            return <Button className='save-search' text={t("Messages.SAVESEARCH")}
                                                onClick={() => {
                                                    if (user) {
                                                        this.setState({ saveSearchPopup: true })
                                                    } else {
                                                        LoginRequiredPopup.show()
                                                    }
                                                }} size='sm' rounded />
                                        }}</UserContextConsumer>}
                                </div>
                            </div>
                        </div>
                        <div className="recently-listed-rightside-container">
                            <div className="recently-listed-rightside-options-container">
                                <div className="filter">
                                    <img src={IC_FILTER} className="filter-icon" onClick={() => this.setState((prevState) => ({ showFilter: !prevState.showFilter }))} />
                                </div>
                            </div>
                            {/*Object.keys(removeDefaultFilterSettings(prepareSearchParams(this.state.filterSettings))).length > 0 && this.state.users && <Carousel items={this.state.users} type='USERS' title='Users' onItemClick={(item) => {
                                this.props.history.push(getUserUrl(item as User))
                            }} />*/}
                            {this.props.showRecents && <RecentItemsCarousel refItemCount={4} onItemClick={(item) => {
                                this.props.history.push(getPostUrl(item as Post))
                            }} />}
                            <ModFindList posts={this.state.posts} user={this.state.users} loading={this.state.loading} ad={this.state.ad} />
                        </div>
                    </div>
                </Row>
            </Container>
        )
    }
}

export default withRouter(class extends React.Component<RouteComponentProps<{ page: string }, {}, Partial<(FilterSettings & { search?: string })>>> {
    render() {
        return <RSwitch>
            <Route path={`/category/:categoryName*`} render={(props) => {
                return <Search {...this.props} {...props as any} page={this.props.match.params.page} />
            }} />
            <Route path={`/brand/:brandName*`} render={(props) => {
                return <Search {...this.props} {...props as any} page={this.props.match.params.page} />
            }} />
            <Route path={`/${this.props.match.params.page}`} render={(props) => {
                return <Search {...this.props} {...props as any} page={this.props.match.params.page} />
            }} />
        </RSwitch>
    }
})