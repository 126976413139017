import * as React from 'react';
import { Container, Row } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getPostUrl, getSearchUrlConfig, getUserUrl } from '../utils/path';
import { User, Post, Brand, PostsAPI, PostSortOrder, PostStatus, PersonalizeAPI, UsersAPI } from 'fullcircle-api';
import { UserContextType } from '../context/user';
import { locateByIP } from '../utils/location';
import { t } from 'i18next';
import { RecentItemsCarousel } from '../components/carousel/RecentItemsCarousel';
import PostCarousel from '../components/carousel/PostCarousel';
import { Favorite } from '../cache/favorite';
import UserBrandCarousel from '../components/carousel/UserBrandCarousel';
import { isUser } from '../utils/helper';
import { LoginRequiredPopup } from '../components/popup/LoginRequiredPopup';

export interface HomeProps extends RouteComponentProps {

}

interface HomeState {
    itemsNear: Post[],
    locationError?: string,
    showItemsNearYou: boolean,
    followingItems?: Post[]
    featuredItems?: Post[],
    vehiclePosts?: Post[]
    businesses?: User[]
    suggestions: (Brand | User)[]
    itemsForYou: Post[]
}
export default withRouter(class Home extends React.Component<HomeProps, HomeState> {

    static contextType = UserContextType
    context: User | undefined

    constructor(p: any) {
        super(p);
        this.state = {
            itemsNear: [],
            itemsForYou: [],
            suggestions: [],
            showItemsNearYou: false
        }
    }

    componentDidMount() {
        Favorite.getFavoritePosts()
        locateByIP().then((data) => {
            PostsAPI.searchPosts(PostSortOrder.Distance, 20, 0, {
                status: PostStatus.Public,
                location: {
                    latitude: data.location.lat, longitude: data.location.lng,
                    distance: 40 * 16.09
                }
            }).then(({ posts }) => {
                this.setState({ itemsNear: posts, showItemsNearYou: true })
            })
        }).catch(() => {
            this.setState({ showItemsNearYou: false })
        })
        PostsAPI.getFeaturedPosts(20, 0, true).then((posts) => {
            this.setState({ featuredItems: posts })
        }).catch((err) => { })
        PersonalizeAPI.getItemsForYou().then((items) => {
            this.setState({ itemsForYou: items })
        })
        if (this.context) {
            PersonalizeAPI.getItemsFollowing().then((items) => {
                this.setState({ followingItems: items })
            })
        }
        PersonalizeAPI.getSuggestions().then((suggestion) => {
            const randomItems = [...suggestion.brands, ...suggestion.users].map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value)
            this.setState({ suggestions: randomItems })
        })
        UsersAPI.getBusinesses().then((users) => {
            this.setState({ businesses: users })
        })
        this.getVehiclePosts()

    }

    private getVehiclePosts() {
        PostsAPI.searchPosts(PostSortOrder.Newest, 10, 0, { onlyVehicles: true }).then(({ posts }) => {
            this.setState({ vehiclePosts: posts })
        }).catch((e) => {
        })
    }

    render() {
        return (
            <Container>

                {this.state.featuredItems && <Row id={'posts_featured'}>
                    <PostCarousel title={t("Messages.FEATURED_ITEMS")} onItemClick={(item) => {
                        this.props.history.push(getPostUrl(item as Post))
                    }} items={this.state.featuredItems} onSeeAllClick={() => {
                        this.props.history.push('/featured')
                    }} />
                </Row>}

                {this.state.followingItems && this.state.followingItems.length > 0 && <Row>
                    <PostCarousel title={t("Messages.PEOPLE_BRANDS_FOLLOWING")} onItemClick={(item) => {
                        this.props.history.push(getPostUrl(item as Post))
                    }} items={this.state.followingItems} />
                </Row>}

                <div id="more_people_brands" style={{ paddingTop: '168px', marginTop: '-168px' }} />

                {this.state.suggestions.length > 0 && <div style={{ margin: '18px 0' }}>
                    <Row className='discover-container-row home-padding'>
                        <div className='discover-info'>
                            <div className='title'>
                                {t('Messages.FOLLOW')}
                            </div>
                            <div className='subtitle'>
                                {t('Messages.MORE_PEOPLE_BRANDS')}
                            </div>
                        </div>
                        <UserBrandCarousel refItemCount={4} onItemClick={(item) => {
                            if (isUser(item)) {
                                this.props.history.push(getUserUrl(item))
                            } else {
                                const [url, state] = getSearchUrlConfig({ brands: [item as Brand] })
                                this.props.history.push(url, state)
                            }
                        }} items={this.state.suggestions} />
                    </Row>
                </div>}


                <Row id={'posts'}>
                    <RecentItemsCarousel onSeeAllClick={() => {
                        this.props.history.push('/recently')
                    }} onItemClick={(item) => {
                        this.props.history.push(getPostUrl(item as Post))
                    }}
                    />
                </Row>

                {this.state.itemsForYou.length > 0 && <div style={{ margin: '18px 0' }}>
                    <Row id={'suggestions'} className='discover-container-row home-padding'>
                        <div className='discover-info green'>
                            <div className='title'>
                                {t('Messages.ITEMS_SELECTED')}
                            </div>
                            <div className='subtitle'>
                                {t('Messages.FOR_YOU')}
                            </div>
                        </div>
                        <PostCarousel refItemCount={3} onItemClick={(item) => {
                            this.props.history.push(getPostUrl(item as Post))
                        }} items={this.state.itemsForYou} />
                    </Row>
                </div>}

                {this.state.showItemsNearYou && <Row id={'posts_near'}>
                    <PostCarousel title={t("Messages.ITEMS_NEAR_YOU")} onItemClick={(item) => {
                        this.props.history.push(getPostUrl(item as Post))
                    }} items={this.state.itemsNear} noItemsText={this.state.locationError} />
                </Row>}

                {this.state.vehiclePosts && <Row id={'posts_vehivles'}>
                    <PostCarousel title={t("Messages.REECENTLY_LISTED_VEHICLES")} onItemClick={(item) => {
                        this.props.history.push(getPostUrl(item as Post))
                    }} items={this.state.vehiclePosts} />
                </Row>}
                {this.state.businesses && <Row id={'businsses'}>
                    <UserBrandCarousel title={t("Messages.BUSINESS_ON_MODFIND")} onItemClick={(item) => {
                        this.props.history.push(getUserUrl(item as User))
                    }} items={this.state.businesses} />
                </Row>}
                {this.state.businesses && this.context?.business == undefined && <Row>
                    <a className="default-link" style={{ marginLeft: 'auto', fontSize: '1.1rem', fontWeight: 600 }} href='#' onClick={(e) => {
                        e.preventDefault()
                        if (!this.context) {
                            LoginRequiredPopup.show()
                        } else {
                            this.props.history.push('/dealer-vendor')
                        }
                    }}>{t("Messages.BECOME_A_BUSINESS")}</a>
                </Row>}
            </Container>
        )
    }
})