import * as React from "react";
import { UserContextType } from "../../../context/user";
import { User, UsersAPI, PaymentsAPI, CardInterface, PayoutAPI } from "fullcircle-api";
import { SeparatorBox } from "../../generic/Box";
import ModFindInput from "../../generic/ModFindInput";

import toastr from 'toastr';
import { Title } from "../../generic/Title";
import Button from "../../generic/Button";
import { errorToString } from "../../../utils/helper";
import { UserSession } from "../../../utils/session";
import ModFindTextarea from "../../generic/ModFindTextArea";
import ModFindToast from "../../generic/ModFindToast";
import { PartialLocation } from "../../../utils/location";
import { formatPriceI18n, getCurrency } from "../../../utils/formater";
import { ModFindLoader } from "../../generic/ModFindLoader";
import { IC_ADD } from "../../../assets";
import ModFindPopUp from "../../popup/ModFindPopUp";
import { PaymentProvider } from "../../../utils/payment";
import { AddCreditCard } from "../payment/AddCreditCard";
import { ZipCodeInput } from "../../generic/ZipCodeInput";
import { StripeSellerAccount } from "fullcircle-api/lib/endpoints/payouts";
import { t } from "i18next";

interface AmbassadorData {
    link: string,
    amount: {
        balance: string;
        pending: string;
    },
    user_count: number
}

export class MyAccount extends React.Component<{ onRequestPayout: () => void }, {
    updating: boolean,
    full_name?: string,
    email?: string,
    car?: string,
    instagram?: string
    bio?: string,
    userType: 'user' | 'dealer' | 'vendor',
    phone?: string,
    location?: PartialLocation,
    currency?: string,
    region?: string,
    city?: string,
    zip?: string,
    cards?: CardInterface[],
    cardsLoading: boolean,
    provider?: PaymentProvider
    payoutAccount?: StripeSellerAccount,
    payoutAccountLoading?: boolean
    payoutLoading?: boolean
    ambassadorData?: AmbassadorData
    [PaymentProvider.CREDITCARD]: boolean,
    [PaymentProvider.APPLE_PAY]: boolean,
    [PaymentProvider.PAY_PAL]: boolean,
}> {

    static contextType = UserContextType
    context: User | undefined

    constructor(p: any) {
        super(p);
        this.state = {
            updating: false,
            userType: 'user',
            cardsLoading: true,
            payoutAccountLoading: true,
            [PaymentProvider.CREDITCARD]: false,
            [PaymentProvider.APPLE_PAY]: false,
            [PaymentProvider.PAY_PAL]: false,
        }
    }

    componentDidMount() {
        if (this.context) {
            this.setState({
                full_name: this.context.full_name,
                email: this.context.email,
                car: this.context.car_type,
                instagram: this.context.instagram_handle,
                phone: this.context.phone_number,
                currency: this.context.currency,
                region: this.context.region,
                city: this.context.city,
                zip: this.context.zip,
                bio: this.context.bio
            })
        }

        UsersAPI.getAmbassadorData().then((data) => {
            this.setState({ ambassadorData: data })
        })

        UsersAPI.getUserType().then((type) => {
            this.setState({ userType: type })
        })

        PayoutAPI.getAccount().then((account) => {
            this.setState({ payoutAccount: account, payoutAccountLoading: false })
        }).catch(err => {
            this.setState({ payoutAccountLoading: false })
        })

        this.getCards()

    }

    private getCards() {
        PaymentsAPI.getCards().then(cards => {
            this.setState({
                cards: cards.filter(c => {
                    return this.state.currency == undefined || c.currency == undefined || c.currency == this.state.currency
                })
            }, () => this.setState({ cardsLoading: false }))
        }).catch((error) => {
            this.setState({ cardsLoading: false })
            toastr.error(t("ErrorMessages.SomethingWentWrong"));
        })
    }

    private saveToast: ModFindToast | null = null

    render() {
        if (!this.context) {
            return null
        }
        return <div className='my-account action-list'>
            {this.state.provider && <ModFindPopUp onClose={() => {
                this.setState({ provider: undefined })
            }}>
                {this.renderProviderPopupContent()}
            </ModFindPopUp>}
            <div className='my-account-list-container'>
                {this.state.ambassadorData && <>
                    <Title>{t("Messages.AMBASSADOR_DETAILS")}</Title>
                    <SeparatorBox direction='row'>
                        <div className='item'>
                            {t("Messages.LINK")}
                        </div>
                        <div className='item'>
                            {this.state.ambassadorData.link}
                        </div>
                    </SeparatorBox>
                    <SeparatorBox direction='row'>
                        <div className='item'>
                            {t("Messages.AMOUNT_EARNED")}
                        </div>
                        <div className='item'>
                            {formatPriceI18n(this.state.ambassadorData.amount.balance, this.state.currency || 'usd')} ({formatPriceI18n(this.state.ambassadorData.amount.pending, this.state.currency || 'usd')} {t("Messages.PENDING")})
                        </div>
                    </SeparatorBox>
                    <SeparatorBox direction='row'>
                        <div className='item'>
                            {t("Messages.USERS_REGISTERED")}
                        </div>
                        <div className='item'>
                            {this.state.ambassadorData.user_count}
                        </div>
                    </SeparatorBox>
                </>}
                <Title>{t("Messages.ACCOUNT_DETAILS")}</Title>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {this.state.userType == 'user' ? t("Messages.FULL_NAME") : t("Messages.COMPANY_NAME")}
                    </div>
                    <div className='item'>
                        <ModFindInput disabled={this.state.updating}
                            placeholder={t("Messages.FULL_NAME")} colored
                            value={this.state.full_name || ''}
                            onValueChanged={(value) => {
                                this.setState({ full_name: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.EMAIL")}
                    </div>
                    <div className='item'>
                        <ModFindInput disabled={this.state.updating} type='email'
                            placeholder={t("Messages.EMAIL")} colored
                            value={this.state.email || ''}
                            onValueChanged={(value) => {
                                this.setState({ email: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.PHONE")}
                    </div>
                    <div className='item'>
                        <ModFindInput disabled={this.state.updating} type='number'
                            placeholder={t("Messages.PHONE")} colored
                            value={this.state.phone || ''}
                            onValueChanged={(value) => {
                                this.setState({ phone: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.CAR")}
                    </div>
                    <div className='item'>
                        <ModFindInput disabled={this.state.updating}
                            placeholder={t("Messages.CAR")} colored
                            value={this.state.car || ''}
                            onValueChanged={(value) => {
                                this.setState({ car: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.INSTA")}
                    </div>
                    <div className='item'>
                        <ModFindInput disabled={this.state.updating}
                            placeholder={t("Messages.INSTA")} colored
                            value={this.state.instagram || ''}
                            onValueChanged={(value) => {
                                this.setState({ instagram: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.BIO")}
                    </div>
                    <div className='item area'>
                        <ModFindTextarea disabled={this.state.updating}
                            placeholder={t("Messages.BIO")} colored
                            value={this.state.bio || ''}
                            onValueChanged={(value) => {
                                this.setState({ bio: value }, () => {

                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.LOCATION")}
                    </div>
                    <ZipCodeInput region={this.context.region_code} defaultZipOrCity={this.state.zip || this.state.city} onLocation={location => {
                        if (location)
                            this.setState({ zip: location?.zip, city: location?.city, location })
                    }} />
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.CURRENCY")}
                    </div>
                    <div className='item'>
                        <ModFindInput
                            disabled
                            placeholder={t("Messages.CURRENCY")}
                            colored
                            value={this.state.currency ? getCurrency(this.state.currency).code : ''}
                            onValueChanged={(value) => { }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.REGION")}
                    </div>
                    <div className='item'>
                        <ModFindInput
                            disabled
                            placeholder={t("Messages.REGION")}
                            colored
                            value={this.state.region || ''}
                            onValueChanged={(value) => { }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.PAYMENT")}
                    </div>
                    <div className='item card-container'>
                        {this.state.cardsLoading ? <ModFindLoader /> : this.state.cards ? this.state.cards.map((card, index, arr) => (
                            <div className="card-box" key={card.id}>
                                <div className="card-name">
                                    <span>{card.provider_name}</span>
                                    {card.last_4 && <span className="card-last-4">{t("Messages.ENDING_ON") + card.last_4}</span>}
                                </div>
                                {index == arr.length - 1 && <img src={IC_ADD} onClick={() => { this.setState({ provider: PaymentProvider.CREDITCARD }) }} />}
                            </div>
                        )) : null}
                    </div>
                </SeparatorBox>
                <div className="save-button-wrapper">
                    <Button size='sm' rounded text={t("Messages.SAVE")} onClick={() => {
                        this.setState({ updating: true })
                        let promises = new Array<Promise<any>>()
                        if (this.state.email != this.context!.email && this.state.email && this.state.email.trim().length > 0) {
                            promises.push(UsersAPI.udpateEmailAddress(this.state.email).then(() => {
                                //toastr.success('Email updated. You will receive a verification email!', undefined, { positionClass: 'toast-bottom-right' })
                                this.saveToast && this.saveToast.show(t("Messages.EMAIL_UPDATED"))
                            }).catch(err => {
                                //toastr.error(errorToString(err).join('\n'))
                                this.saveToast && this.saveToast.show(errorToString(err).join('\n'), true)
                            }))
                        }
                        promises.push(UsersAPI.updateProfile({
                            car_type: this.state.car,
                            instagram_handle: this.state.instagram,
                            full_name: this.state.full_name,
                            bio: this.state.bio,
                            phone_number: this.state.phone,
                            city: this.state.location && this.state.location.city,
                            zip: this.state.location && this.state.location.zip,
                        }).then((user) => {
                            UserSession.getUserUpdatedHandler()(user)
                            //toastr.success('Successfully updated your profile', undefined, { positionClass: 'toast-bottom-right' })
                            this.saveToast && this.saveToast.show(t("Messages.PROFILE_UPDATED"))
                        }).catch(err => {
                            //toastr.error(errorToString(err).join('\n'))
                            this.saveToast && this.saveToast.show(errorToString(err).join('\n'), true)
                        }))
                        Promise.all(promises).then(() => {
                            this.setState({ updating: false })
                        })
                    }} loading={this.state.updating} />
                    <ModFindToast ref={(ref) => this.saveToast = ref} position={'right'} />
                </div>
            </div>

            {<div className="bank-button-wrapper">
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.BANK")}
                    </div>
                    {this.state.payoutAccountLoading && <ModFindLoader />}
                    {!this.state.payoutAccountLoading && this.state.payoutAccount && <div className='item card-container'>
                        <div className="card-box remove-button-wrapper">
                            <div className="card-name">
                                <span>{this.state.payoutAccount.bank_data.bank_name}</span>
                            </div>
                            <Button size='sm' className="remove-button " classNameText="remove-button-text" rounded text='Remove' onClick={() => {
                                this.setState({ payoutLoading: true })
                                PayoutAPI.removeAccount().then(() => {
                                    this.setState({ payoutLoading: false, payoutAccount: undefined })
                                }).catch(err => {
                                    toastr.error(t("ErrorMessages.SomethingWentWrong"))
                                    this.setState({ payoutLoading: false })
                                })
                            }} loading={this.state.payoutLoading} />
                        </div>
                        <div className="request-button-wrapper">
                            <Button size='sm' rounded text={t("Messages.REQUEST_PAYOUT")} onClick={() => {
                                this.props.onRequestPayout()
                            }} loading={this.state.payoutLoading} />
                        </div>
                    </div>}
                    {!this.state.payoutAccountLoading && !this.state.payoutAccount && <div className='item card-container'>
                        <div className="connect-button-wrapper">
                            <Button size='sm' rounded text={t("Messages.CONNECT")} onClick={() => {
                                this.setState({ payoutLoading: true })
                                PayoutAPI.createAccount().then(({ url }) => {
                                    location.href = url
                                }).catch(err => {
                                    toastr.error(t("ErrorMessages.SomethingWentWrong"))
                                    this.setState({ payoutLoading: false })
                                })
                            }} loading={this.state.payoutLoading} />
                        </div>
                    </div>}
                </SeparatorBox>
            </div>}
        </div>
    }

    private renderProviderPopupContent() {
        switch (this.state.provider) {
            case PaymentProvider.CREDITCARD:
                return <AddCreditCard onAdded={(card) => {
                    let cards = this.state.cards ? this.state.cards.slice() : []
                    cards.push(card)
                    this.setState({ provider: undefined, cards })
                }} onCancel={() => {
                    this.setState({ provider: undefined })
                }} />
            default:
                return <span>{t("Messages.SOMETHING_WENT_WRONG")}</span>

        }
    }
}