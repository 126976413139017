import * as React from "react";
import { ModFindLoader } from "../generic/ModFindLoader";
import { User, RatingAPI, RatingCategoryRated, RatingCategory } from "fullcircle-api";

import toastr from 'toastr'
import { Title, SubTitle } from "../generic/Title";
import { RATING_FILLED, RATING } from "../../assets";
import Button from "../generic/Button";
import { t } from "i18next";

export interface RatingsProperties {
    user?: User,
    editMode?: boolean,
    ratingType?: 'seller' | 'buyer'
    onSubmit?: (ratings: { [key: string]: number }) => void
    popup?: boolean
}

export class ModFindRating extends React.Component<RatingsProperties, {
    loading: boolean
    editingCategories: { [key: string]: number }
    ratings?: RatingCategoryRated[],
    categories?: RatingCategory[],
}> {

    constructor(p: RatingsProperties) {
        super(p);
        this.state = {
            loading: true,
            editingCategories: {}
        }
    }

    componentDidMount() {
        if (this.props.user) {
            RatingAPI.getUserRatings(this.props.user.id).then(ratings => {
                this.setState({ ratings, loading: false })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toastr.error(t("ErrorMessages.SomethingWentWrong") as string)
            })
        } else {
            RatingAPI.getCategories(this.props.ratingType!).then(categories => {
                this.setState({ categories, loading: false })
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toastr.error(t("ErrorMessages.SomethingWentWrong") as string)
            })
        }
    }

    render() {
        if (this.state.loading) {
            return <ModFindLoader inMain={!this.props.popup} />
        }
        if (!this.state.categories && !this.state.ratings) {
            return <div />
        }
        if (this.props.user && this.state.ratings && this.state.ratings.length == 0) {
            return <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: 32 }}>
                <h5>{t("Messages.NO_RATINGS")}</h5>
            </div>
        }
        return <div className="rating-container">
            {!this.props.popup && <div className="rate-row header">
                <div className="rate-item">
                    <Title>{t("Messages.CRITERIA")}</Title>
                </div>
                <div className="rate-item">
                    <Title>{this.props.editMode ? t("Messages.RATING") : t("Messages.AVERAGE")}</Title>
                </div>
            </div>}
            {(this.state.categories || this.state.ratings)!.sort((i1, i2) => {
                return (i1.order || 1) - (i2.order || 1)
            }).map(category => {
                return <div className={(this.props.popup ? "rate-column" : "rate-row")}>
                    <div className="rate-item">
                        <SubTitle>{category.name}</SubTitle>
                    </div>
                    <div className="rate-item">
                        <FCRating
                            disabled={!this.props.editMode}
                            ratingCount={(category as RatingCategoryRated).rating || this.state.editingCategories[category.id] || 0} onPress={(value) => {
                                this.setState({ editingCategories: Object.assign({}, this.state.editingCategories, { [category.id]: value }) })
                            }}
                        />
                    </div>

                </div>
            }
            )}
            {this.props.onSubmit && <div className="button-container">
                <Button text='Submit' size='sm' rounded onClick={() => {
                    this.props.onSubmit!(this.state.editingCategories)
                }} />
            </div>}
        </div>
    }
}

interface FCRatingProperties {
    ratingCount: number;
    onPress?: (value: number) => void;
    style?: React.CSSProperties
    disabled: boolean
}

class FCRating extends React.Component<FCRatingProperties> {

    constructor(props: FCRatingProperties) {
        super(props);
        this.state = {
            ratingArray: []
        }
    }

    render() {
        return (
            <div style={{ ...this.props.style }} className='rate-container'>
                {[1, 2, 3, 4, 5].map((_value, index) => {
                    return (
                        <div style={this.props.disabled ? {} : { cursor: 'pointer' }} key={index} onClick={() => {
                            if (this.props.disabled) {
                                return
                            }
                            if (index == 0 && this.props.ratingCount == 1) {
                                this.props.onPress && this.props.onPress(0)
                            } else
                                this.props.onPress && this.props.onPress(index + 1)
                        }}>
                            <div>
                                <img className="rate-img"
                                    src={this.props.ratingCount >= (index + 1) ? RATING_FILLED : RATING} />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}