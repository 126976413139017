import { t } from "i18next";
import _ from "lodash";
import React from "react"

import { getCityOrZip, getLocationFromZipOrCity, PartialLocation } from "../../utils/location";
import ModFindInput from "./ModFindInput";

interface ZipCodeInputProps {
    region?: string
    style?: React.CSSProperties
    textContainerSize?: 'xs' | 'sm' | 'lg'
    defaultZipOrCity?: string
    onLocation: (location?: PartialLocation) => void,
    className?: string,
}

export class ZipCodeInput extends React.Component<ZipCodeInputProps, { zipOrCity: string, zipValid?: boolean, location?: PartialLocation }> {

    constructor(props: ZipCodeInputProps) {
        super(props);
        this.state = {
            zipOrCity: props.defaultZipOrCity || ''
        }
        if (this.props.defaultZipOrCity) {
            this.loadLocationForZip()
        }
        this.loadLocationForZip = _.debounce(this.loadLocationForZip, 200).bind(this)
    }

    componentDidUpdate(prevProps: ZipCodeInputProps) {
        if (prevProps.region != this.props.region && this.state.zipValid !== undefined) {
            this.loadLocationForZip()
        }
        if (prevProps.defaultZipOrCity != this.props.defaultZipOrCity && this.props.defaultZipOrCity && !this.state.zipOrCity) {
            this.setState({ zipOrCity: this.props.defaultZipOrCity }, () => {
                this.loadLocationForZip()
            })
        }
    }

    private loadLocationForZip() {
        const loadingZip = this.state.zipOrCity
        getLocationFromZipOrCity(this.state.zipOrCity, this.props.region).then((location) => {
            if (this.state.zipOrCity == loadingZip) {
                this.setLocation(location)
            }
        }).catch(err => {
            if (this.state.zipOrCity == loadingZip) {
                this.setState({ zipValid: false })
                this.props.onLocation(undefined)
            }
        })
    }

    private setLocation(location: PartialLocation) {
        this.setState({ location: location, zipValid: true, zipOrCity: getCityOrZip(location, this.state.zipOrCity) })
        this.props.onLocation(location)
    }

    private getLocationLabel(): string {
        let formattedAddress = this.state.location && this.state.location.city
        return formattedAddress || '';
    }

    render() {
        const { className, style } = this.props;
        return <div
            style={style}
            className={'zipcodeinput ' + (this.state.zipValid !== undefined ? 'no-bottom-border' : '') + (className ? ` ${className}` : '')}>
            <ModFindInput
                type={'string'}
                value={this.state.zipOrCity}
                placeholder={t("Messages.ZIP_CODE_OR_CITY")}
                colored containerSize={this.props.textContainerSize}
                rounded
                onValueChanged={(value) => {
                    this.setState(
                        { zipOrCity: value },
                        () => {
                            this.loadLocationForZip()
                        }
                    )
                }}
            />
            {this.state.zipValid !== undefined && <span className={`city-text ${this.state.zipValid === false ? 'error' : ''}`}>
                {this.state.zipValid === true && <span>{this.getLocationLabel()}</span>}
                {this.state.zipValid === false && <span>{t("ErrorMessages.ZipOrCityInvalid")}</span>}
            </span>}
        </div>
    }
}