import { Brand } from 'fullcircle-api';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import BrandCarousel from '../components/carousel/BrandCarousel';
import { encodeName } from '../utils/slug';


export function MakesPage() {

    const history = useHistory()

    return <Container>
        <Row id={'brands'}>
            <BrandCarousel onItemClick={(item) => {
                history.push('/brand/' + encodeName((item as Brand).name))
            }} defaultExpanded hideSeeAll />
        </Row>
    </Container>
}