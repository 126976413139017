import * as React from "react";

export interface TagBoxProps {
    selected: string[]
    onSelectedItemsChange: (items: string[]) => void
    disabled?: boolean
    style?: React.CSSProperties
    className?: string
    placeholder?: string
    colored?: boolean
    rounded?: boolean
}

export class TagBox extends React.Component<TagBoxProps, {
}> {

    constructor(props: TagBoxProps) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { colored, rounded, className, selected } = this.props

        return <div className={`TagBox ${rounded ? 'rd' : ''} ${className || ''}`}>
            <div className={`list-wrapper`}>

                {/* //^ Search Input */}
                <div className={`input-wrapper ${selected.length ? 'has-selection' : ''}`}>
                    {
                        selected.map((item, index) => {
                            return <div
                                key={index}
                                className="input-tag tag-row ">
                                <span className="input-tag-text">
                                    {item}
                                </span>
                                <span className="delete-wrapper" onClick={() => {
                                    this.props.onSelectedItemsChange(selected.filter(s => s != item))
                                }}>
                                    <i className="delete icon" />
                                </span>
                            </div>
                        })
                    }

                    <input
                        className={`model-type-input tag-row ${colored ? 'modFind-input' : ''} ${selected.length == 0 ? 'empty' : ''}`}
                        type="text"
                        placeholder={this.props.placeholder}
                        onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                                const value = e.currentTarget.value.trim()
                                if (value) {
                                    this.props.onSelectedItemsChange([...selected, value].unique())
                                    e.currentTarget.value = '';
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    }
}