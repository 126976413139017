import * as currencyFormatter from 'currency-formatter'
import moment from 'moment'
const LNG = navigator.language

function getSeparator(separatorType: 'decimal' | 'group') {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    const nf = Intl.NumberFormat()
    if (nf && 'formatToParts' in nf)
        return nf.formatToParts(numberWithGroupAndDecimalSeparator)?.find(part => part.type === separatorType)?.value;
    return '.'
}

export function formatPriceI18n(price: string | number | string[], currency: string): string {
    if (!currency) {
        currency = 'usd'
    }
    return currencyFormatter.format((unformatPriceI18n(price, currency)), { code: currency.toUpperCase(), locale: LNG, decimal: getSeparator('decimal'), thousand: getSeparator('group') });
}

export function unformatPriceI18n(price: string | number | string[], currency: string): number {
    if (typeof price === 'number') {
        return price
    }
    if (Array.isArray(price)) {
        price = price[0]
    }
    if (!Number.isNaN(Number(price))) {
        return Number(price)
    }
    const curr = currencyFormatter.findCurrency(currency.toUpperCase())
    return currencyFormatter.unformat((price.replace(curr.symbol, '')), { code: currency.toUpperCase(), locale: LNG, decimal: getSeparator('decimal'), thousand: getSeparator('group') });
}

export function getCurrency(currency: string) {
    return currencyFormatter.currencies.find(c => c.code.toUpperCase() === currency.toUpperCase()) || currencyFormatter.currencies.find(c => c.code === 'USD')!
}

export function formatHoursDays(date: Date): string {
    let diffDays: number = moment(date).diff(moment(new Date()), 'days');
    if (diffDays == 0) {
        let diffHours: number = moment(date).diff(moment(new Date()), 'hours');
        if (diffHours == 0) {
            let diffMinutes: number = moment(date).diff(moment(new Date()), 'minutes');
            if (diffMinutes == 0) {
                return 'Now';
            } else {
                return -diffMinutes + 'm';
            }
        } else {
            return -diffHours + 'h';
        }
    } else {
        return -diffDays + 'd';
    }
}
export function getDateDaysFromToday(date: Date): number {
    return moment(date)
        .startOf('days')
        .diff(moment(new Date()).startOf('days'), 'days');
}
export function formatRelative(
    date: Date,
    formatToday: (date: Date) => string,
    formatTomorrow: (date: Date) => string,
    formatYesterday: (date: Date) => string,
    formatNextWeek: (date: Date, daysDiff: number) => string,
    formatLastWeek: (date: Date, daysDiff: number) => string,
    formatWeeks: (date: Date, weeksDiff: number) => string,
    formatMonths: (date: Date, monthsDiff: number) => string
): string {
    var diff: number = getDateDaysFromToday(date);
    let mDiff: number = moment().diff(date, 'months');
    let wDiff: number = moment().diff(date, 'weeks');
    var valueReturn = '';
    if (diff == 0) {
        valueReturn = formatToday(date);
    } else if (diff == 1) {
        valueReturn = formatTomorrow(date);
    } else if (diff == -1) {
        valueReturn = formatYesterday(date);
    } else if (diff <= 7 && diff >= -7) {
        if (diff < 0) {
            valueReturn = formatLastWeek(date, diff);
        } else {
            valueReturn = formatNextWeek(date, diff);
        }
    } else if (mDiff > 0) {
        valueReturn = formatMonths(date, mDiff);
    } else {
        valueReturn = formatWeeks(date, wDiff);
    }
    return valueReturn;
}
export function formatRelativeDate(date: Date, formatToday?: string): string {
    return formatRelative(
        date,
        () => formatToday || 'Today',
        () => 'Tomorrow',
        () => 'Yesterday',
        (date, diff) => 'in ' + diff + ' day' + (diff == 1 ? '' : 's'),
        (date, diff) => -diff + ' day' + (diff == 1 ? '' : 's'),
        (date, diff) => {
            return diff + ' week' + (diff == 1 ? '' : 's');
        },
        (date, diff) => {
            return diff + ' month' + (diff == 1 ? '' : 's');
        }
    );
}

export function formatTime(date: Date): string {
    return moment(date).format('LT')
}

export function formatDate(date: Date): Date {
    return moment(date).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).toDate()
}

export function formatDay(date: Date): string {
    return moment(date).format('dddd, D. MMMM YYYY')
}

