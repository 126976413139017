export const Colors = {
    DODGER_BLUE: '#00AEEF',
    DODGER_BLUE56: 'rgba(0, 165, 255, 0.56)',
    DODGER_BLUE10: 'rgba(0, 165, 255, 0.1)',
    GREY: '#8E8E93',
    LIGHT_GREY: '#f6f6f6',
    RED: '#F81049',
    FB_BLUE: '#3B5998',
    GREEN: 'rgb(10, 152, 94)',
    BLACK: '#000000'
}