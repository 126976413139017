import * as React from 'react'
import { FeaturedItem } from 'fullcircle-api'
import { Meta } from '../../../cache/meta'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getPostUrl } from '../../../utils/path';

export default withRouter(class Banner extends React.Component<{
    style?: React.CSSProperties
} & RouteComponentProps, { items?: FeaturedItem[] }> {

    constructor(p: any) {
        super(p);
        this.state = {

        }
    }

    componentDidMount() {
        Meta.getBanner().then(items => {
            this.setState({ items })
        })
    }

    render() {
        const { style } = this.props
        const { items } = this.state
        return (
            <div className='banner'>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', ...style, cursor: 'pointer' }}>
                            {items && <Carousel showArrows useKeyboardArrows autoPlay
                                stopOnHover infiniteLoop interval={8000} transitionTime={500}
                                showThumbs={false} showStatus={false} showIndicators={false} dynamicHeight onClickItem={(index: number) => {
                                    const banner = items![index]
                                    if (banner.post_id) {
                                        this.props.history.push(getPostUrl({ id: banner.post_id }))
                                    } else if (banner.link) {
                                        window.open(banner.link, '_blank')
                                    }
                                }}>
                                {items.map(item => {
                                    return <div className={'banner-container ' + (item.link || item.post_id ? 'link' : '')} key={item.id}>
                                        <img src={item.preview_image_url} alt={item.title} />
                                        <div className='banner-text'>
                                            <div className='banner-title'>{item.title}</div>
                                            <div className='banner-subtitle'>{item.subtitle}</div>
                                        </div>
                                    </div>
                                })}
                            </Carousel>}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
})