import { t } from 'i18next';
import React from 'react';
import { IC_ATTACHEMENT, IC_CLOSE } from '../../../assets';

import { getImageOrientation } from '../../../utils/helper';
import { ModFindImage } from '../../grid/ModFindImageGrid';

export interface ModFindChatInputProps {
    sendChat: (message?: string, image?: ModFindImage) => void;
    disabled?: boolean
}

export default class ModFindChatInput extends React.Component<ModFindChatInputProps, {
    inputValue: string;
    image?: ModFindImage
}> {

    constructor(props: ModFindChatInputProps) {
        super(props)
        this.state = {
            inputValue: ''
        }
    }

    private inputChangeHandler = (event) => {
        this.setState({ inputValue: event.target.value });
    }

    private sumbitHandler = (event: React.KeyboardEvent) => {

        if (event.keyCode !== 13 || event.shiftKey || event.ctrlKey) return;

        event.preventDefault();
        this.submit()
    }

    private submit() {
        if (this.state.inputValue.length > 0 || this.state.image) {
            this.props.sendChat(this.state.inputValue, this.state.image);
            this.setState({ inputValue: '' });
        }
    }

    private fileInput: HTMLInputElement | null = null

    render() {
        return (
            <div className="chat-input-container">
                {this.state.image && <div className="img-section">
                    <img src={this.state.image.url} />
                    <div className="delete" onClick={() => {
                        this.setState({ image: undefined })
                    }}>
                        <img src={IC_CLOSE} />
                    </div>
                </div>}
                <div className="bottom-section">
                    <textarea className="chat-input-textarea" placeholder="Type your message here..." value={this.state.inputValue} onKeyDown={this.sumbitHandler} onChange={this.inputChangeHandler} />
                    <div className="chat-send">
                        <span onClick={() => this.submit()}>{t("Messages.SEND")}</span>
                    </div>
                    <div className="chat-input-image" onClick={() => {
                        this.fileInput && this.fileInput.click()
                    }}>
                        <img src={IC_ATTACHEMENT} />
                    </div>
                    <input
                        disabled={this.props.disabled}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => this.selectImage(e)}
                        accept="image/png, image/gif, image/jpeg, video/*, video/mp4"
                        ref={ele => (this.fileInput = ele)}
                    />
                </div>

            </div>

        )
    }

    private selectImage(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        let files = e.target.files
        let fileOrientation: number = 1
        let orientation = new Promise<number>(
            function (resolve, reject) {
                if (files && files[0]) {
                    getImageOrientation(files[0], function (orientation: number) {
                        fileOrientation = orientation
                        resolve(orientation)
                    })
                } else {
                    reject('File is undefined')
                }

            }
        )
        orientation.then((value) => {
            fileOrientation = value
        }).catch(err => {
        }).finally(() => {
            if (files && files[0]) {
                let url = URL.createObjectURL(files[0])
                let image: ModFindImage = {
                    file: files[0],
                    url,
                    type: files[0].type.split('/')[0],
                    orientation: fileOrientation,
                    position: 0
                }
                this.setState({ image })
            }

        })
    }
}
