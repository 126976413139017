import * as React from "react";
import ModFindPopUp, { TitleMessageContent } from './ModFindPopUp';

import { t } from "i18next";

export class VerificationRequiredPopup extends React.Component<{}, { visible: boolean }> {

    static _instance: VerificationRequiredPopup

    static show() {
        VerificationRequiredPopup._instance && VerificationRequiredPopup._instance.setState({ visible: true })
    }

    constructor(p: any) {
        super(p);
        VerificationRequiredPopup._instance = this;
        this.state = {
            visible: false
        }
    }

    render() {
        if (this.state.visible == false) {
            return null
        }
        return <ModFindPopUp onClose={() => this.setState({ visible: false })}>
            <TitleMessageContent icon='info'
                title={t("Messages.SIGNUP_TITLE")}
                message={t("Messages.SIGNUP_MESSAGE")} />
        </ModFindPopUp>
    }

}