import React from 'react';
import { Post, AdBanner, User } from 'fullcircle-api';

import { ModFindLoader } from '../generic/ModFindLoader';
import { AdBannerView } from '../views/banner/AdBanner';
import { UserContextType } from '../../context/user';
import { t } from 'i18next';
import { ModFindPostListItem } from './ModFindPostListItem';
import { ModFindUserListItem } from './ModFindUserListItem';

export interface ModFindListProps {
    posts?: Post[];
    user?: User[];
    loading?: boolean;
    ad?: AdBanner
}

export default class ModFindList extends React.Component<ModFindListProps, {}> {

    static contextType = UserContextType
    context: React.ContextType<typeof UserContextType>

    constructor(props: ModFindListProps) {
        super(props)
    }

    private renderPostList = () => {
        if (this.props.posts === undefined) return null;
        const postList = this.props.posts.map(post => (
            <ModFindPostListItem key={post.id} post={post} currentUser={this.context} />
        ))
        if (this.props.ad && postList.length > 0) {
            postList.splice(1, 0, <AdBannerView key={'mf_add'} ad={this.props.ad} />)
        }
        return (postList.length < 1) ? <div className='empty'>
            <h5>
                {t("Messages.NO_ITEMS")}
            </h5>
        </div> : postList;
    }

    private renderUserList = () => {
        if (this.props.user === undefined) return null;
        const userList = this.props.user.map((user) => (
            <ModFindUserListItem key={user.id} user={user} />
        ))
        if (this.props.ad && userList.length > 0) {
            userList.splice(1, 0, <AdBannerView key={'mf_add'} ad={this.props.ad} />)
        }
        return (userList.length < 1) ? <div className='empty'>
            <h5>
                {t("Messages.NO_USERS_FOUND")}
            </h5>
        </div> : userList;
    }

    render() {
        return (
            <div className="list-container">
                {this.renderPostList()}
                {this.renderUserList()}
                {this.props.loading === true && <div style={{ marginTop: 10, marginBottom: 20 }}>
                    <ModFindLoader />
                </div>}
            </div>
        )
    }
}