import React from 'react';
import { User, ChatAPI, Post } from 'fullcircle-api';
import ModFindChatInput from './ModFindChatInput';
import { Chat, Message } from '../../../api/database';
import ChatHandler, { ChatHandlerEvents, isMessageRead, sortMessages } from '../../../api/chathandller';
import { IC_PROFILE, IC_CLOSE, IC_ARROW_DOWN_COLOR } from '../../../assets';
import { formatTime, formatDate, formatDay, formatPriceI18n } from '../../../utils/formater';
import { UserContextType } from '../../../context/user';
import ClipLoader from 'react-spinners/ClipLoader'
import { ModFindImage } from '../../grid/ModFindImageGrid';
import { Content } from '../../../api/events';
import { getPostImageUrl } from '../../../utils/helper';

import { t } from 'i18next';
import { Link } from 'react-router-dom';

interface MessageSection {
    date: Date,
    messages: Message[]
}
export default class ModFindChat extends React.Component<{ chat: Chat, user: User, closeChat: (chat: Chat) => void, onChatClick: (Chat: Chat) => void }, {
    notifications: number;
    messages: Array<Message>;
    minimized: boolean;
    removed: boolean;
    sendFailed: boolean,
    tmpMsg?: string
    tmpImg?: ModFindImage
    sendImgFailed: boolean
    messageSections: MessageSection[]
    online: boolean,
}> {

    constructor(props) {
        super(props)
        this.state = {
            notifications: 0,
            messages: [],
            minimized: false,
            removed: false,
            sendFailed: false,
            messageSections: [],
            sendImgFailed: false,
            online: false,
        }
        this.chatDidUpdate = this.chatDidUpdate.bind(this);
        this.onlineListener = this.onlineListener.bind(this)
    }

    static contextType = UserContextType
    context: User | undefined;

    private chatContainer: any = React.createRef();

    chatDidUpdate(message?: Message) {
        if (!message || message.chat_id == this.props.chat.id) {
            const newMessages = sortMessages(ChatHandler.instance()!.getChats().find(c => c.id == this.props.chat.id)!.messages)
            if (this.state.minimized) {
                this.setState({ notifications: newMessages.filter(f => !isMessageRead(f, this.props.user)).length });
            } else {
                ChatHandler.instance()?.markMessagesAsRead(newMessages.filter(m => !isMessageRead(m, this.props.user)))
            }
            let sections: MessageSection[] = []
            let dates: Date[] = []

            newMessages.map((message) => {
                let day = formatDate(message.created_at)
                if (!dates.find((date) => date.getTime() == day.getTime())) {
                    dates.push(day)
                }
            })
            dates.map((date) => {
                let filteredMessages = newMessages.filter((message) => formatDate(message.created_at).getTime() == date.getTime())
                sections.push({
                    date: date,
                    messages: filteredMessages
                })
            })
            this.setState({ messageSections: sections }, () => {
                this.chatContainer.current.scrollTo(0, this.chatContainer.current.scrollHeight);
            });
        }
    }

    onlineListener({ online }: Content.Incoming.OnlineStatusDidChange) {
        this.setState({ online })
    }


    componentDidMount() {
        this.chatDidUpdate()
        ChatHandler.instance() && ChatHandler.instance()?.addListener(ChatHandlerEvents.MessageUpdate, this.chatDidUpdate);
        this.chatContainer.current.scrollTo(0, this.chatContainer.current.scrollHeight);
        const otherUser = this.getOtherUser(this.props.chat);
        otherUser && ChatHandler.instance()?.subscribeOnlineStatus(otherUser.id, this.onlineListener).then(({ online }) => {
            this.setState({ online })
        })
    }

    componentWillUnmount() {
        ChatHandler.instance() && ChatHandler.instance()?.removeListener(ChatHandlerEvents.MessageUpdate, this.chatDidUpdate);
        ChatHandler.instance() && ChatHandler.instance()?.unsubscribeOnlineStatus(this.props.user.id, this.onlineListener)
    }

    private renderChatItem = () => (
        <ChatProductHeader post={this.props.chat.post} onClick={() => {
            this.props.onChatClick(this.props.chat)
        }} />
    );

    private renderChatMessages = () => {
        return (
            <div className="chat-message-container" ref={this.chatContainer}>
                <div className="chat-messsage">
                    <div className="chat-messsage-contents-container">
                        <span className='chat-message-text  disclaimer'>{t("Messages.SCAM_DISCLAIMER")}</span>
                    </div>
                </div>
                {this.state.messageSections.map((section, i) => (
                    <span key={`Section_${i}`}>
                        <div className="date-section">{formatDay(section.date)}</div>
                        {section.messages.map((message, j) => (
                            <ModFindChatMessage
                                key={`Section_${i}_Message_${j}`}
                                message={message} userId={message.user}
                                userName={this.getUserValueOrUndefined(message.user, 'full_name') as string}
                                profileImg={this.getUserValueOrUndefined(message.user, 'profile_image_thumbnail_url') || IC_PROFILE}
                                ownMessage={this.isOwnMessage(message.user)}
                            />
                        ))}
                    </span>
                ))}
                {this.state.tmpMsg && this.context && <div className="chat-messsage">
                    <div className="chat-messsage-profile-container">
                        <img className='chat-message-profile-img' src={this.getUserValueOrUndefined(this.context.id, 'profile_image_thumbnail_url') || IC_PROFILE} alt="" />
                    </div>
                    <div className="chat-messsage-contents-container">
                        <div style={{ flexDirection: 'row' }}>
                            <span className="chat-profile">{this.getUserValueOrUndefined(this.context.id, 'full_name') as string}</span>
                            {this.state.sendFailed ? <span className="chat-message-text" style={{ marginLeft: 8 }}>{`${formatTime(new Date())} ` + t("ErrorMessages.FAILED")}</span> : <span style={{ marginLeft: 8 }}><ClipLoader size={10} /></span>}
                        </div>
                        <span className='chat-message-text'>{this.state.tmpMsg}</span>
                    </div>
                </div>}
                {this.state.tmpImg && this.context && <div className="chat-messsage">
                    <div className="chat-messsage-profile-container">
                        <img className='chat-message-profile-img' src={this.getUserValueOrUndefined(this.context.id, 'profile_image_thumbnail_url') || IC_PROFILE} alt="" />
                    </div>

                    <div className="chat-messsage-contents-container">
                        <div style={{ flexDirection: 'row' }}>
                            <span className="chat-profile">{this.getUserValueOrUndefined(this.context.id, 'full_name') as string}</span>
                            {this.state.sendFailed ? <span className="chat-message-text" style={{ marginLeft: 8 }}>{`${formatTime(new Date())} ` + t("ErrorMessages.FAILED")}</span> : <span style={{ marginLeft: 8 }}><ClipLoader size={10} /></span>}
                        </div>
                        {this.state.tmpImg.type == 'image' && (
                            <div className="chat-message-image-container">
                                <img src={this.state.tmpImg.url} alt="uploaded image" />
                            </div>
                        )}
                        {this.state.tmpImg.type == 'video' && (
                            <div className="chat-message-image-container">
                                <video src={this.state.tmpImg.url} />
                            </div>
                        )}
                    </div>
                </div>}
            </div>
        )
    }

    private isOwnMessage(user: string) {
        return this.context && this.context.id == user
    }

    private handlerMinimization = (e) => {
        e.stopPropagation();
        this.setState((prevState => ({ minimized: !prevState.minimized })), () => {
            if (!this.state.minimized) {
                ChatHandler.instance()?.markMessagesAsRead(this.state.messages.filter(m => !isMessageRead(m, this.props.user))).then(() => {
                })
                this.setState({ notifications: 0 })
            }
        });
    }

    private getUserValueOrUndefined(userId: string, key: keyof User) {
        const user = this.props.chat.users.find(u => u.id == userId)
        return user ? user[key] : undefined
    }

    private getOtherUserId(chat: Chat): string {
        let otherUserId = '';
        let user = this.getOtherUser(chat);
        otherUserId = user ? user.id : '';
        return otherUserId;
    }

    private getOtherUser(chat: Chat): User | undefined {
        let otherUser: User | undefined;
        chat.users.forEach((user) => {
            if (user.id != this.props.user.id) {
                otherUser = user;
            }
        });
        return otherUser;
    }

    private sendMessage = (message?: string, image?: ModFindImage) => {
        this.setState({ tmpMsg: message, sendFailed: false, tmpImg: image, sendImgFailed: false }, () => {
            this.chatContainer.current.scrollTo(0, this.chatContainer.current.scrollHeight);
        })
        if (message) {
            ChatHandler.instance()?.sendMessage(this.props.chat.post_id, message, this.getOtherUserId(this.props.chat), 'text').then(() => {
                this.setState({ tmpMsg: undefined })
            }).catch(() => {
                this.setState({ sendFailed: true })
            })
        }
        if (image) {
            let promise = Promise.resolve({ url: '' })
            if (image.type == 'video') {
                promise = ChatAPI.uploadPostVideo(image.file)
            } else {
                promise = ChatAPI.uploadPostImage(image.file)
            }
            promise.then((val) => {
                ChatHandler.instance()?.sendMessage(this.props.chat.post_id, val.url, this.getOtherUserId(this.props.chat), image.type == 'video' ? 'video' : 'image').then(() => {
                    this.setState({ tmpImg: undefined })
                }).catch(() => {
                    this.setState({ sendImgFailed: true })
                })
            }).catch((e) => {
                this.setState({ sendImgFailed: true })
            })
        }

    }

    private getBadge(badge: number) {
        if (badge > 9) {
            return '9+'
        }
        return badge
    }

    render() {
        return (
            <div className={`chat-container ${this.state.removed ? "removed" : this.state.minimized ? "closed" : "open"}`}>

                <div className="chat-header" onClick={(e) => {
                    if (!this.state.minimized) return;
                    this.handlerMinimization(e);
                }}>
                    <span className='chat-header-close' onClick={() => {
                        this.setState({ removed: true }, () => {
                            setTimeout(() => {
                                this.props.closeChat(this.props.chat)
                            }, 500);
                        })
                    }
                    }><img src={IC_CLOSE} /></span>
                    <div className="header-name-online-wrapper">
                        <Link to={`/profile/${this.getOtherUserId(this.props.chat)}`}>
                            <span
                                className='chat-header-name'>
                                {this.getUserValueOrUndefined(this.getOtherUserId(this.props.chat), 'full_name')}
                            </span>
                        </Link>
                        {this.state.online && <span className="online-indicator"></span>}
                    </div>


                    {this.state.notifications > 0 && <div className={'chat-header-notification'}>
                        <span className="chat-header-notification-text">{this.getBadge(this.state.notifications)}</span>
                    </div>}
                    <span className={'chat-header-minimize ' + (this.state.minimized ? "minimized" : "")}
                        onClick={this.handlerMinimization}><img src={IC_ARROW_DOWN_COLOR} /></span>
                </div>
                {this.renderChatItem()}
                {this.renderChatMessages()}
                {this.getOtherUser(this.props.chat)?.blocked && <div className='chat-blocked-message'>
                    {t("Messages.ACCOUNT_BLOCKED")}
                </div>}
                {!(this.getOtherUser(this.props.chat)?.blocked) && <ModFindChatInput sendChat={this.sendMessage} />}

            </div>
        )
    }
}

interface ModFindChatMessageProps {
    message: Message
    userId: string
    profileImg: string
    userName: string
    ownMessage?: boolean
}

class ModFindChatMessage extends React.Component<ModFindChatMessageProps, {
    isUnread: boolean
}> {

    constructor(props: ModFindChatMessageProps) {
        super(props);
        this.state = {
            isUnread: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { message, profileImg, userName, ownMessage, userId } = this.props

        return (
            <div className="chat-messsage">
                <div className="chat-messsage-profile-container">
                    <Link to={`/profile/${userId}`}>
                        <img className='chat-message-profile-img' src={profileImg} alt="" />
                    </Link>
                </div>

                <div className="chat-messsage-contents-container">
                    <Link to={`/profile/${userId}`}>
                        <div className="chat-profile">{(userName).replace(/ /g, "\u00a0")}</div>
                    </Link>
                    <div className="chat-message-text">{`${formatTime(message.created_at)}\u00a0${ownMessage ? message.read ? t("Messages.READ") : t("Messages.DELIVERED") : ''}`}</div>
                    <div className={!message.read && !ownMessage ? ' chat-message-unread' : ''} />
                    {message.type == 'text' && <span className='chat-message-text'>{message.content}</span>}
                    {message.type == 'image' && (
                        <div className="chat-message-image-container">
                            <img src={message.content} alt="uploaded image" />
                        </div>
                    )}
                    {message.type == 'video' && (
                        <div className="chat-message-image-container">
                            <video src={message.content} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const ChatProductHeader: React.FunctionComponent<{ onClick?: () => void, post: Post }> = (props) => {
    return <div className="product-header-container" onClick={() => {
        props.onClick && props.onClick()
    }}>
        <img className="item-img" src={getPostImageUrl(props.post)} alt="item" />
        <div className="item-info">
            <span className="item-title">{props.post ? props.post.title : ""}</span>
            <span className="item-price">{props.post && !props.post.price_on_request ? formatPriceI18n(props.post.price, 'usd') : ""}</span>
        </div>
    </div>
}