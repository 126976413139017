import * as React from 'react'

export interface ModFindInputProps extends React.InputHTMLAttributes<any> {
    placeholder?: string
    disabled?: boolean
    style?: React.CSSProperties
    colored?: boolean
    rounded?: boolean
    containerSize?: 'xs' | 'sm' | 'lg'
    info?: {
        err?: boolean
        text: string
    }
    type?: string
    inputStyle?: React.CSSProperties;
    inputClassName?: string;
    onValueChanged?: (value: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    onEnter?: () => void;
}

export default class ModFindInput extends React.Component<ModFindInputProps, {
    value: string | number | string[] | undefined
}> {

    inputeRef?: HTMLInputElement | null

    constructor(props: ModFindInputProps) {
        super(props)
        this.state = {
            value: props.value
        }
        this.valueChanged = this.valueChanged.bind(this)
    }

    focus() {
        this.inputeRef?.focus()
    }

    componentDidUpdate(props: ModFindInputProps) {
        if (this.props.value != this.state.value) {
            this.setState({ value: this.props.value })
        }
    }

    private valueChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ value: event.target.value })
        this.props.onValueChanged && this.props.onValueChanged(event.target.value)
    }

    setText(text: string) {
        this.setState({ value: text })
    }

    render() {
        const { placeholder, style, colored, rounded, containerSize, info, type, inputStyle, onValueChanged, onEnter, className, inputClassName, ...rest } = this.props
        return (
            <div
                className={className}
                style={{ width: "100%", ...style }}
            >
                <input ref={ref => this.inputeRef = ref}
                    className={`${colored ? 'modFind-input' : ''} ${rounded ? 'rd' : ''} ${inputClassName || ''}`}
                    style={{ ...inputStyle, width: '100%', height: containerSize === 'lg' ? 52 : containerSize === 'xs' ? 32 : 42, }}
                    type={type}
                    disabled={this.props.disabled}
                    value={this.state.value}
                    placeholder={placeholder}
                    onChange={this.valueChanged}
                    onKeyDown={(e) => {
                        if (onEnter && e.key == 'Enter') {
                            onEnter()
                        }
                    }}
                    onFocus={() => {
                        this.props.onFocus && this.props.onFocus()
                    }}
                    onBlur={() => {
                        this.props.onBlur && this.props.onBlur()
                    }} {...rest}
                />
                {info && <span className={`modFind-input-info${info.err ? ' err' : ''}`}>{info.text}</span>}
            </div>

        )
    }
}