import { UserAddress } from 'fullcircle-api';
import Geocode from "react-geocode";
import MemoryCache from 'lscache'
import { t } from 'i18next';

export const API_KEY = 'AIzaSyAv2dSrN7gv6P0y_vhFPHWcRqqV1m2HIGk'
Geocode.setApiKey(API_KEY)

export interface PartialLocation {
    city: string;
    zip?: string;
    state: string;
}
export interface LocationDetails {
    city?: string;
    zip?: string;
    state?: string;
    street?: string;
    streetNumber?: string
    country?: string;
    formatted_address: string;
    location: {
        latitude: number;
        longitude: number;
    }
}

export type Types = 'street_number' | 'postal_code' | 'route' | 'sublocality_level_1' | 'sublocality' | 'political' | 'locality' | 'administrative_area_level_2' | 'administrative_area_level_1' | 'country' | 'subpremise' | 'neighborhood'
export interface AddressComponent {
    long_name: string,
    short_name: string,
    types: [Types]
}
export interface Geometry {
    location: {
        lat: number | (() => number)
        lng: number | (() => number)
    }
}

export interface GoogleDetails {
    address_components?: AddressComponent[],
    formatted_address?: string,
    geometry?: Geometry,
    place_id?: string
}

export const LoadingMessages = {
    IN_RANGE: 'You’re in range for free delivery!',
    NOT_IN_RANGE: 'You’re not in range for free delivery!',
    LOADING: 'Loading...',
    ERROR_CALCULATING_DISTANCE: 'Couldn\'t calculate distance!',
    LOCATION_NOT_LOADED: 'Couldn\'t find your location!',
    ADDRESS_NOT_LOADED: 'Couldn\'t load adress for your location!',
    INVALID_DEFAULT_ADDRESS: 'Couldn\'t load location for your address! Please check your address in the settings or set a new address here',
    LOCATION_DISABLED: 'Enable location or select location manually'
}

export function checkValidAddress(details: LocationDetails, requiredKeys?: (keyof LocationDetails)[]): string[] | true {
    let keys = requiredKeys || ['country', 'city', 'street', 'streetNumber']
    let missingKeys: string[] = []
    keys.forEach((key) => {
        if (!details[key]) {
            missingKeys.push(key)
        }
    })
    return missingKeys.length == 0 ? true : missingKeys
}

export function convertGoogle(details: GoogleDetails, streetNumberOptional?: number): LocationDetails | undefined {
    if (!details.geometry) { return undefined }

    let addressComp = details.address_components
    let city = addressComp && addressComp.find(comp => comp.types.includes('locality'))
    if (!city) {
        city = addressComp && addressComp.find(comp => comp.types.includes('sublocality_level_1'))
    }
    if (!city) {
        city = addressComp && addressComp.find(comp => comp.types.includes('political'))
    }
    let postalCode = addressComp && addressComp.find(comp => comp.types.includes('postal_code'))
    let state = addressComp && addressComp.find(comp => comp.types.includes('administrative_area_level_1'))
    if (city?.long_name == 'Singapore' && !state) {
        state = city
    }
    let street = addressComp && addressComp.find(comp => comp.types.includes('route'))
    let streetNumber = addressComp && addressComp.find(comp => comp.types.includes('street_number'))
    if (!streetNumber && streetNumberOptional != undefined) {
        streetNumber = { long_name: streetNumberOptional.toString(), short_name: streetNumberOptional.toString(), types: ['street_number'] }
    }
    let country = addressComp && addressComp.find(comp => comp.types.includes('country'))
    if (!details.formatted_address) {
        return undefined
    }
    return {
        country: country && country.long_name,
        location: {
            longitude: (typeof details.geometry.location.lng === 'function') ? details.geometry.location.lng() : details.geometry.location.lng,
            latitude: (typeof details.geometry.location.lat === 'function') ? details.geometry.location.lat() : details.geometry.location.lat,
        },
        formatted_address: details.formatted_address,
        city: city && city.long_name,
        zip: postalCode && postalCode.long_name,
        state: state && state.long_name,
        street: street && street.long_name,
        streetNumber: streetNumber && streetNumber.long_name
    }
}

export function hasRegionPostCode(region?: string) {
    return !(region?.toLowerCase() == 'ua' || region?.toLowerCase() == 'hk') // United Arab Emirates and Hong Kong dont have post codes
}

export async function getLocationFromZipOrCity(zipOrZity: string, region?: string): Promise<PartialLocation> {
    if (hasRegionPostCode(region)) {
        return getFromZip(zipOrZity, region).catch(() => getFromCity(zipOrZity, region))
    } else {
        return getFromCity(zipOrZity, region)
    }
}

export function getCityOrZip(location: PartialLocation, state: string) {
    if (compareStr(location.zip, state)) {
        return state
    }
    if (compareStr(location.city, state)) {
        return state
    }
    return location.zip || location.city
}

function compareStr(str1: string | undefined, str2: string) {
    return str1?.trim().toLowerCase() == str2.trim().toLowerCase()
}

async function getFromZip(zip: string, region?: string): Promise<PartialLocation> {
    let components = [`postal_code:${zip}`]
    if (region) {
        components.push(`country:${region}`)
        if (region.toLowerCase() == 'us') {
            components.push(`country:pr`)
        }
    }
    const url = `https://maps.google.com/maps/api/geocode/json?components=${components.join('|')}&key=${API_KEY}`
    const data = await fetch(url).then((r) => r.json())
    let location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    } else if (!location.city || !location.state || !location.zip) {
        throw new Error('Couldn\'t determine location');
    }
    if (!compareStr(location.zip, zip)) {
        throw new Error('Couldn\'t determine location');
    }
    return location as PartialLocation;
}

async function getFromCity(city: string, region?: string): Promise<PartialLocation> {
    let components = [`locality:${city}`]
    if (region) {
        components.push(`country:${region}`)
        if (region.toLowerCase() == 'us') {
            components.push(`country:pr`)
        }
    }
    const url = `https://maps.google.com/maps/api/geocode/json?components=${components.join('|')}&key=${API_KEY}`
    const data = await fetch(url).then((r) => r.json())
    let location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    } else if (!location.city || !location.state) {
        throw new Error('Couldn\'t determine location');
    }
    if (!location.zip) {
        try {
            location.zip = (await getLocationDetails(location.location.latitude, location.location.longitude)).zip
        } catch (error) {

        }
    }
    if (!compareStr(location.city, city)) {
        throw new Error('Couldn\'t determine location');
    }
    return location as PartialLocation;
}

export async function loadLocationForAddress(address: { address_line_1: string }): Promise<LocationDetails> {
    const data = await Geocode.fromAddress(address.address_line_1);
    let location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    }
    return location;
}

export function getLocationDetails(latitude: number, longitude: number): Promise<LocationDetails> {
    return Geocode.fromLatLng(latitude, longitude).then(data => {
        let location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
        if (!location) {
            throw new Error('Couldn\'t determine location');
        }
        return location
    })
}

export async function loadLocationForLatLng(lat: number, lng: number): Promise<LocationDetails> {
    const data = await Geocode.fromLatLng(lat, lng);
    let location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    }
    return location;
}

export function addressToString(defaultAddress: UserAddress) {
    let address = (defaultAddress.address_line) + '\n' +
        defaultAddress.city + ' ' + defaultAddress.state + ' ' + (defaultAddress.zip !== null ? (defaultAddress.zip + '') : '')
        + '\n' + defaultAddress.country
    if (defaultAddress.first_name || defaultAddress.last_name) {
        address = `${defaultAddress.first_name || ''} ${defaultAddress.last_name || ''}`.trim() + '\n' + address
    }
    return address
}


function _loadLocation(): Promise<LocationDetails> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                resolve(getLocationDetails(position.coords.latitude, position.coords.longitude))
            }, (error) => {
                reject(error)
            },
            { enableHighAccuracy: false, timeout: 5000 }
        );
    })

}

export function getCurrentLocation(): Promise<LocationDetails> {
    return _loadLocation().catch(err => {
        throw new Error(`Loading ${t("Messages.LOCATION_DISABLED")}`)
    })

}

export function validCords(lat?: number, lng?: number): boolean {
    return lat !== undefined && Math.abs(lat) <= 90 && lng !== undefined && Math.abs(lng) <= 180;
}

export function validCircle(lat?: number, lng?: number, radius?: number): boolean {
    return validCords(lat, lng) && radius !== undefined && radius > 0;
}


export function locateByIP(): Promise<{ location: { lat: number, lng: number } }> {
    const LOCAL_STORAGE_KEY = 'last_location'

    if (MemoryCache.get(LOCAL_STORAGE_KEY)) {
        try {
            return Promise.resolve(MemoryCache.get(LOCAL_STORAGE_KEY))
        } catch (error) {

        }
    }
    return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`, {
        method: 'POST'
    }).then((r) => r.json()).then((data) => {
        MemoryCache.set(LOCAL_STORAGE_KEY, data, 10)
        return data
    })
}

export interface GeoData {
    ip: string
    city: string
    region: string
    region_code: string
    country: string,
    country_code: string
    country_code_iso3: string
    country_capital: string
    country_tld: string
    country_name: string
    continent_code: string
    in_eu: boolean
    postal: string
    // latitude: number
    // longitude: number
    timezone: string
    utc_offset: string
    country_calling_code: string
    currency: string
    currency_name: string
    languages: string
    country_area: number
    country_population: number
    asn: string
    org: string
}

export const getGeoInfo = (callback: Function) => {
    fetch('https://ipapi.co/json/').then((res) => res.json()).then((response) => {
        callback(response)
    }).catch(err => {
        callback(undefined)
    })
}

export const getGeoData = () => new Promise<GeoData>(
    function (resolve, reject) {
        getGeoInfo(function (data: GeoData | undefined) {
            if (data) {
                resolve(data)
            } else {
                reject('GeoInfo is undefined')
            }
        })
    }
)