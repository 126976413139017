import { SocketConstructable, SocketInterface } from "./socket-interface";
import { Chat, ChatDatabase, Message } from "./database";
import { EventEmitter } from "eventemitter3";
import * as uuid from "uuid";
import { OutgoingPacketEventType, IncomingPacketEventType, Content } from "./events";
import { FCError } from "fullcircle-api";

/**
 * These options are used when creating ChatClients
 */
export interface ChatClientOptions {
    /**
     * The url of the remote server
     */
    url: string;
    /**
     * The token to use for authenticatino
     */
    auth_token: string;

    userId: string
}

/**
 * This class is a fully contained counterpart to the full-circle-chat-server and is to be used as the datasource for an in-app chat
 */
export class ChatClient extends EventEmitter {

    private requestListeners: { [key: string]: (error?: any, data?: any) => void };
    private messageUpdatedListener: { [key: string]: (message: Message) => void };
    private chatUpdatedListener: { [key: string]: (chat?: Chat) => void };
    private socket: SocketInterface;
    private database: ChatDatabase;

    /**
     * Sets up a new chatclient with the contained db. It automatically reconnects, authenticates and fetches the latest messages
     * @param {SocketConstructable} socket - The correct socket.io implementation for the platform
     * @param {ChatClientOptions} options - The options to use when creating the chat client
     */
    constructor(socket: SocketConstructable, options: ChatClientOptions) {
        super();
        let self = this;
        this.requestListeners = {};
        this.messageUpdatedListener = {};
        this.chatUpdatedListener = {}

        this.database = new ChatDatabase(options.userId);
        this.socket = new socket(options.url);
        this.socket.connect();
        this.socket.on('connect', () => {
            self.emit('connect');
            this.socket.emit(OutgoingPacketEventType.Authenticate, {
                'auth_token': options.auth_token,
                'request_id': 'AUTHENTICATION'
            });
        });
        this.socket.on('request_response', (data) => {
            if (data.request_id === 'AUTHENTICATION') {
                self.emit('authenticated', data);
                this.sendRequest<any>(OutgoingPacketEventType.GetMessages, {
                    since: this.database.latestDate(),
                    include_own: true
                }).then((messages: any[]) => {
                    this.database.saveMessages(messages);
                    this.chatDidUpdate() // initial load, update chats
                }).catch((error) => {
                    self.emit('error', error);
                });
            } else if (this.requestListeners[data.request_id]) {
                this.requestListeners[data.request_id](null, data.data)
            }
        });
        this.socket.on('request_error', (data) => {
            if (data.request_id === 'AUTHENTICATION') {
                self.emit('authentication_failed', data);
            } else {
                if (this.requestListeners[data.request_id]) {
                    this.requestListeners[data.request_id](data, null)
                }
            }
        });

        this.socket.on(IncomingPacketEventType.NewMessage, (data) => {
            let messages = this.database.saveMessages([data]);
            this.messagesDidUpdate(messages)
        });
        this.socket.on(IncomingPacketEventType.MessageUpdated, (data) => {
            this.database.saveMessages([data])
            this.messagesDidUpdate([data])
        });
        this.socket.on(IncomingPacketEventType.MessagesUpdated, (data) => {
            this.database.saveMessages(data)
            this.messagesDidUpdate(data)
        });
        this.socket.on(IncomingPacketEventType.OnlineStatusChange, (data) => {
            this.emit('online-status', data)
        });
        this.socket.on('disconnect', () => {
            self.emit('disconnect');
        });
    }

    private messagesDidUpdate(messages: Message[]) {
        if (messages.length >= 1) {
            Object.keys(this.messageUpdatedListener).forEach((token) => {
                this.messageUpdatedListener[token](messages[0]);
            })
        }
    }

    private chatDidUpdate(chat?: Chat) {
        Object.keys(this.chatUpdatedListener).forEach((token) => {
            this.chatUpdatedListener[token](chat);
        })
    }

    /*
     * Request listeners
     */

    /**
     * This function sends a request and
     * @param {IncomingPacketEventType} channel
     * @param content
     * @returns {Promise<T>}
     */
    private sendRequest<T>(channel: OutgoingPacketEventType, content: any): Promise<T> {
        let requestID = uuid.v1();
        let promise = this.addRequestListener<T>(requestID);
        this.socket.emit(channel, Object.assign({}, content, { request_id: requestID }));
        return promise;
    }

    /**
     * This function adds an automatic request listener with a timeout
     * @param request_id
     * @param {number} timeout
     * @returns {Promise<T>}
     */
    private addRequestListener<T>(request_id: string, timeout: number = 25): Promise<T> {
        return new Promise((resolve, reject) => {
            let timer = setTimeout(() => {
                if (this.requestListeners[request_id]) {
                    try {
                        this.requestListeners[request_id](new Error('Timeout'))
                    } catch (error) {

                    }
                }
                delete this.requestListeners[request_id];
            }, timeout * 1000);
            this.requestListeners[request_id] = (error?: any, data?: T) => {
                clearTimeout(timer);
                if (data) {
                    resolve(data)
                } else if (error) {
                    reject(error)
                } else {
                    reject(new FCError('An unknown error occurred'));
                }
                delete this.requestListeners[request_id]
            }
        })
    }

    /*
     * Public actions
     */

    /**
     * This function sends a message to a chat
     * @param {string} chat
     * @param {string} content
     * @returns {Promise<Message>}
     */
    public sendMessage(chat: string, content: string, to_user_id: string, type: string): Promise<Message> {
        return this.sendRequest(OutgoingPacketEventType.SendMessage, {
            chat: chat,
            content: content,
            type,
            with_user: to_user_id
        }).then((messageData) => {
            let message = this.database.saveMessages([messageData])[0]
            this.messagesDidUpdate([message])
            return message
        })
    }

    public markMessageRead(message_id: string): Promise<Message> {
        return this.sendRequest<any[]>(OutgoingPacketEventType.MessageRead, {
            id: message_id
        }).then((messageData) => {
            let messages = this.database.saveMessages(messageData)
            this.messagesDidUpdate(messages)
            return messages[0]
        })
    }

    public markMessagesRead(message_ids: string[]): Promise<Message[]> {
        return this.sendRequest<any[]>(OutgoingPacketEventType.MessagesRead, {
            ids: message_ids
        }).then((messageData) => {
            let messages = this.database.saveMessages(messageData)
            this.messagesDidUpdate(messages)
            return messages
        })
    }

    public getChats() {
        return this.database.getChats()
    }

    public getMesages() {
        return this.database.getMessages()
    }

    /**
     * This function disconnects the chat socket
     */
    public disconnect() {
        return this.socket.disconnect();
    }

    /**
     * This function completely shuts down the client
     */
    public shutdown() {
        return this.socket.disconnect();
    }

    /**
     * This function connects the chat socket again
     */
    public connect() {
        this.socket.connect();
    }

    public chats(): Array<Chat> {
        return this.database.getChats();
    }

    public deleteChat(chat: Chat) {
        this.database.deleteChat(chat);
        this.chatDidUpdate()
    }

    /*
     * Public listeners
     */

    public addMessageUpdatedListener(listener: (message: Message) => void): string {
        let token = uuid.v1();
        this.messageUpdatedListener[token] = listener;
        return token;
    }

    public removeMessageUpdatedListener(token: string) {
        delete this.messageUpdatedListener[token];
    }

    public addChatUpdatedListener(listener: (chat?: Chat) => void): string {
        let token = uuid.v1();
        this.chatUpdatedListener[token] = listener;
        return token;
    }

    public removeChatUpdatedListener(token: string) {
        delete this.chatUpdatedListener[token];
    }

    public subscribeOnlineStatus(user_id: string): Promise<Content.Incoming.OnlineStatusDidChange> {
        return this.sendRequest(OutgoingPacketEventType.SubscribeOnlineStatus, {
            user_id: user_id
        })
    }

    public unSubscribeOnlineStatus(user_id: string): Promise<{}> {
        return this.sendRequest(OutgoingPacketEventType.UnsubscribeOnlineStatus, {
            user_id: user_id
        })
    }

}
