import { Post, User } from "fullcircle-api";
import { FilterSettings } from "../components/search/filtersettings";

export function getPostUrl(post: { id: string }) {
    return `/post-detail?postId=${post.id}`
}

export function getUserUrl(user: User) {
    return `/profile/${user.id}`
}
type Config = (FilterSettings & { search: string })
export function getSearchUrlConfig(filter: Partial<Config>, currentState?: {} | null): [string, Object] {
    return ['/search', Object.assign({}, (currentState || {}), { ...filter })]
}