import { DeliveryRequest, DeliveryRequestStatus, Post, User } from "fullcircle-api"
import { VariantOption } from "fullcircle-api/lib/models/post"
import moment from "moment"
import * as money from 'money-math'
import { DeliveryState } from "../pages/Checkout"

export function getPropertiesForCarsForSale() {
    const REQUIRED = ['VIN',
        'Condition',
        'Mileage',
        'Vehicle Title']
    const OPTIONAL = ['Color Exterior', 'Color Interior', 'Transmission', 'Fuel Type', 'Engine']
    return {
        REQUIRED,
        OPTIONAL
    }
}
export function getKeyForProperty(property: string) {
    return property.replace(/ /g, '_').toLowerCase()
}

export function calculateTotal(post: Post, method: DeliveryState.DELIVER | DeliveryState.PICK_UP, quantity: number, variantsSelected: string[], shipping_rate?: string, tax?: string) {
    let price = post.price
    if (variantsSelected && variantsSelected.length) {
        const variantPricing = post.variants?.pricing.find((p) => compareVariantArray(p.options, variantsSelected));
        if (variantPricing && variantPricing.price) {
            price = variantPricing.price;
        }
    }
    let amount = price
    for (let index = 1; index < quantity; index++) {
        amount = money.add(amount, price)
    }
    if (tax) {
        amount = money.add(amount, tax)
    }
    if (method == DeliveryState.DELIVER && !post.calculate_shipping_price_at_checkout && post.shipping_price && post.shipping_price > '0.00') {
        for (let index = 0; index < quantity; index++) {
            amount = money.add(amount, post.shipping_price)
        }
    }
    if (method == DeliveryState.DELIVER && post.calculate_shipping_price_at_checkout && shipping_rate) {
        for (let index = 0; index < quantity; index++) {
            amount = money.add(amount, shipping_rate)
        }
    }
    return amount
}

export function calculateShipping(quantity: number, shipping_rate: string) {
    let amount = shipping_rate

    for (let index = 1; index < quantity; index++) {
        amount = money.add(amount, shipping_rate)
    }

    return amount
}

function getRequestPriority(r1: DeliveryRequest, user: User) {
    if (r1.buyer?.id == user.id) {
        switch (r1.status) {
            case DeliveryRequestStatus.Unpaid:
                return 20
            case DeliveryRequestStatus.InDelivery:
                return 10
            case DeliveryRequestStatus.Rated_Buyer:
                return 5
            case DeliveryRequestStatus.Pending:
                return 4
            default:
                return 1
        }
    } else {
        switch (r1.status) {
            case DeliveryRequestStatus.Pending:
                return 20
            case DeliveryRequestStatus.Received:
                return 10
            case DeliveryRequestStatus.Rated_Seller:
                return 5
            case DeliveryRequestStatus.InDelivery:
                return 4
            default:
                return 1
        }
    }
}

export function sortDeliveryRequests(requests: DeliveryRequest[], user: User) {
    return requests.sort((r1, r2) => {
        const p1 = getRequestPriority(r1, user)
        const p2 = getRequestPriority(r2, user)
        if (p1 == p2) {
            return new Date(r2.created_at).getTime() - new Date(r1.created_at).getTime()
        }
        return p2 - p1
    })
}

export function isPostFeatured(post: Post) {
    return post.featured_until ? moment(post.featured_until).isAfter() : false
}

export function isPostFeaturedEnding(post: Post) {
    return isPostFeatured(post) && post.featured_until ? moment(post.featured_until).isBefore(moment().add(-1, 'day')) : false
}

export function compareVariantArray(var1: string[], var2: string[], variantsRequired?: number) {
    if (variantsRequired != undefined && var2.length != variantsRequired) {
        return false;
    }
    return var1.length === var2.length && var1.every((value) => var2.includes(value))
}