import React from "react";
import { User } from "fullcircle-api";
import Carousel from "./Carousel"
import { t } from "i18next";
import CarouselItem from "./Carouseltem";

export default class UserCarousel extends Carousel<User> {

    renderItems(items: User[]): React.ReactElement[] {
        return items.map((user) => {
            return (
                <CarouselItem
                    key={user.id} alt={user.full_name}
                    title={user.full_name} centerTitle
                    imageUrl={user.profile_image_medium_url}
                    onItemClick={() => this.props.onItemClick && this.props.onItemClick(user)}
                />
            )
        })
    }

    getEmptyPlaceholder(): string {
        return t("Messages.NO_USERS")
    }
}

