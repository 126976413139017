import * as React from "react";
import { Favorite } from "../../cache/favorite";
import { SavedSearch, PostsAPI } from "fullcircle-api";
import { ModFindLoader } from "../generic/ModFindLoader";
import { SeparatorBox } from "../generic/Box";
import { CLOSE_BLUE } from "../../assets";
import { prepareSearchParams, searchParamToFilterSettings, FilterSettings } from "../search/filtersettings";
import { t } from "i18next";

export class ModFindSavedSearches extends React.Component<{
    onViewResults: (filterSettings: FilterSettings, search: string) => void
    onRemove: (search: SavedSearch) => void
}, { searches?: SavedSearch[], searchCounts: { [key: string]: number }, confirmPopup?: React.ReactChild }> {

    constructor(p: any) {
        super(p);
        this.state = {
            searchCounts: {}
        }
    }

    componentDidMount() {
        Favorite.getSavedSearches().then(searches => {
            this.setState({ searches: searches })
            Promise.all(searches.map(search => {
                return PostsAPI.searchPostCount(prepareSearchParams(searchParamToFilterSettings(search.content), search.content.search) as any).then(({ count }) => {
                    return { id: search.id, count }
                })
            })).then((items) => {
                this.setState({
                    searchCounts: items.reduce((prev, cur) => {
                        prev[cur.id] = cur.count
                        return prev
                    }, {})
                })
            })
        })
    }

    render() {
        const searches = this.state.searches
        if (searches && searches.length == 0) {
            return <div className='saved-searches action-list'><h5>{t("Messages.NO_SAVED_SEARCHES")}</h5></div>
        }
        if (!searches) return (
            <div className='saved-searches action-list'>  <ModFindLoader /></div>
        )
        return <div className='saved-searches action-list'>
            {this.state.confirmPopup != undefined && this.state.confirmPopup}
            {searches.map(search => {
                return <div className='saved-search' key={search.id}>
                    <SeparatorBox direction={'row'}>
                        <span className='item-close' onClick={() => {
                            this.props.onRemove(search)
                        }}>
                            <img src={CLOSE_BLUE} />
                        </span>
                        <span className='item center'>
                            {search.title}
                        </span>
                        <span className='item right blue' onClick={() => {
                            this.props.onViewResults((searchParamToFilterSettings(search.content)), search.content.search)
                        }}>
                            View {this.state.searchCounts[search.id] == undefined ? '…' : this.state.searchCounts[search.id]} Results
                        </span>
                    </SeparatorBox>
                </div>
            })}
        </div>
    }
}