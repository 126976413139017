import * as React from 'react'
import ModFindInput, { ModFindInputProps } from './ModFindInput';
import { formatPriceI18n, unformatPriceI18n } from '../../utils/formater';

export interface ModFindCurrencyInputProps extends ModFindInputProps {
    currency: string
    allowEmpty?: boolean
}

export const ModFindCurrencyInput: React.FC<ModFindCurrencyInputProps> = (props) => {

    const [value, setValue] = React.useState<string | number | string[] | undefined>(props.value)
    const [focused, setFocused] = React.useState<boolean>(false)

    let priceInput: ModFindInput | null = null

    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <ModFindInput
            {...props}
            ref={(ref) => priceInput = ref}
            value={value}
            onFocus={() => {
                if (value == undefined) {
                    setFocused(true)
                    return
                }
                setValue(unformatPriceI18n(value, props.currency));
                setFocused(true)
                props.onFocus && props.onFocus()
            }}
            onBlur={() => {
                if (focused) {
                    if ((value === '' || !value) && props.allowEmpty) {
                        setValue('')
                        props.onValueChanged && props.onValueChanged('')
                    } else if (value != undefined) {
                        let formatedPrice = formatPriceI18n(value, props.currency)
                        setValue(formatedPrice)
                        setFocused(false)
                        priceInput && priceInput.setText(formatedPrice)
                        props.onValueChanged && props.onValueChanged(formatedPrice)
                    }
                }
                props.onBlur && props.onBlur()
            }}
            onValueChanged={(text) => {
                if (!focused) {
                    if ((!value || value === '') && props.allowEmpty) {
                        setValue('')
                    } else if (value) {
                        setValue(formatPriceI18n(value, props.currency))
                        props.onValueChanged && props.onValueChanged(formatPriceI18n(value, props.currency))
                    }
                } else {
                    setValue(text)
                }
            }}
        />
    )
}