import * as React from "react"
import { Colors } from "../../../styles/colors"
import Button from "../../generic/Button"

import { Region, Currency, MetaAPI } from "fullcircle-api"
import ModFindDropDown from "../../dropdown/ModFindDropDown"
import { DropdownItem } from "reactstrap"
import { PartialLocation, getGeoData } from "../../../utils/location"
import { ZipCodeInput } from "../../generic/ZipCodeInput"
import { t } from "i18next"

export class SignUpSelectCurrencyAndRegion extends React.Component<{
    onSelection: (region: Region, currency: Currency, location: PartialLocation) => void
    onCancel: () => void,
}, {
    region?: Region;
    currency?: Currency;
    location?: PartialLocation;
    regions?: Region[];
    currencies?: Currency[];
    loadingDropdown: boolean;
}> {

    constructor(props: any) {
        super(props)
        this.state = {
            loadingDropdown: true
        }
    }

    componentDidMount() {
        Promise.all([
            MetaAPI.getRegions(), MetaAPI.getCurrencies(), getGeoData().catch(() => undefined)
        ]).then(([regions, currencies, geoData]) => {
            const regionCode = geoData?.country_code || 'United States';
            const region = regions!.find(r => r.code.toLowerCase() === regionCode.toLowerCase());
            const currencyCode = geoData?.currency || 'usd';
            const currency = currencies!.find(c => c.code.toLowerCase() === currencyCode.toLowerCase());
            this.setState({
                region,
                currency,
                regions,
                currencies,
                loadingDropdown: false
            });
        });
    }

    render() {
        return (
            <>
                <ModFindDropDown value={this.state.loadingDropdown ? '-' : this.state.region ? this.state.region.name : 'United States'} textColor={'black'} style={{ backgroundColor: Colors.LIGHT_GREY, marginBottom: 4 }} rounded>
                    {(this.state.regions || []).map((region) => {
                        return (<DropdownItem key={region.name} onClick={() => {
                            let currency = this.state.currency
                            if (region) {
                                currency = this.state.currencies!.find(cr => cr.code == region.currency.toLowerCase())
                            }
                            this.setState({ region, currency });
                        }}>{region.name}
                        </DropdownItem>);
                    })}
                </ModFindDropDown>
                <ModFindDropDown value={this.state.loadingDropdown ? '-' : this.state.currency ? this.state.currency.code.toUpperCase() : 'USD'} textColor={'black'} style={{ backgroundColor: Colors.LIGHT_GREY, marginBottom: 4 }} rounded>
                    {(this.state.currencies || []).map((currency) => {
                        return (<DropdownItem key={currency.code} onClick={() => {
                            this.setState({ currency });
                        }}>{currency.code.toUpperCase()}
                        </DropdownItem>);
                    })}
                </ModFindDropDown>
                <ZipCodeInput region={this.state.region?.code} onLocation={(location) => {
                    this.setState({ location })
                }} />
                <div style={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
                    <Button text={t("Messages.SIGN_UP")} size={'lg'} rounded disabled={!Boolean(this.state.currency && this.state.region && this.state.location)}
                        onClick={() => { this.props.onSelection(this.state.region!, this.state.currency!, this.state.location!) }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
                    <Button text={t("Messages.CANCEL")} size={'sm'} rounded style={{ backgroundColor: Colors.GREY }}
                        onClick={() => { this.props.onCancel() }} />
                </div>
            </>
        )
    }
}