import React from "react";
import { User, UsersAPI } from "fullcircle-api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as toastr from 'toastr';
import { Container } from "reactstrap";
import { SeparatorBox } from "../components/generic/Box";
import ModFindInput from "../components/generic/ModFindInput";

import { StaticSourceDropDown } from "../components/dropdown/StaticSourceDropDown";
import { Colors } from "../styles/colors";
import Button from "../components/generic/Button";
import { errorToString } from "../utils/helper";
import ModFindTextarea from "../components/generic/ModFindTextArea";
import Autocomplete from 'react-google-autocomplete';
import { API_KEY, GoogleDetails } from "../utils/location";
import { Popup } from "semantic-ui-react";
import { UserContextType } from "../context/user";
import { UserSession } from "../utils/session";
import { t } from "i18next";

export const BecomeDealerVendor = withRouter(class extends React.Component<RouteComponentProps<{}, {}, { type?: 'dealer' | 'vendor' }>, {
    updating: boolean,
    company: string,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    website?: string,
    location?: string
    instagram?: string
    description: string,
    type: 'vendor' | 'dealer',
    valid: boolean
    logo?: File
    logoBuffer?: string
}> {

    private fileInput?: HTMLInputElement | null;
    static contextType = UserContextType
    context!: User
    prevContext: User | undefined

    constructor(p: RouteComponentProps<{}, {}, { type?: 'dealer' | 'vendor' }>) {
        super(p);
        this.state = {
            updating: false,
            type: p.location?.state?.type || 'dealer',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            description: '',
            company: '',
            valid: false
        }

        this.validateForm = this.validateForm.bind(this)
        this.prevContext = this.context
    }

    updateContext() {
        if (this.context?.business) {
            this.setState({
                first_name: this.context.business.contact_first_name,
                last_name: this.context.business.contact_last_name,
                email: this.context.business.contact_email,
                phone: this.context.business.contact_phone,
                company: this.context.business.name,
                description: this.context.bio || '',
                type: this.context.business.type,
                logoBuffer: this.context.profile_image_medium_url,
                website: this.context.business.website,
                location: this.context.business.location_address,
                instagram: this.context.instagram_handle
            }, this.validateForm)
        } else if (this.context) {
            this.setState({
                email: this.context.email || '',
                phone: this.context.phone_number || '',
                instagram: this.context.instagram_handle,
                first_name: this.context.full_name.split(' ')[0],
                last_name: this.context.full_name.split(' ').pop() || ''
            })
        }
    }

    componentDidUpdate() {
        if (this.context != this.prevContext) {
            this.updateContext()
        }
        this.prevContext = this.context
    }

    private validateForm(setState: boolean = true) {
        let validOrError: string | true = true
        if (!validateString(this.state.company)) {
            validOrError = t("ErrorMessages.BecomeVendorDealer.CompanyNameMissing") as string
        } else if (!validateString(this.state.description, 5)) {
            validOrError = t("ErrorMessages.BecomeVendorDealer.CompanyDescriptionMissing") as string
        } else if (!(validateString(this.state.first_name) && validateString(this.state.last_name) && validateString(this.state.email) && validateString(this.state.phone))) {
            validOrError = t("ErrorMessages.BecomeVendorDealer.ContactDataMissing") as string
        } else if (!this.state.logo && !this.context?.business) {
            validOrError = t("ErrorMessages.BecomeVendorDealer.LogoMissing") as string
        }
        let newValid = validOrError === true ? true : false
        if (setState && this.state.valid != newValid) {
            this.setState({ valid: newValid })
        }
        return validOrError
    }

    render() {
        return <Container>
            <div className='become-dealer-vendor action-list'>
                <input ref={(ref) => this.fileInput = ref} accept='image/png, image/gif, image/jpeg' type='file'
                    style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', top: -5 }} onChange={(e) => {
                        let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                        if (file) {
                            var fr = new FileReader();
                            fr.onload = () => {
                                this.setState({
                                    logo: file, logoBuffer: fr.result as string
                                }, () => {
                                    this.validateForm()
                                })
                            }
                            fr.readAsDataURL(file);
                        } else {

                        }
                        e.target.value = ''
                    }} />
                <h3 style={{ marginTop: 20, textAlign: 'center', marginBottom: '2rem' }}>{t("Messages.BECOME_DEALER_VENDOR")}</h3>
                <p>{t("Messages.BECOME_DEALER_VENDOR_TEXT_L1")}</p>
                <p>{t("Messages.BECOME_DEALER_VENDOR_TEXT_L2")}</p>
                <p>{t("Messages.BECOME_DEALER_VENDOR_TEXT_L3")}</p>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.TYPE")}*
                    </div>
                    <div className='item'>
                        <StaticSourceDropDown selected={[{ id: this.state.type, name: '' }]} style={{ backgroundColor: Colors.LIGHT_GREY, maxHeight: 42, minHeight: 0 }}
                            items={[{ id: 'dealer', name: 'Car Dealer' }, { id: 'vendor', name: 'Parts Vendor' }]}
                            onSelectedItemsChange={(item) => {
                                this.setState({ type: item[0].id as 'dealer' }, () => {

                                })
                            }} dropDownOptions={{ search: false }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.COMPANY_NAME")}*
                    </div>
                    <div className='item'>
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.COMPANY_NAME")}
                            colored
                            rounded
                            style={{ maxWidth: 300 }}
                            value={this.state.company}
                            onValueChanged={(value) => {
                                this.setState({ company: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item'>
                        {t("Messages.COMPANY_DESC")}*
                    </div>
                    <ModFindTextarea disabled={this.state.updating}
                        placeholder={t("Messages.COMPANY_DESC")} colored style={{ maxWidth: 300 }}
                        value={this.state.description || ''}
                        onValueChanged={(value) => {
                            this.setState({ description: value }, () => {
                                this.validateForm()
                            })
                        }} />
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.CONTACT")}*
                    </div>
                    <div className='item' style={{ maxWidth: 300 }}>
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.FIRST_NAME")}
                            colored
                            rounded
                            value={this.state.first_name}
                            onValueChanged={(value) => {
                                this.setState({ first_name: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.LAST_NAME")}
                            colored
                            rounded
                            style={{ marginTop: 12 }}
                            value={this.state.last_name}
                            onValueChanged={(value) => {
                                this.setState({ last_name: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.EMAIL")}
                            colored
                            rounded type={'email'}
                            style={{ marginTop: 12 }}
                            value={this.state.email}
                            onValueChanged={(value) => {
                                this.setState({ email: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.PHONE")}
                            colored
                            rounded
                            style={{ marginTop: 12 }}
                            value={this.state.phone}
                            onValueChanged={(value) => {
                                this.setState({ phone: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.INSTA")}
                    </div>
                    <div className='item'>
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.INSTA")}
                            colored
                            rounded
                            style={{ maxWidth: 300 }}
                            value={this.state.instagram || ''}
                            onValueChanged={(value) => {
                                this.setState({ instagram: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.WEBSITE")}
                    </div>
                    <div className='item'>
                        <ModFindInput
                            disabled={this.state.updating}
                            placeholder={t("Messages.WEBSITE")}
                            colored
                            rounded
                            style={{ maxWidth: 300 }}
                            value={this.state.website || ''}
                            onValueChanged={(value) => {
                                this.setState({ website: value }, () => {
                                    this.validateForm()
                                })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.LOCATION")}
                    </div>
                    <div className='item'>
                        <Autocomplete apiKey={API_KEY}
                            options={{ types: ['address'] }}
                            style={{
                                width: '100%',
                                maxWidth: 300,
                                height: '42px',
                                padding: '8px 16px',
                                backgroundColor: Colors.LIGHT_GREY,
                                borderRadius: 4
                            } as React.CSSProperties}
                            onPlaceSelected={(details: GoogleDetails) => {
                                this.setState({ location: details.formatted_address })
                            }} />
                    </div>
                </SeparatorBox>
                <SeparatorBox direction='row'>
                    <div className='item' style={{ display: 'flex', alignItems: 'center' }}>
                        {t("Messages.LOGO")}*
                        {this.state.logoBuffer && <div>
                            <img src={this.state.logoBuffer} style={{ maxWidth: 120, marginTop: 4, marginLeft: 10 }} />
                        </div>}
                    </div>
                    <Button size='sm' rounded disabled={this.state.updating}
                        text={t("Messages.SELECT_LOGO")} onClick={() => {
                            this.fileInput && this.fileInput.click()
                        }} />
                </SeparatorBox>
                <div style={{ marginTop: 20 }}>
                    <Popup content={this.validateForm(false) == true ? undefined : this.validateForm(false)} disabled={this.validateForm(false) === true} trigger={<Button size='lg'
                        rounded disabled={!this.state.valid} loading={this.state.updating}
                        text={this.context?.business ? t("Messages.UPDATE_BUSINESS") : t("Messages.SUBMIT_APPLICATION")} onClick={() => {
                            this.setState({ updating: true })
                            UsersAPI.submitDealerVendorApplication(this.state.type,
                                this.state.company!,
                                this.state.description!,
                                { first_name: this.state.first_name, last_name: this.state.last_name, email: this.state.email, phone: this.state.phone },
                                this.state.logo!, this.state.website, this.state.location, this.state.instagram).then((u) => {
                                    UserSession.getUserUpdatedHandler()(u)
                                    toastr.success(t("Messages.APPLICATION_APPROVED"))
                                    this.props.history.push('/home')
                                }).catch(err => {
                                    toastr.error(errorToString(err).join('\n'))
                                    this.setState({ updating: false })
                                })
                        }} />} />
                </div>
            </div>
        </Container>
    }
})

function validateString(str?: string, minLength: number = 1) {
    return str && str.trim().length >= minLength
}