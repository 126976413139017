import * as React from "react";
import { HelpTopic, MetaAPI, User } from "fullcircle-api";
import { Meta } from "../cache/meta";
import { errorToString } from "../utils/helper";
import toastr from 'toastr'
import { Container, Row, Col } from "reactstrap";
import { ModFindLoader } from "../components/generic/ModFindLoader";
import { Title, SubTitle } from "../components/generic/Title";
import ModFindInput from "../components/generic/ModFindInput";
import ModFindTextarea from "../components/generic/ModFindTextArea";
import Button from "../components/generic/Button";
import { Accordion, Icon } from 'semantic-ui-react';
import { UserContextType } from "../context/user";
import { t } from "i18next";

export class Help extends React.Component<{}, {
    content?: HelpTopic[],
    contactTile: string,
    contactMessage: string,
    contactLoading: boolean,
    activeTopic: number
    activeArticle?: number
    currArticleIndex: number
}> {

    static contextType = UserContextType
    context: User | undefined

    constructor(p: any) {
        super(p);
        this.state = {
            contactTile: '',
            contactMessage: '',
            contactLoading: false,
            activeTopic: -1,
            currArticleIndex: 0
        }
    }

    componentDidMount() {
        Meta.getHelpContent().then((content) => {
            let removeTutorial = content
            removeTutorial[0].articles = removeTutorial[0].articles.filter(item => item.id !== 'tutorial')
            this.setState({ content: removeTutorial })
        }).catch(err => {
            toastr.error(errorToString(err).join('\n'))
        })
    }

    render() {
        let articleSum: number = 0
        return <Container className='help'>
            <div className="headline">
                <span>{t("Messages.HELP_CONTACT")}</span>
            </div>
            {!this.state.content && <ModFindLoader inMain />}
            <Accordion>
                {this.state.content && this.state.content.map((topic, topicIndex, topicArr) => {
                    articleSum += topicIndex === 0 ? 0 : topicArr[topicIndex - 1].articles.length
                    return <div className="topic-container">
                        <Accordion.Title
                            className="topic-title"
                            index={topicIndex}
                            active={this.state.activeTopic === topicIndex}
                            onClick={() => {
                                this.setState((prevState) => ({ activeTopic: prevState.activeTopic === topicIndex ? -1 : topicIndex }))
                            }}
                        >
                            <span>{topic.title}</span>
                            <Icon name={this.state.activeTopic === topicIndex ? 'chevron up' : 'chevron down'} size={'large'} />
                        </Accordion.Title>
                        <Accordion.Content
                            className="article-container"
                            active={this.state.activeTopic === topicIndex}
                        >
                            {topic.articles.map((article, articleIndex) => {
                                let currArticleIndex = articleSum + articleIndex
                                return <>
                                    <Accordion.Title
                                        className="article-title"
                                        index={currArticleIndex}
                                        active={this.state.activeArticle === currArticleIndex}
                                        onClick={() => {
                                            this.setState((prevState) => ({ activeArticle: prevState.activeArticle === currArticleIndex ? - 1 : currArticleIndex }))
                                        }}
                                    >
                                        <span>{article.title}</span>
                                        <Icon name={this.state.activeArticle === currArticleIndex ? 'chevron up' : 'chevron down'} size={'large'} />
                                    </Accordion.Title>
                                    <Accordion.Content
                                        active={this.state.activeArticle === currArticleIndex}
                                        className="article-content"
                                    >
                                        <span>{
                                            <p>{article.content.split('\n').map((content, i) => {
                                                if (content.trim() == '') {
                                                    content = String.fromCharCode(160)
                                                }
                                                return <p key={i}>{content}</p>
                                            })}</p>}
                                        </span>
                                    </Accordion.Content>
                                </>
                            })}
                        </Accordion.Content>
                    </div>
                })}
            </Accordion>
            {this.context && <div className='topic-content'>
                <h4 style={{ marginTop: 20 }}>{t("Messages.CONTACT_US")}</h4>
                <Row>
                    <Col style={{ maxWidth: 400 }}>
                        <ModFindInput value={this.state.contactTile} onValueChanged={(val) => this.setState({ contactTile: val })}
                            placeholder={t("Messages.TITLE_HERE")} colored />
                        <ModFindTextarea value={this.state.contactMessage} onValueChanged={(val) => this.setState({ contactMessage: val })}
                            colored placeholder={t("Messages.ENTER_DESCRIPTION")} style={{ marginTop: 10 }}
                        />
                        <Button text={'Send Report'} size='lg' rounded style={{ marginTop: 20, float: 'right', marginBottom: 40 }} onClick={() => {
                            this.setState({ contactLoading: true })
                            MetaAPI.reportProblem(this.state.contactTile, this.state.contactMessage).then(() => {
                                this.setState({ contactLoading: false, contactMessage: '', contactTile: '' })
                                toastr.success(t("Messages.REPORT_INFO_SHORT"))
                            }).catch(err => {
                                this.setState({ contactLoading: false })
                                toastr.error(errorToString(err).join('\n'))
                            })
                        }} disabled={this.state.contactTile.trim().length == 0 || this.state.contactMessage.trim().length == 0}
                            loading={this.state.contactLoading} />
                    </Col>
                </Row>
            </div>}
        </Container>
    }


    private onTopicClick(index: number) {
        this.setState((prevState) => ({ activeTopic: prevState.activeTopic === index ? - 1 : index }))
    }
}