import { t } from "i18next";
import * as React from "react";
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react'

export interface GenericDropDownWithRequiredProps<T extends { name: string, id: string }> extends GenericDropDownProps<T> {
    apiFunc: () => Promise<T[]>
}

export interface GenericDropDownProps<T extends { name: string, id: string }> {
    dropDownOptions?: DropdownProps

    style?: React.CSSProperties
    className?: string

    selected: T[]
    onSelectedItemsChange: (items: T[]) => void

    allowMultiSelect?: boolean
    disabled?: boolean
    placeholder?: string
}

export class GenericDropDown<T extends { name: string, id: string }> extends React.Component<GenericDropDownWithRequiredProps<T>, {
    items?: T[],
    options: DropdownItemProps[]
}> {
    private reloadTimer?: NodeJS.Timeout;

    constructor(props: GenericDropDownWithRequiredProps<T>) {
        super(props);
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        this.load()
    }

    componentWillUnmount() {
        this.reloadTimer && clearTimeout(this.reloadTimer)
    }

    private load() {
        this.props.apiFunc().then(items => {
            this.setState({ items, options: this.getOptions(items) })
        }).catch(err => {
            this.reloadTimer = setTimeout(() => {
                this.load()
            }, 1000)
        })
    }

    getOptions(items: T[]) {
        return items.map(item => {
            return {
                key: item.id,
                value: item.id,
                text: item.name
            }
        })
    }

    render() {
        const { style, dropDownOptions, className } = this.props
        return <Dropdown options={this.state.options} disabled={this.props.disabled} style={style} className={className}
            value={this.props.allowMultiSelect ? this.props.selected.map(t => t.id) : this.props.selected.length > 0 ? this.props.selected[0].id : ''}
            onChange={(_, { value }) => {
                if (this.props.allowMultiSelect) {
                    this.props.onSelectedItemsChange(this.state.items!.filter(d => (value as Array<string>).includes(d.id)))
                } else {
                    if (value) {
                        this.props.onSelectedItemsChange([this.state.items!.find(d => (value as string) == (d.id))!])
                    } else {
                        this.props.onSelectedItemsChange([])
                    }
                }
            }} multiple={Boolean(this.props.allowMultiSelect)} selection search selectOnBlur={false}
            placeholder={this.props.placeholder || t("Messages.SELECT_HOR")} loading={!this.state.items} {...dropDownOptions} />
    }
}