import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

// Import translations
import translation_en from './locales/en/translation.json';
import translation_de from './locales/de/translation.json';

const resources = {
    en: {
        translation: translation_en
    },
    de: {
        translation: translation_de
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            caches: []
        },
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;