import { Category, PostSortOrder, Brand, PostStatus, SubCategory } from 'fullcircle-api';
import { LocationDetails } from '../../utils/location';
export interface FilterSettings {
    location?: LocationDetails;
    distance?: number;
    categories?: Category[];
    subCategories?: SubCategory[];
    sort: PostSortOrder;
    hasKickback?: boolean;
    brands?: Brand[];
    cars?: string[]
    locationEnabled?: boolean;
    sold?: boolean;
    price_min?: string;
    price_max?: string;
    region?: string;
}

/**
 * Set the default region filter after bootstraping the application to the current users region.
 * Example in React Native:
 * 
 * setDefaultRegion(RNLocalize.getCountryName() || 'United States'),
 * 
 * @param region The region name in english!, like 'United States'
 */
export function setDefaultRegion(region: string) {
    DefaultFilterSettings.region = region;
}

/**
 * Returns default filter settings used when user does not set any filter, and also to calculate the delta between the filters a user has set and the defaults
 */
export const DefaultFilterSettings: FilterSettings & { status: PostStatus } = {
    location: undefined,
    categories: undefined,
    subCategories: undefined,
    brands: undefined,
    hasKickback: undefined,
    sort: PostSortOrder.Newest,
    locationEnabled: false,
    cars: undefined,
    sold: false,
    status: PostStatus.Public,
    price_min: undefined,
    price_max: undefined,
    region: 'All'
}

/**
 * Prepares filter paramters for the API call. The return value can be passed to the API call. Can also be used to store a saved search
 * Example:
 * 
 * const filterSettings = prepareSearchParams(this.props.filterSettings, searchTerm)
 * PostsAPI.searchPosts(this.props.filterSettings ? this.props.filterSettings.sort : PostSortOrder.Newest, this.first, this.first * this.step, filterSettings as any, this.currentPostsSearchToken)
 * 
 * 
 * @param filterSettings FilterSettings
 * @param search string
 */
export function prepareSearchParams(filterSettings?: Partial<FilterSettings>, search?: string) {
    let l = filterSettings && filterSettings.location;
    let location = Object.assign({}, l && { ...l.location, address: l.formatted_address })
    return {
        search,
        location:
            filterSettings &&
                filterSettings.locationEnabled &&
                filterSettings.location &&
                filterSettings.distance &&
                filterSettings.distance != 100
                ? {
                    ...location,
                    distance: filterSettings.distance ? filterSettings.distance * 16.09 : undefined
                }
                : undefined,
        categories: (filterSettings && filterSettings.categories && filterSettings.categories.map((category) => {
            return category.id
        })) || undefined,
        subCategories: (filterSettings && filterSettings.subCategories && filterSettings.subCategories.map((category) => {
            return category.id
        })) || undefined,
        kickback: filterSettings && filterSettings.hasKickback || undefined,
        brands: (filterSettings && filterSettings.brands && filterSettings.brands.map((brand) => {
            return brand.id
        })) || undefined,
        cars: (filterSettings && filterSettings.cars && filterSettings.cars) || undefined,
        sold: filterSettings && filterSettings.sold,
        status: filterSettings && filterSettings.sold ? 'sold' : 'public',
        price_min: (filterSettings && filterSettings.price_min) ? filterSettings.price_min : undefined,
        price_max: (filterSettings && filterSettings.price_max) ? filterSettings.price_max : undefined,
        region: (filterSettings ? filterSettings.region : undefined)
    }
}

/**
 * Returns FilterSettings and search term from a saved search content object.
 * @param params SavedSearch content
 */
export function searchParamToFilterSettings(params: any): FilterSettings & { search: string } {
    return {
        search: params.search as string,
        locationEnabled: params.location != undefined,
        location: params.location,
        distance: (params.location && params.location.distance) ? params.location.distance / 16.09 : undefined,
        categories: params.categories,
        subCategories: params.subCategories,
        hasKickback: params.kickback,
        brands: params.brands,
        cars: (params.cars || []).map(c => c.id),
        sold: params.sold,
        sort: DefaultFilterSettings.sort,
        price_min: params.price_min ? params.price_min : undefined,
        price_max: params.price_max ? params.price_max : undefined,
        region: params.region
    }
}

/**
 * Removes default filter settings from the passed object. Can be used to get the delta of user specified filter settings. 
 * For example Object.keys(searchParamToFilterSettings(settings)).length would return the count of custom filter settings set.
 * Will also be used for the searchPostCount API call.
 * 
 * @param obj any (FilterSettings)
 */
export function removeDefaultFilterSettings(obj: any) {
    let nObj = Object.assign({}, obj)
    Object.keys(nObj).forEach((key) => {
        if ((DefaultFilterSettings as unknown as { [key: string]: string })[key] == nObj[key]) {
            delete nObj[key]
        }
        if (nObj[key] == undefined || (Array.isArray(nObj[key]) && nObj[key].length == 0)) {
            delete nObj[key]
        }
        if (key == 'price_min' || key == 'price_max') {
            if (nObj[key] == '' || nObj[key] == '$') { // ToDo: International
                // delete nObj[key]
            }
        }
    })
    return nObj;
}