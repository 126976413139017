import * as React from "react";
import { GenericDropDown, GenericDropDownProps } from "./GenericDropDown";

export interface StaticSourceDropDownProps<T extends { name: string, id: string }> extends GenericDropDownProps<T> {
    items: T[]
}
export class StaticSourceDropDown<T extends { name: string, id: string }> extends React.Component<StaticSourceDropDownProps<T>, {}> {

    constructor(props: StaticSourceDropDownProps<T>) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <GenericDropDown apiFunc={() => Promise.resolve(this.props.items)}  {...this.props} />
    }
}