import * as React from 'react';
import { IC_CARS_SALE } from '../../assets';
import { formatPriceI18n } from '../../utils/formater';

import { t } from 'i18next';

export default class CarouselItem extends React.Component<{
    title?: string
    alt?: string
    imageUrl?: string;
    fullImage?: boolean;
    centerTitle?: boolean;
    info?: {
        price?: string
        kickbackPrice?: string
        currency: string
    }
    onItemClick?: () => void;
}> {


    render() {
        const { title, imageUrl, fullImage, info, onItemClick, centerTitle } = this.props
        const descrAddStyle = centerTitle ? 'centered ' : '';
        return (
            <div className="modFind-carousel-item-container">
                <div className="modFind-carousel-item-container-ratio">
                    <div className="modFind-carousel-item" onClick={onItemClick}>
                        <div className={"carousel-img-container " + (fullImage ? "full-image" : '')}>
                            <img src={imageUrl || IC_CARS_SALE} alt={this.props.alt} className={"image"} />
                        </div>
                    </div>
                </div>
                {(info != undefined || title != undefined) && <div className={`modFind-carousel-description ${descrAddStyle}`} onClick={onItemClick}>
                    <span className="item-name">{title}</span>
                    {info != undefined && <>
                        {info.price && <span className="price-text">{formatPriceI18n(info!.price, info!.currency)}</span>}
                        {!info.price && <span className="price-text">{t("Messages.PRICE_ON_REQUEST")}</span>}
                        <span className="kickback-text">{info.kickbackPrice ? `${formatPriceI18n(info!.kickbackPrice, info!.currency)} Kickback` : <span>&nbsp;</span>}</span>
                    </>}
                </div>}
            </div>
        )
    }
}