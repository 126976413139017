import * as React from 'react'
import { Category, PaginationResponse, Post, PostDraft, PostStatus, User } from 'fullcircle-api';

import { Meta } from '../../cache/meta';
import { ModFindLoader } from '../generic/ModFindLoader';
import { isCategory, isPost, isUser } from '../../utils/helper';
import { Title } from '../generic/Title';
import { formatPriceI18n } from '../../utils/formater';
import { PROFILE_TITLE_PLACEHOLDER } from '../../assets';
import { isPostFeatured, isPostFeaturedEnding } from '../../utils/post_util';
import { t } from 'i18next';
import { ModFindPaging } from '../generic/ModfindPaging';
import ModFindInput from '../generic/ModFindInput';

export interface ModFindGridProps {
    items?: (Category | Post | User | PostDraft)[]
    loading?: boolean
    type: 'CATEGORIES' | 'POSTS' | 'USERS' | 'DRAFTS'
    empty?: React.ReactNode
    currentUser?: User

    paging?: PaginationResponse;
    onPage?: (page: number) => void;
    onSearchChanged?: (search: string) => void
    onItemClick: (item: User | Post | Category | PostDraft) => void
}

export default class ModFindGrid extends React.Component<ModFindGridProps, {
    items?: (Category | Post | User | PostDraft)[]
    loading: boolean
}> {

    constructor(props: ModFindGridProps) {
        super(props)
        this.state = {
            loading: !!props.loading,
            items: props.items
        }
        this.callApiFunc()
    }

    showPaging() {
        return this.props.paging != undefined
    }

    componentDidUpdate(prevProps: ModFindGridProps) {
        if (this.props.items && prevProps.items != this.props.items) {
            this.setState({ items: this.props.items, loading: false })
        }
    }

    private callApiFunc() {
        if (this.props.loading) {
            if (this.props.items)
                this.setState({ items: this.props.items, loading: false })
        }
        switch (this.props.type) {
            case 'CATEGORIES':
                Meta.getCategories().then((categories) => {
                    this.setState({ items: categories, loading: false })
                }).catch((err) => {
                    this.setState({ loading: false })
                })
        }
    }

    render() {
        return (
            <div className="grid-outer-container">
                <div className="grid-title">
                    <Title>{this.getHeadline()}</Title>
                </div>
                {this.props.loading ?
                    <div>
                        <ModFindLoader />
                    </div>
                    :
                    this.state.items && (this.props.onSearchChanged || this.state.items.length > 0) ?
                        <>
                            {this.props.onSearchChanged && <ModFindInput
                                className='selling-search'
                                placeholder='Search'
                                colored
                                rounded
                                containerSize={'sm'} onValueChanged={(text) => {
                                    this.props.onSearchChanged?.(text)
                                }} />}
                            {this.state.items.length > 0 && <div className="grid-container">
                                {this.renderItems(this.state.items)}
                            </div>}
                            {this.state.items.length > 0 && this.showPaging() && <ModFindPaging paging={this.props.paging!} onPageSelected={(page) => {
                                this.props.onPage?.(page)
                            }} />}
                        </>
                        :
                        <div className="grid-empty-container">
                            {this.props.empty ? this.props.empty : <></>}
                        </div>
                }
            </div>
        )
    }

    getHeadline() {
        switch (this.props.type) {
            case "CATEGORIES":
                return t("Messages.CATEGORIES")
        }
    }

    private renderItems(items: (Category | Post | User | PostDraft)[]) {
        return items.map(item => {
            if (isCategory(item)) {
                return (
                    <ModFindGridItem
                        key={item.id}
                        imageUrl={(item as Category).preview_image_url}
                        name={(item as Category).name}
                        onItemClick={() => {
                            this.props.onItemClick(item)
                        }}
                    />
                )
            } else if (isUser(item)) {
                return (
                    <ModFindContainedGridItem
                        key={item.id}
                        imageUrl={(item as User).profile_image_medium_url || PROFILE_TITLE_PLACEHOLDER}
                        name={(item as User).full_name}
                        onItemClick={() => {
                            this.props.onItemClick(item)
                        }}
                    />
                )
            } else if (isPost(item)) {
                return (
                    <ModFindGridPostItem post={item}
                        key={item.id} currentUser={this.props.currentUser}
                        onItemClick={() => {
                            this.props.onItemClick(item)
                        }}

                    />
                )
            } else {
                const post = item as PostDraft
                const hasImages = (post.post_attributes?.images && post.post_attributes?.images.length > 0)
                const imageUri = (hasImages) ? (post.post_attributes!.images![0].type == 'image' ? post.post_attributes!.images![0].image_url : post.post_attributes!.images![0].thumbnail_url) : undefined
                return (
                    <ModFindContainedGridItem
                        key={item.id}
                        imageUrl={hasImages ? imageUri : undefined}
                        name={item.title}
                        onItemClick={() => {
                            this.props.onItemClick(item)
                        }}
                        price={post.post_attributes.price || undefined}
                        kickbackPrice={post.post_attributes.kickback_price || undefined}
                    />
                )
            }
        })
    }
}



export interface ModFindGridItemProps {
    imageUrl?: string
    name: string
    onItemClick: () => void
    badge?: string;
    price?: string;
    kickbackPrice?: string,
    currency?: string
}


export class ModFindGridItem extends React.Component<ModFindGridItemProps, {}> {

    render() {
        const { imageUrl, name, badge } = this.props
        return (
            <div className="grid-item">
                <div className="grid-item-container" onClick={this.props.onItemClick}>
                    <div className="img-container">
                        <img src={imageUrl} alt={`Image_${name}`} className="grid-item-img" />
                    </div>
                    <span className="grid-item-name">{name}</span>
                    {badge && <span className="grid-item-badge">{badge}</span>}
                </div>
            </div>
        )
    }
}

export class ModFindContainedGridItem extends React.Component<ModFindGridItemProps, {}> {

    render() {
        const { imageUrl, name, badge, price, kickbackPrice, currency } = this.props
        return (
            <div className="grid-item contained">
                <div className="grid-item-container" onClick={this.props.onItemClick} style={{ backgroundImage: `url('${imageUrl}')` }}>
                    <span className="grid-item-name">{name}</span>
                    {badge && <span className="grid-item-badge">{badge}</span>}
                    <div className="grid-item-price">
                        {price && currency && <span className="price">{formatPriceI18n(price, currency)}</span>}
                        {kickbackPrice && currency && <span className="kickback">{formatPriceI18n(kickbackPrice, currency)} Kickback</span>}
                    </div>
                </div>
            </div>
        )
    }
}

export class ModFindGridPostItem extends React.Component<{ post: Post, onItemClick: () => void, currentUser?: User }, {}> {

    render() {
        const item = this.props.post
        const imageUrl = item.images && item.images.length > 0 ? item.images[0].type === 'image' ? item.images[0].medium_url : item.images[0].thumbnail_url : undefined
        const { title, price, kickback_price, currency, user_id } = this.props.post
        let expired = this.props.currentUser && this.props.currentUser.id == item.user_id && (item.expired || item.expires_soon)

        let badge = [PostStatus.Sold, PostStatus.PrivateSold].includes(item.status) ? 'Sold' : undefined
        if (expired) {
            badge = 'Expired'
        }

        const isFeatured = isPostFeatured(this.props.post)
        const isMyPost = this.props.currentUser && this.props.currentUser.id == user_id
        const isFeaturedEnding = isMyPost && isPostFeaturedEnding(this.props.post)


        return (
            <div className="grid-item contained">
                <div className={"grid-item-container" + (isFeatured ? ' featured' : '') + (isFeaturedEnding ? ' expiring' : '')} onClick={this.props.onItemClick} style={{ backgroundImage: `url('${imageUrl}')` }}>
                    <span className="grid-item-name">{title}</span>
                    {badge && <span className="grid-item-badge">{badge}</span>}
                    <div className="grid-item-price">
                        {price && currency && <span className="price">{formatPriceI18n(price, currency)}</span>}
                        {kickback_price && currency && <span className="kickback">{formatPriceI18n(kickback_price, currency)} Kickback</span>}
                    </div>
                </div>
            </div>
        )
    }
}