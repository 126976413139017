import React from "react";
import { Brand, User } from "fullcircle-api";
import Carousel from "./Carousel"
import { t } from "i18next";
import { isUser } from "../../utils/helper";
import UserCarouselItem from "./UserCarouselItem";
import BrandCarouselItem from "./BrandCarouselItem";

export default class UserBrandCarousel extends Carousel<(User | Brand)> {

    renderItems(items: (User | Brand)[]): React.ReactElement[] {
        return items.map((item) => {
            if (isUser(item)) {
                return (
                    <UserCarouselItem currentUser={this.context}
                        key={item.id} user={item}
                        onItemClick={() => this.props.onItemClick && this.props.onItemClick(item)}
                    />
                )
            }
            return (
                <BrandCarouselItem
                    key={item.id}
                    brand={item} currentUser={this.context}
                    onItemClick={() => this.props.onItemClick && this.props.onItemClick(item)}
                />
            )
        })
    }

    getEmptyPlaceholder(): string {
        return t("Messages.NO_ITEMS")
    }
}

