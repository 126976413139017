import * as React from 'react'

export interface ModFindTextareaProps {
    value: string;
    placeholder?: string;
    style?: React.CSSProperties;
    colored?: boolean;
    inputStyle?: React.CSSProperties;
    inputClassName?: string;
    info?: {
        err: boolean,
        text: string
    }
    onValueChanged: (value: string) => void;
    disabled?: boolean;
}

export default class ModFindTextarea extends React.Component<ModFindTextareaProps, {}> {

    constructor(props: ModFindTextareaProps) {
        super(props)
        this.state = {
        }
        this.valueChanged = this.valueChanged.bind(this)
    }

    private valueChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onValueChanged(event.target.value)
    }

    render() {
        const { placeholder, style, colored, inputStyle, info, inputClassName } = this.props
        return (
            <div className={`${colored ? "modFind-textarea-container" : ''}`} style={{ width: "100%", ...style }}>
                <textarea
                    disabled={this.props.disabled}
                    rows={6}
                    className={`modFind-textarea ${inputClassName || ''}`}
                    style={{ ...inputStyle }}
                    value={this.props.value}
                    placeholder={placeholder}
                    onChange={this.valueChanged}
                />
                {info && <span className={`modFind-input-info${info.err ? ' err' : ''}`}>{info.text}</span>}
            </div>

        )
    }
}