import React, { useEffect, useState } from "react";
import { Brand, MetaAPI } from "fullcircle-api";
import Carousel, { CarouselProps } from "./Carousel"
import { t } from "i18next";
import BrandCarouselItem from "./BrandCarouselItem";


export default function BrandCarousel(props: Omit<CarouselProps<Brand>, 'title'> & { title?: string }) {
    const [items, setItems] = useState<Brand[]>()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        MetaAPI.getBrands().then((brands) => {
            setItems(brands)
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
        })
    }, [])

    return <BrandCarouselRenderer items={items} loading={loading} title={t("Messages.MAKES")} {...props} />
}

class BrandCarouselRenderer extends Carousel<Brand> {

    renderItems(items: Brand[]): React.ReactElement[] {
        return items.map((brand) => {
            return (
                <BrandCarouselItem currentUser={this.context}
                    key={brand.id} brand={brand}
                    onItemClick={() => this.props.onItemClick && this.props.onItemClick(brand)}
                />
            )
        })
    }

    getEmptyPlaceholder(): string {
        return t("Messages.NO_BRANDS")
    }
}



