import * as React from 'react'
import ModFindPopUp from './ModFindPopUp';
import { Dropdown } from 'semantic-ui-react'
import Button from '../generic/Button';
import { APIError, DeliveryAPI } from 'fullcircle-api';
import toastr from 'toastr'

import { t } from 'i18next';
import { errorToString } from '../../utils/helper';
import ModFindTextarea from '../generic/ModFindTextArea';

interface ModFindRequestCancelItemPopupProps {
    deliveryId: string
    onClose: () => void
}

export default class ModFindRequestCancelItemPopup extends React.Component<ModFindRequestCancelItemPopupProps, {
    reportIssue: string
    comment: string
    sendReport: boolean
}> {

    constructor(props: ModFindRequestCancelItemPopupProps) {
        super(props);
        this.state = {
            comment: '',
            reportIssue: 'item_sold_out',
            sendReport: false
        }
    }

    options() {
        const ISSUE_DATA = [
            {
                value: 'item_sold_out',
                text: t("Messages.ISSUE_ITEM_SOLD_OUT"),
            },
            {
                value: 'unable_to_ship',
                text: t("Messages.ISSUE_UNABLE_TO_SHIP")
            },
            {
                value: 'wrong_item_ordered',
                text: t("Messages.ISSUE_WRONG_ITEM_ORDERED")
            },
            {
                value: 'other',
                text: t("Messages.ISSUE_OTHER")
            }
        ]
        return { ISSUE_DATA }
    }

    render() {
        const { ISSUE_DATA } = this.options()
        return (
            <ModFindPopUp onClose={() => this.props.onClose()}>
                <div className="modfind-popup-content-wrapper">
                    <span className="modfind-popup-input-title">{t("Messages.ISSUE_CANCEL_REASON")}</span>
                    <Dropdown
                        options={ISSUE_DATA}
                        className={'report-dropdown modfind-dropdown-content-bold'}
                        defaultValue={this.state.reportIssue}
                        onChange={(_, { value }) => {
                            this.setState({ reportIssue: (value as string) })
                        }}
                        fluid
                        selection
                    />
                    <ModFindTextarea disabled={this.state.sendReport}
                        placeholder={t("Messages.ADDITIONAL_COMMENTS")} colored
                        value={this.state.comment || ''}
                        onValueChanged={(value) => {
                            this.setState({ comment: value }, () => {

                            })
                        }} />
                    <div className="separator" />

                    <div className="modfind-popup-info">{t("Messages.ISSUE_CANCEL_ORDER_INFO")}</div>
                    <div className="buttons-container">
                        <Button
                            text={t("Messages.SEND")}
                            size={'sm'}
                            rounded
                            className="left-button"
                            onClick={() => { this.report() }}
                            loading={this.state.sendReport}
                            disabled={this.state.sendReport}
                        />
                        <Button
                            text={t("Messages.CANCEL")}
                            size={'sm'}
                            rounded
                            className="right-button"
                            onClick={() => this.props.onClose()}
                            disabled={this.state.sendReport}
                        />
                    </div>
                </div>
            </ModFindPopUp>
        )
    }

    private report() {
        this.setState({ sendReport: true })
        DeliveryAPI.requestCancelDelivery(this.props.deliveryId, this.state.reportIssue, this.state.comment).then(() => {
            toastr.success(t("Messages.ISSUE_CANCEL_ORDER_INFO"))
        }).catch((e: APIError) => {
            toastr.error(errorToString(e).join('\n'))
        }).finally(() => {
            this.setState({ sendReport: false })
            this.props.onClose()
        })
    }
}